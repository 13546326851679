import request from './request'
import prefix from './prefix'

exports.one = function (suggestionId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/suggestions/${suggestionId}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.submitSuggestion = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/suggestions/')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.fetchSuggestions = function (sortByTrending, sortByUserSchool, page) {
  page = page || 0
  let query = sortByTrending ? '?sortby=netvotes' : '?sortby=created_at'
  query = sortByUserSchool ? query : query + '&allSchools=true'
  query += `&page=${page}`

  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/suggestions' + query).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.fetchUsedSuggestions = function (sortByTrending) {
  const query = sortByTrending ? '?sortby=netvotes' : '?sortby=created_at'

  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/suggestions/used' + query).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.upvote = function (suggestionId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/suggestion/${suggestionId}/upvote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.downvote = function (suggestionId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/suggestion/${suggestionId}/downvote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.novote = function (suggestionId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/suggestion/${suggestionId}/novote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}
