import { ActionTypes } from '../actions'

const defaultState = {
  surveys: null,
  surveyPage: 0,
  endOfPagesReached: false,
}

const AllSurveysReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SURVEYS:
      return Object.assign({}, state, {
        surveys: action.payload,
        endOfPagesReached: false,
      })
    case ActionTypes.APPEND_TO_SURVEYS:
      return Object.assign({}, state, {
        surveys: state.surveys.concat(action.payload),
        endOfPagesReached: !action.payload || !action.payload.length,
      })
    case ActionTypes.INCREMENT_SURVEY_PAGE:
      return Object.assign({}, state, {
        surveyPage: state.surveyPage + 1,
      })
    case ActionTypes.RESET_SURVEY_PAGE:
      return Object.assign({}, state, {
        surveyPage: 0,
        endOfPagesReached: false,
      })
    default:
      return state
  }
}

export default AllSurveysReducer
