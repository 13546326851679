import React from 'react'
import _ from 'lodash'
import { store } from '../../../../../store'

import { connect } from 'react-redux'
import { Checkbox, Button, Header, Form } from 'semantic-ui-react'
import {
  fetchDemographicsOptions,
  setTargetingMechanism,
  toggleAllSchools,
} from '../../../../../actions/create-view-survey-actions/temp-demographics-actions'
import {
  removeTopLevelCategoryFromFaceted,
  toggleDemOptionInFaceted,
  setDemOptionsForCategory,
} from '../../../../../actions/create-view-survey-actions/temp-demographics-actions'
import { fetchSurveyProperty, setSurveyProperty } from '../../../../../../api/admin'

class TargetingContent extends React.Component {
  constructor() {
    super()
    this.state = {
      demographicQuestions: [],
      changesMade: false,
    }
    this.fetchProperty = this.fetchProperty.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
  }
  componentWillMount() {
    if (!this.props.survey) {
      return
    }
    //this.props.fetchDemographicsOptions()
    this.fetchProperty()
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.survey && nextProps.survey) {
      this.fetchProperty()
    }
  }

  fetchProperty() {
    fetchSurveyProperty({
      _id: this.props.survey._id,
      property: 'demographicQuestions',
    }).then(demographicQuestions => {
      this.setState({ demographicQuestions })
      console.log(demographicQuestions)
      if (!demographicQuestions.includes('studentType')) window.alert('uh oh')
    })
  }

  onConfirm() {
    this.setState({ changesMade: false })
    setSurveyProperty({
      _id: this.props.survey._id,
      property: 'demographicQuestions',
      value: this.state.demographicQuestions,
      store: store,
    }).then(resp => {
      this.setState({ demographicQuestions: resp.demographicQuestions })
    })
  }

  render() {
    // Approach:
    // This module will be a standalone one that fetches the property directly from the survey
    // and sets it directly. Instead of updating and saving the monolith survey, we should move
    // towards having individual components that only know about specifically requested parts of
    // the survey.

    const { demOptions } = this.props

    let facet = [
      ...this.props.survey.facetedTargetedDemographics.filter(f => !f.includes(':')),
      ...this.props.survey.inverseFacetedTargetedDemographics.filter(f => !f.includes(':')),
    ]

    return (
      <div className="sidebar-object">
        <Button
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
          color={this.state.changesMade ? 'green' : undefined}
          onClick={this.onConfirm}
        >
          Update
        </Button>
        <Form>
          {Object.keys(demOptions)
            .filter(dem => demOptions[dem].question)
            .map(dem => (
              <Form.Field>
                <Checkbox
                  label={dem}
                  checked={this.state.demographicQuestions.includes(dem)}
                  style={
                    facet.find(f => f === dem)
                      ? this.state.demographicQuestions.includes(dem)
                        ? { opacity: 0.5, pointerEvents: 'none' }
                        : { backgroundColor: '#FF7F7F', padding: '0.25rem' }
                      : {}
                  }
                  onChange={() => {
                    this.setState({ changesMade: true })
                    this.setState({
                      demographicQuestions: _.xor(this.state.demographicQuestions, [dem]),
                    })
                  }}
                />
              </Form.Field>
            ))}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
  school: ((state.user.user.demographics || {}).school || [])[0] || 'Your School',
  demOptions: state.createViewTempDemographics.demOptions,
})

export default connect(mapStateToProps, {
  fetchDemographicsOptions,
  setTargetingMechanism,
  toggleAllSchools,
  removeTopLevelCategoryFromFaceted,
  toggleDemOptionInFaceted,
  setDemOptionsForCategory,
})(TargetingContent)
