import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { editPropertyForQuestionAtIndex } from '../../../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'

class NumericBox extends React.Component {
  constructor(props) {
    super(props)

    this.makeRule = this.makeRule.bind(this)
    this.handleNumericResponseChange = this.handleNumericResponseChange.bind(this)

    this.state = {
      invalidResponse: false,
    }
  }

  makeRule() {
    const question = this.props.questions[this.props.currentQuestionIndex]
    const modifier = question.rangeInc === 1 ? 'whole ' : ''

    if (_.isNil(question.maximum) && _.isNil(question.minimum)) {
      return `Enter a ${modifier}number.`
    } else if (_.isNil(question.maximum)) {
      return `Enter a ${modifier}number greater than or equal to ${question.minimum}.`
    } else if (_.isNil(question.minimum)) {
      return `Enter a ${modifier}number less than or equal to ${question.maximum}.`
    } else {
      return `Enter a ${modifier}number between ${question.minimum} and ${question.maximum}.`
    }
  }

  handleNumericResponseChange(ev) {
    const value = ev.target.value
    const question = this.props.questions[this.props.currentQuestionIndex]
    const hasMax = !_.isNil(question.maximum)
    const hasMin = !_.isNil(question.minimum)
    const noDecimalsAllowed = question.rangeInc === 1

    if (
      !_.isNil(value) &&
      value !== '' &&
      ((hasMax && value > question.maximum) ||
        (hasMin && value < question.minimum) ||
        (noDecimalsAllowed && value % 1 !== 0) ||
        isNaN(value))
    ) {
      this.setState({
        invalidResponse: true,
      })
    } else {
      if (this.state.invalidResponse) {
        this.setState({
          invalidResponse: false,
        })
      }
    }
  }

  render() {
    const question = this.props.questions[this.props.currentQuestionIndex]
    const rule = this.makeRule()

    let placeholder
    if (!_.isNil(question.minimum)) {
      placeholder =
        question.rangeInc === 1 ? question.minimum : parseFloat(question.minimum, 10).toFixed(2)
    } else {
      placeholder = question.rangeInc === 1 ? 0 : '0.0'
    }
    return (
      <div className="numeric-box-container">
        <div className={`prompt-text ${this.state.invalidResponse ? 'red' : ''}`}>{rule}</div>
        <div className="number-input-row">
          <input
            type={this.props.desktopView ? 'text' : 'number'}
            id="numeric-input-box"
            className={`number-box ${this.state.invalidResponse ? 'red' : ''}`}
            placeholder={placeholder}
            onChange={this.handleNumericResponseChange}
            disabled={true}
          />
          {question.unitLabel ? `${question.unitLabel}` : ''}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    questions: state.activeSurvey.questions,
    currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
  }
}

export default connect(mapStateToProps, { editPropertyForQuestionAtIndex })(NumericBox)
