import React from 'react'

export default class NoQuestionsContainer extends React.Component {
  render() {
    return (
      <div className="no-question-container">
        <span>Poll has no content.</span>
        <span>Start by selecting a question template in the right sidebar.</span>
        <span>
          {' '}
          To view all your questions and change settings, select the ‘QUESTIONS’ tab on the sidebar.
        </span>
      </div>
    )
  }
}
