import React from 'react'
import { Elements, StripeProvider } from 'react-stripe-elements'
import SurveyCard from '../../../../../../common/survey-card/survey-card'
import _ from 'lodash'
import PaymentInfo from './PaymentInfo'

import { stripePublicKey } from '../../../../../../lib/constants'
import { connect } from 'react-redux'
import { setActiveSurvey } from '../../../../../actions/create-view-survey-actions/set-survey-actions'
import { exitCreateView } from '../../../../../actions/create-view-navigation-actions'

class ReviewViewContent extends React.Component {
  // do nothing
  setsurvey() {}

  render() {
    if (!this.props.survey.id) {
      return <div />
    }

    let audienceText = ''

    for (var key in this.props.survey.targetedDemographics) {
      if (
        this.props.survey.targetedDemographics.hasOwnProperty(key) &&
        _.isArray(this.props.survey.targetedDemographics[key])
      ) {
        this.props.survey.targetedDemographics[key].map(demOption => {
          audienceText += demOption
          audienceText += ', '
        })
      }
    }

    if (this.props.survey.targetingMechanism === 'individuals') {
      audienceText = 'Specific Individuals  '
    }

    if (audienceText === '') {
      audienceText = 'Everyone'
    } else {
      audienceText = audienceText.slice(0, -2)
    }

    let mcCount = 0,
      openCount = 0,
      numericCount = 0
    this.props.survey.questions.forEach(q => {
      if (q.type === 'mc') {
        mcCount++
      } else if (q.type === 'text') {
        openCount++
      } else if (q.type === 'numeric') {
        numericCount++
      }
    })

    const previewLink = `${window.location.origin}/survey/${this.props.survey.id}`
    const previewDisplayLinkLine1 = `${window.location.origin}/survey/`
    const previewDisplayLinkLine2 = `${this.props.survey.id}`

    return (
      <div className="admin-review-main">
        <div className="survey-preview">
          {/*<StripeProvider apiKey={stripePublicKey}>
            <div className="example">
              <h1>React Stripe Elements Example</h1>
              <Elements>
                <PaymentInfo />
              </Elements>
            </div>
          </StripeProvider>*/}
          <div className={`survey-preview-title ${this.isSafari ? 'safari' : ''}`}>
            <span>Survey Overview</span>
          </div>
          <div className={`survey-info-table ${this.isSafari ? 'safari' : ''}`}>
            <div className="survey-info-row">
              <div className="survey-row-title">SURVEY LINK</div>
              <div className="survey-row-content">
                <a href={previewLink}>
                  {previewDisplayLinkLine1}
                  <br />
                  {previewDisplayLinkLine2}
                </a>
              </div>
            </div>
            <div className="survey-info-row questions">
              <div className="survey-row-title">QUESTIONS</div>
              <div className="survey-row-content">
                <div className="question-count-row bold">
                  <span>Total</span>
                  <span>{this.props.survey.questions.length}</span>
                </div>
                <div className="question-count-row">
                  <span>Multiple Choice</span>
                  <span>{mcCount}</span>
                </div>
                <div className="question-count-row">
                  <span>Open Answer</span>
                  <span>{openCount}</span>
                </div>
                <div className="question-count-row">
                  <span>Numeric</span>
                  <span>{numericCount}</span>
                </div>
              </div>
            </div>
            <div className="survey-info-row">
              <div className="survey-row-title">TARGET AUDIENCE</div>
              <div className="survey-row-content">{audienceText}</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
  viewOnly: state.createViewMeta.viewOnly,
})

export default connect(mapStateToProps, {
  setActiveSurvey,
  exitCreateView,
})(ReviewViewContent)
