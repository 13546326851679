import request from './request'
import prefix from './prefix'

exports.getCampaigns = function (_id) {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/panel/campaigns/for-survey/${_id}`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.getCampaignData = function (name, demo) {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/panel/campaigns/breakdown/${name}/${demo}`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}
