import React, { useEffect, useState } from 'react'
import {
  fetchSurveyProperty,
  setSurveyProperty,
  getOneSchool,
  getSchoolName,
  getUserByEmail,
} from '../../../../../api/admin'
import { store } from '../../../../store'
import _ from 'lodash'
import './AmbassadorView.styl'
import urljoin from 'url-join'
import { json2csv } from 'json-2-csv'
import FileSaver from 'file-saver'

// UI Components
import { Card, Button, Input, Divider, Label, Icon } from 'semantic-ui-react'

const AmbassadorView = ({ _id }) => {
  const [targetedSchools, setTargetedSchools] = useState([])
  const [ambassadors, setAmbassadors] = useState({})
  const [changesMade, setChangesMade] = useState(false)
  const [devMode, setDevMode] = useState(false)
  const [quickAdd, setQuickAdd] = useState('')

  const quickAddFunction = async () => {
    setChangesMade(true)

    let user = await getUser({
      email: quickAdd,
    })

    if (!_.isEmpty(user) && targetedSchools.find(school => school.id === user.school.id)) {
      let currentAmbassadors = { ...ambassadors }
      currentAmbassadors[user.school.id] = [
        ...(currentAmbassadors?.[user.school.id] || []),
        {
          email: user.email,
          incentive: targetedSchools.find(school => school.id === user.school.id).incentive,
          _unique: _.uniqueId(),
          referralId: user.referralId,
          demographics: user.demographics,
          userId: user.id,
        },
      ]
      setAmbassadors(currentAmbassadors)
      setQuickAdd('')
    } else {
      setQuickAdd(!_.isEmpty(user) ? 'School not targeted' : 'User not found')
    }
  }

  const getUser = async ({ email }) => {
    try {
      let { user } = await getUserByEmail(email)
      return user
    } catch (err) {
      console.log(err)
    }
  }

  const getCsv = async () => {
    //['School Name', 'School ID', 'Last Name', 'Incentive Value', 'UTM Link']
    let csvData = []
    Object.keys(ambassadors).forEach(schoolID => {
      !_.isEmpty(ambassadors[schoolID]) &&
        ambassadors[schoolID].forEach(ambassador => {
          csvData.push({
            schoolName: targetedSchools.find(t => t.id === schoolID).name, // School Name
            schoolID: schoolID, // School ID
            email: ambassador.email, // Email
            referralId: ambassador.referralId,
            userId: ambassador.userId,
            incentive: ambassador.incentive, // Incentive Value
            utmLink: urljoin(
              `https://app.collegepulse.com/external-survey/${_id}`,
              `?growthChannel=${ambassador?.referralId || '<REFERRAL_ID>'}`,
              `?schoolName=${encodeURIComponent(
                targetedSchools.find(t => t.id === schoolID).name
              )}`,
              '?snowballsample=true',
              '?ref=snowball-',
              `?sb_convenience=${ambassador.incentive}`
            ),
          })
        })
    })

    json2csv(csvData, (err, csv) => {
      let blob = new Blob(['', csv], { type: 'text/csv;charset=utf-8' })
      FileSaver(blob, 'Ambassador.csv')
    })
  }

  const saveChanges = () => {
    setChangesMade(false)

    setSurveyProperty({
      _id,
      property: 'ambassadorLinks',
      value: ambassadors,
      store,
    })
  }

  useEffect(() => {
    // Get initial data
    getData({ setTargetedSchools, _id, setAmbassadors })
  }, [])

  const toggleSchool = id => {
    if (ambassadors[id]) {
      // Remove ambassador school
      setAmbassadors(c => ({ ...c, [id]: undefined }))
    } else {
      // Add ambassador school
      setAmbassadors(c => ({ ...c, [id]: [] }))
    }
  }

  return (
    <div>
      <Card fluid>
        <Card.Content>
          <Card.Header>Actions</Card.Header>
          <Card.Description>
            <Button disabled={!changesMade} onClick={() => saveChanges()}>
              Save Changes
            </Button>

            <Button
              onClick={() => {
                getCsv()
              }}
            >
              Export CSV
            </Button>

            <Input
              action={{
                labelPosition: 'right',
                icon: 'add',
                content: 'Quick Add Email',
                onClick: () => quickAddFunction(),
              }}
              onChange={e => setQuickAdd(e.target.value)}
              value={quickAdd}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Listing all available schools</Card.Header>
          <Card.Description>
            {targetedSchools &&
              targetedSchools.map(school => (
                <Button
                  color={ambassadors[school.id] ? 'green' : undefined}
                  onClick={() => toggleSchool(school.id)}
                  style={{ marginBottom: '0.25rem' }}
                  key={school.id}
                >
                  {school.name}
                </Button>
              ))}
          </Card.Description>
        </Card.Content>
      </Card>
      {Object.keys(ambassadors).map(
        id =>
          ambassadors[id] && ( // Ensure component gets removed
            <Card fluid key={id}>
              <Card.Content>
                <Card.Header>{targetedSchools.find(t => t.id === id).name}</Card.Header>
                <Card.Description>
                  <div>
                    <div className="ambassador-select-header">
                      <h5>Ambassadors:</h5>
                      <Button
                        onClick={() => {
                          setChangesMade(true)
                          setAmbassadors(c => ({
                            ...c,
                            [id]: [
                              ...c[id],
                              {
                                email: '',
                                incentive: +targetedSchools.find(t => t.id === id).incentive,
                                _unique: _.uniqueId(),
                              },
                            ],
                          }))
                        }}
                      >
                        Add an ambassador
                      </Button>
                    </div>
                    {!_.isEmpty(ambassadors[id]) && (
                      <div className="ambassador-list">
                        {ambassadors[id].map(ambassador => (
                          <div key={ambassador._unique}>
                            <div className="ambassador-list-item">
                              <div style={{ flex: 2 }}>
                                <Input
                                  fluid
                                  icon={{
                                    link: true,
                                    name: 'search',
                                    onClick: async () => {
                                      if (
                                        ambassador.email.length > 5 &&
                                        ambassador.email.includes('@') &&
                                        ambassador.email.includes('.')
                                      ) {
                                        let {
                                          referralId,
                                          demographics,
                                          id: userId,
                                        } = await getUser({
                                          email: ambassador.email,
                                        })

                                        setChangesMade(true)
                                        let current = { ...ambassadors }

                                        // Set referral ID
                                        current[id].find(
                                          a => a._unique === ambassador._unique
                                        ).referralId = referralId

                                        // Set demographics
                                        current[id].find(
                                          a => a._unique === ambassador._unique
                                        ).demographics = demographics

                                        // Set referral ID
                                        current[id].find(
                                          a => a._unique === ambassador._unique
                                        ).userId = userId

                                        setAmbassadors(current)
                                      }
                                    },
                                  }}
                                  value={ambassador.email}
                                  placeholder="Email"
                                  label="Email"
                                  onChange={e => {
                                    setChangesMade(true)
                                    let current = { ...ambassadors }
                                    current[id].find(a => a._unique === ambassador._unique).email =
                                      e.target.value
                                    setAmbassadors(current)
                                  }}
                                />
                              </div>

                              <div>
                                <Input
                                  fluid
                                  value={ambassador.incentive}
                                  placeholder="Incentive"
                                  label="Incentive"
                                  onChange={e => {
                                    setChangesMade(true)
                                    let current = { ...ambassadors }
                                    current[id].find(
                                      a => a._unique === ambassador._unique
                                    ).incentive = +e.target.value
                                    setAmbassadors(current)
                                  }}
                                />
                              </div>

                              <div className="utm-link">
                                <Input
                                  error={
                                    ambassador.email &&
                                    ambassador.referralId &&
                                    ambassador.incentive
                                      ? false
                                      : true
                                  }
                                  fluid
                                  label="UTM Link"
                                  value={urljoin(
                                    `https://app.collegepulse.com/external-survey/${_id}`,
                                    `?growthChannel=${ambassador?.referralId || '<REFERRAL_ID>'}`, //TODO
                                    `?schoolName=${encodeURIComponent(
                                      targetedSchools.find(t => t.id === id).name
                                    )}`,
                                    '?snowballsample=true',
                                    '?ref=snowball-',
                                    `?sb_convenience=${ambassador?.incentive || '<INCENTIVE>'}`
                                  )}
                                />
                              </div>

                              <div style={{ flex: 0 }}>
                                <Button
                                  onClick={() => {
                                    setChangesMade(true)
                                    setAmbassadors(c => ({
                                      ...c,
                                      [id]: c[id].filter(f => f._unique !== ambassador._unique),
                                    }))
                                  }}
                                  className="ambassador-delete-item"
                                >
                                  X
                                </Button>
                              </div>
                            </div>
                            <div className="ambassador-list-item">
                              <Input label="Referral ID" value={ambassador?.referralId || ''} />
                              {ambassador?.demographics?.race?.map(race => (
                                <Label className="demo-icon">
                                  <Icon name="user" /> {race}
                                </Label>
                              ))}
                              {ambassador?.demographics?.gender?.map(gender => (
                                <Label className="demo-icon">
                                  <Icon name="venus mars" /> {gender}
                                </Label>
                              ))}
                              <Input label="User ID" value={ambassador?.userId || ''} />
                            </div>
                            <Divider style={{ marginTop: '2rem' }} />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </Card.Description>
              </Card.Content>
            </Card>
          )
      )}
      <Button onClick={() => setDevMode(c => !c)}>Toggle Dev Mode</Button>
      {devMode && (
        <div>
          Targeted Schools
          <pre>{JSON.stringify(targetedSchools, null, 2)}</pre>
          <br />
          Ambassadors
          <pre>{JSON.stringify(ambassadors, null, 2)}</pre>
        </div>
      )}
    </div>
  )
}

const getData = async ({ setTargetedSchools, _id, setAmbassadors }) => {
  try {
    let referralConfig = await fetchSurveyProperty({
      _id: _id,
      property: 'referralConfig',
    })

    console.log('referralConfig', referralConfig)

    let targetedSchools

    if (referralConfig.all || _.isEmpty(referralConfig)) {
      let facetedTargetedDemographics = await fetchSurveyProperty({
        _id: _id,
        property: 'facetedTargetedDemographics',
      })

      let names = facetedTargetedDemographics
        .filter(x => x.includes('school:'))
        .map(x => x.replace('school:', ''))

      targetedSchools = await Promise.all(
        names.map(async name => {
          let school = await getOneSchool({ name: name })
          return { id: school._id, name }
        })
      )
    } else {
      targetedSchools = await Promise.all(
        Object.keys(referralConfig).map(async id => {
          let name = await getSchoolName(id)
          return { id, name: name[0].name, incentive: referralConfig[id].directIncentiveAmount }
        })
      )
    }

    console.log('targetedSchools', targetedSchools)

    setTargetedSchools(targetedSchools)

    let ambassadorLinks = await fetchSurveyProperty({
      _id,
      property: 'ambassadorLinks',
    })

    if (!_.isEmpty(ambassadorLinks)) {
      Object.values(ambassadorLinks).forEach(school => {
        school.forEach(ambassador => (ambassador._unique = _.uniqueId()))
      })
      setAmbassadors(ambassadorLinks)
    }
  } catch (err) {
    console.error(err.message)
  }
}

export default AmbassadorView
