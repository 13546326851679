import { ActionTypes } from '../actions'

const defaultState = {
  demOptions: {},
  targetedDemographics: {},
  specificFreeInputUsers: '',
}

const CreateViewTempDemographicsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_SELECTED_TEMP_TARGETING_CATEGORY:
      return Object.assign({}, state, {
        selectedCategory: action.payload,
      })
    case ActionTypes.SET_DEMOGRAPHICS_OPTIONS:
      return Object.assign({}, state, {
        demOptions: action.payload.demOptions,
      })
    case ActionTypes.INITIALIZE_TEMP_TARGETED_DEM:
      return Object.assign({}, state, {
        targetedDemographics: action.payload.targetedDemographics,
      })
    case ActionTypes.SET_TEMP_TARGETED_DEM_CATEGORY:
      return Object.assign({}, state, {
        targetedDemographics: Object.assign({}, state.targetedDemographics, {
          [action.payload.category]: action.payload.value,
        }),
      })
    case ActionTypes.INITIALIZE_TEMP_PRICE_BY_DEMOGRAPHICS:
      return Object.assign({}, state, {
        selectedPriceByOptions: action.payload.selectedPriceByOptions,
      })
    case ActionTypes.SET_PRICE_BY_OPTIONS:
      return Object.assign({}, state, {
        selectedPriceByOptions: action.payload.selectedPriceByOptions,
      })
    case ActionTypes.INITIALIZE_TEMP_SPECIFIC_INDIVIDUALS:
      return Object.assign({}, state, {
        specificSelectUsers: action.payload.specificSelectUsers,
        specificFreeInputUsers: action.payload.specificFreeInputUsers,
        specificSelectGroups: action.payload.specificSelectGroups,
      })
    case ActionTypes.SET_SPECIFIC_SELECT_USERS:
      return Object.assign({}, state, {
        specificSelectUsers: action.payload,
      })
    case ActionTypes.SET_SPECIFIC_FREE_INPUT_USERS:
      return Object.assign({}, state, {
        specificFreeInputUsers: action.payload,
      })
    case ActionTypes.SET_SPECIFIC_SELECT_GROUPS:
      return Object.assign({}, state, {
        specificSelectGroups: action.payload,
      })
    default:
      return state
  }
}

export default CreateViewTempDemographicsReducer
