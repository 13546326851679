import { ActionTypes } from '../actions'

const defaultState = {
  viewingAddNew: true,
  displayMcOptionsUnderAddNew: false, // when a new mc question added, show options under the add new tab
}

const CVQuestionsSidebarReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_VIEWING_ADD_NEW:
      return Object.assign({}, state, {
        viewingAddNew: action.payload.viewingAddNew,
      })
    case ActionTypes.SET_DISPLAY_MC_OPTIONS:
      return Object.assign({}, state, {
        displayMcOptionsUnderAddNew: action.payload.displayMcOptionsUnderAddNew,
      })
    default:
      return state
  }
}

export default CVQuestionsSidebarReducer
