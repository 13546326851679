import { ActionTypes } from '../actions'

const defaultState = {
  viewOnly: true,
  reviewingForPublish: false,
}

const CreateViewMetaReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_EDIT_PRIVILEGES:
      return Object.assign({}, state, {
        viewOnly: action.payload.viewOnly,
        reviewingForPublish: action.payload.reviewingForPublish,
      })
    case ActionTypes.STRIPE_TOKEN:
      return Object.assign({}, state, {
        stripeToken: action.payload.token,
      })
    case ActionTypes.HIGHLIGHT_PAYMENT:
      return Object.assign({}, state, {
        highlightPayment: action.payload,
      })
    default:
      return state
  }
}

export default CreateViewMetaReducer
