import React from 'react'

import { connect } from 'react-redux'
import { changeQuestionType } from '../../../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'

class NoTypeBox extends React.Component {
  constructor() {
    super()
    this.selectMessage = this.selectMessage.bind(this)
    this.selectMultipleChoice = this.selectMultipleChoice.bind(this)
    this.selectOpenEnded = this.selectOpenEnded.bind(this)
    this.selectNumeric = this.selectNumeric.bind(this)
  }
  selectMessage() {
    this.props.changeQuestionType({
      index: this.props.currentQuestionIndex,
      question: this.props.questions[this.props.currentQuestionIndex],
      newQuestionType: 'message',
    })
  }
  selectMultipleChoice() {
    this.props.changeQuestionType({
      index: this.props.currentQuestionIndex,
      question: this.props.questions[this.props.currentQuestionIndex],
      newQuestionType: 'mc',
    })
  }
  selectOpenEnded() {
    this.props.changeQuestionType({
      index: this.props.currentQuestionIndex,
      question: this.props.questions[this.props.currentQuestionIndex],
      newQuestionType: 'text',
    })
  }
  selectNumeric() {
    this.props.changeQuestionType({
      index: this.props.currentQuestionIndex,
      question: this.props.questions[this.props.currentQuestionIndex],
      newQuestionType: 'numeric',
    })
  }
  render() {
    return (
      <div className="no-type-container">
        <div className="individual-no-type-container" onClick={this.selectMessage}>
          <img src="/img/add-msg-white.svg" />
          Message
        </div>
        <div className="individual-no-type-container" onClick={this.selectMultipleChoice}>
          <img src="/img/mc-choice.svg" />
          Multiple Choice
        </div>
        <div className="individual-no-type-container" onClick={this.selectOpenEnded}>
          <img src="/img/open-ended-q.svg" />
          Text Entry
        </div>
        <div className="individual-no-type-container" onClick={this.selectNumeric}>
          <img src="/img/numbers.svg" />
          Numeric
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
  currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
})

export default connect(mapStateToProps, { changeQuestionType })(NoTypeBox)
