import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers'
import { createBrowserHistory } from 'history'

export const history = createBrowserHistory()

export const store = createStore(
  reducers(),
  {},
  compose(applyMiddleware(thunk), window.devToolsExtension ? window.devToolsExtension() : f => f)
)

export const increment = () => console.log('hello') //store.dispatch({ type: 'INCREMENT_VERSION' })
