import React from 'react'
import Hammer from 'react-hammerjs'
import Modal from 'react-modal'
import PropTypes from 'prop-types'
import { PulseLoader as Loader } from 'halogenium'
import moment from 'moment'

import api from '../../../api'
import SurveyCard from '../../../common/survey-card/survey-card'
import LongitudinalModal from '../feed-modals/LongitudinalModal'

export default class AdminSurveyCard extends React.Component {
  constructor() {
    super()
    this.state = {
      published: false,
      csvModalShown: false,
      surveyLinkShown: false,
      changeAudienceShown: false,
      emailNonRespondentsShown: false,
      longitudinalShown: false,
      updatingCloseProperty: false,
      updatingPublishedProperty: false,
      nonRespondents: [],
    }

    const boundMethods =
      'openResults openSurvey deleteSurvey duplicateSurvey closeSurvey unCloseSurvey unpublishSurvey checkParticipantsData downloadCSV closeModal showSurveyLink showChangeAudienceModal showEmailNonRespondentsModal openLongitudinalModal getNonRespondents copyToClipboard'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  /*
   * downloads the CSV and opens the modal
   */
  downloadCSV() {
    window.open(`https://csv.collegepulse.com/main/survey/${this.props.survey.id}`, '_blank')

    /* Legacy code, now going to csv domain */
    // this.setState({ csvModalShown: true })
    // api.admin
    //   .downloadResults(this.props.survey.id)
    //   .then(() => {
    //     this.setState({ csvModalShown: false })
    //   })
    //   .catch(err => {
    //     this.setState({ csvModalShown: false })
    //     // TODO: get rid of all alerts, like this one
    //     alert(err)
    //   })
  }

  getNonRespondents(id) {
    api.admin
      .checkParticipantsData(id)
      .then(res => {
        this.setState({ nonRespondents: res.notTaken })
      })
      .catch(err => {
        alert(err)
      })
  }

  showEmailNonRespondentsModal() {
    this.setState({ emailNonRespondentsShown: true })
    this.getNonRespondents(this.props.survey.id)
  }

  openLongitudinalModal() {
    this.setState({ longitudinalShown: true })
  }

  showSurveyLink() {
    this.setState({ surveyLinkShown: true })
  }

  showChangeAudienceModal() {
    this.setState({ changeAudienceShown: true })
  }

  closeModal() {
    this.setState({ csvModalShown: false })
    this.setState({ surveyLinkShown: false })
    this.setState({ changeAudienceShown: false })
    this.setState({ emailNonRespondentsShown: false })
    this.setState({ longitudinalShown: false })
  }

  checkParticipantsData() {
    api.admin.checkParticipantsData(this.props.survey.id).then(data => {
      this.props.showSurveyData(this.props.survey, data)
    })
  }

  publishSurvey() {
    if (!this.state.updatingPublishedProperty && this.props.survey.submittedAt) {
      this.setState({
        updatingPublishedProperty: true,
      })

      api.admin
        .publish(this.props.survey.id)
        .then(() => {
          this.props.refreshSurveys(this.props.user)
          this.setState({
            updatingPublishedProperty: false,
          })
        })
        .catch(() => {
          this.setState({
            published: false,
          })
          //alert(err)
        })
    }
  }

  unpublishSurvey() {
    if (!this.state.updatingPublishedProperty) {
      this.setState({
        updatingPublishedProperty: true,
      })

      api.admin
        .unpublish(this.props.survey.id)
        .then(() => {
          this.props.refreshSurveys(this.props.user)
          this.setState({
            updatingPublishedProperty: false,
          })
        })
        .catch(() => {
          this.setState({
            updatingPublishedProperty: false,
          })
        })
    }
  }

  closeSurvey() {
    if (!this.state.updatingCloseProperty) {
      this.setState({
        updatingCloseProperty: true,
      })
      api.admin
        .close(this.props.survey.id)
        .then(() => {
          this.props.refreshSurveys(this.props.user)
          this.setState({
            updatingCloseProperty: false,
          })
        })
        .catch(err => {
          alert(err)
        })
    }
  }

  unCloseSurvey() {
    if (!this.state.updatingCloseProperty) {
      this.setState({
        updatingCloseProperty: true,
      })
      api.admin
        .unclose(this.props.survey.id)
        .then(() => {
          this.props.refreshSurveys(this.props.user)
          this.setState({
            updatingCloseProperty: false,
          })
        })
        .catch(err => {
          alert(err)
        })
    }
  }

  deleteSurvey() {
    if (
      window.confirm('Are you sure you want to delete this survey? This action cannot be undone')
    ) {
      if (!this.state.published) {
        api.admin
          .deleteSurvey(this.props.survey.id)
          .then(() => {
            this.props.refreshSurveys(this.props.user)
          })
          .catch(() => {
            // alert(err)
          })
      }
    }
  }

  duplicateSurvey() {
    if (window.confirm('Make a copy of this survey?')) {
      if (!this.state.published) {
        api.admin
          .duplicateSurvey(this.props.survey.id)
          .then(survey => {
            this.props.openSurvey(survey.id)
            this.props.refreshSurveys(this.props.user)
          })
          .catch(() => {
            // alert(err)
          })
      }
    }
  }

  openResults() {
    this.props.openResults(this.props.id)
  }

  openSurvey(sueveyId) {
    if (!sueveyId) {
      this.props.openSurvey(this.props.id)
    }
    this.props.openSurvey(sueveyId)
  }

  copyToClipboard() {
    let information = document.querySelector('.modal-button')
    information.addEventListener('click', () => {
      let text = information.previousSibling
      var range = document.createRange()
      range.selectNode(text)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
    })
  }

  renderModal() {
    let modalStyle = {
      content: { top: '36%', bottom: '34%', left: '28%', right: '28%' },
      overlay: { backgroundColor: 'rgba(0,0,0,.4)' },
    }
    let nonRespondentsModalStyle = {
      content: { top: '23%', bottom: '23%', left: '32%', right: '32%' },
      backgroundColor: 'rgba(0, 0, 0, .4)',
    }
    let textAreaStyle = {
      fontSize: '17px',
      color: '#333',
      width: '80%',
    }

    let emailList = (this.state.nonRespondents || []).join('\n')
    return [
      <Modal
        isOpen={this.state.csvModalShown}
        style={modalStyle}
        contentLabel="CSV Sent Modal"
        key="csv"
      >
        <div className="csv-modal-container">
          <span className="modal-text">Crunching Data...</span>
          <Loader color="#26A65B" size="14px" margin="4px" />
        </div>
      </Modal>,
      <Modal
        isOpen={this.state.surveyLinkShown}
        style={modalStyle}
        contentLabel="Survey Link"
        key="surveyLink"
      >
        <div className="csv-modal-container">
          <div className="close-exit-button">
            <Hammer onTap={this.closeModal}>
              <img className="close-button" src="/img/close-gray.svg" />
            </Hammer>
          </div>
          <div className="modal-text">{'The survey link is:'}</div>
          <span className="modal-text">{`${window.location.origin}/external-survey/${this.props.survey.id}`}</span>
          <Hammer onTap={this.copyToClipboard}>
            <div className="modal-button">Copy Link</div>
          </Hammer>
        </div>
      </Modal>,
      <Modal
        isOpen={this.state.emailNonRespondentsShown}
        style={nonRespondentsModalStyle}
        contentLabel="Survey Link"
        key="nonRespondents"
      >
        <div className="csv-modal-container">
          <div className="close-exit-button">
            <Hammer onTap={this.closeModal}>
              <img className="close-button" src="/img/close-gray.svg" />
            </Hammer>
          </div>
          <div className="modal-text">{'List of Non-Respondents'}</div>
          {<textarea style={textAreaStyle} rows="20" cols="50" value={emailList} />}
          <Hammer onTap={this.copyToClipboard}>
            <div className="modal-button">Copy List</div>
          </Hammer>
        </div>
      </Modal>,
      <LongitudinalModal
        isOpen={this.state.longitudinalShown}
        closeModal={this.closeModal}
        surveyId={this.props.survey._id}
      />,
    ]

    // if (this.state.csvModalShown) {
    //   return (
    //     <Modal
    //       isOpen={this.state.csvModalShown}
    //       style={modalStyle}
    //       contentLabel="CSV Sent Modal"
    //     >
    //       <div className="csv-modal-container">
    //         <span className="modal-text">Crunching Data...</span>
    //         <Loader color="#26A65B" size="14px" margin="4px" />
    //       </div>
    //     </Modal>
    //   )
    // } else if (this.state.surveyLinkShown) {
    //   return (
    //     <Modal
    //       isOpen={this.state.surveyLinkShown}
    //       style={modalStyle}
    //       contentLabel="Survey Link"
    //     >
    //       <div className="csv-modal-container">
    //         <div className="close-exit-button">
    //           <Hammer onTap={this.closeModal}>
    //             <img className="close-button" src="/img/close-gray.svg" />
    //           </Hammer>
    //         </div>
    //         <div className="modal-text">{'The survey link is:'}</div>
    //         <span className="modal-text">{`${
    //           window.location.origin
    //         }/external-survey/${this.props.survey.id}`}</span>
    //         <Hammer onTap={this.copyToClipboard}>
    //           <div className="modal-button">Copy Link</div>
    //         </Hammer>
    //       </div>
    //     </Modal>
    //   )
    // } else if (this.state.emailNonRespondentsShown) {
    //   let emailList = this.state.nonRespondents.join('\n')
    //   return (
    //     <Modal
    //       isOpen={this.state.emailNonRespondentsShown}
    //       style={nonRespondentsModalStyle}
    //       contentLabel="Survey Link"
    //     >
    //       <div className="csv-modal-container">
    //         <div className="close-exit-button">
    //           <Hammer onTap={this.closeModal}>
    //             <img className="close-button" src="/img/close-gray.svg" />
    //           </Hammer>
    //         </div>
    //         <div className="modal-text">{'List of Non-Respondents'}</div>
    //         {
    //           <textarea
    //             style={textAreaStyle}
    //             rows="20"
    //             cols="50"
    //             value={emailList}
    //           />
    //         }
    //         <Hammer onTap={this.copyToClipboard}>
    //           <div className="modal-button">Copy List</div>
    //         </Hammer>
    //       </div>
    //     </Modal>
    //   )
    // }
  }

  /**
   * Expects props to have
   *   .survey
   * Options props
   *   .onClose – should be set if being rendered by survey-view
   *              shouldn't be set if rendered by home
   */
  render() {
    let options

    if (this.props.create) {
      return <div />
    }

    let status
    if (!this.props.survey.publishedAt) {
      status = 'draft'
    } else if (this.props.survey.closesAt && new Date(this.props.survey.closesAt) < new Date()) {
      status = 'closed'
    } else {
      status = 'live'
    }

    const duplicateOption = (
      <Hammer onTap={this.duplicateSurvey}>
        <div className="option">
          <img src="/img/new-survey.svg" />
          Duplicate
        </div>
      </Hammer>
    )

    if (!this.props.survey.publishedAt) {
      let deleteOption

      if (!this.props.survey.submittedAt) {
        deleteOption = (
          <Hammer onTap={this.deleteSurvey}>
            <div className="option">
              <img src="/img/empty.svg" />
              Delete
            </div>
          </Hammer>
        )
      }

      options = (
        <div className="options">
          <Hammer onTap={this.openSurvey}>
            <div className="option">
              <img src="/img/setting_edit.svg" />
              {!this.props.survey.submittedAt ? 'Edit' : 'View'}
            </div>
          </Hammer>
          {deleteOption}
          {duplicateOption}
        </div>
      )
    } else {
      let masterOption,
        closeOption,
        deleteOption,
        viewResultsOption,
        emailNonRespondentsOption,
        surveyLinkOption,
        changeAudienceOption
      if (this.props.user.master) {
        masterOption = (
          <Hammer onTap={this.unpublishSurvey}>
            <div className="option">
              {this.state.updatingPublishedProperty ? (
                <div style={{ width: '50px' }}>
                  <Loader color="#26A65B" size="6px" margin="4px" />
                </div>
              ) : (
                <img src="/img/no.svg" />
              )}
              Unpublish
            </div>
          </Hammer>
        )
      }

      if (!this.props.survey.closesAt || new Date(this.props.survey.closesAt) > Date.now()) {
        closeOption = (
          <Hammer onTap={this.closeSurvey}>
            <div className="option">
              {this.state.updatingCloseProperty ? (
                <div style={{ width: '50px' }}>
                  <Loader color="#26A65B" size="6px" margin="4px" />
                </div>
              ) : (
                <img src="/img/locked.svg" />
              )}
              Close
            </div>
          </Hammer>
        )
      } else {
        closeOption = (
          <Hammer onTap={this.unCloseSurvey}>
            <div className="option">
              {this.state.updatingCloseProperty ? (
                <div style={{ width: '50px' }}>
                  <Loader color="#26A65B" size="6px" margin="4px" />
                </div>
              ) : (
                <img src="/img/unlocked.svg" />
              )}
              Unclose
            </div>
          </Hammer>
        )
      }

      // if (this.props.survey.targetingMechanism === 'individuals') {
      // Implement the emailNonRespondents
      emailNonRespondentsOption = (
        <Hammer onTap={this.showEmailNonRespondentsModal}>
          <div className="option">
            <img src="/img/mail.svg" />
            Email Non-Respondents
          </div>
        </Hammer>
      )

      // }

      // deleteOption = (
      //   <Hammer onTap={this.deleteSurvey}>
      //     <div className='option'>
      //       <img src='/img/empty.svg' />
      //       Delete
      //     </div>
      //   </Hammer>
      // )

      viewResultsOption = (
        <Hammer onTap={this.openResults}>
          <div className="option">
            <img src="/img/chart.svg" />
            View Results
          </div>
        </Hammer>
      )

      surveyLinkOption = (
        <Hammer onTap={this.showSurveyLink}>
          <div className="option">
            <img src="/img/blog-icon.svg" />
            Survey Link
          </div>
        </Hammer>
      )

      options = (
        <div className="options">
          <Hammer
            onTap={() => window.open('/create/survey/metadata/' + this.props.survey._id, '_blank')}
          >
            <div className="option">
              <img src="/img/bulb-green.svg" />
              <span>Metadata</span>
            </div>
          </Hammer>
          <Hammer
            onTap={() =>
              window.open('/create/survey/programming/' + this.props.survey._id, '_blank')
            }
          >
            <div className="option">
              <img src="/img/order-form-green.svg" />
              <span>Programming</span>
            </div>
          </Hammer>
          <Hammer
            onTap={() => window.open('/create/survey/fielding/' + this.props.survey._id, '_blank')}
          >
            <div className="option">
              <img src="/img/chart.svg" />
              <span>Fielding</span>
            </div>
          </Hammer>
          <Hammer
            onTap={() => window.open('/create/survey/metadata/' + this.props.survey._id, '_blank')}
          >
            <div className="option">
              <img src="/img/cloud_download.svg" />
              <span>Insights</span>
            </div>
          </Hammer>
          {surveyLinkOption}
          {/* duplicateOption temp disable*/}
        </div>
      )

      // options = (
      //   <div className="options">
      //     {viewResultsOption}
      //     <Hammer onTap={this.downloadCSV}>
      //       <div className="option">
      //         <img src="/img/cloud_download.svg" />
      //         <span>Download Data</span>
      //       </div>
      //     </Hammer>
      //     <Hammer onTap={this.openLongitudinalModal}>
      //       <div className="option">
      //         <img src="/img/clock-green.svg" />
      //         <span>Create Longitudinal Study</span>
      //       </div>
      //     </Hammer>
      //     {emailNonRespondentsOption}
      //     {masterOption}
      //     {surveyLinkOption}
      //     {closeOption}
      //     {duplicateOption}
      //     {deleteOption}
      //   </div>
      // )
    }

    let modal = this.renderModal()
    return (
      <div className="admin-survey-card-container" style={{ position: 'relative' }}>
        {modal}
        <div
          style={{
            width: '30px',
            height: '30px',
            position: 'absolute',
            zIndex: '10',
            margin: '0.5rem',
          }}
        >
          {this.props.user?.favoriteSurveys &&
          this.props.user?.favoriteSurveys.includes(this.props.survey?._id.toString() || null) ? (
            <img
              onClick={() => this.props.removeSurveyToFavorite(this.props.survey._id.toString())}
              src="/img/fav-star-selected.png"
              alt="favorite-icon"
              style={{ objectFit: 'fill', height: '30px', cursor: 'pointer' }}
            />
          ) : (
            <img
              onClick={() => this.props.addSurveyToFavorite(this.props.survey._id.toString())}
              src="/img/fav-star.png"
              alt="favorite-icon"
              style={{ objectFit: 'fill', height: '30px', cursor: 'pointer' }}
            />
          )}
        </div>
        <SurveyCard
          type={'available'}
          survey={this.props.survey}
          surveyId={this.props.survey._id}
          key={this.props.survey._id}
          hideDescription={false}
          setActive={this.openSurvey}
        />
        <div className="options-container">
          <div className={`admin-card-status-container ${status}`}>{status}</div>
          {options}
        </div>
      </div>
    )
  }
}

AdminSurveyCard.propTypes = {
  create: PropTypes.bool,
}

AdminSurveyCard.defaultProps = {
  create: false,
}
