import React from 'react'
import _ from 'lodash'
import TopLevelCategoryContainer from './TopLevelCategoryContainer'
import CheckboxContainer from './individualDemContainers/CheckboxContainer'
import SearchContainer from './individualDemContainers/SearchContainer'
import { fetchSurveyProperty, setSurveyProperty } from '../../../../../../api/admin'
import { Button } from 'semantic-ui-react'
import { store } from '../../../../../store'

import { connect } from 'react-redux'
import {
  fetchDemographicsOptions,
  setTargetingMechanism,
  toggleAllSchools,
} from '../../../../../actions/create-view-survey-actions/temp-demographics-actions'
import {
  removeTopLevelCategoryFromFaceted,
  toggleDemOptionInFaceted,
  setDemOptionsForCategory,
} from '../../../../../actions/create-view-survey-actions/temp-demographics-actions'

const searchableDemographics = ['school', 'major']
const groupedDemographics = ['gender']

const computeSelectedForUngrouped = (facetedTargetedDemographics, dem) =>
  facetedTargetedDemographics
    .filter(optStr => optStr.includes(':') && optStr.split(':')[0] === dem)
    .map(optStr => optStr.split(':')[1])

const computeSelectedForGrouped = (facetedTargetedDemographics, dem, groups) => {
  const ungroupedSelected = computeSelectedForUngrouped(facetedTargetedDemographics, dem)
  return _.map(
    groups.filter(group => group.contents.every(dem => ungroupedSelected.includes(dem))),
    'title'
  )
}

class TargetingContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      facetedTargetedDemographics: [],
      inverseFacetedTargetedDemographics: [],
      changesMade: false,
    }
    this.fetchProperty = this.fetchProperty.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.setTargetingMechanism = this.setTargetingMechanism.bind(this)
    this.addTopLevelCategoryToFaceted = this.addTopLevelCategoryToFaceted.bind(this)
    this.toggleAllSchools = this.toggleAllSchools.bind(this)
    this.removeTopLevelCategoryFromFaceted = this.removeTopLevelCategoryFromFaceted.bind(this)
    this.toggleDemOptionInFaceted = this.toggleDemOptionInFaceted.bind(this)
    this.setDemOptionsForCategory = this.setDemOptionsForCategory.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
    this.toggleDemOptionsInInverseFaceted = this.toggleDemOptionsInInverseFaceted.bind(this)
  }
  componentWillMount() {
    this.fetchProperty()
  }

  addTopLevelCategoryToFaceted(dem) {
    console.log(dem)
    this.setState({
      facetedTargetedDemographics: _.uniq(this.state.facetedTargetedDemographics.concat([dem])),
    })
  }

  setTargetingMechanism(category) {
    this.setState({
      targetingMechanism: category.value,
    })
  }

  toggleAllSchools() {
    this.setState({
      allSchools: !this.state.allSchools,
    })
  }

  removeTopLevelCategoryFromFaceted(prop) {
    this.setState({ changesMade: true })
    this.setState({
      facetedTargetedDemographics: _.pullAllWith(
        this.state.facetedTargetedDemographics.slice(),
        [prop],
        (facetedVal, dem) => {
          return facetedVal === dem || facetedVal.split(':')[0] === dem
        }
      ),
    })
  }

  toggleDemOptionInFaceted({ category, option, explicitOnOrOff = null }) {
    this.setState({ changesMade: true })
    const optionStr = `${category}:${option}`
    const currentlyIncludes = _.isNil(explicitOnOrOff)
      ? this.state.facetedTargetedDemographics.includes(optionStr)
      : !explicitOnOrOff
    this.setState({
      facetedTargetedDemographics: currentlyIncludes
        ? _.pull(this.state.facetedTargetedDemographics.slice(), optionStr)
        : _.uniq(this.state.facetedTargetedDemographics.concat([optionStr, category])),
    })
  }

  toggleDemOptionsInInverseFaceted({ options, explicitOnOrOff = null }) {
    this.setState({ changesMade: true })
    const shouldRemove = _.isNil(explicitOnOrOff)
      ? options.every(filter =>
          (this.state.inverseFacetedTargetedDemographics || []).includes(filter)
        )
      : !explicitOnOrOff

    this.setState({
      inverseFacetedTargetedDemographics: shouldRemove
        ? _.pullAll(this.state.inverseFacetedTargetedDemographics.slice(), options)
        : _.uniq(this.state.inverseFacetedTargetedDemographics.concat(options)),
    })
  }

  setDemOptionsForCategory({ category, options }) {
    this.setState({ changesMade: true })
    const optionStrings = options.map(option => `${category}:${option}`)
    this.setState({
      facetedTargetedDemographics: [
        ...this.state.facetedTargetedDemographics.filter(
          option => !option.includes(`${category}:`)
        ),
        ...optionStrings,
      ],
    })
  }

  // Get properties from api
  fetchProperty() {
    fetchSurveyProperty({
      _id: this.props.surveyID,
      property: 'facetedTargetedDemographics',
    }).then(facetedTargetedDemographics =>
      this.setState({ facetedTargetedDemographics: facetedTargetedDemographics })
    )

    fetchSurveyProperty({
      _id: this.props.surveyID,
      property: 'inverseFacetedTargetedDemographics',
    }).then(inverseFacetedTargetedDemographics =>
      this.setState({ inverseFacetedTargetedDemographics: inverseFacetedTargetedDemographics })
    )
  }

  // Update Function
  onConfirm() {
    this.setState({ changesMade: false })

    setSurveyProperty({
      _id: this.props.surveyID,
      property: 'facetedTargetedDemographics',
      value: this.state.facetedTargetedDemographics,
      store: store,
    }).then(resp =>
      this.setState({ facetedTargetedDemographics: resp.facetedTargetedDemographics })
    )

    setSurveyProperty({
      _id: this.props.surveyID,
      property: 'inverseFacetedTargetedDemographics',
      value: this.state.inverseFacetedTargetedDemographics,
      store: store,
    }).then(resp =>
      this.setState({
        inverseFacetedTargetedDemographics: resp.inverseFacetedTargetedDemographics,
      })
    )
  }

  render() {
    const { demOptions } = this.props
    const facetedTargetedDemographics = this.state.facetedTargetedDemographics || {}

    const individualDemContainers = Object.keys(demOptions)
      .filter(dem => facetedTargetedDemographics.includes(dem))
      .map(dem => {
        const DemContainer = searchableDemographics.includes(dem)
          ? SearchContainer
          : CheckboxContainer

        const shouldGroup = groupedDemographics.includes(dem)
        const options = shouldGroup
          ? demOptions[dem].groups.map(g => g.title)
          : demOptions[dem].options
        const selected = shouldGroup
          ? computeSelectedForGrouped(facetedTargetedDemographics, dem, demOptions[dem].groups)
          : computeSelectedForUngrouped(facetedTargetedDemographics, dem)

        return (
          <DemContainer
            demographic={dem}
            options={options}
            groups={demOptions[dem].groups}
            grouped={shouldGroup}
            removeDemographic={this.removeTopLevelCategoryFromFaceted}
            toggleDemOption={this.toggleDemOptionInFaceted}
            setDemOptionsForCategory={this.setDemOptionsForCategory}
            selected={selected}
            key={dem}
          />
        )
      })

    return (
      <div
        className={`dem-container ${
          this.isSafari && this.state.demAllOrNot === 'groups' ? 'safari' : ''
        }`}
      >
        <Button
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
          color={this.state.changesMade ? 'green' : undefined}
          onClick={this.onConfirm}
        >
          Update
        </Button>
        <TopLevelCategoryContainer
          toggleDemOptionsInInverseFaceted={this.toggleDemOptionsInInverseFaceted}
          addTopLevelCategoryToFaceted={this.addTopLevelCategoryToFaceted}
          facetedTargetedDemographics={this.state.facetedTargetedDemographics}
          inverseFacetedTargetedDemographics={this.state.inverseFacetedTargetedDemographics}
        />
        {individualDemContainers}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  school: ((state.user.user.demographics || {}).school || [])[0] || 'Your School',
  demOptions: state.createViewTempDemographics.demOptions,
})

export default connect(mapStateToProps, {
  fetchDemographicsOptions,
  //setTargetingMechanism,
  //toggleAllSchools,
  //removeTopLevelCategoryFromFaceted,
  //toggleDemOptionInFaceted,
  //setDemOptionsForCategory,
})(TargetingContent)
