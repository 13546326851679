import request from './request'
import prefix from './prefix'

//TODO(Zap): On of the two functions is outdated, which causes an error that makes sms tab
// unusable. Once done with ambassador outreach check out what is wrong :)
export const getSmsCampaigns = _id => {
  return new Promise(async (resolve, reject) => {
    try {
      let res = await request.get(prefix + `/api/panel/sms-campaigns/${_id.toString()}`)

      if (res?.body?.error) return reject(res.body.error)

      return resolve(res.body)
    } catch (err) {
      reject(err)
    }
  })
}

exports.getCampaignData = function (name, demo) {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/panel/sms-campaigns/breakdown/${name}/${demo}`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}
