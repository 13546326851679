import request from './request'
import prefix from './prefix'

exports.fieldingProgress = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/admin-tooling/fielding-progress').end((err, res) => {
      if (err) return reject(err)

      let sortedTables = [...res.body].sort((a, b) => (a.deadline > b.deadline ? 1 : -1))
      return resolve(sortedTables)
    })
  })
}

exports.updateIncentive = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/admin-tooling/update-incentives')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res)
      })
  })
}

exports.createGuestUser = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/admin-tooling/guest-token')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res)
      })
  })
}

exports.dashboardGetLinks = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/admin-tooling/dashboard').end((err, res) => {
      console.log(res)
      console.log(res.body)
      if (err) return reject(err)

      return resolve(res)
    })
  })
}
exports.dashboardAddLink = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/admin-tooling/dashboard')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res)
      })
  })
}
exports.dashboardRemoveLink = function (_id) {
  return new Promise((resolve, reject) => {
    request.delete(prefix + '/api/admin-tooling/dashboard/' + _id).end((err, res) => {
      if (err) return reject(err)

      return resolve(res)
    })
  })
}
