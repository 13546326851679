import store from 'store'
import cookie from 'js-cookie'
import expirePlugin from 'store/plugins/expire'
store.addPlugin(expirePlugin)

const isLocalStorage = () => {
  try {
    localStorage.setItem('test', 'test')
    localStorage.removeItem('test')
    return true
  } catch (e) {
    return false
  }
}

const get = (name, val) => {
  // another consideration. how do we ensure that someone who clicks on one link, then another,
  // has the second token override the first such that they can properly open the first

  // overriding the existing token could work, but that signs out real users when you click the link

  // putting a GUEST- in front of the token could also work actually. We only override the token if
  // the existing token has GUEST- at the front. Thats a good solution

  if (isLocalStorage()) {
    return store.get(name)
  } else {
    return cookie.getJSON(name)
  }
}

const set = (name, val, neverExpire, expirationInDays) => {
  // 30 years!
  const thirtyYearsInDays = 365 * 30
  const thirtyYearsInMilli = 1000 * 60 * 60 * 24 * thirtyYearsInDays
  const dateLongTimeFromNow = neverExpire
    ? new Date().getTime() + thirtyYearsInMilli
    : 'doesnt matter'

  if (isLocalStorage()) {
    if (neverExpire) {
      store.set(name, val, dateLongTimeFromNow)
    } else if (expirationInDays) {
      const expirationInMilli = 1000 * 60 * 60 * 24 * expirationInDays
      store.set(name, val, new Date().getTime() + expirationInMilli)
    } else {
      store.set(name, val)
    }
  } else {
    if (neverExpire) {
      cookie.set(name, val, { expires: thirtyYearsInDays })
    } else if (expirationInDays) {
      cookie.set(name, val, { expires: expirationInDays })
    } else {
      cookie.set(name, val)
    }
  }
  return true
}

const remove = name => {
  if (isLocalStorage()) {
    store.remove(name)
  } else {
    cookie.remove(name)
  }
}

const clear = () => {
  if (isLocalStorage()) {
    store.clearAll()
  } else {
    Object.keys(cookie.getJSON()).forEach(key => {
      cookie.remove(key)
    })
  }
  return true
}

export default { get, set, remove, clear }
