import { ActionTypes } from '../actions'

const defaultState = {
  currentPage: 'questions',
  sectionTitle: 'Create a New Survey',
  navigationErrors: [],
}

const CreateViewNavigationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_PAGE:
      return Object.assign({}, state, {
        currentPage: action.payload.currentPage,
        sectionTitle: action.payload.sectionTitle,
      })
    case ActionTypes.SET_NAVIGATION_ERRORS:
      return Object.assign({}, state, {
        navigationErrors: action.payload,
      })
    default:
      return state
  }
}

export default CreateViewNavigationReducer
