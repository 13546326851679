import React, { useEffect, useState } from 'react'
import { Table, Button, Dropdown } from 'semantic-ui-react'
import { fetchSurveyProperty, getFieldingProgress } from '../../../api/admin'
import _ from 'lodash'

export default ({ match }) => {
  // State variables
  const [activeTable, setActiveTable] = useState(null) // Currently shown data
  const [activeDem, setActiveDem] = useState(null) // Selected demographic
  const [loading, setLoading] = useState(false) // Are we loading?
  const [allCount, setAllCount] = useState(null) // Total completes
  const [precision, setPrecision] = useState(0) // How many decimal places for percentage?

  // Available demographics. Array to be turned into buttons of dems.
  const allDems = [
    'gender',
    'race',
    'school',
    'twoYearFourYear',
    'publicOrPrivate',
    'financialAid',
    'orientation',
    'politicalLeaning',
    'studentType',
    'year',
  ]

  // Get initial data (All count)
  useEffect(() => {
    getInitialData({ setAllCount, _id: match?.params?.surveyId })
  }, [])

  // Get new data when activeDem changes.
  useEffect(() => {
    if (activeDem) getTable({ setLoading, _id: match?.params?.surveyId, activeDem, setActiveTable })
  }, [activeDem])

  return (
    <div className="track-view-container">
      <h2>Survey Tracking</h2>
      <hr />

      <div className="dem-buttons">
        {allDems?.map((dem, i) => (
          <Button
            color={activeDem === dem ? 'green' : undefined}
            onClick={() => setActiveDem(dem)}
            key={i}
          >
            {_.startCase(dem)}
          </Button>
        ))}
      </div>

      {allCount && (
        <div className="tracking-component-container">
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Total Responses</Table.HeaderCell>
                <Table.HeaderCell width={6}>{!!allCount && allCount}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
          </Table>
        </div>
      )}

      <div className="tracking-component-container">
        {loading && (
          <div style={{ padding: '4rem' }}>
            <h5 style={{ opacity: 0.3, fontSize: '2.5rem' }}>Loading Data...</h5>
          </div>
        )}
        {!loading && !activeTable && (
          <div style={{ padding: '4rem' }}>
            <h5 style={{ opacity: 0.3, fontSize: '2.5rem' }}>Select a category...</h5>
          </div>
        )}
        {!loading && activeTable && (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={2}>IPEDS</Table.HeaderCell>
                <Table.HeaderCell width={7}>{_.startCase(activeDem)}</Table.HeaderCell>
                <Table.HeaderCell width={3}>Count</Table.HeaderCell>
                <Table.HeaderCell width={3}>
                  Percent
                  <Dropdown
                    selection
                    style={{ marginLeft: '2rem' }}
                    options={[
                      {
                        value: 0,
                        text: '0 decimal places',
                      },
                      {
                        value: 1,
                        text: '1 decimal places',
                      },
                      {
                        value: 2,
                        text: '2 decimal places',
                      },
                      {
                        value: 3,
                        text: '3 decimal places',
                      },
                    ]}
                    value={precision}
                    onChange={(e, d) => setPrecision(d.value)}
                  />
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {activeTable?.[1]?.map((row, i) => (
                <Table.Row key={i}>
                  <Table.Cell width={2}>
                    {/* row.value is the specific demographic option */}
                    {row?.unitId || 'N/A'}
                  </Table.Cell>
                  <Table.Cell width={7}>
                    {/* row.value is the specific demographic option */}
                    {typeof row.value === 'string' ? _.startCase(row.value) : row.value}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {/* row.cound is the count of responses */}
                    {row.count}
                  </Table.Cell>
                  <Table.Cell width={3}>
                    {/* Percentage is calculated by the specific option divided by the sum of all options. */}
                    {((row.count / _.sumBy(activeTable?.[1], o => o.count)) * 100).toFixed(
                      precision
                    )}
                    %
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </div>
    </div>
  )
}

// Get initial data
const getInitialData = ({ setAllCount, _id }) => {
  getFieldingProgress({ _id })
    .then(res => {
      // Data is stored in res.table
      // res.table[0] holds total response data
      // res.table[1] holds demographic specific data
      // res.table[0][0] has properties count: 218, and value: "total responses"

      setAllCount(res.table[0][0].count)
    })
    .catch(err => {
      console.log('Error getting initial data...')
      console.dir(err)
    })
}

// Get data for selected demographic
const getTable = ({ setLoading, _id, activeDem, setActiveTable }) => {
  setLoading(true)
  getFieldingProgress({ _id, category: activeDem })
    .then(res => {
      // Data is stored in res.table
      // res.table[0] holds total response data
      // res.table[1] holds demographic specific data
      // res.table[1][row] holds value (specific demographic option) and count (number of responses)

      res.table[1] = _.sortBy(res.table[1], ['count']).reverse()
      setActiveTable(res.table)
      setLoading(false)
    })
    .catch(err => {
      console.log('There was an error!')
      console.dir(err)
      setLoading(false)
    })
}
