import React from 'react'
import dragula from 'react-dragula'
import dom from 'component-dom'
import _ from 'lodash'
import SidebarQuestionBox from './sidebar-question-box'

import { connect } from 'react-redux'
import {
  changeQuestion,
  setCurrentQuestionPermanentStatus,
  setDeleteConfirmingIndex,
} from '../../../../../../actions/cv-questions-builder-actions'
import {
  removeQuestionAtIndex,
  reorderQuestions,
  toggleRandomize,
  toggleRequired,
  toggleLogic,
  setQuizConfiguration,
  toggleOther,
  toggleYesNo,
  setMaxSelectAllowedForQ,
  setTextResponseCount,
  changeQuestionDescription,
  changeQuestionType,
  setSpectrumWeighted,
  setTypologyWeight,
  duplicateQuestion,
  toggleMaxSelect,
  setStaticSkipTo,
  toggleIsScreenerQ,
  toggleHideQuestionFromDE,
} from '../../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'
import { editOptionTypologyWeights } from '../../../../../../actions/create-view-survey-actions/edit-survey-question-options-actions'
import {
  setSpectrumName,
  setSpectrumLearnMore,
  setIsTypology,
  setIsSpectrum,
  setTypologyTopLine,
  setResults,
  addDimension,
  changeDimensionName,
  removeDimension,
  toggleFixed,
} from '../../../../../../actions/create-view-survey-actions/edit-survey-actions'

class ExistingQuestionsMenu extends React.Component {
  constructor() {
    super()

    const boundMethods = [
      'setMaxSelectVal',
      'setConfirmingDeletion',
      'onConfirmDelete',
      'questionChange',
      'onClickOutsideDelete',
      'toggleRandomize',
      'toggleQuiz',
      'toggleRequired',
      'toggleLogic',
      'toggleYesNo',
      'toggleOther',
      'dragulaDecorator',
      'shakeBox',
      'determineError',
      'determineRangeError',
      'setSpectrumName',
      'setSpectrumLearnMore',
      'toggleSpectrumWeighted',
      'toggleIsScreenerQ',
      'toggleHideQuestionFromDE',
    ]
    boundMethods.forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  questionChange(index) {
    const minError = this.determineError('minimum')
    const maxError = this.determineError('maximum')
    const rangeError = this.determineRangeError()

    if (!minError && !maxError && !rangeError) {
      this.props.changeQuestion(index)
    } else {
      if (minError || rangeError) {
        this.shakeBox('minimum-input-box')
      }

      if (maxError || rangeError) {
        this.shakeBox('maximum-input-box')
      }
    }
  }

  determineError(minOrMax) {
    const question = this.props.questions[this.props.currentQuestionIndex]
    return question.type === 'numeric' && question.rangeInc === 1 && question[minOrMax] % 1 !== 0
  }

  determineRangeError() {
    const question = this.props.questions[this.props.currentQuestionIndex]
    return (
      !_.isNil(question.minimum) &&
      !_.isNil(question.maximum) &&
      question.minimum >= question.maximum
    )
  }

  shakeBox(boxId) {
    const box = document.getElementById(boxId)
    if (box) {
      box.classList.add('error')
      setTimeout(() => {
        box.classList.remove('error')
      }, 300)
    }
  }

  /*
   * Delete sign pressed, set button to ask for confirmation
   */
  setConfirmingDeletion(index) {
    this.props.setCurrentQuestionPermanentStatus(true)
    this.props.setDeleteConfirmingIndex(index)
  }

  /**
   * Actually delete the question at index
   */
  onConfirmDelete(index) {
    this.props.removeQuestionAtIndex({
      index,
      questions: this.props.questions,
      currentQuestionIndex: this.props.currentQuestionIndex,
    })
  }

  onClickOutsideDelete() {
    this.props.setDeleteConfirmingIndex(-1)
  }

  setMaxSelectVal(index, newValue) {
    this.props.toggleYesNo(index, false)
    this.props.setMaxSelectAllowedForQ({
      index,
      maxSelect: newValue,
    })
  }

  setSpectrumName(index, newValue) {
    this.props.setSpectrumWeighted({
      index,
      spectrumWeighted: true,
    })
    this.props.setSpectrumName(newValue)
  }

  setSpectrumLearnMore(index, newValue) {
    this.props.setSpectrumLearnMore(newValue)
  }

  toggleSpectrumWeighted(index) {
    let q = this.props.questions[index]
    this.props.setSpectrumWeighted({
      index,
      spectrumWeighted: !q.spectrumQ,
    })
  }

  toggleRandomize(index) {
    let randomize = !(this.props.questions[index].optionOrderSettings || {}).randomizeOptions
    this.props.toggleYesNo(index, false)
    this.props.toggleRandomize(index, randomize)
  }

  toggleQuiz(index) {
    const quizSettings = Object.assign({}, this.props.questions[index].quizSettings || {}, {
      isQuiz: !(this.props.questions[index].quizSettings || {}).isQuiz,
    })
    this.props.setQuizConfiguration(index, quizSettings)
  }

  toggleRequired(index) {
    let required = !this.props.questions[index].required
    this.props.toggleRequired(index, required)
  }

  toggleLogic(index) {
    let logic = !this.props.questions[index].logicQuestion
    this.props.toggleLogic(index, logic)
  }

  toggleIsScreenerQ(index) {
    let logic = !this.props.questions[index].isScreenerQ
    this.props.toggleIsScreenerQ(index, logic)
  }

  toggleHideQuestionFromDE(index) {
    let logic = !this.props.questions[index].hideQuestionFromDE
    this.props.toggleHideQuestionFromDE(index, logic)
  }

  toggleYesNo(index) {
    const yesNo = !this.props.questions[index].yesNo
    this.props.toggleRandomize(index, false)
    this.props.toggleOther(index, false)
    this.props.toggleYesNo(index, yesNo)
  }

  toggleOther(index) {
    this.props.toggleYesNo(index, false)
    let allowOther = !this.props.questions[index].input
    this.props.toggleOther(index, allowOther)
  }

  dragulaDecorator(componentBackingInstance) {
    if (componentBackingInstance) {
      let options = { revertOnSpill: true }
      this.drake = dragula([componentBackingInstance], options)
      this.drake.on('drop', () => {
        let els = dom('.sidebar-q-container').select(function (div) {
          return !dom(div).hasClass('gu-mirror')
        })

        let newOrder = []
        els.each(e => {
          newOrder.push(e.attr('data'))
        })

        this.drake.cancel(true)
        newOrder = newOrder.map(int => parseInt(int))

        this.props.reorderQuestions({
          newOrderByOldIndices: newOrder,
          oldOrderedQuestions: this.props.questions,
          currentQuestionKey: this.props.questions[this.props.currentQuestionIndex].key,
        })
      })
    }
  }

  destroyDragula() {
    if (this.drake) this.drake.destroy()
  }

  componentWillUnmount() {
    this.destroyDragula()
  }

  render() {
    let messageCount = 0
    let questions = this.props.questions.map((question, index) => {
      if (question.type === 'message') {
        messageCount++
      }
      return (
        <SidebarQuestionBox
          key={question.key}
          index={index}
          messageCount={messageCount}
          deleteConfirmingIndex={this.props.deleteConfirmingIndex}
          question={question}
          currentQuestionIndex={this.props.currentQuestionIndex}
          setConfirmingDeletion={this.setConfirmingDeletion}
          onConfirmDelete={this.onConfirmDelete}
          onClickOutsideDelete={this.onClickOutsideDelete}
          questionChange={this.questionChange}
          toggleRandomize={this.toggleRandomize}
          toggleRequired={this.toggleRequired}
          toggleLogic={this.toggleLogic}
          toggleIsScreenerQ={this.toggleIsScreenerQ}
          toggleHideQuestionFromDE={this.toggleHideQuestionFromDE}
          toggleQuiz={this.toggleQuiz}
          toggleOther={this.toggleOther}
          toggleYesNo={this.toggleYesNo}
          toggleMaxSelect={this.props.toggleMaxSelect}
          setMaxSelectVal={this.setMaxSelectVal}
          setSpectrumName={this.setSpectrumName}
          setSpectrumLearnMore={this.setSpectrumLearnMore}
          toggleSpectrumQ={this.toggleSpectrumWeighted}
          spectrumName={this.props.spectrumName}
          isSpectrum={this.props.isSpectrum}
          isTypology={this.props.isTypology}
          typologyConfig={this.props.typologyConfig}
          typologyTopLine={this.props.typologyTopLine}
          setResults={this.props.setResults}
          setStaticSkipTo={this.props.setStaticSkipTo}
          addDimension={this.props.addDimension}
          changeDimensionName={this.props.changeDimensionName}
          removeDimension={this.props.removeDimension}
          setTypologyWeight={this.props.setTypologyWeight}
          spectrumLearnMore={this.props.spectrumLearnMore}
          changeQuestionType={this.props.changeQuestionType}
          setIsTypology={this.props.setIsTypology}
          setIsSpectrum={this.props.setIsSpectrum}
          setTypologyTopLine={this.props.setTypologyTopLine}
          questions={this.props.questions}
          editOptionTypologyWeights={this.props.editOptionTypologyWeights}
          randomizeQuestions={this.props.randomizeQuestions}
          fixedQuestions={this.props.fixedQuestions}
          toggleFixed={this.props.toggleFixed}
          duplicateQuestion={this.props.duplicateQuestion}
          setTextResponseCount={this.props.setTextResponseCount}
        />
      )
    })

    return (
      <div className="sidebar-questions-container" ref={this.dragulaDecorator}>
        {questions}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
  spectrumName: (state.activeSurvey.spectrumConfig || {}).name,
  spectrumLearnMore: (state.activeSurvey.spectrumConfig || {}).learnMore,
  isSpectrum: (state.activeSurvey.spectrumConfig || {}).isSpectrum,
  randomizeQuestions: (state.activeSurvey.questionOrderSettings || {}).randomize,
  fixedQuestions: (state.activeSurvey.questionOrderSettings || {}).fixedQuestions || [],
  typologyTopLine: (state.activeSurvey.typologyConfig || {}).topLine,
  isTypology: (state.activeSurvey.typologyConfig || {}).isTypology,
  typologyConfig: state.activeSurvey.typologyConfig || {},
  deleteConfirmingIndex: state.cvQuestionsBuilder.deleteConfirmingIndex,
  currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
})

export default connect(mapStateToProps, {
  changeQuestion,
  reorderQuestions,
  setCurrentQuestionPermanentStatus,
  setDeleteConfirmingIndex,
  removeQuestionAtIndex,
  toggleRandomize,
  toggleRequired,
  toggleFixed,
  toggleLogic,
  setQuizConfiguration,
  toggleOther,
  toggleYesNo,
  setMaxSelectAllowedForQ,
  setTextResponseCount,
  changeQuestionDescription,
  changeQuestionType,
  setSpectrumName,
  setStaticSkipTo,
  setSpectrumLearnMore,
  setSpectrumWeighted,
  setIsTypology,
  setIsSpectrum,
  setTypologyTopLine,
  setResults,
  addDimension,
  changeDimensionName,
  removeDimension,
  setTypologyWeight,
  editOptionTypologyWeights,
  duplicateQuestion,
  toggleMaxSelect,
  toggleIsScreenerQ,
  toggleHideQuestionFromDE,
})(ExistingQuestionsMenu)
