import api from '../../../api'
import { browserHistory } from 'react-router'

export const ActionTypes = {
  CHANGE_SURVEY_PROPERTY: 'CHANGE_SURVEY_PROPERTY',
  CHANGE_SURVEY_SUB_PROPERTY: 'CHANGE_SURVEY_SUB_PROPERTY',
  TOGGLE_SURVEY_SUB_PROPERTY: 'TOGGLE_SURVEY_SUB_PROPERTY',
  INCREMENT_MESSAGE_COUNT: 'INCREMENT_MESSAGE_COUNT',
  DECREMENT_MESSAGE_COUNT: 'DECREMENT_MESSAGE_COUNT',
  ADD_RESULT: 'ADD_RESULT',
  SET_RESULT: 'SET_RESULT',
  ADD_DIMENSION: 'ADD_DIMENSION',
  REMOVE_DIMENSION: 'REMOVE_DIMENSION',
  CHANGE_DIMENSION: 'CHANGE_DIMENSION',
  TOGGLE_FIXED_Q: 'TOGGLE_FIXED_Q',
}

export function setImg(url) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'imgSrc',
        value: url,
      },
    })
  }
}

export function setPointVal(pointVal) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'pointValue',
        value: pointVal,
      },
    })
  }
}

export function setMaxRespondents(maxRespondents) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'maxNumberOfParticipants',
        value: maxRespondents,
      },
    })
  }
}

export function setAuthorImg(url) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'authorImg',
        value: url,
      },
    })
  }
}

export function setTitle(title) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'title',
        value: title,
      },
    })
  }
}

export function setType(type) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'surveyCategory',
        value: type,
      },
    })
  }
}

export function setAuthor(authorName) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'authorName',
        value: authorName,
      },
    })
  }
}

export function setDescription(description) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'description',
        value: description,
      },
    })
  }
}

export function setTags(tagArray) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'tags',
        value: tagArray,
      },
    })
  }
}

export function setAttachedPromoCode(promoCodeObj) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'promoCodeObj',
        value: promoCodeObj,
      },
    })
  }
}

export function setMessageCount(count) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'messageCount',
        value: count,
      },
    })
  }
}

export function setIsSpectrum() {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'spectrumConfig',
        property: 'isSpectrum',
        value: true,
      },
    })
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'typologyConfig',
        property: 'isTypology',
        value: false,
      },
    })
  }
}

export function toggleFixed(qId) {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_FIXED_Q,
      payload: {
        qId,
      },
    })
  }
}

export function setIsTypology() {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'typologyConfig',
        property: 'isTypology',
        value: true,
      },
    })
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'spectrumConfig',
        property: 'isSpectrum',
        value: false,
      },
    })
  }
}

export function toggleRandomize() {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'questionOrderSettings',
        property: 'randomize',
      },
    })
  }
}

export function setRandomizedGroups(groups) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'questionOrderSettings',
        property: 'randomizedGroups',
        value: groups,
      },
    })
  }
}

export function setMultiversionGroups(groups) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'questionOrderSettings',
        property: 'multiversionGroups',
        value: groups,
      },
    })
  }
}

export function addResult() {
  return dispatch => {
    dispatch({
      type: ActionTypes.ADD_RESULT,
    })
  }
}

export function setResults(results) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_RESULT,
      payload: results,
    })
  }
}

export function addDimension() {
  return dispatch => {
    dispatch({
      type: ActionTypes.ADD_DIMENSION,
      payload: {},
    })
  }
}

export function changeDimensionName(index, newName) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_DIMENSION,
      payload: { index, newName },
    })
  }
}

export function removeDimension(index) {
  return dispatch => {
    dispatch({
      type: ActionTypes.REMOVE_DIMENSION,
      payload: { index },
    })
  }
}

export function setSpectrumName(name) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'spectrumConfig',
        property: 'name',
        value: name,
      },
    })
    dispatch(setIsSpectrum())
  }
}

export function setTypologyTopLine(topLine) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'typologyConfig',
        property: 'topLine',
        value: topLine,
      },
    })
    dispatch(setIsTypology())
  }
}

export function setSpectrumLearnMore(text) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_SUB_PROPERTY,
      payload: {
        topLevel: 'spectrumConfig',
        property: 'learnMore',
        value: text,
      },
    })
  }
}

export function changeClosesAtDate(date) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'closesAt',
        value: date,
      },
    })
  }
}

export function incrementMessageCount(increment) {
  return dispatch => {
    dispatch({
      type: ActionTypes.INCREMENT_MESSAGE_COUNT,
      payload: {
        increment,
      },
    })
  }
}

export function decrementMessageCount(decrement) {
  return dispatch => {
    dispatch({
      type: ActionTypes.DECREMENT_MESSAGE_COUNT,
      payload: {
        decrement,
      },
    })
  }
}
