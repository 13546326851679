export const ActionTypes = {
  SET_EDIT_PRIVILEGES: 'SET_EDIT_PRIVILEGES',
  STRIPE_TOKEN: 'STRIPE_TOKEN',
  HIGHLIGHT_PAYMENT: 'HIGHLIGHT_PAYMENT',
}

export function setEditPrivileges(options) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_EDIT_PRIVILEGES,
      payload: {
        viewOnly: options.viewOnly,
        reviewingForPublish: options.reviewingForPublish,
      },
    })
  }
}

export function setStripeToken(token) {
  return dispatch => {
    dispatch({
      type: ActionTypes.STRIPE_TOKEN,
      payload: {
        token,
      },
    })
  }
}

export function highlightPayment() {
  return dispatch => {
    dispatch({
      type: ActionTypes.HIGHLIGHT_PAYMENT,
      payload: true,
    })
  }
}

export function unHighlightPayment() {
  return dispatch => {
    dispatch({
      type: ActionTypes.HIGHLIGHT_PAYMENT,
      payload: false,
    })
  }
}
