import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga'

// Components
import Admin from './components/admin'
import SurveyList from './components/survey-list/survey-list'
import ProgrammingView from './components/programming-view/programming-view'
import MetadataView from './components/metadata-view/metadata-view'
import FieldingView from './components/fielding-view/fielding-view'
import TrackView from './components/track-view/TrackView'
import Layout from './components/layout/Layout'

// Store
import { connect } from 'react-redux'
import { resetUser } from './actions/user-actions'

import 'semantic-ui-css/semantic.min.css'
import './admin.styl'

import api from '../api'

class RouteHandler extends React.Component {
  constructor() {
    super()

    this.state = {
      changesMade: false,
    }

    const boundMethods = 'setChangesMade'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  componentWillMount() {
    const loggedIn = api.signin.isSignedIn()
    // If there is a token, overwrite user
    const params = new URLSearchParams(window.location.search)
    if (params.has('token')) {
      let token = params.get('token')
      api.signin.processTokenDirectly(token)
      this.props.resetUser()
    } else if (localStorage.token) {
      this.props.resetUser()
    } else if (window.location.host.includes('collegepulse.com')) {
      window.location = `https://app.collegepulse.com/login?returnurl=${window.location.href}&returntoken=true`
    } else if (window.location.host.includes('localhost')) {
      window.location = `http://localhost:7000/login?returnurl=${window.location.href}&returntoken=true`
    }

    if (window.location.hostname === 'localhost') {
      ReactGA.initialize('UA-00000000-0')
    } else {
      ReactGA.initialize('UA-72011304-2')
    }
  }

  setChangesMade(status) {
    console.log(status)
    this.setState({ changesMade: status })
  }

  logPageView() {
    ReactGA.pageview(window.location.pathname)
  }

  render() {
    return (
      <>
        <Switch>
          {/* default route */}
          <Route path="/" exact>
            <Admin>
              <SurveyList />
            </Admin>
          </Route>

          {/* Survey Manager */}
          <Layout setChangesMade={this.setChangesMade} changesMade={this.state.changesMade}>
            <>
              <Route
                path="/create/survey/metadata"
                exact
                render={props => (
                  <MetadataView
                    {...props}
                    changesMade={this.state.changesMade}
                    setChangesMade={this.setChangesMade}
                  />
                )}
              />
              <Route
                path="/create/survey/metadata/:surveyId"
                render={props => (
                  <MetadataView
                    {...props}
                    changesMade={this.state.changesMade}
                    setChangesMade={this.setChangesMade}
                  />
                )}
              />
              <Route
                path="/create/survey/programming/:surveyId"
                render={props => (
                  <ProgrammingView
                    {...props}
                    changesMade={this.state.changesMade}
                    setChangesMade={this.setChangesMade}
                  />
                )}
              />
              <Route path="/create/survey/fielding/:surveyId" component={FieldingView} />
              <Route
                path="/create/survey/tracking/:surveyId"
                render={props => (
                  <TrackView
                    {...props}
                    changesMade={this.state.changesMade}
                    setChangesMade={this.setChangesMade}
                  />
                )}
              />
            </>
          </Layout>
        </Switch>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
})

export default connect(mapStateToProps, {
  resetUser,
})(RouteHandler)
