import React from 'react'
import PropTypes from 'prop-types'
import Hammer from 'react-hammerjs'

export default class Searchbar extends React.Component {
  constructor(props) {
    super(props)

    const boundMethods = ['onInputChange', 'toggleCollapsed']
    boundMethods.forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  // called when the search bar is clicked.
  toggleCollapsed() {
    if (this.props.searchbarCollapsed) {
      this.props.changeSearchBarState(this.props.searchbarCollapsed)
    } else {
      if (!this.props.searchQuery) {
        this.props.changeSearchBarState(this.props.searchbarCollapsed)
      } else {
        this.props.handleSearchReset()
      }
    }
  }

  onInputChange(ev) {
    this.props.handleSearchChange(ev.target.value)
  }

  render() {
    if (this.props.searchbarCollapsed && !this.props.noCollapse) {
      return (
        <div className="searchbar-collapsed" ref={this.searchInput}>
          <Hammer onTap={this.toggleCollapsed}>
            <img src="/img/search-grey.svg" className="searchIcon" />
          </Hammer>
          <Hammer onTap={this.toggleCollapsed}>
            <div className="defaultSearchText">What do you want to know about?</div>
          </Hammer>
        </div>
      )
    } else {
      return (
        <div className={`searchbar ${this.props.customClass}`}>
          {!this.props.iconRight && (
            <Hammer onTap={this.toggleCollapsed}>
              <img src="/img/search-grey.svg" className="searchIcon" />
            </Hammer>
          )}
          <input
            className={`searchInput ${this.props.customClass}`}
            autoFocus
            value={this.props.searchQuery}
            onChange={this.onInputChange}
            ref={this.searchInput}
            placeholder="Search for a topic..."
          />
          {this.props.changeSearchBarState && (
            <Hammer onTap={this.toggleCollapsed}>
              <div className="reset-button-container">
                <img src="/img/circle-x-grey.svg" className="resetIcon" />
              </div>
            </Hammer>
          )}
          {this.props.iconRight && (
            <Hammer onTap={this.toggleCollapsed}>
              <img src="/img/search-grey.svg" className="searchIcon" />
            </Hammer>
          )}
        </div>
      )
    }
  }
}

Searchbar.propTypes = {
  searchQuery: PropTypes.string,
  handleSearchChange: PropTypes.func,
  handleSearchReset: PropTypes.func,
  changeSearchBarState: PropTypes.func,
  searchbarCollapsed: PropTypes.bool,
}
