import React from 'react'
import Modal from 'react-modal'
import Hammer from 'react-hammerjs'
import converter from 'json-2-csv'
import modalStyle from './modalStyle'
import _ from 'lodash'
import FileReaderInput from 'react-file-reader-input'
import FileSaver from 'file-saver'

export default class EnterTitleModal extends React.Component {
  constructor() {
    super()
    this.state = {
      mainLine: '',
      learnMore: '',
      csv: '',
    }
    this.onMainLineChange = this.onMainLineChange.bind(this)
    this.onLearnMoreChange = this.onLearnMoreChange.bind(this)
    this.onCSVUpdated = this.onCSVUpdated.bind(this)
    this.downloadQuestionOptionCSV = this.downloadQuestionOptionCSV.bind(this)
    this.handleOptionWeightsUpload = this.handleOptionWeightsUpload.bind(this)
  }

  componentWillMount() {
    this.jsonToCSV(this.props.results || [])
    // this.jsonToCSV([
    //   {
    //     Make: 'Nissan',
    //     Model: 'Murano',
    //     Year: '2013',
    //     Specifications: { Mileage: '7106', Trim: 'S AWD' }
    //   },
    //   {
    //     Make: 'BMW',
    //     Model: 'X5',
    //     Year: '2014',
    //     Specifications: { Mileage: '3287', Trim: 'M' }
    //   }
    // ])
  }

  onMainLineChange(ev) {
    this.setState({
      mainLine: ev.target.value,
    })
  }

  onLearnMoreChange(ev) {
    this.setState({
      learnMore: ev.target.value,
    })
  }

  jsonToCSV(json) {
    if (!json || !json.length) {
      return ''
    }
    return converter.json2csv(
      json,
      (err, csv) => {
        this.setState({ csv })
      },
      {
        prependHeader: false,
        delimiter: {
          wrap: "'",
        },
        keys: [
          'title',
          'description',
          'imageUrl',
          ...this.props.dimensions
            .concat()
            .sort()
            .map(d => `dimensions.${d}`),
        ],
        // keys: `title, description, imageUrl, ${this.props.dimensions
        //   .concat()
        //   .sort()
        //   .map(d => `dimensions.${d}`)
        //   .join(', ')}\n`.split(',')
      }
    )
  }

  onCSVUpdated(ev) {
    const str =
      `title, description, imageUrl, ${this.props.dimensions
        .concat()
        .sort()
        .map(d => `dimensions.${d}`)
        .join(', ')}\n` + ev.target.value
    this.setState({ csv: ev.target.value })
    return converter.csv2json(
      str,
      (err, json) => {
        this.props.setResults(json)
        // TODO: here we want to update results globally, listen for it in didReceiveProps, and then convert to csv for rendering
      },
      {
        trimHeaderFields: true,
        trimFieldValues: true,
        delimiter: {
          wrap: "'",
        },
      }
    )
  }

  downloadQuestionOptionCSV() {
    // need questions and options
    // let user upload csv?
    const body = this.props.questions
      .filter(q => q.spectrumQ)
      .reduce((acc, q, i) => {
        acc += q.options
          .filter(o => o.text)
          .reduce(
            (accO, o, j) =>
              (accO += `${i},${j},Q${i + 1},'${o.value.split(',')[0]}',${Object.keys(
                o.typologyWeights || {}
              )
                .sort()
                .map(key => o.typologyWeights[key])
                .join(',')}\n`),
            ''
          )
        return acc
      }, '')

    const csv =
      `questionIndex,optionIndex,question,option,${this.props.dimensions
        .concat()
        .sort()
        .map(d => `dimensions.${d}`)
        .join(', ')}\n` + body

    let blob = new Blob([csv], { type: 'text/csv' })
    FileSaver.saveAs(blob, 'thing.csv')
  }

  handleOptionWeightsUpload(e, results) {
    const [content] = results[0]
    const text = content.target.result

    converter.csv2json(
      text,
      (err, json) => {
        if (err) {
          console.log(err)
        }
        json.forEach(questionOption => {
          this.props.editOptionTypologyWeights({
            questionIndex: parseInt(questionOption.questionIndex),
            optionIndex: parseInt(questionOption.optionIndex),
            typologyWeights: questionOption.dimensions,
          })
        })
        // this.props.setResults(json)
        // TODO: here we want to update results globally, listen for it in didReceiveProps, and then convert to csv for rendering
      },
      {
        trimHeaderFields: true,
        trimFieldValues: true,
        delimiter: {
          wrap: "'",
        },
      }
    )
    // results.forEach(result => {
    //   const [e, file] = result
    //   console.log(e.target.result)
    //   console.log(file)
    // })
  }

  // want to let user add results here.
  // for each result, needs to set the:
  // name
  // dimensions corresponding
  // description
  // image (optional)
  // render() {
  //   return (
  //     <Modal
  //       isOpen={this.props.isOpen}
  //       style={modalStyle}
  //       contentLabel="spectrum config modal"
  //     >
  //       <div className="top-row">
  //         <span className="modal-text">Typology Configuration</span>
  //         <div className="modal-buttons-container">
  //           <Hammer onTap={this.props.toggle}>
  //             <div className="save modal-button">
  //               <div>Close</div>
  //             </div>
  //           </Hammer>
  //         </div>
  //       </div>
  //       <input
  //         className="modal-input"
  //         type="text"
  //         value={this.props.typologyTopLine}
  //         onChange={this.props.setTypologyTopLine}
  //         placeholder="You scored more <happy> than"
  //       />
  //       <span className="modal-text">Possible Categories</span>
  //       <div className="possible-categories">
  //         {[
  //           {
  //             category: 'cat1',
  //             dimensions: {},
  //             description: 'oh fuck yeah',
  //             imageUrl: null
  //           },
  //           {
  //             category: 'cat2',
  //             dimensions: {},
  //             description: null,
  //             imageUrl: null
  //           }
  //         ].map(result => (
  //           <div className="typology-category">
  //             <div className="fieldRow">
  //               <span>Title:</span>
  //               <input value={result.category} />
  //             </div>
  //             <textarea
  //               className="modal-textbox"
  //               type="text"
  //               value={result.description}
  //               placeholder={
  //                 'Ravenclaw House prizes learning, wisdom, wit, and intellect in its members.'
  //               }
  //             />
  //             <div className="fieldRow">
  //               <span>Image (optional):</span>
  //               <input value={result.imageUrl} />
  //             </div>
  //             <div className="fieldRow">
  //               <span>Dimensions:</span>
  //               <div className="dimension-inputs">
  //                 {this.props.dimensions.map(d => (
  //                   <div className="fieldRow">
  //                     <span>{d}</span>
  //                     <input value={result.dimensions[d.toLowerCase()]} />
  //                   </div>
  //                 ))}
  //               </div>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </Modal>
  //   )
  // }

  render() {
    return (
      <Modal isOpen={this.props.isOpen} style={modalStyle} contentLabel="spectrum config modal">
        <div className="top-row">
          <span className="modal-text">Typology Configuration</span>
          <div className="modal-buttons-container">
            <Hammer onTap={this.props.toggle}>
              <div className="save modal-button">
                <div>Close</div>
              </div>
            </Hammer>
          </div>
        </div>
        <input
          className="modal-input"
          type="text"
          value={this.props.typologyTopLine}
          onChange={this.props.setTypologyTopLine}
          placeholder="You scored more <happy> than"
        />
        <button className="typology-button" onClick={this.downloadQuestionOptionCSV}>
          Download Option Weight Template
        </button>
        <label htmlFor="my-file-input" className="typology-label">
          Upload Option Weights:
        </label>
        <FileReaderInput as="text" id="my-file-input" onChange={this.handleOptionWeightsUpload}>
          <button className="typology-button">Select a file!</button>
        </FileReaderInput>
        <span className="modal-text">Possible Categories</span>
        <div className="category-section">
          <div className="entry-section">
            <span>{`title, description, imageUrl, ${this.props.dimensions
              .concat()
              .sort()
              .map(d => `dimensions.${d}`)
              .join(', ')}`}</span>
            <textarea
              value={this.state.csv}
              placeholder={`ENTJ, a very nice person, https://photos.com/1, ${this.props.dimensions.map(
                () => 1
              )}\nINTJ, also a very nice person, https://photos.com/1, ${this.props.dimensions.map(
                () => 0
              )}`}
              onChange={this.onCSVUpdated}
            />
          </div>
          <div className="results-section">
            <pre style={{ textAlign: 'left' }}>
              <code>{`${JSON.stringify(this.props.results, null, 4)}`}</code>
            </pre>
          </div>
        </div>
      </Modal>
    )
  }
}
