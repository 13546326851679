import React from 'react'

import OptionsBox from './options-box/options-box'
import MessageBox from './message-box/message-box'
import NoTypeBox from './no-type-box/no-type-box'
import NumericBox from './numeric-box/numeric-box'

import { connect } from 'react-redux'

class QuestionBody extends React.Component {
  render() {
    const props = this.props
    let currentQuestion = props.questions[props.currentQuestionIndex]

    if (!currentQuestion) {
      return <div></div>
    }

    let questionBody

    if (currentQuestion.type === 'mc') {
      if (this.props.maxSelect > 0) {
        questionBody = (
          <div style={{ textAlign: 'left', width: '100%' }}>
            <span
              style={{
                color: '#9b9b9b',
                marginLeft: '18%',
                position: 'relative',
                top: '20px',
              }}
            >{`Select up to ${this.props.maxSelect} answer options`}</span>
            <OptionsBox viewOnly={props.viewOnly} />
          </div>
        )
      } else {
        questionBody = <OptionsBox viewOnly={props.viewOnly} />
      }
    } else if (currentQuestion.type === 'message') {
      questionBody = (
        <MessageBox onMessageChange={props.onMessageChange} viewOnly={props.viewOnly} />
      )
    } else if (currentQuestion.type === 'text') {
      questionBody = <MessageBox onMessageChange={null} viewOnly={true} textEntry={true} />
    } else if (currentQuestion.type === 'noType') {
      questionBody = <NoTypeBox />
    } else if (currentQuestion.type === 'numeric') {
      questionBody = <NumericBox />
    }

    return (
      <div className="results-container">
        {currentQuestion.imageUrl && (
          <div className="question-image-container-main">
            <img src={currentQuestion.imageUrl} />
          </div>
        )}
        {questionBody}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
  currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
})

export default connect(mapStateToProps, {})(QuestionBody)
