import request from './request'
import prefix from './prefix'

exports.one = function (id) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/group/${id}`).end((err, res) => {
      if (err) return reject(err)

      resolve(res.body)
    })
  })
}
