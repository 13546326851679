import request from './request'
import prefix from './prefix'
import FileSaver from 'file-saver'
import storage from '../lib/storage'

//import { increment } from '../clients/admin/store'
//import {store} from '../clients/admin/store'

exports.getOneSchoolById = function (schoolId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/panel/oneById/${schoolId}`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.mySurveys = function (page, searchQuery) {
  return new Promise((resolve, reject) => {
    request
      .get(prefix + '/api/admin/survey/mine')
      .query({ page })
      .query({ searchQuery })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.downloadSurveyPDFLink = _id => {
  return `${prefix}/api/surveypdf/${_id}?token=${storage.get('token')}`
}

exports.one = function (surveyId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/admin/survey/${surveyId}`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.createSurvey = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/admin/survey/create')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.editSurvey = function (data, surveyId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/admin/survey/${surveyId}/edit`)
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.submitSurvey = function (surveyId, stripeToken) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/admin/survey/${surveyId}/submit`)
      .send({ stripeToken })
      .end((err, res) => {
        if (res.body && res.body.err) return reject(res.body.err)
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.deleteSurvey = function (surveyId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/admin/survey/${surveyId}/delete`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.setConclusions = function (conclusions, surveyId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/admin/survey/${surveyId}/setconclusions`)
      .send(conclusions)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.downloadResults = function (surveyId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/admin/survey/${surveyId}/csv`).end((err, res) => {
      if (err) return reject(err)

      var blob = new Blob([res.text], { type: 'text/csv' })
      FileSaver.saveAs(blob, 'college_pulse_data.csv')

      return resolve('success')
    })
  })
}

exports.searchUsers = function (query) {
  return new Promise((resolve, reject) => {
    request
      .get(prefix + '/api/admin/user/search')
      .query({ query: query })
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.getUserByEmail = function (query) {
  return new Promise((resolve, reject) => {
    request
      .get(prefix + '/api/admin/user/byEmail')
      .query({ email: query })
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.searchGroups = function (query) {
  return new Promise((resolve, reject) => {
    request
      .get(prefix + '/api/admin/groups/search')
      .query({ query: query })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.checkParticipantsData = function (id) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/admin/survey/${id}/checktaken`).end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

exports.publish = function (surveyId, stripeToken, promoCode) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/master/survey/${surveyId}/publish`)
      .send({ stripeToken, promoCode })
      .end((err, res) => {
        if (res.body && res.body.err) return reject(res.body.err)
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.unpublish = function (surveyId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/master/survey/${surveyId}/unpublish`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.close = function (surveyId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/master/survey/${surveyId}/close`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.unclose = function (surveyId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/master/survey/${surveyId}/unclose`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.createLongitudinal = function (surveyId, { frequencyInDays, numberRuns, sampleSize }) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/survey/longitudinal/${surveyId}`)
      .send({ frequencyInDays, numberRuns, sampleSize })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.duplicateSurvey = function (surveyId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/admin/survey/${surveyId}/duplicate`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

exports.createBrandTracker = function (data) {
  const url = '/api/brand-tracker'
  return new Promise((resolve, reject) => {
    request
      .post(prefix + url)
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        resolve(res.body)
      })
  })
}

exports.fetchSurveyProperty = function ({ _id, property }) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/surveycreate/${_id}/${property}`).end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

// Return one school from database from a search query
exports.getOneSchool = function ({ name }) {
  return new Promise((resolve, reject) => {
    request
      .get(
        prefix +
          `/api/demographics/searchschools?search=${encodeURIComponent(name)}&directMatch=true`
      )
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body[0])
      })
  })
}

// Get school name from ID from database
exports.getSchoolName = function (id) {
  return new Promise((resolve, reject) => {
    request
      .get(prefix + '/api/demographics/schoolnames/_id')
      .query({ school_id: [id] })
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.setSurveyProperty = function ({ _id, property, value, store }) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + `/api/surveycreate/${_id}`)
      .set('content-type', 'application/json')
      .send({ [property]: value })
      .end((err, res) => {
        if (err) return reject(err)

        //increment()
        store.dispatch({ type: 'INCREMENT_VERSION' })
        return resolve(res.body)
      })
  })
}

exports.getFieldingProgress = function ({ _id, category }) {
  return new Promise((resolve, reject) => {
    request
      .get(prefix + `/api/admin-tooling/fielding-progress/${_id}/${category || ''}`)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}
