import api from '../../../api'
import { setActiveSurvey } from './set-survey-actions'
import { assignUniqueKeysAndTemporaryVariables } from './edit-survey-questions-actions'
import { setJustSavedForPeriod } from '../cv-questions-builder-actions'
import { setSpecificFreeInputUsers } from './temp-demographics-actions'
import _ from 'lodash'
import defaultQuestions from '../lib/default-questions'
import { history } from '../../../index'

export const ActionTypes = {
  SET_ACTIVE_SURVEY: 'SET_ACTIVE_SURVEY',
  SET_SURVEY_TARGETING_OPTIONS: 'SET_SURVEY_TARGETING_OPTIONS',
  SET_SAVE_ERROR: 'SET_SAVE_ERROR',
  INCREMENT_VERSION: 'INCREMENT_VERSION',
}

/**
 * Initial creation of survey when moving on from the tags view
 */
export function initialSurveySave(survey, { shouldClose, pageToOpenTo, newSurveyTitle }) {
  return dispatch => {
    survey.questionCount = survey.questions.length

    survey.questions = survey.questions.filter(q => q.type !== 'noType')

    if (!survey.title) {
      survey.title = newSurveyTitle || 'New Survey'
    }
    api.admin.createSurvey(survey).then(survey => {
      // push id into route. Even if we are closing, push id into route anyways so that if they
      // go back, they go to the current survey (since url will contain correct id)
      history.push(`/create/survey/metadata/${survey.id}`)
      if (shouldClose) {
        history.push('/create/')
      }
      dispatch(setActiveSurvey(survey.id, null, pageToOpenTo))
      dispatch(setJustSavedForPeriod())
    })
  }
}

/**
 * Increment Version
 */
export function incrementVersion() {
  return dispatch =>
    dispatch({
      type: ActionTypes.INCREMENT_VERSION,
    })
}

/**
 * Save survey
 *
 * @param {Object} survey the survey object to save
 * @param {Boolean} shouldFlash whether or not the save sign should flash a check
 * @param {Function} whenDone (OPTIONAL) functional to call when done
 */
export function saveSurvey(survey, shouldFlash, whenDone) {
  return dispatch => {
    survey = _.cloneDeep(survey)

    // TODO: are we removing null options?

    survey.questionCount = survey.questions.length

    api.admin
      .editSurvey(survey, survey.id)
      .then(newSurvey => {
        if (!newSurvey.questions || !newSurvey.questions.length) {
          newSurvey.questions.push(defaultQuestions.noType)
        }

        dispatch({
          type: ActionTypes.SET_ACTIVE_SURVEY,
          payload: newSurvey,
        })

        dispatch(assignUniqueKeysAndTemporaryVariables(newSurvey.questions))

        if (shouldFlash) {
          dispatch(setJustSavedForPeriod())
        }

        if (typeof whenDone === 'function') {
          whenDone()
        }
      })
      .catch(err => {
        // Did saving the survey fail?
        dispatch({
          type: ActionTypes.SET_SAVE_ERROR,
        })
      })
  }
}

/*** FUNCTIONS TO MAP THE TEMPORARY DEMOGRAPHICS STATE TO THE ACTIVE SURVEY ***/

export function putTempTargetedIndividualsOnSurveyAndSave(
  tempTargetingState,
  survey,
  { newSurveyTitle, shouldClose, pageToOpenTo }
) {
  return dispatch => {
    survey = _.cloneDeep(survey)

    // map free input users to targeted individuals
    let targetedIndividuals = survey.targetedIndividuals
    let freeInputUsers = tempTargetingState.specificFreeInputUsers.split(/[ ,]+/)

    freeInputUsers.forEach(userEmail => {
      userEmail = userEmail.trim()
      if (
        userEmail.split(' ').length === 1 &&
        userEmail.indexOf('@') > -1 &&
        userEmail.length < 100
      ) {
        targetedIndividuals.push(userEmail)
      }
    })

    dispatch(setSpecificFreeInputUsers(''))

    // let groupPromises = tempTargetingState.specificSelectGroups.map((group) => {
    //   return api.group.one(group.value)
    //     .then((group) => {
    //       group.users.map((user) => {
    //         targetedIndividuals.push(user)
    //       })
    //     })
    // })

    // Promise.all(groupPromises).then(() => {
    // dispatch({
    //   type: ActionTypes.SET_SURVEY_TARGETING_OPTIONS,
    //   payload: {
    //     targetingMechanism: 'individuals',
    //     targetedIndividuals,
    //     targetedDemographics: null,
    //     priceBy: null,
    //   }
    // })

    survey.targetingMechanism = 'individuals'
    survey.targetedIndividuals = targetedIndividuals

    if (!survey.title) {
      survey.title = newSurveyTitle || 'No Title'
    }

    if (survey.id) {
      dispatch(saveSurvey(survey, true))
    } else {
      dispatch(initialSurveySave(survey, { shouldClose: shouldClose, pageToOpenTo }))
    }
  }
}
