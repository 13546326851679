import React from 'react'
import Hammer from 'react-hammerjs'

import InputBox from '../../../../../../../common/input-box/input-box'

export default class MCSettingsPanel extends React.Component {
  constructor() {
    super()
    this.state = {
      showSpectrumModal: false,
      showTypologyModal: false,
    }
    this.toggleSpectrumModal = this.toggleSpectrumModal.bind(this)
    this.toggleTypologyModal = this.toggleTypologyModal.bind(this)
    this.setTypologyWeight = this.setTypologyWeight.bind(this)
    this.changeDimensionName = this.changeDimensionName.bind(this)
  }
  toggleSpectrumModal() {
    this.setState({ showSpectrumModal: !this.state.showSpectrumModal })
  }
  toggleTypologyModal() {
    this.setState({ showTypologyModal: !this.state.showTypologyModal })
  }
  setTypologyWeight(dimension) {
    return ev => this.props.setTypologyWeight({ dimension, weight: ev.target.value })
  }
  changeDimensionName(index) {
    return ev => this.props.changeDimensionName(index, ev.target.value)
  }
  /**
   * callback
   * @return {[type]} [description]
   */
  render() {
    const props = this.props
    return (
      <div className="edit-panel">
        <span className="edit-panel-title">QUESTION OPTIONS</span>
        {props.options.map(option => [
          option.customLabel ? (
            <div className="edit-panel-option">
              <div onClick={option.callback}>
                <InputBox
                  name={option.key}
                  color="questionHeaderColor"
                  backgroundColor="#FFFFFF"
                  selected={option.selected}
                  key={option.key}
                />
              </div>
              {option.customLabel}
            </div>
          ) : (
            <Hammer onTap={option.callback}>
              <div className="edit-panel-option">
                <InputBox
                  name={option.key}
                  color="questionHeaderColor"
                  backgroundColor="#FFFFFF"
                  selected={option.selected}
                  key={option.key}
                />
                <div className="edit-panel-option-label">{option.title}</div>
              </div>
            </Hammer>
          ),
          option.selected && option.extension ? option.extension : <div />,
        ])}
      </div>
    )
  }
}
