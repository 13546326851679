import React, { useState, useEffect } from 'react'
import { fetchSurveyProperty, setSurveyProperty } from '../../../../../api/admin'
import { Input, Button } from 'semantic-ui-react'
import { store } from '../../../../store'

export default ({ _id }) => {
  // State
  const [maxCount, setMaxCount] = useState(null)
  const [changesMade, setChangesMade] = useState(false)

  // Get data
  useEffect(() => {
    fetchSurveyProperty({
      _id: _id,
      property: 'maxNumberOfParticipants',
    }).then(response => {
      setMaxCount(response)
    })
  }, [])

  const sendConfig = () => {
    setChangesMade(false)
    setSurveyProperty({
      _id: _id,
      property: 'maxNumberOfParticipants',
      value: maxCount ? maxCount : null,
      store: store,
    })
  }

  return (
    <div className="count-view-container">
      <Button
        style={{ margin: '1rem 0rem' }}
        color={changesMade ? 'green' : undefined}
        onClick={() => sendConfig()}
        disabled={!changesMade}
      >
        Update Response Count
      </Button>

      <h5>Maximum amount of survey responses</h5>
      <Input
        type="number"
        value={maxCount}
        placeholder="Enter a number..."
        onChange={e => {
          setMaxCount(e.target.value || null)
          setChangesMade(true)
        }}
      />
    </div>
  )
}
