import React from 'react'
import { Card, Button } from 'semantic-ui-react'

export default ({ activeGroup, devMode, setDevMode }) => {
  return (
    <>
      <hr />
      <section>
        <h4>{activeGroup?.title || 'No title found'}</h4>
        <p>
          <em>
            {activeGroup?.startTime || 'NA'} - {activeGroup?.endTime || 'NA'}
          </em>
        </p>
      </section>
      <hr />
      <section>
        {activeGroup?.adSets.map(adSet => (
          <Card
            fluid
            href={`https://business.facebook.com/adsmanager/manage/ads?act=843447062482511&business_id=843446909149193&global_scope_id=843446909149193&columns=name%2Cdelivery%2Ccampaign_name%2Cbid%2Cbudget%2Clast_significant_edit%2Cattribution_setting%2Cresults%2Creach%2Cimpressions%2Ccost_per_result%2Cquality_score_organic%2Cquality_score_ectr%2Cquality_score_ecvr%2Cspend%2Cend_time%2Cschedule%2Cactions%3Alink_click%2Cactions%3Aoffsite_conversion.fb_pixel_purchase%2Cactions%3Aonsite_conversion.purchase&attribution_windows=default&selected_campaign_ids=23843907342210602&selected_adset_ids=${adSet.fbAdSetId}`}
          >
            <Card.Content>
              <Card.Header>
                Ad for: <span style={{ fontWeight: 300 }}>{adSet.schoolName}</span>
              </Card.Header>
              <Card.Description>Status: {adSet?.status || 'NA'}</Card.Description>
            </Card.Content>
          </Card>
        ))}
        <hr />
        <Button onClick={() => setDevMode(!devMode)}>Turn dev mode {devMode ? 'off' : 'on'}</Button>
        {devMode && (
          <>
            <pre>{JSON.stringify(activeGroup?.adSets, null, 2)}</pre>
            <hr />
            <pre>{JSON.stringify(activeGroup, null, 2)}</pre>
          </>
        )}
      </section>
    </>
  )
}
