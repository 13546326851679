import urljoin from 'url-join'
import request from './request'
import prefix from './prefix'
import signin from './signin'
import storage from '../lib/storage'
import queryString from 'query-string'

exports.downloadToplineLink = surveyId =>
  `${prefix}/api/topline/recent/${surveyId}?token=${storage.get('token')}`

exports.downloadHighlightsPDFLink = surveyId =>
  `${prefix}/api/survey/highlightsFile/${surveyId}?token=${storage.get('token')}`

exports.downloadCrossTabLink = (surveyId, demographics, filterSettings) =>
  `${prefix}/api/crosstabs/${surveyId}?${demographics
    .map(dem => `categories=${dem}`)
    .join('&')}&token=${storage.get('token')}&variableFilters=${JSON.stringify(
    filterSettings || {}
  )}`

exports.downloadMethodologyLink = surveyOrQuestionId =>
  `${prefix}/api/topline/methodology/${surveyOrQuestionId}`

exports.downloadCommentsLink = questionId =>
  `${prefix}/api/comment/export/${questionId}?token=${storage.get('token')}`

/*
 * metadata: fetch survey metadata
 * in accordance with search query
 */
exports.metadata = function (searchQuery) {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/survey/metadata' + `?searchQuery=${searchQuery}`).end((err, res) => {
      if (err) return reject(err)

      resolve(res.body)
    })
  })
}

/*
 * fetchAll: Fetch all surveys.
 */
exports.fetchAll = function (sortBy, page, searchQuery, clientOnly) {
  searchQuery = searchQuery || ''
  const query = `?sortby=${sortBy}&page=${page}&searchQuery=${searchQuery}&clientOnly=${clientOnly}`

  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/survey/fetchAll' + query).end((err, res) => {
      if (err) return reject(err)
      resolve(res.body)
    })
  })
}

/*
 * available: fetch available surveys
 */
exports.available = function (sortBy, page, searchQuery) {
  const query = `?sortby=${sortBy}&page=${page}&searchQuery=${searchQuery}`

  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/survey/available' + query).end((err, res) => {
      if (err) return reject(err)

      resolve(res.body)
    })
  })
}

/*
 * completed: fetch completed surveys
 */
exports.completed = function (sortBy, page, searchQuery) {
  const query = `?sortby=${sortBy}&page=${page}&searchQuery=${searchQuery}`

  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/survey/completed' + query).end((err, res) => {
      if (err) return reject(err)

      resolve(res.body)
    })
  })
}

exports.topQuestions = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/questions/topQuestions').end((err, res) => {
      if (err) return reject(err)
      resolve(res.body)
    })
  })
}

exports.one = function (id, options = {}) {
  const { skipDems, longDems, snowballSample, sbConvenience, schoolName } = options
  const queryParams = queryString.parse(location.search)
  let url = signin.isSignedIn() ? `/api/survey/${id}` : `/api/survey/external/${id}`

  if (!signin.isSignedIn()) {
    if (snowballSample && sbConvenience && schoolName) {
      url = urljoin(
        url,
        `?sb_convenience=${sbConvenience}&snowballsample=true&schoolName=${schoolName}`
      )
      const dontAppend = ['sb_convenience', 'snowballsample', 'schoolName']
      Object.keys(queryParams)
        .filter(key => !dontAppend.includes(key))
        .map(key => `&${key}=${queryParams[key]}`)
        .forEach(append => (url = urljoin(url, append)))
    } else {
      url = urljoin(
        url,
        `${window.location.search || '?'}&skipDems=${skipDems}&longDems=${longDems}`
      )
    }
  }

  return new Promise((resolve, reject) => {
    request.get(prefix + url).end((err, res) => {
      if (err) return reject(err)

      resolve(res.body)
    })
  })
}

exports.oneSummary = function (id) {
  const url = `/api/survey/summary/${id}`
  return new Promise((resolve, reject) => {
    request.get(prefix + url).end((err, res) => {
      if (err) return reject(err)

      resolve(res.body)
    })
  })
}

exports.oneQ = function (id) {
  const url = `/api/question/${id}`
  return new Promise((resolve, reject) => {
    request.get(prefix + url).end((err, res) => {
      if (err) return reject(err)

      resolve(res.body)
    })
  })
}
