import React from 'react'
import Modal from 'react-modal'
import Hammer from 'react-hammerjs'
import modalStyle from './modalStyle'

export default class EnterTitleModal extends React.Component {
  constructor() {
    super()
    this.state = {
      mainLine: '',
      learnMore: '',
    }
    this.onMainLineChange = this.onMainLineChange.bind(this)
    this.onLearnMoreChange = this.onLearnMoreChange.bind(this)
  }
  onMainLineChange(ev) {
    this.setState({
      mainLine: ev.target.value,
    })
  }
  onLearnMoreChange(ev) {
    this.setState({
      learnMore: ev.target.value,
    })
  }
  render() {
    return (
      <Modal isOpen={this.props.isOpen} style={modalStyle} contentLabel="spectrum config modal">
        <span className="modal-text">Spectrum Configuration</span>
        <input
          className="modal-input"
          type="text"
          value={this.props.spectrumName}
          onChange={this.props.updateSpectrumName}
          placeholder="You scored more <happy> than"
        />
        <textarea
          className="modal-textbox"
          type="text"
          value={this.props.spectrumLearnMore}
          onChange={this.props.updateSpectrumLearnMore}
          placeholder={'<Gryffindor>\nThis is a good house\n<Hufflepuff>\nThis is a bad house'}
        />
        <div className="modal-buttons-container">
          <Hammer onTap={this.props.toggleSpectrumModal}>
            <div className="save modal-button">
              <div>Close</div>
            </div>
          </Hammer>
        </div>
      </Modal>
    )
  }
}
