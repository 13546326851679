import storage from './storage'

module.exports.setCampaign = function () {
  var qs = (function (a) {
    if (a == '') return {}
    var b = {}
    for (var i = 0; i < a.length; ++i) {
      var p = a[i].split('=', 2)
      if (p.length == 1) b[p[0]] = ''
      else {
        b[p[0]] = decodeURIComponent(p[1].replace(/\+/g, ' '))
      }
    }
    return b
  })(window.location.search.substr(1).split('&'))

  const campaignObj = {}
  if (qs.p_source) {
    campaignObj.source = qs['p_source']
  }
  if (qs.p_campaign) {
    campaignObj.campaign = qs['p_campaign']
  }

  if (campaignObj.source) {
    storage.set('p_campaign', campaignObj, false, 0.05)
  }
  // if a parameter is present in URL, set it in storage for 24 hours
  // else do nothing
}

module.exports.getCampaign = function () {
  const campaign = storage.get('p_campaign')

  if (!campaign || !campaign.source) {
    return { source: 'organic', campaign: 'none' }
  }

  return campaign
}
