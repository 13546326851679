import prefix from './prefix'
import request from './request'

exports.submitRequest = function (email, route) {
  return new Promise((resolve, reject) => {
    request
      .post(`${prefix}/api/password/reset`)
      .send({ email, route })
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.submitReset = function (resetId, password) {
  return new Promise((resolve, reject) => {
    request
      .post(`${prefix}/api/password/reset/${resetId}`)
      .send({ password })
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.markVisited = function (resetId) {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/password/reset/${resetId}`).end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

exports.fetchPasswordRecoveryOpts = function (username) {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/password/recoveryOpts/${username}`).end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

exports.submitSecurityAnswer = function (username, answer) {
  return new Promise((resolve, reject) => {
    request
      .post(`${prefix}/api/password/answer`)
      .send({ username, answer })
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.submitRecoveryRequest = function (username, recoveryEmail) {
  return new Promise((resolve, reject) => {
    request
      .post(`${prefix}/api/password/recoveryRequest`)
      .send({ username, recoveryEmail })
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}
