export const enabled = customStyle => ({
  option: provided => ({
    ...provided,
  }),
  control: provided => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    ...(customStyle || {}),
  }),
  singleValue: provided => {
    return { ...provided }
  },
})

export const disabled = customStyle => ({
  option: provided => ({
    ...provided,
  }),
  control: provided => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    ...(customStyle || {}),
  }),
  singleValue: provided => {
    return { ...provided }
  },
})
