import api from '../../api'
import _ from 'lodash'

export const ActionTypes = {
  SET_SURVEYS: 'SET_SURVEYS',
  APPEND_TO_SURVEYS: 'APPEND_TO_SURVEYS',
  RESET_SURVEY_PAGE: 'RESET_SURVEY_PAGE',
  INCREMENT_SURVEY_PAGE: 'INCREMENT_SURVEY_PAGE',
}

export function refreshSurveys(user, page, searchQuery, filterChecks = undefined) {
  const initial = page ? page === 0 : true
  user = user || {}
  return dispatch => {
    return new Promise(function (resolve, reject) {
      if (user.master) {
        api.master
          .surveysForMaster(page, searchQuery, filterChecks)
          .then(masterSurveys => {
            // api.admin.mySurveys()
            // .then(adminSurveys => {
            dispatch({
              type: initial ? ActionTypes.SET_SURVEYS : ActionTypes.APPEND_TO_SURVEYS,
              payload: masterSurveys,
            })
            resolve(masterSurveys)
            // })
            // .catch(err => {
            //   console.log(err)
            //   reject(err)
            // })
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      } else {
        api.admin
          .mySurveys(page, searchQuery)
          .then(surveys => {
            dispatch({
              type: initial ? ActionTypes.SET_SURVEYS : ActionTypes.APPEND_TO_SURVEYS,
              payload: surveys,
            })
            resolve(surveys)
          })
          .catch(err => {
            console.log(err)
            reject(err)
          })
      }
    })
  }
}

export function incrementPage() {
  return dispatch => {
    dispatch({
      type: ActionTypes.INCREMENT_SURVEY_PAGE,
    })
  }
}

export function resetSurveyPage() {
  return dispatch => {
    dispatch({
      type: ActionTypes.RESET_SURVEY_PAGE,
    })
  }
}
