import React from 'react'
import Modal from 'react-modal'
import modalStyle from './modalStyle'
import Avatar from '../../../../common/avatar/avatar'
import ReactFilepicker from 'react-filepicker'
import Hammer from 'react-hammerjs'

export default class SelectAuthorImgModal extends React.Component {
  constructor() {
    super()

    this.state = {
      imgUrl: null,
    }

    this.authorUploadKey = 'AUi37ssFTieo2GNZYg3Mwz'

    this.closeSelf = this.closeSelf.bind(this)
    this.saveAndCloseSelf = this.saveAndCloseSelf.bind(this)
    this.onImageSelected = this.onImageSelected.bind(this)
  }

  closeSelf() {
    this.setState({
      imgUrl: null,
    })
    this.props.hideAuthorImgModal()
  }

  saveAndCloseSelf() {
    if (this.state.imgUrl) {
      this.props.setNewAuthorImg(this.state.imgUrl)
    }

    this.closeSelf()
  }

  onImageSelected(blob) {
    if (blob.url) {
      this.setState({
        imgUrl: blob.url,
      })
    }
  }

  render() {
    const props = this.props

    const authorFilepickerOptions = {
      mimetype: 'image/*',
      container: 'modal',
      services: ['COMPUTER', 'URL', 'FACEBOOK', 'GOOGLE_DRIVE', 'DROPBOX', 'CONVERT'],
      cropRatio: 1 / 1,
      cropForce: true,
      imageMax: [250, 250],
    }

    return (
      <Modal isOpen={props.isOpen} style={modalStyle} contentLabel={'Author Image Modal'}>
        <div className="modal-author-container">
          {/* if they have selected a new image, display that. else, display survey author. else, display default */}
          <Avatar
            src={this.state.imgUrl || props.authorImgUrl || '/img/user-white.svg'}
            draggable={false}
          />
          <ReactFilepicker
            apikey={this.authorUploadKey}
            defaultWidget={true}
            mode="pick"
            options={authorFilepickerOptions}
            onSuccess={this.onImageSelected}
          />
        </div>
        <div className="modal-buttons-container">
          <Hammer onTap={this.closeSelf}>
            <div className="discard modal-button">
              <div>Cancel</div>
            </div>
          </Hammer>
          <Hammer onTap={this.saveAndCloseSelf}>
            <div className="save modal-button">
              <div>Save</div>
            </div>
          </Hammer>
        </div>
      </Modal>
    )
  }
}
