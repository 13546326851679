import React, { useEffect, useState } from 'react'
import { Grid, Card, Table, Icon } from 'semantic-ui-react'
import { ResponsivePie } from '@nivo/pie'
import _ from 'lodash'
import { getCampaignData } from '../../../../../api/sms'
import api from '../../../../../api'

export default ({ _id }) => {
  const [campaigns, setCampaigns] = useState(null)
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [campaignData, setCampaignData] = useState(null)
  const [loading, setLoading] = useState(false)

  // Get available compaigns
  useEffect(() => {
    const getData = async () => {
      const data = await api.sms.getSmsCampaigns(_id)
      setCampaigns(data)
    }
    getData()
  }, [])

  useEffect(() => {
    const getData = async () => {
      if (selectedCampaign) {
        setLoading(true)
        let newCampaignData = {
          institution: (await getCampaignData(selectedCampaign.campaignName, 'school')).school,
          gender: (await getCampaignData(selectedCampaign.campaignName, 'gender')).gender,
          sorting: {
            direction: null,
            column: null,
          },
        }
        setCampaignData(newCampaignData)
        setLoading(false)
      } else {
        setCampaignData(null)
      }
    }
    getData()
  }, [selectedCampaign])

  const sortData = column => {
    // Column is already there
    // There is no column
    // It's a different column

    if (campaignData.sorting.column && campaignData.sorting.column === column) {
      if (campaignData.sorting.direction === 'desc') {
        // Remove
        setCampaignData(current => ({
          ...current,
          sorting: {
            direction: null,
            column: null,
          },
        }))
      } else {
        // Add
        setCampaignData(current => ({
          ...current,
          sorting: {
            direction: 'desc',
            column: column,
          },
        }))
      }
    } else {
      // Set to Asc
      setCampaignData(current => ({
        ...current,
        sorting: {
          direction: 'asc',
          column: column,
        },
      }))
    }
  }

  return (
    <div style={{ marginTop: '1rem' }}>
      {!selectedCampaign && (
        <div>
          {campaigns && (
            <>
              {campaigns.map(camp => (
                <Card fluid onClick={() => setSelectedCampaign(camp)}>
                  <Card.Content>
                    <Card.Header>
                      <h3>{camp.campaignName}</h3>
                    </Card.Header>
                    <Card.Description>
                      {/* <h5>{camp.subject}</h5> */}
                      <p>Created at: {new Date(camp.createdAt).toLocaleDateString()}</p>
                    </Card.Description>
                  </Card.Content>
                </Card>
              ))}
            </>
          )}
          {!campaigns && (
            <>
              <p>Loading Campaigns</p>
            </>
          )}
        </div>
      )}

      {selectedCampaign && (
        <Grid>
          {loading && (
            <Grid.Row>
              <Grid.Column fluid>
                <h3>Loading data...</h3>
                <p>
                  <em>
                    <small>This might take a second...</small>
                  </em>
                </p>
              </Grid.Column>
            </Grid.Row>
          )}
          {!loading && campaignData && (
            <>
              <Grid.Row>
                <Grid.Column fluid>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>
                        <h3 style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
                          {_.startCase(selectedCampaign.campaignName)}
                        </h3>
                      </Card.Header>
                      <Card.Description>
                        <p>
                          <strong>Average Incentive:</strong>{' '}
                          {selectedCampaign?.averageIncentive || 'NA'}
                        </p>
                        <p>
                          <strong>Created At:</strong>{' '}
                          {new Date(selectedCampaign?.createdAt).toLocaleDateString() || 'NA'}
                        </p>
                        <p>
                          {/* <strong>Email Subject:</strong> {selectedCampaign?.subject || 'NA'} */}
                        </p>
                        <p>
                          <strong>Total Sent:</strong>{' '}
                          {_.sum(Object.values(campaignData.institution))}
                        </p>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width="8">
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Institution breakdown</Card.Header>
                      <Card.Description style={{ height: 300 }}>
                        <ResponsivePie
                          margin={{
                            top: 30,
                            bottom: 30,
                            left: 30,
                            right: 30,
                          }}
                          sortByValue
                          enableSliceLabels={false}
                          radialLabelsSkipAngle={15}
                          colors={{ scheme: 'set3' }}
                          fit
                          data={Object.keys(campaignData.institution).map(k => ({
                            id: _.startCase(k),
                            label: k,
                            value: campaignData.institution[k],
                          }))}
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
                <Grid.Column width="8">
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Gender</Card.Header>
                      <Card.Description style={{ height: 300 }}>
                        <ResponsivePie
                          margin={{
                            top: 30,
                            bottom: 30,
                            left: 30,
                            right: 30,
                          }}
                          sortByValue
                          enableSliceLabels={false}
                          radialLabelsSkipAngle={15}
                          colors={{ scheme: 'set3' }}
                          fit
                          data={Object.keys(campaignData.gender).map(k => ({
                            id: _.startCase(k),
                            label: k,
                            value: campaignData.gender[k],
                          }))}
                        />
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column fluid>
                  <Card fluid>
                    <Card.Content>
                      <Card.Header>Institutions Send Chart</Card.Header>
                      <Card.Description>
                        <Table celled sortable>
                          <Table.Header>
                            <Table.Row>
                              <Table.HeaderCell onClick={() => sortData('institution')}>
                                Institution
                                {campaignData?.sorting?.column === 'institution' &&
                                  campaignData.sorting.direction === 'asc' && (
                                    <Icon name="angle up" />
                                  )}
                                {campaignData?.sorting?.column === 'institution' &&
                                  campaignData.sorting.direction === 'desc' && (
                                    <Icon name="angle down" />
                                  )}
                              </Table.HeaderCell>
                              <Table.HeaderCell onClick={() => sortData('sent')}>
                                SMS sent
                                {campaignData?.sorting?.column === 'sent' &&
                                  campaignData.sorting.direction === 'asc' && (
                                    <Icon name="angle up" />
                                  )}
                                {campaignData?.sorting?.column === 'sent' &&
                                  campaignData.sorting.direction === 'desc' && (
                                    <Icon name="angle down" />
                                  )}
                              </Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {(campaignData.sorting.column === 'sent'
                              ? campaignData.sorting.direction === 'asc'
                                ? Object.entries(campaignData.institution).sort(
                                    (a, b) => b[1] - a[1]
                                  )
                                : Object.entries(campaignData.institution).sort(
                                    (a, b) => a[1] - b[1]
                                  )
                              : campaignData.sorting.direction === 'asc'
                              ? Object.keys(campaignData.institution)
                                  .sort()
                                  .map(i => [i, campaignData.institution[i]])
                              : Object.keys(campaignData.institution)
                                  .sort()
                                  .reverse()
                                  .map(i => [i, campaignData.institution[i]])
                            ).map(ins => (
                              <Table.Row>
                                <Table.Cell>{ins[0]}</Table.Cell>
                                <Table.Cell>{ins[1]}</Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
          <pre>{campaignData && JSON.stringify(campaignData, null, 2)}</pre>
        </Grid>
      )}
    </div>
  )
}
