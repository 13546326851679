import request from './request'
import prefix from './prefix'

exports.addSurveyToFavorite = function (surveyId) {
  return new Promise((resolve, reject) => {
    let myRequest = request
      .post(prefix + '/api/master/surveys/add-fav')
      .send({ surveyId: surveyId })

    myRequest.end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

exports.removeSurveyToFavorite = function (surveyId) {
  return new Promise((resolve, reject) => {
    let myRequest = request
      .post(prefix + '/api/master/surveys/remove-fav')
      .send({ surveyId: surveyId })

    myRequest.end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}
