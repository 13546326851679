import _ from 'lodash'
import {
  setCurrentQuestionIndex,
  setDeleteConfirmingIndex,
  setCurrentQuestionPermanentStatus,
} from '../cv-questions-builder-actions'
import { setDisplayMcOptionsUnderAddNew } from '../cv-questions-builder-sidebar-actions'
import { incrementMessageCount, decrementMessageCount } from './edit-survey-actions'
import defaultNewQuestions from '../lib/default-questions'

/**
 * Actions in this file alter the questions on the survey being
 * edited
 */

export const ActionTypes = {
  CHANGE_SURVEY_PROPERTY: 'CHANGE_SURVEY_PROPERTY',
  CHANGE_QUESTION_PROPERTY: 'CHANGE_QUESTION_PROPERTY',
  CHANGE_QUESTION_PROPERTY_BY_ID: 'CHANGE_QUESTION_PROPERTY_BY_ID',
  TOGGLE_WRITE_IN_OPTION: 'TOGGLE_WRITE_IN_OPTION',
  TOGGLE_MAX_SELECT: 'TOGGLE_MAX_SELECT',
  CHANGE_QUESTION_SUB_PROPERTY: 'CHANGE_QUESTION_SUB_PROPERTY',
  REPLACE_QUESTION: 'REPLACE_QUESTION',
  CHANGE_TYPOLOGY_WEIGHT: 'CHANGE_TYPOLOGY_WEIGHT',
  TOGGLE_FIXED_OPTION: 'TOGGLE_FIXED_OPTION',
  DUPLICATE_QUESTION: 'DUPLICATE_QUESTION',
}

/* unique key to attach to each new question (increment each time we attach) */
let nextQKey = 0

// we need all the things like create new question, edit title, edit options,
// edit multi/other/random, etc. Then we can just integrate that into the sidebar
// and the content and be done

export function insertNewQuestionAtIndex(options) {
  // deconstruct options object
  let { newQuestion, index, currentQuestions } = options

  return dispatch => {
    if (newQuestion.type === 'message') {
      dispatch(incrementMessageCount(1))
    }

    // assign unique key
    newQuestion.key = nextQKey
    nextQKey += 1

    let questions = _.cloneDeep(currentQuestions)
    questions.splice(index, 0, newQuestion)

    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'questions',
        value: questions,
      },
    })
    dispatch(setCurrentQuestionIndex(index))
  }
}

// wrap function calls in dispatch. Call this function from the various components,
// import the default questions and use the passed in type to find right new q
export function appendNewQuestionAfterCurrent(type, options) {
  let { currentQIsPermanent, currentQuestions, currentQuestionIndex } = options

  return dispatch => {
    // if the previously created question had not been edited at all
    // and we have not changed questions since creating it, delete it
    // if (currentQuestions.length > 0 && !currentQIsPermanent) {
    //   if (currentQuestions[currentQuestionIndex].type === 'message'){
    //     dispatch(decrementMessageCount(1))
    //   }
    //   currentQuestions = _.cloneDeep(currentQuestions)
    //   currentQuestions.splice(currentQuestionIndex, 1)
    //
    //   currentQuestionIndex = currentQuestionIndex === 0 ? 0 : currentQuestionIndex - 1
    // }

    let question = _.cloneDeep(defaultNewQuestions[type])

    // if pressed add new mc, show an options menu under the add new mc button
    dispatch(setDisplayMcOptionsUnderAddNew(question.type === 'mc'))

    dispatch(setCurrentQuestionPermanentStatus(false))

    // append the question after current question, or beginning if none exist
    let insertIndex = currentQuestions.length === 0 ? 0 : currentQuestionIndex + 1

    dispatch(
      insertNewQuestionAtIndex({
        newQuestion: question,
        index: insertIndex,
        currentQuestions: currentQuestions,
      })
    )
  }
}
export function removeQuestionAtIndex({ index, questions, currentQuestionIndex }) {
  return dispatch => {
    if (questions[index].type === 'message') {
      dispatch(decrementMessageCount(1))
    }

    // if we are deleting current question, must make another question current
    if (currentQuestionIndex === index) {
      currentQuestionIndex = currentQuestionIndex === 0 ? 0 : currentQuestionIndex - 1
    }

    questions = _.cloneDeep(questions)
    questions.splice(index, 1)
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'questions',
        value: questions,
      },
    })

    dispatch(setDeleteConfirmingIndex(-1))
    dispatch(setCurrentQuestionIndex(currentQuestionIndex))
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function changeQuestionType({ index, question, newQuestionType }) {
  return dispatch => {
    let newQuestion = defaultNewQuestions[newQuestionType]
    newQuestion.description = question.description
    newQuestion.key = question.key

    dispatch({
      type: ActionTypes.REPLACE_QUESTION,
      payload: {
        index,
        newQuestion,
      },
    })
  }
}

export function reorderQuestions({
  newOrderByOldIndices,
  oldOrderedQuestions,
  currentQuestionKey,
}) {
  return dispatch => {
    let newOrderedQuestions = []
    newOrderByOldIndices.forEach(index => {
      newOrderedQuestions.push(oldOrderedQuestions[index])
    })

    let newCurrentQuestionIndex = newOrderedQuestions.findIndex(q => {
      return q.key === currentQuestionKey
    })

    if (newCurrentQuestionIndex < 0) {
      newCurrentQuestionIndex = 0
    }

    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'questions',
        value: newOrderedQuestions,
      },
    })
    dispatch(setCurrentQuestionIndex(newCurrentQuestionIndex))
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function assignUniqueKeysAndTemporaryVariables(questions) {
  return dispatch => {
    questions = _.cloneDeep(questions)
    questions.forEach(q => {
      // unique key
      q.key = nextQKey
      nextQKey++
    })
    dispatch({
      type: ActionTypes.CHANGE_SURVEY_PROPERTY,
      payload: {
        propertyName: 'questions',
        value: questions,
      },
    })
  }
}

export function toggleRandomize(index, randomize) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_SUB_PROPERTY,
      payload: {
        index,
        topLevel: 'optionOrderSettings',
        propertyName: 'randomizeOptions',
        value: randomize,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function toggleIsScreenerQ(index, value) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'isScreenerQ',
        value: value,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function toggleHideQuestionFromDE(index, value) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'hideQuestionFromDE',
        value: value,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function toggleFixedOption(index, value) {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_FIXED_OPTION,
      payload: {
        index,
        value,
      },
    })
  }
}

export function setQuizConfiguration(index, quizSettings) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'quizSettings',
        value: quizSettings,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function toggleRequired(index, required) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'required',
        value: required,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function toggleLogic(index, isLogic) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'logicQuestion',
        value: isLogic,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function setTextResponseCount(index, textResponseCount) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'textResponseCount',
        value: textResponseCount,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function setStaticSkipTo(index, value) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'staticSkipTo',
        value,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function toggleOther(index, allowOther) {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_WRITE_IN_OPTION,
      payload: {
        questionIndex: index,
        isWriteIn: allowOther,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
    // if allowOther === true, should add an option with value 'other' (if it does not already exist)
    // else, should remove any option with value 'other'
  }
}

export function toggleYesNo(index, yesNo) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'yesNo',
        value: yesNo,
      },
    })
  }
}

export function changeQuestionDescription(index, newTitle) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'description',
        value: newTitle,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function changeQuestionImage(index, newImage) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'imageUrl',
        value: newImage,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function editMessageForQuestionAtIndex(params) {
  let { questionIndex, newMessage } = params

  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index: questionIndex,
        propertyName: 'message',
        value: newMessage,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function toggleMaxSelect(index) {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_MAX_SELECT,
      payload: {
        index,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function setMaxSelectAllowedForQ({ index, maxSelect }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'maxSelect',
        value: maxSelect,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function setSpectrumWeighted({ index, spectrumWeighted }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName: 'spectrumQ',
        value: spectrumWeighted,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function editPropertyForQuestionAtIndex({ index, propertyName, value }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index,
        propertyName,
        value,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function setTypologyWeight({ index, dimension, weight }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_TYPOLOGY_WEIGHT,
      payload: {
        index,
        dimension: dimension.toLowerCase(),
        weight,
      },
    })
  }
}

export function duplicateQuestion(index) {
  return dispatch => {
    dispatch({
      type: ActionTypes.DUPLICATE_QUESTION,
      payload: {
        index,
        key: nextQKey++,
      },
    })
  }
}

export function setFilterConfig({ _id, toFilterConfig, options }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY_BY_ID,
      payload: {
        _id,
        propertyName: 'toFilterConfig',
        value: toFilterConfig,
      },
    })
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY_BY_ID,
      payload: {
        _id,
        propertyName: 'options',
        value: options,
      },
    })
  }
}

export function setOptionsDependency({ _id, optionsDependency }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY_BY_ID,
      payload: {
        _id,
        propertyName: 'optionsDependency',
        value: optionsDependency,
      },
    })
  }
}
