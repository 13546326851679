import { ActionTypes } from '../actions'
import _ from 'lodash'

const defaultState = {
  questions: [],
  saveError: false,
}

const CreateViewSurveyReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.INCREMENT_VERSION:
      return Object.assign({}, state, { versionControl: state.versionControl + 1 })
    case ActionTypes.SET_ACTIVE_SURVEY:
      return Object.assign({}, defaultState, action.payload)
    case ActionTypes.SET_SAVE_ERROR:
      return Object.assign({}, state, { saveError: true })
    case ActionTypes.CLEAR_ACTIVE_SURVEY:
      return defaultState // reset active survey to empty object
    case ActionTypes.CHANGE_SURVEY_PROPERTY:
      return Object.assign({}, state, {
        [action.payload.propertyName]: action.payload.value,
      })
    case ActionTypes.CHANGE_SURVEY_SUB_PROPERTY:
      return Object.assign({}, state, {
        [action.payload.topLevel]: {
          ...state[action.payload.topLevel],
          [action.payload.property]: action.payload.value,
        },
      })
    case ActionTypes.TOGGLE_SURVEY_SUB_PROPERTY:
      return Object.assign({}, state, {
        [action.payload.topLevel]: {
          ...state[action.payload.topLevel],
          [action.payload.property]: !(state[action.payload.topLevel] || {})[
            action.payload.property
          ],
        },
      })
    case ActionTypes.TOGGLE_FIXED_Q: {
      const fixedQuestions = (state.questionOrderSettings || {}).fixedQuestions || []
      return Object.assign({}, state, {
        questionOrderSettings: {
          ...(state.questionOrderSettings || {}),
          fixedQuestions: state.questionOrderSettings.fixedQuestions.includes(action.payload.qId)
            ? _.without(fixedQuestions, action.payload.qId)
            : fixedQuestions.concat([action.payload.qId]),
        },
      })
    }
    case ActionTypes.ADD_RESULT:
      return Object.assign({}, state, {
        typologyConfig: {
          ...state.typologyConfig,
          results: [...state.typologyConfig.results, { category: 'new' }],
        },
      })
    case ActionTypes.SET_RESULT:
      return Object.assign({}, state, {
        typologyConfig: {
          ...state.typologyConfig,
          results: action.payload,
        },
      })
    case ActionTypes.ADD_DIMENSION:
      return Object.assign({}, state, {
        typologyConfig: {
          ...state.typologyConfig,
          dimensions: [
            ...state.typologyConfig.dimensions,
            `Dimension ${state.typologyConfig.dimensions.length + 1}`,
          ],
        },
      })
    case ActionTypes.CHANGE_DIMENSION: {
      return Object.assign({}, state, {
        typologyConfig: {
          ...state.typologyConfig,
          dimensions: [
            ...state.typologyConfig.dimensions.slice(0, action.payload.index),
            action.payload.newName,
            ...state.typologyConfig.dimensions.slice(action.payload.index + 1),
          ],
        },
      })
    }
    case ActionTypes.REMOVE_DIMENSION:
      return Object.assign({}, state, {
        typologyConfig: {
          ...state.typologyConfig,
          dimensions: [
            ...state.typologyConfig.dimensions.slice(0, action.payload.index),
            ...state.typologyConfig.dimensions.slice(action.payload.index + 1),
          ],
        },
      })
    case ActionTypes.CHANGE_QUESTION_PROPERTY:
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, action.payload.index),
          Object.assign({}, state.questions[action.payload.index], {
            [action.payload.propertyName]: action.payload.value,
          }),
          ...state.questions.slice(action.payload.index + 1),
        ],
      })
    case ActionTypes.CHANGE_QUESTION_PROPERTY_BY_ID: {
      const index = state.questions.findIndex(q => q._id === action.payload._id)
      if (index < 0) {
        return state
      }
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, index),
          Object.assign({}, state.questions[index], {
            [action.payload.propertyName]: action.payload.value,
          }),
          ...state.questions.slice(index + 1),
        ],
      })
    }
    case ActionTypes.TOGGLE_MAX_SELECT:
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, action.payload.index),
          Object.assign({}, state.questions[action.payload.index], {
            maxSelect:
              state.questions[action.payload.index].maxSelect > 1
                ? 1
                : state.questions[action.payload.index].options.length,
          }),
          ...state.questions.slice(action.payload.index + 1),
        ],
      })
    case ActionTypes.CHANGE_QUESTION_SUB_PROPERTY:
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, action.payload.index),
          Object.assign({}, state.questions[action.payload.index], {
            [action.payload.topLevel]: {
              ...state.questions[action.payload.index][action.payload.topLevel],
              [action.payload.propertyName]: action.payload.value,
            },
          }),
          ...state.questions.slice(action.payload.index + 1),
        ],
      })
    case ActionTypes.TOGGLE_FIXED_OPTION: {
      const { index, value } = action.payload
      const question = state.questions[index]
      const fixedOptions = (question.optionOrderSettings || {}).fixedOptions || []
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, index),
          Object.assign({}, state.questions[index], {
            optionOrderSettings: {
              ...state.questions[index].optionOrderSettings,
              fixedOptions: fixedOptions.includes(value)
                ? _.without(fixedOptions, value)
                : fixedOptions.concat([value]),
            },
          }),
          ...state.questions.slice(index + 1),
        ],
      })
    }
    case ActionTypes.CHANGE_TYPOLOGY_WEIGHT:
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, action.payload.index),
          Object.assign({}, state.questions[action.payload.index], {
            typologyWeights: {
              ...(state.questions[action.payload.index].typologyWeights || {}),
              [action.payload.dimension]: action.payload.weight,
            },
          }),
          ...state.questions.slice(action.payload.index + 1),
        ],
      })
    case ActionTypes.DUPLICATE_QUESTION: {
      const { index, key } = action.payload
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, index + 1),
          Object.assign({}, state.questions[index], {
            _id: undefined,
            id: undefined,
            key,
          }),
          ...state.questions.slice(index + 1),
        ],
      })
    }
    case ActionTypes.REPLACE_QUESTION:
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, action.payload.index),
          action.payload.newQuestion,
          ...state.questions.slice(action.payload.index + 1),
        ],
      })
    case ActionTypes.INSERT_QUESTION_OPTION: {
      let questionIndex = action.payload.questionIndex
      let optionIndex = action.payload.optionIndex
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, questionIndex),
          Object.assign({}, state.questions[questionIndex], {
            options: [
              ...state.questions[questionIndex].options.slice(0, optionIndex + 1),
              action.payload.newOption,
              ...state.questions[questionIndex].options.slice(optionIndex + 1),
            ],
          }),
          ...state.questions.slice(questionIndex + 1),
        ],
      })
    }
    case ActionTypes.TOGGLE_WRITE_IN_OPTION: {
      const { isWriteIn, questionIndex } = action.payload
      const hasExistingOtherOpt = state.questions[questionIndex].options.find(
        opt => opt.value && opt.value.toLowerCase() === 'other'
      )

      // if isWriteIn, should add an option with value 'other' (if it does not already exist)
      // else, should remove any option with value 'other'
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, questionIndex),
          Object.assign({}, state.questions[questionIndex], {
            input: isWriteIn,
            options: isWriteIn
              ? hasExistingOtherOpt
                ? state.questions[questionIndex].options
                : [...state.questions[questionIndex].options, { value: 'other', text: 'Other' }]
              : state.questions[questionIndex].options.filter(
                  opt => opt.value && opt.value.toLowerCase() !== 'other'
                ),
          }),
          ...state.questions.slice(questionIndex + 1),
        ],
      })
    }
    case ActionTypes.CHANGE_OPTION_NAME_AND_VALUE: {
      const { questionIndex, optionIndex, keepValueStatic } = action.payload
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, questionIndex),
          Object.assign({}, state.questions[questionIndex], {
            options: [
              ...state.questions[questionIndex].options.slice(0, optionIndex),
              Object.assign({}, state.questions[questionIndex].options[optionIndex], {
                text: action.payload.newValue,
                ...(keepValueStatic ? {} : { value: action.payload.newValue }),
              }),
              ...state.questions[questionIndex].options.slice(optionIndex + 1),
            ],
          }),
          ...state.questions.slice(questionIndex + 1),
        ],
      })
    }
    case ActionTypes.CHANGE_OPTION_WEIGHT: {
      let questionIndex = action.payload.questionIndex
      let optionIndex = action.payload.optionIndex
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, questionIndex),
          Object.assign({}, state.questions[questionIndex], {
            options: [
              ...state.questions[questionIndex].options.slice(0, optionIndex),
              Object.assign({}, state.questions[questionIndex].options[optionIndex], {
                spectrumWeight: action.payload.newWeight,
              }),
              ...state.questions[questionIndex].options.slice(optionIndex + 1),
            ],
          }),
          ...state.questions.slice(questionIndex + 1),
        ],
      })
    }
    case ActionTypes.CHANGE_OPTION_SKIP_TO: {
      let questionIndex = action.payload.questionIndex
      let optionIndex = action.payload.optionIndex
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, questionIndex),
          Object.assign({}, state.questions[questionIndex], {
            options: [
              ...state.questions[questionIndex].options.slice(0, optionIndex),
              Object.assign({}, state.questions[questionIndex].options[optionIndex], {
                skipTo: action.payload.skipToQId,
              }),
              ...state.questions[questionIndex].options.slice(optionIndex + 1),
            ],
          }),
          ...state.questions.slice(questionIndex + 1),
        ],
      })
    }
    case ActionTypes.CHANGE_OPTION_PROPERTY: {
      const { questionIndex, optionIndex, property, value } = action.payload
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, questionIndex),
          Object.assign({}, state.questions[questionIndex], {
            options: [
              ...state.questions[questionIndex].options.slice(0, optionIndex),
              Object.assign({}, state.questions[questionIndex].options[optionIndex], {
                [property]: value,
              }),
              ...state.questions[questionIndex].options.slice(optionIndex + 1),
            ],
          }),
          ...state.questions.slice(questionIndex + 1),
        ],
      })
    }
    case ActionTypes.CHANGE_OPTION_TYPOLOGY_WEIGHTS: {
      let questionIndex = action.payload.questionIndex
      let optionIndex = action.payload.optionIndex
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, questionIndex),
          Object.assign({}, state.questions[questionIndex], {
            options: [
              ...state.questions[questionIndex].options.slice(0, optionIndex),
              Object.assign({}, state.questions[questionIndex].options[optionIndex], {
                typologyWeights: action.payload.typologyWeights,
              }),
              ...state.questions[questionIndex].options.slice(optionIndex + 1),
            ],
          }),
          ...state.questions.slice(questionIndex + 1),
        ],
      })
    }
    case ActionTypes.CHANGE_OPTION_NAME_AND_VALUE_AND_APPEND: {
      const { questionIndex, optionIndex, keepValueStatic } = action.payload
      return Object.assign({}, state, {
        questions: [
          ...state.questions.slice(0, questionIndex),
          Object.assign({}, state.questions[questionIndex], {
            options: [
              ...state.questions[questionIndex].options.slice(0, optionIndex),
              Object.assign({}, state.questions[questionIndex].options[optionIndex], {
                text: action.payload.newValue,
                ...(keepValueStatic ? {} : { value: action.payload.newValue }),
              }),
              ...state.questions[questionIndex].options.slice(optionIndex + 1),
              action.payload.newOption,
            ],
          }),
          ...state.questions.slice(questionIndex + 1),
        ],
      })
    }
    case ActionTypes.SET_SURVEY_TARGETING_OPTIONS:
      return Object.assign({}, state, {
        targetingMechanism: action.payload.targetingMechanism,
        targetedIndividuals: action.payload.targetedIndividuals,
        targetedDemographics: action.payload.targetedDemographics,
        priceBy: action.payload.priceBy,
      })
    case ActionTypes.INCREMENT_MESSAGE_COUNT:
      return Object.assign({}, state, {
        messageCount: state.messageCount + action.payload.increment,
      })
    case ActionTypes.DECREMENT_MESSAGE_COUNT:
      return Object.assign({}, state, {
        messageCount: state.messageCount - action.payload.decrement,
      })
    case ActionTypes.SET_TARGETING_MECHANISM:
      return Object.assign({}, state, {
        targetingMechanism: action.payload,
      })
    case ActionTypes.TOGGLE_ALL_SCHOOLS:
      return Object.assign({}, state, {
        allSchools: !state.allSchools,
      })
    case ActionTypes.SET_TARGETED_INDIVIDUALS:
      return Object.assign({}, state, {
        targetedIndividuals: action.payload,
      })
    case ActionTypes.SET_TARGETED_DEMOGRAPHICS_FOR_CATEGORY:
      return Object.assign({}, state, {
        targetedDemographics: Object.assign({}, state.targetedDemographics, {
          [action.payload.category]: action.payload.value,
        }),
      })
    case ActionTypes.ADD_TOP_LEVEL_CATEGORY_TO_FACETED:
      return Object.assign({}, state, {
        facetedTargetedDemographics: _.uniq(
          state.facetedTargetedDemographics.concat([action.payload])
        ),
      })
    case ActionTypes.REMOVE_TOP_LEVEL_CATEGORY_FROM_FACETED:
      return Object.assign({}, state, {
        facetedTargetedDemographics: _.pullAllWith(
          state.facetedTargetedDemographics.slice(),
          [action.payload],
          (facetedVal, dem) => {
            return facetedVal === dem || facetedVal.split(':')[0] === dem
          }
        ),
      })
    case ActionTypes.TOGGLE_DEM_OPTION_IN_FACETED: {
      const { category, option, explicitOnOrOff } = action.payload
      const optionStr = `${category}:${option}`
      const currentlyIncludes = _.isNil(explicitOnOrOff)
        ? state.facetedTargetedDemographics.includes(optionStr)
        : !explicitOnOrOff
      return Object.assign({}, state, {
        facetedTargetedDemographics: currentlyIncludes
          ? _.pull(state.facetedTargetedDemographics.slice(), optionStr)
          : _.uniq(state.facetedTargetedDemographics.concat([optionStr, category])),
      })
    }
    case ActionTypes.TOGGLE_DEM_OPTION_IN_INVERSE_FACETED: {
      const { options, explicitOnOrOff } = action.payload
      const shouldRemove = _.isNil(explicitOnOrOff)
        ? options.every(filter => (state.inverseFacetedTargetedDemographics || []).includes(filter))
        : !explicitOnOrOff

      const { inverseFacetedTargetedDemographics = [] } = state
      return Object.assign({}, state, {
        inverseFacetedTargetedDemographics: shouldRemove
          ? _.pullAll(inverseFacetedTargetedDemographics.slice(), options)
          : _.uniq(inverseFacetedTargetedDemographics.concat(options)),
      })
    }
    case ActionTypes.SET_DEM_OPTIONS_FOR_CATEGORY: {
      const { category, options } = action.payload
      const optionStrings = options.map(option => `${category}:${option}`)
      return Object.assign({}, state, {
        facetedTargetedDemographics: [
          ...state.facetedTargetedDemographics.filter(option => !option.includes(`${category}:`)),
          ...optionStrings,
        ],
      })
    }
    case ActionTypes.SET_PRICE_BY_OPTIONS:
      return Object.assign({}, state, {
        priceBy: action.payload.selectedPriceByOptions,
      })
    default:
      return state
  }
}

export default CreateViewSurveyReducer
