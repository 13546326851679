import _ from 'lodash'

const individualActionTypes = [
  require('./create-view-survey-actions/edit-survey-actions').ActionTypes,
  require('./create-view-survey-actions/edit-survey-question-options-actions').ActionTypes,
  require('./create-view-survey-actions/edit-survey-questions-actions').ActionTypes,
  require('./create-view-survey-actions/save-survey-actions').ActionTypes,
  require('./create-view-survey-actions/set-survey-actions').ActionTypes,
  require('./create-view-survey-actions/temp-demographics-actions').ActionTypes,
  require('./all-surveys-actions').ActionTypes,
  require('./create-view-meta-actions').ActionTypes,
  require('./create-view-modal-actions').ActionTypes,
  require('./create-view-navigation-actions').ActionTypes,
  require('./cv-questions-builder-actions').ActionTypes,
  require('./cv-questions-builder-sidebar-actions').ActionTypes,
  require('./edit-privilege-actions').ActionTypes,
  require('./user-actions').ActionTypes,
]

export let ActionTypes = {}
individualActionTypes.forEach(types => {
  _.assign(ActionTypes, types)
  Object.assign({}, ActionTypes, types)
})
