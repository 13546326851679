import React from 'react'
import Select from 'react-select'
import { Image, Grid, Button, Card, Divider } from 'semantic-ui-react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { setRandomizedGroups } from '../../../../actions/create-view-survey-actions/edit-survey-actions'
import { enabled as SelectEnabledStyle } from '../../../../../marketing/styles/Select'

const selectStyle = SelectEnabledStyle()

const groupColors = [
  '#d3f6f3',
  '#f9fce1',
  '#fee9b2',
  '#fbd1b7',
  '#e5e5e5',
  '#303960',
  '#679b9b',
  '#ff5200',
  '#79d70f',
]

class QuestionGrouping extends React.Component {
  constructor() {
    super()
    this.addGroup = this.addGroup.bind(this)
    this.onSetQuestionGroup = this.onSetQuestionGroup.bind(this)
    this.onRemoveGroup = this.onRemoveGroup.bind(this)
    this.onAddAll = this.onAddAll.bind(this)
    this.onRemoveAll = this.onRemoveAll.bind(this)
  }
  addGroup() {
    this.props.setRandomizedGroups([
      ...this.props.randomizedGroups,
      { questions: [], mirrorGroup: null },
    ])
  }
  onSetQuestionGroup(qId, groupIndex) {
    this.props.setRandomizedGroups(
      this.props.randomizedGroups.map((group, i) =>
        i === groupIndex
          ? { ...group, questions: [...group.questions, qId] }
          : { ...group, questions: group.questions.filter(id => id !== qId) }
      )
    )
  }
  onAddAll(groupIndex) {
    const { questions } = this.props
    this.props.setRandomizedGroups(
      this.props.randomizedGroups.map((group, i) =>
        i === groupIndex
          ? { ...group, questions: questions.map(q => q._id) }
          : { ...group, questions: [] }
      )
    )
  }
  onRemoveAll(groupIndex) {
    this.props.setRandomizedGroups(
      this.props.randomizedGroups.map((group, i) =>
        i === groupIndex ? { ...group, questions: [] } : group
      )
    )
  }
  onRemoveGroup(groupIndex) {
    this.props.setRandomizedGroups([
      ...this.props.randomizedGroups.slice(0, groupIndex),
      ...this.props.randomizedGroups.slice(groupIndex + 1),
    ])
  }
  determineGroupNumber(qId) {
    const { randomizedGroups } = this.props
    const groupInd = randomizedGroups.findIndex(group => group.questions.includes(qId))
    return groupInd
  }
  determineValue(qId) {
    const groupInd = this.determineGroupNumber(qId)
    if (groupInd > -1) {
      return { value: groupInd, label: `Group ${groupInd + 1}` }
    }
  }
  render() {
    const { questions, randomizedGroups } = this.props

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <h2>Questions</h2>
            <Divider horizontal />
            {questions.map((q, i) => (
              <Card.Group itemsPerRow={1}>
                <Card style={{ backgroundColor: groupColors[this.determineGroupNumber(q._id)] }}>
                  <Card.Content>
                    <Card.Header>
                      Q{i + 1}: {_.truncate(q.description, { length: 180 })}
                    </Card.Header>
                    <Card.Content extra>
                      <Select
                        options={[{ value: -1, label: 'None' }].concat(
                          randomizedGroups.map((val, i) => ({
                            value: i,
                            label: `Group ${i + 1}`,
                          }))
                        )}
                        value={this.determineValue(q._id)}
                        onChange={selected =>
                          this.onSetQuestionGroup(q._id, (selected || { value: -1 }).value)
                        }
                      />
                    </Card.Content>
                  </Card.Content>
                </Card>
              </Card.Group>
            ))}
          </Grid.Column>
          <Grid.Column width={8}>
            <div className="question-grouping-header-image">
              Example Image:
              <Image size="large" src="/img/admin/ordering.png" />
              <Button onClick={this.addGroup} primary>
                Add Group
              </Button>
            </div>
            <Divider horizontal />
            {randomizedGroups.map((group, i) => (
              <Card.Group itemsPerRow={1}>
                <Card style={{ backgroundColor: groupColors[i] }}>
                  <Card.Content>
                    <Card.Header>Group {i + 1}</Card.Header>
                    <Card.Meta>{group.questions.length} Questions</Card.Meta>
                    <Button.Group basic vertical>
                      <Button basic color="blue" onClick={() => this.onAddAll(i)}>
                        Add All Questions
                      </Button>
                      <Button basic color="blue" onClick={() => this.onRemoveAll(i)}>
                        Remove All Questions
                      </Button>
                      <Button basic color="red" onClick={() => this.onRemoveGroup(i)}>
                        Delete Group
                      </Button>
                    </Button.Group>
                  </Card.Content>
                </Card>
              </Card.Group>
            ))}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

// connect redux, pull in questions, render those in the left column
// pull in questionOrderSettings and render those in the right column

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
  randomizedGroups: (state.activeSurvey.questionOrderSettings || {}).randomizedGroups || [],
})

export default connect(mapStateToProps, { setRandomizedGroups })(QuestionGrouping)
