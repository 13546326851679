import React from 'react'

import { connect } from 'react-redux'
import { navigateBack } from '../../../../../actions/create-view-navigation-actions'

class TargetingSidebar extends React.Component {
  constructor() {
    super()
    this.onBack = this.onBack.bind(this)
  }
  onBack() {
    this.props.navigateBack(this.props.currentPage)
  }
  render() {
    const props = this.props

    return <div className="admin-edit-sidebar demographics"></div>
  }
}

const mapStateToProps = state => ({
  currentPage: state.createViewNav.currentPage,
})

export default connect(mapStateToProps, {
  navigateBack,
})(TargetingSidebar)
