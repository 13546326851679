import React from 'react'
import QuestionHeader from './question-header/question-header'
import QuestionBody from './question-body/question-body'
import NoQuestionsContainer from './no-questions-container/no-questions-container'

import { connect } from 'react-redux'

class QuestionsBuilderContent extends React.Component {
  render() {
    const props = this.props

    if (!props.questions || props.questions.length === 0) {
      return <NoQuestionsContainer />
    }

    return (
      <div className="question-container">
        <QuestionHeader viewOnly={props.viewOnly} changesMade={this.props.changesMade} />
        <QuestionBody
          onMessageChange={props.onMessageChange}
          viewOnly={props.viewOnly}
          maxSelect={props.maxSelect}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
})

export default connect(mapStateToProps, {})(QuestionsBuilderContent)
