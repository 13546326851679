import React from 'react'
import { Checkbox } from 'semantic-ui-react'

import { connect } from 'react-redux'
import {
  initializeTempTargetedDemographics,
  setTargetedDemographicsCategory,
  addTopLevelCategoryToFaceted,
  toggleDemOptionsInInverseFaceted,
} from '../../../../../actions/create-view-survey-actions/temp-demographics-actions'
import clientInverseFilters from '../../../../../actions/lib/clientInverseFilters'
import { fetchSurveyProperty, setSurveyProperty } from '../../../../../../api/admin'

class TopLevelCategoryContainer extends React.Component {
  constructor() {
    super()
    this.state = { showBlocked: false }
    this.toggleClientInverseFilters = this.toggleClientInverseFilters.bind(this)
  }

  toggleClientInverseFilters() {
    this.props.toggleDemOptionsInInverseFaceted({ options: clientInverseFilters })
  }
  render() {
    const props = this.props
    const demOptions = props.demOptions

    const facetedTargetedDemographics = this.props.facetedTargetedDemographics || []

    let clientFiltersApplied = clientInverseFilters.every(filter =>
      (this.props.inverseFacetedTargetedDemographics || []).includes(filter)
    )

    // all inverseFacetedDemographics after we have toggled Apply Client Filters
    let inverseFacetedDemList = []
    if (this.state.showBlocked) {
      inverseFacetedDemList = this.props.inverseFacetedTargetedDemographics
        .filter(dem => dem.includes(':'))
        .map(inverseDem => (
          <div className="pillBox inverseDemBox" key={inverseDem}>
            {inverseDem}
          </div>
        ))
      if (inverseFacetedDemList.length === 0) {
        inverseFacetedDemList.push(
          <h4 className="inverseDemTitle">
            There are no Inverse Faceted Demographics, or unable to get them for the current survey.
          </h4>
        )
      }
    }

    // all demographics _not_ currently being targeted by
    const demBoxes = Object.keys(demOptions)
      .filter(demographic => !facetedTargetedDemographics.includes(demographic))
      .map(demographic => (
        <div
          className="pillBox demBox"
          onClick={() => this.props.addTopLevelCategoryToFaceted(demographic)}
          key={demographic}
        >
          {demOptions[demographic].title}
        </div>
      ))

    return (
      <div className="topLevelDemCategories">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <h3 className="demTitle">Add another demographic target</h3>
          {!!props.user.master && (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Checkbox
                label="Apply Client Filters"
                toggle
                onChange={this.toggleClientInverseFilters}
                checked={clientFiltersApplied}
              />
              <Checkbox
                label="Show Blocked"
                toggle
                onChange={() => this.setState({ showBlocked: !this.state.showBlocked })}
                checked={this.state.showBlocked}
                style={{ marginTop: '0.5rem' }}
              />
            </div>
          )}
        </div>
        <div className="demBoxes">{demBoxes}</div>
        <div style={{ marginTop: '1rem' }}>
          {this.state.showBlocked && (
            <div className="inverseDemTitle">
              <h3 style={{ marginBottom: '0' }}>Blocked demographics</h3>
              <p>
                Anyone trying to take this survey who select one of these demographics will be
                rejected.
              </p>
            </div>
          )}
          <div className="demBoxes">{inverseFacetedDemList}</div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  demOptions: state.createViewTempDemographics.demOptions,
})

export default connect(mapStateToProps, {
  setTargetedDemographicsCategory,
  initializeTempTargetedDemographics,
})(TopLevelCategoryContainer)
