import React from 'react'
import { Button, Card, Modal, Grid } from 'semantic-ui-react'
import { getAdGroups, removeAdGroup } from '../../../../../../api/ads'

export default ({ allGroups, setGroup, devMode, setDevMode, setAllGroups, _id }) => {
  return (
    <>
      <section>
        <Button onClick={() => setGroup('new')}>Create an Ad Group</Button>
      </section>
      <hr />
      <section>
        {allGroups?.map(group => (
          <Card fluid key={group._id}>
            <Card.Content>
              <Card.Header>{group?.title || 'No Title'}</Card.Header>
              <hr />
              <Card.Description>
                <Grid fullwidth>
                  <Grid.Column width={8}>
                    <p>Start Time: {group?.startTime || 'No Start Time'}</p>
                    <p>EndTime: {group?.endTime || 'No End Time'}</p>
                    <p>Ads Created: {group?.adSets?.length || 0}</p>
                  </Grid.Column>
                  <Grid.Column
                    width={8}
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'flex-start',
                    }}
                  >
                    <Modal
                      trigger={<Button style={{ width: '50%', maxWidth: 200 }}>Delete</Button>}
                      header="Warning!"
                      content={`Are you sure you want to remove the AdGroup ${
                        group?.title || ''
                      }?\nThis will NOT delete the ads!\nIt will only remove this Ad Group from this list.`}
                      actions={[
                        'Cancel',
                        {
                          key: 'yes',
                          content: 'Yes!',
                          negative: true,
                          onClick: async () => {
                            await removeAdGroup(_id, group._id)
                            let allAdGroups = await getAdGroups(_id)
                            setAllGroups(allAdGroups.adGroups.reverse())
                          },
                        },
                      ]}
                    />
                    <Button
                      positive
                      onClick={() => setGroup(group._id)}
                      style={{ width: '50%', maxWidth: 200, marginLeft: '1rem' }}
                    >
                      View
                    </Button>
                  </Grid.Column>
                </Grid>
              </Card.Description>
            </Card.Content>
          </Card>
        ))}
        <hr />
        <Button onClick={() => setDevMode(!devMode)}>Turn dev mode {devMode ? 'off' : 'on'}</Button>
        {devMode && (
          <>
            <pre>{JSON.stringify(allGroups, null, 2)}</pre>
          </>
        )}
      </section>
    </>
  )
}
