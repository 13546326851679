import React, { useEffect, useState } from 'react'
import { fetchSurveyProperty, setSurveyProperty, getOneSchool } from '../../../../../api/admin'
import { Button, Grid, Card, Dropdown, Checkbox, Icon, Input, Divider } from 'semantic-ui-react'
import { store } from '../../../../store'
import { one } from '../../../../../api/survey'

const RecommendView = ({ _id }) => {
  const [recommendedSurvey, setRecommendedSurvey] = useState({
    survey: '',
    headerText: '',
    bodyText: '',
  })
  const [loading, setLoading] = useState(true)
  const [dataChanged, setDataChanged] = useState(false)
  const [updatingData, setUpdatingData] = useState(false)
  const [selectedSurvey, setSelectedSurvey] = useState(null)

  useEffect(() => {
    getExistingInformation(setRecommendedSurvey, setLoading, _id)
  }, [])
  useEffect(() => {
    // Get survey metadata
    if (recommendedSurvey.survey.length === 24) {
      getNewSurvey(setSelectedSurvey, recommendedSurvey.survey)
    }
  }, [recommendedSurvey.survey])

  const updateData = async () => {
    setUpdatingData(true)
    await setSurveyProperty({
      _id,
      property: 'recommendedSurvey',
      value: recommendedSurvey,
      store,
    })

    setDataChanged(false)
    setUpdatingData(false)
  }

  const removeData = async () => {
    setLoading(true)

    // Remove the current details from the front-end
    setRecommendedSurvey({
      survey: '',
      headerText: '',
      bodyText: '',
    })
    setSelectedSurvey(null)
    setDataChanged(false)

    // Remove the current details from the database
    await setSurveyProperty({
      _id,
      property: 'recommendedSurvey',
      value: null,
      store,
    })

    setLoading(false)
  }

  return (
    <div style={{ width: '100%', paddingTop: '1rem' }}>
      {loading ? (
        <p>Loading some information...</p>
      ) : recommendedSurvey ? (
        <Grid>
          <Grid.Row>
            <Grid.Column fluid>
              <Input
                //24
                error={dataChanged && recommendedSurvey.survey.length !== 24}
                type="text"
                value={recommendedSurvey.survey}
                onChange={e => {
                  setDataChanged(true)
                  setRecommendedSurvey(c => ({
                    ...c,
                    survey: e.target.value,
                  }))
                }}
                fluid
                placeholder="abc123abc123..."
                label="Survey ID"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column fluid>
              <Input
                type="text"
                value={recommendedSurvey.headerText}
                onChange={e => {
                  setDataChanged(true)
                  setRecommendedSurvey(c => ({
                    ...c,
                    headerText: e.target.value,
                  }))
                }}
                fluid
                placeholder="Take another survey..."
                label="Header Text"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column fluid>
              <Input
                type="text"
                value={recommendedSurvey.bodyText}
                onChange={e => {
                  setDataChanged(true)
                  setRecommendedSurvey(c => ({
                    ...c,
                    bodyText: e.target.value,
                  }))
                }}
                fluid
                placeholder="This is a survey about..."
                label="Body Text"
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column fluid>
              <Button
                loading={loading || updatingData}
                disabled={loading || !dataChanged || updatingData}
                onClick={() => {
                  updateData()
                }}
              >
                Update Survey
              </Button>

              <Button
                negative
                disabled={loading || !recommendedSurvey.survey}
                loading={loading}
                onClick={() => {
                  removeData()
                }}
              >
                Remove Recommended
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          {selectedSurvey && (
            <>
              <Grid.Row>
                <Grid.Column style={{ display: 'flex', justifyContent: 'center' }}>
                  <h4>What the student will see:</h4>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <div style={{ textAlign: 'right', marginRight: '1.5rem' }}>
                    <h5 style={{ marginBottom: 5 }}>
                      {recommendedSurvey?.headerText || 'Take another survey!'}
                    </h5>
                    <p style={{ maxWidth: 220 }}>
                      {recommendedSurvey?.bodyText ||
                        `Get another reward by completing this next survey. Don't worry, we'll send this
                    incentive to your email.`}
                    </p>
                    <Button icon labelPosition="right" positive>
                      Take the survey
                      <Icon name="right arrow" />
                    </Button>
                  </div>
                  <Card
                    style={{
                      margin: 0,
                      width: 500,
                      display: 'flex',
                      flexDirection: 'row',
                      overflow: 'hidden',
                    }}
                  >
                    <div style={{ flex: 3, paddingRight: '0' }}>
                      <img src={selectedSurvey.imgSrc} style={{ width: '100%', height: '100%' }} />
                    </div>
                    <Card.Content style={{ flex: 4 }}>
                      <Card.Header style={{ display: 'flex' }}>
                        <strong>
                          <h5>{selectedSurvey?.title || 'There is no title for this survey.'}</h5>
                        </strong>
                      </Card.Header>
                      <Card.Description style={{ marginTop: '0.75rem' }}>
                        <Divider fitted />
                        <p style={{ marginTop: '0.5rem' }}>
                          {selectedSurvey?.description ||
                            'There is no description for this survey.'}
                        </p>
                      </Card.Description>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              </Grid.Row>
            </>
          )}
        </Grid>
      ) : (
        <p>There was an error... Yell at engineers.</p>
      )}
    </div>
  )
}

const getExistingInformation = async (setRecommendedSurvey, setLoading, _id) => {
  setLoading(true)
  let recommendedSurveyData = await fetchSurveyProperty({ _id, property: 'recommendedSurvey' })

  if (recommendedSurveyData) {
    setRecommendedSurvey(recommendedSurveyData)
  }

  setLoading(false)
}

const getNewSurvey = async (setSelectedSurvey, id) => {
  const { authorName, imgSrc, title, description } = await one(id)

  setSelectedSurvey({
    authorName,
    imgSrc,
    title,
    description,
  })
}

export default RecommendView
