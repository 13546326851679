import React from 'react'
import PropTypes from 'prop-types'
import Hammer from 'react-hammerjs'
import moment from 'moment'

import Avatar from '../avatar/avatar'

export default class SurveyCard extends React.Component {
  constructor() {
    super()
    this.state = {
      surveyCardClassName: 'survey-card',
      bannerClassName: 'banner-container',
    }

    const boundMethods = 'onClick onCloseClick'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  componentWillMount() {
    if (this.props.onClose) {
      this.setState({
        surveyCardClassName: 'survey-card survey-card-active',
        bannerClassName: 'banner-container banner-container-active',
      })
    }
  }

  onClick() {
    if (this.props.mobileOnly) {
      return
    }
    if (this.props.finishDemographicsFunc) {
      // go and finish demographics, but keep track of selected survey
      this.props.finishDemographicsFunc(this.props.survey.id)
    } else if (this.props.setActive) {
      // proceed to selected survey
      this.props.setActive(this.props.surveyId)
    }
  }

  onCloseClick(ev) {
    ev.srcEvent.stopPropagation()
    this.props.setActive(null)
  }

  /**
   * Clips survey author's name if it's too long
   * for mobile view.
   */
  maxAuthorName(text) {
    text = text || ''
    let width = window.innerWidth
    if (width <= 568 && text.length >= 10) return text.substring(0, 10) + '...'
    else if (width <= 812 && text.length >= 16) return text.substring(0, 16) + '...'
    else if (width <= 1112 && text.length >= 20) return text.substring(0, 20) + '...'
    else if (width <= 1200 && text.length >= 25) return text.substring(0, 25) + '...'
    return text
  }

  /**
   * Expects props to have
   *   .survey
   * Options props
   *   .onClose – should be set if being rendered by survey-view
   *              shouldn't be set if rendered by home
   */
  render() {
    const questionCount = this.props.survey.questionCount || 5
    const surveyDuration = moment.utc(10000 * questionCount).format('mm:ss')

    //TODO: determine which of the things below we actually need, probably NONE
    let createdAt = moment(this.props.survey.createdAt)
    let resultsAt = moment(this.props.survey.resultsAt)
    let wordCount = this.props.survey.wordCount
    let now = moment(Date.now())
    let ago = 'Pulse Points'
    let resultsDuration = moment.duration(resultsAt.diff(now)).humanize()
    let resultsAvailable = 'Results in'

    if (resultsAt < now) {
      resultsAvailable = 'Results'
      resultsDuration = 'LIVE'
    }

    let header
    if (this.props.type === 'complete') {
      // structuring the statement in this way ensures the survey card in
      // admin view (review + pay) is rendered correctly
      header = (
        <div className="header-container complete">
          <div className="prompt-container">
            <div className="prompt">
              <div>View the Data</div>
              <img src="/img/arrow-right-white.svg" />
            </div>
          </div>
        </div>
      )
    } else {
      header = (
        <div className="header-container available">
          <div className="points-container">
            <span>{this.props.survey.pointValue}</span>
            <span className="smaller-text">Points</span>
          </div>
        </div>
      )
    }

    let participantsContainer
    if (this.props.type) {
      participantsContainer = (
        <div className="participants-container">
          <img className="svg-shadow" src="/img/peeps.svg" />
          <span>{this.props.survey.numParticipants}</span>
        </div>
      )
    }

    let descriptionContainer
    if (!this.props.hideDescription) {
      descriptionContainer = (
        <div className="description-container">
          <div className="description">
            <span>{this.props.survey.description}</span>
          </div>
        </div>
      )
    }

    const surveyCardClassName =
      this.state.surveyCardClassName + (this.props.mobileOnly ? ' mobile-only' : '')

    return (
      <Hammer onTap={this.onClick}>
        <div className={surveyCardClassName}>
          {this.props.mobileOnly && (
            <div key="claim" className="gray-overlay">
              <p>Mobile Only</p>
              <p className="small">Requires College Pulse app on iPhone or Android</p>
            </div>
          )}
          <div
            className={this.state.bannerClassName}
            style={{ backgroundImage: `url(${this.props.survey.imgSrc})` }}
          >
            {header}
            <div className="survey-title-container">
              <div className="survey-title">{this.props.survey.title}</div>
              {participantsContainer}
            </div>
          </div>
          {descriptionContainer}
          <div className="bottom-container">
            <div className="bottom-content-container">
              <div className="author-container">
                <Avatar src={this.props.survey.authorImg} />
                <span className="author-name">
                  {this.maxAuthorName(this.props.survey.authorName)}
                </span>
              </div>
              <div className="survey-stats-container">
                <div className="stat-container">
                  <img src="/img/list-choose-green.svg" />
                  <span>{questionCount}</span>
                </div>
                <div className="stat-container">
                  <img src="/img/clock-green.svg" />
                  <span>{surveyDuration}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Hammer>
    )
  }
}

SurveyCard.propTypes = {
  onClose: PropTypes.func,
  finishDemographicsFunc: PropTypes.func,
  survey: PropTypes.object,
  setActive: PropTypes.func,
  surveyId: PropTypes.string,
  type: PropTypes.string,
  hideDescription: PropTypes.bool,
}
