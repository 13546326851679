exports.wordpress = require('./wordpress')
exports.answer = require('./answer')
exports.demographics = require('./demographics')
exports.survey = require('./survey')
exports.signin = require('./signin')
exports.signup = require('./signup')
exports.user = require('./user')
exports.prize = require('./prize')
exports.admin = require('./admin')
exports.master = require('./master')
exports.suggestion = require('./suggestion')
exports.group = require('./group')
exports.passwordReset = require('./password-reset')
exports.comments = require('./comments')
exports.reportBug = require('./report-bug')
exports.promocodes = require('./promocodes')
exports.share = require('./share')
exports.question = require('./question')
exports.reports = require('./reports')
exports.zapier = require('./zapier')
exports.adminTooling = require('./admin-tooling')
exports.incentive = require('./incentive')
exports.external = require('./external')
exports.mock = require('./mock')
exports.favoriteSurveys = require('./favorite-surveys')
exports.sms = require('./sms')
exports.ambassador = require('./ambassador')
