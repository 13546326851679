import React, { useState, useEffect } from 'react'
import { fetchSurveyProperty, getOneSchool, getSchoolName } from '../../../../../api/admin'
import {
  pairSchoolToFacebook,
  getStatus,
  getSchoolNameFromFacebook,
} from '../../../../../api/external/fbSchool'

import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import { getImage, getFBID } from '../../../../../api/school'
import { getAdGroups } from '../../../../../api/ads'

// Local components
import NewGroup from './components/NewGroup'
import ExistingGroup from './components/ExistingGroup'
import NoGroup from './components/NoGroup'

export default ({ _id }) => {
  // Status State
  const [devMode, setDevMode] = useState(false)

  // List of all available groups from the api
  // A group is a collection of AdSets that share the same general parameters customized by only .
  // Cost, runtime, etc.
  const [allGroups, setAllGroups] = useState([])

  // Get all existing adGroups for a survey
  // Initial data fetch
  useEffect(() => {
    getExistingAdGroups(_id, setAllGroups)
  }, [])

  // Initial data fetch 2
  useEffect(() => {
    // Get school data from a survey on-load
    fetchSchoolData(setFaceted, _id)
  }, [])

  // Group ID of active group (Controls view)
  const [group, setGroup] = useState(null)
  const [activeGroup, setActiveGroup] = useState(null)
  useEffect(() => {
    if (group && group !== 'new') {
      getExistingAdGroup(allGroups, group, setActiveGroup)
    }
  }, [group])

  // New group default config
  const [groupConfig, setGroupConfig] = useState({
    title: '',
    adCopy: '',
    adConfig: {
      defaultMaxBid: 10,
      defaultDailyBudget: 100,
      startTime: null,
      endTime: null,
    },
    utmConfig: {
      growthChannel: 'FacebookDirect',
      ref: 'snowball-',
      defaultIncentive: 5,
    },
    activeSchools: [],
  })

  // Holds the list of schools from faceted survey property
  const [faceted, setFaceted] = useState()

  // Which schools are active
  const [activeSchools, setActiveSchools] = useState([])
  useEffect(() => {
    // Pass active schools to groupConfig
    setGroupConfig(p => ({ ...p, activeSchools: activeSchools }))
  }, [activeSchools])

  // {
  //   name: schoolResponse.institutionName,
  //   schoolID: schoolResponse._id,
  //   facebookResponseName: name,
  //   facebookID: savedFB || fb?.facebookID || 'notFound',
  //   facebookName: fb?.facebookName || 'notFound',
  //   imageURL: image,
  // },

  // Toggle whether or not a school is active
  const schoolToggle = async ({ _id }) => {
    if (activeSchools.find(f => f.id === _id)) {
      setActiveSchools(p => p.filter(x => x.id !== _id))
    } else {
      let activeSchool = faceted.find(x => x.schoolID === _id)
      setActiveSchools(p => [
        ...p,
        {
          ...activeSchool,
          id: _id,
          altName: '',
        },
      ])
    }
  }

  // Toggle all school switch.
  const schoolToggleAll = () => {
    if (activeSchools.length === faceted.length) {
      // If all schools are active, reset
      setActiveSchools([])
    } else {
      // {
      //   name: schoolResponse.institutionName,
      //   schoolID: schoolResponse._id,
      //   facebookResponseName: name,
      //   facebookID: savedFB || fb?.facebookID || 'notFound',
      //   facebookName: fb?.facebookName || 'notFound',
      //   imageURL: image,
      // },

      // Toggle all schools that are not currently active
      faceted.forEach(f => {
        if (!activeSchools.find(e => e.id === f.schoolID)) {
          schoolToggle({ _id: f.schoolID })
        }
      })
    }
  }

  return (
    <>
      {group && group === 'new' && (
        <NewGroup
          groupConfig={groupConfig}
          setGroupConfig={setGroupConfig}
          activeSchools={activeSchools}
          faceted={faceted}
          setActiveSchools={setActiveSchools}
          schoolToggle={schoolToggle}
          schoolToggleAll={schoolToggleAll}
          setAllGroups={setAllGroups}
          setGroup={setGroup}
          devMode={devMode}
          setDevMode={setDevMode}
          _id={_id}
        />
      )}
      {group && group !== 'new' && (
        <ExistingGroup activeGroup={activeGroup} devMode={devMode} setDevMode={setDevMode} />
      )}
      {!group && (
        <NoGroup
          allGroups={allGroups}
          setGroup={setGroup}
          devMode={devMode}
          setDevMode={setDevMode}
          setAllGroups={setAllGroups}
          _id={_id}
        />
      )}
    </>
  )
}

const pairSchool = async ({ _id, name }) => {
  /*
  Pair a College Pulse school to a Facebook School.
  */
  const data = await pairSchoolToFacebook({ _id, name })

  return data
}

const fetchSchoolData = (setFaceted, _id) => {
  /*
    Get the facetedTargetedDemographics
    Turn them into an array of school names
    Get additional data from each school
    Attempt to pair school using pairSchool
  */

  fetchSurveyProperty({
    _id: _id,
    property: 'facetedTargetedDemographics',
  }).then(response => {
    // 1: Turn dems into list of school names
    let schools = response.filter(x => x.includes('school:')).map(x => x.replace('school:', ''))

    // 2: For each school, get their school object
    schools.forEach(async (name, index) => {
      getOneSchool({ name: name }).then(async schoolResponse => {
        // 3: Add the school name, and id, to the faceted state
        if (schoolResponse) {
          // Check if the school already has an ID
          const savedFB = await getFBID(schoolResponse._id)

          // Try to auto-pair to facebook if there is no savedFB
          let fb = {}
          if (!savedFB) {
            fb = await pairSchool({
              _id: schoolResponse._id,
              name: schoolResponse.institutionName,
            })
          } else {
            // Get the facebook name if there is a saved ID
            let name = await getSchoolNameFromFacebook(savedFB)
            fb.facebookName = name
          }

          // Try to get the current imgUrl from the database
          let image = (await getImage(schoolResponse._id)) || null

          // Set default school values inside of faceted.
          setFaceted(prev => [
            ...(prev || []),
            {
              name: schoolResponse.institutionName,
              schoolID: schoolResponse._id,
              facebookResponseName: name,
              facebookID: savedFB || fb?.facebookID || 'notFound',
              facebookName: fb?.facebookName || 'notFound',
              imageURL: image,
            },
          ])

          // id: _id,
          // name: activeSchool[0],
          // facebookID: activeSchool[3],
          // facebookName: activeSchool[4],
          // imageURL: activeSchool[5] || undefined,
          // altName: '',
        }
      })
    })
  })
}

const getExistingAdGroup = async (allGroups, group, setActiveGroup) => {
  /*
  Get a single existing Ad Group from our API from the selected group and assign it to state.
  */

  let active = { ...allGroups.find(x => x._id === group) }
  active.adSets = await Promise.all(
    active.adSets.filter(Boolean).map(async adSet => {
      let updatedAdSet = { ...adSet }
      let schoolName = await getSchoolName(adSet.institutionId)
      let status = await getStatus(adSet.fbAdSetId)
      updatedAdSet.status = status.status
      updatedAdSet.schoolName = schoolName[0].name
      return updatedAdSet
    })
  )

  setActiveGroup(active)
}

const getExistingAdGroups = async (_id, setAllGroups) => {
  /*
  Get all existing ad groups from the survey ID and assign them to state.
  */

  let allAdGroups = await getAdGroups(_id)
  setAllGroups(allAdGroups.adGroups.reverse())
}
