import FileSaver from 'file-saver'
import request from './request'
import prefix from './prefix'
import storage from '../lib/storage'
import _ from 'lodash'

exports.fetchMainReports = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/reports').end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.fetchSingleReport = function (_id) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/report/${_id}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.purchaseReport = function (_id, stripeToken) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/report/purchase/${_id}`)
      .send({ stripeToken, saveCard: true, useExisting: true })
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}
