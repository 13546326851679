import request from './request'
import storage from '../lib/storage'
import prefix from './prefix'

exports.one = function (commentId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/comment/${commentId}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}
exports.forQuestion = function (questionId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/comment/question/${questionId}/replies`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.forAnswer = function (answerId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/comment/answer/${answerId}/replies`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.subcommentsForComment = function (commentId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/comment/comment/${commentId}/replies`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.replyToQuestion = function (questionId, comment) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/comment/question/${questionId}/reply`)
      .send({ text: comment })
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.replyToAnswer = function (answerId, comment) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/comment/answer/${answerId}/reply`)
      .send({ text: comment })
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.replyToComment = function (commentId, comment) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/comment/comment/${commentId}/reply`)
      .send({ text: comment })
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.upvote = function (commentId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/comment/comment/${commentId}/upvote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.downvote = function (commentId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/comment/comment/${commentId}/downvote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)
        return resolve(res.body)
      })
  })
}

exports.novote = function (commentId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/comment/comment/${commentId}/novote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}
