import React from 'react'
import _ from 'lodash'
import ExistingQuestionsMenu from './sidebar-components/existing-questions-menu'
import LogicModal from '../../../modals/LogicModal'
import { Button, Icon } from 'semantic-ui-react'

import { connect } from 'react-redux'
import { navigateBack } from '../../../../../actions/create-view-navigation-actions'
import { appendNewQuestionAfterCurrent } from '../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'
import { toggleRandomize } from '../../../../../actions/create-view-survey-actions/edit-survey-actions'
import { toggleLogicModal } from '../../../../../actions/create-view-modal-actions'

class QuestionsBuilderSidebar extends React.Component {
  constructor() {
    super()
    this.onBack = this.onBack.bind(this)
    this.addQuestion = this.addQuestion.bind(this)
    this.shakeBox = this.shakeBox.bind(this)
    this.determineError = this.determineError.bind(this)
    this.determineRangeError = this.determineRangeError.bind(this)
  }

  onBack() {
    this.props.navigateBack(this.props.currentPage)
  }

  shakeBox(boxId) {
    if (document.getElementById(boxId)) {
      document.getElementById(boxId).classList.add('error')
      setTimeout(() => {
        document.getElementById(boxId).classList.remove('error')
      }, 300)
    }
  }

  addQuestion() {
    const minError = this.determineError('minimum')
    const maxError = this.determineError('maximum')
    const rangeError = this.determineRangeError()
    if (!minError && !maxError && !rangeError) {
      this.props.appendNewQuestionAfterCurrent('noType', {
        currentQuestions: this.props.survey.questions,
        currentQuestionIndex: this.props.currentQuestionIndex,
      })
    }
    if (minError || rangeError) {
      this.shakeBox('minimum-input-box')
    }
    if (maxError || rangeError) {
      this.shakeBox('maximum-input-box')
    }
  }

  determineError(minOrMax) {
    const question = this.props.questions[this.props.currentQuestionIndex]
    return question.type === 'numeric' && question.rangeInc === 1 && question[minOrMax] % 1 !== 0
  }

  determineRangeError() {
    const question = this.props.questions[this.props.currentQuestionIndex]
    return (
      !_.isNil(question.minimum) &&
      !_.isNil(question.maximum) &&
      question.minimum >= question.maximum
    )
  }

  render() {
    const { showLogicModal } = this.props
    return (
      <div className="admin-edit-sidebar questions">
        <LogicModal isOpen={showLogicModal} toggle={this.props.toggleLogicModal} />
        <div className="sidebar-header">
          <span className="question-list-text">QUESTION LIST</span>
          <div
            className={`add-new-button ${this.props.randomize ? 'highlighted' : ''}`}
            onClick={this.props.toggleLogicModal}
          >
            <img
              src={this.props.randomize ? '/img/shuffle-white.png' : '/img/shuffle.png'}
              style={{ height: '25px', width: '35px', marginRight: '5px' }}
            />
            <span>Logic</span>
          </div>
          <div className="add-new-button" onClick={this.addQuestion}>
            <img src="/img/greenCirclePlus.svg" />
            <span>Add a Question</span>
          </div>
        </div>
        <div className="sidebar-content">
          <div>
            <ExistingQuestionsMenu />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  survey: state.activeSurvey,
  currentPage: state.createViewNav.currentPage,
  justSaved: state.cvQuestionsBuilder.justSaved,
  currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
  questions: state.activeSurvey.questions,
  randomize: (state.activeSurvey.questionOrderSettings || {}).randomize,
  showLogicModal: state.createViewModals.logic,
})

export default connect(mapStateToProps, {
  navigateBack,
  appendNewQuestionAfterCurrent,
  toggleRandomize,
  toggleLogicModal,
})(QuestionsBuilderSidebar)
