import React, { useState } from 'react'
import { Input, TextArea, Card, Grid, Button, Dropdown, Modal } from 'semantic-ui-react'

import { getPotentialSchools, createAds } from '../../../../../../api/external/fbSchool'
import urljoin from 'url-join'

import SemanticDatepicker from 'react-semantic-ui-datepickers'
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css'
import { uploadImage } from '../../../../../../api/external/imgur'
import { setFBID } from '../../../../../../api/school'
import { addAdGroup, getAdGroups } from '../../../../../../api/ads'

export default ({
  groupConfig,
  setGroupConfig,
  activeSchools,
  faceted,
  setActiveSchools,
  schoolToggle,
  schoolToggleAll,
  setAllGroups,
  setGroup,
  devMode,
  setDevMode,
  _id,
}) => {
  const [loading, setLoading] = useState(false)
  const [finished, setFinished] = useState(false)
  const [errorModal, setErrorModal] = useState(null)

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <section>
        <Input
          value={groupConfig.title}
          onChange={e => setGroupConfig(c => ({ ...c, title: e.target.value }))}
          placeholder="Title / Client"
        />
      </section>
      <Card style={{ padding: '1rem', width: '100%' }}>
        <h4 style={{ marginBottom: 0 }}>Ad Copy</h4>
        <p style={{ marginBottom: 0 }}>
          <em>
            Put your copy in curley brackets. Available fields are: <span>schoolname</span>
          </em>
        </p>
        <hr style={{ marginBottom: '1rem', marginTop: '1rem', width: '100%' }} />
        <TextArea
          style={{ padding: '1rem' }}
          value={groupConfig.adCopy}
          onChange={e => setGroupConfig(c => ({ ...c, adCopy: e.target.value }))}
          placeholder={'We need you to complete this vote right now!\nWhat do you say?'}
        />
      </Card>
      <Card style={{ padding: '1rem', width: '100%', paddingBottom: 0 }}>
        <h4 style={{ marginBottom: 0 }}>Ad Config</h4>
        <p style={{ marginBottom: 0 }}>
          <em>Configure the adset properties</em>
        </p>
        <hr style={{ marginBottom: '1rem', marginTop: '1rem', width: '100%' }} />
        <div className="spacing-input-layout utm" style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div>
            <h5>Default Max Bid</h5>
            <Input
              onChange={e =>
                setGroupConfig(c => ({
                  ...c,
                  adConfig: { ...c.adConfig, defaultMaxBid: e.target.value },
                }))
              }
              type="number"
              value={groupConfig?.defaultMaxBid}
              size="small"
              placeholder="10"
              label="$"
            />
          </div>

          <div>
            <h5>Default Daily Budget</h5>
            <Input
              onChange={e =>
                setGroupConfig(c => ({
                  ...c,
                  adConfig: { ...c.adConfig, defaultDailyBudget: e.target.value },
                }))
              }
              type="number"
              value={groupConfig?.defaultDailyBudget}
              size="small"
              placeholder="100"
              label="$"
            />
          </div>

          <div>
            <h5>Start Time</h5>
            <SemanticDatepicker
              size="small"
              className="cal"
              onChange={(e, data) =>
                setGroupConfig(c => ({
                  ...c,
                  adConfig: { ...c.adConfig, startTime: data.value },
                }))
              }
            />
          </div>

          <div>
            <h5>End Time</h5>
            <SemanticDatepicker
              size="mini"
              className="cal"
              onChange={(e, data) =>
                setGroupConfig(c => ({
                  ...c,
                  adConfig: { ...c.adConfig, endTime: data.value },
                }))
              }
            />
          </div>
        </div>
      </Card>
      <Card style={{ padding: '1rem', width: '100%', paddingBottom: 0 }}>
        <h4 style={{ marginBottom: 0 }}>UTM Config</h4>
        <p style={{ marginBottom: 0 }}>
          <em>Configure the Url</em>
        </p>
        <hr style={{ marginBottom: '1rem', marginTop: '1rem', width: '100%' }} />
        <Grid divided>
          <Grid.Row>
            <Grid.Column width="10">
              <div
                className="spacing-input-layout utm"
                style={{ display: 'flex', flexWrap: 'wrap' }}
              >
                <div>
                  <h5>Growth Channel</h5>
                  <Input
                    onChange={e =>
                      setGroupConfig(c => ({
                        ...c,
                        utmConfig: { ...c.utmConfig, growthChannel: e.target.value },
                      }))
                    }
                    value={groupConfig.utmConfig.growthChannel}
                    className="cal"
                    size="mini"
                    placeholder="snowball"
                    type="text"
                  />
                </div>

                <div>
                  <h5>Ref</h5>
                  <Input
                    onChange={e =>
                      setGroupConfig(c => ({
                        ...c,
                        utmConfig: { ...c.utmConfig, ref: e.target.value },
                      }))
                    }
                    className="cal"
                    size="mini"
                    placeholder="usc-school"
                    type="text"
                    value={groupConfig.utmConfig.ref}
                  />
                </div>

                <div>
                  <h5>Default Incentive</h5>
                  <Input
                    onChange={e =>
                      setGroupConfig(c => ({
                        ...c,
                        utmConfig: { ...c.utmConfig, defaultIncentive: e.target.value },
                      }))
                    }
                    size="small"
                    placeholder="5"
                    type="number"
                    label="$"
                    value={groupConfig.utmConfig.defaultIncentive}
                  />
                </div>
              </div>
            </Grid.Column>
            <Grid.Column width="6" style={{ paddingBottom: '1rem' }}>
              <div className="utm-url">
                {urljoin(
                  `https://app.collegepulse.com/external-survey/${_id}`,
                  groupConfig.utmConfig.growthChannel
                    ? `?growthChannel=${groupConfig.utmConfig.growthChannel}`
                    : '',
                  groupConfig.utmConfig.ref ? `?ref=${groupConfig.utmConfig.ref}` : '',
                  groupConfig.utmConfig.defaultIncentive
                    ? `?sb_convenience=${groupConfig.utmConfig.defaultIncentive}`
                    : '',
                  '?snowballsample=true'
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Card>
      <Card style={{ padding: '1rem', width: '100%' }}>
        <h4 style={{ marginBottom: 0 }}>Toggle Schools</h4>
        <p style={{ marginBottom: 0 }}>
          <em>Choose which schools you would like to create ads for</em>
        </p>
        <hr style={{ marginBottom: '1rem', marginTop: '1rem', width: '100%' }} />

        <div>
          <Button style={{ marginBottom: '0.5rem' }} onClick={() => schoolToggleAll()}>
            Toggle All
          </Button>
          {faceted?.map(school => (
            <Button
              key={school.id}
              style={{ marginBottom: '0.5rem' }}
              color={activeSchools.find(x => school.schoolID === x.id) ? 'green' : undefined}
              onClick={() => schoolToggle({ _id: school.schoolID })}
            >
              {school.name}
            </Button>
          ))}
        </div>
      </Card>
      {activeSchools &&
        activeSchools.map(school => (
          <Card
            key={school.id}
            className={groupConfig?.errorSchools?.find(x => x === school.id) ? 'error-school' : ''}
            style={{ padding: '1rem', width: '100%' }}
          >
            <h4 style={{ marginBottom: 0 }}>{school.name}</h4>

            <hr style={{ marginBottom: '1rem', marginTop: '1rem', width: '100%' }} />
            <Grid divided style={{ width: '100%' }}>
              <Grid.Row style={{ width: '100%' }}>
                <Grid.Column width="4">
                  <Grid>
                    <Grid.Row>
                      {school?.imageURL && (
                        <Grid.Column width="8">
                          <img
                            style={{ width: '100%', height: 'auto', border: '3px solid #20bc9c' }}
                            alt="School Campus"
                            src={school?.imageURL}
                          />
                        </Grid.Column>
                      )}

                      <Grid.Column width="8">
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                            border: '1px solid black',
                            position: 'relative',
                          }}
                          className="image-upload"
                        >
                          Upload Image
                          <input
                            onChange={async e => {
                              let curActive = [...activeSchools]
                              let curIndex = activeSchools.indexOf(school)
                              curActive[curIndex].imageURL = await uploadImage({
                                image: e.target.files[0],
                                _id: school.id,
                              })
                              setActiveSchools(curActive)
                            }}
                            accept="image/png, image/jpeg"
                            type="file"
                            style={{
                              opacity: 0,
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                            }}
                          />
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Grid.Column>
                <Grid.Column width="9">
                  <div
                    className="spacing-input-layout utm school"
                    style={{ display: 'flex', flexWrap: 'wrap' }}
                  >
                    <div>
                      <h5>Incentive Amount</h5>
                      <Input
                        label="$"
                        type="number"
                        size="small"
                        placeholder={groupConfig.utmConfig.defaultIncentive}
                        value={school?.incentive || undefined}
                        onChange={e => {
                          let curActive = [...activeSchools]
                          let curIndex = activeSchools.indexOf(school)
                          curActive[curIndex].incentive = e.target.value
                          setActiveSchools(curActive)
                        }}
                      />
                    </div>

                    <div>
                      <h5>Daily Budget</h5>
                      <Input
                        label="$"
                        type="number"
                        size="small"
                        placeholder={groupConfig.adConfig.defaultDailyBudget}
                        value={school?.dailyBudget || undefined}
                        onChange={e => {
                          let curActive = [...activeSchools]
                          let curIndex = activeSchools.indexOf(school)
                          curActive[curIndex].dailyBudget = e.target.value
                          setActiveSchools(curActive)
                        }}
                      />
                    </div>
                    <div>
                      <h5>Max Bid</h5>
                      <Input
                        label="$"
                        type="number"
                        size="small"
                        placeholder={groupConfig.adConfig.defaultMaxBid}
                        value={school?.maxBid || undefined}
                        onChange={e => {
                          let curActive = [...activeSchools]
                          let curIndex = activeSchools.indexOf(school)
                          curActive[curIndex].maxBid = e.target.value
                          setActiveSchools(curActive)
                        }}
                      />
                    </div>
                    <div>
                      <h5>Alternate School Name</h5>
                      <Input
                        type="text"
                        size="small"
                        placeholder={school?.name || 'School name not found'}
                        value={school?.altName || undefined}
                        onChange={e => {
                          let curActive = [...activeSchools]
                          let curIndex = activeSchools.indexOf(school)
                          curActive[curIndex].altName = e.target.value
                          setActiveSchools(curActive)
                        }}
                      />
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column width="3">
                  <h5 style={{ marginBottom: 0 }}>Facebook Pairing</h5>
                  <hr style={{ marginBottom: '1rem', marginTop: '1rem', width: '100%' }} />

                  {school.facebookID && school.facebookID !== 'notFound' ? (
                    <div>
                      <h5>School paired to:</h5>
                      <p>Name: {school.facebookName}</p>
                      <p>ID: {school.facebookID}</p>
                    </div>
                  ) : (
                    'School not paired!'
                  )}

                  {school.facebookID && school.facebookID !== 'notFound' && (
                    <Button
                      onClick={() => {
                        let curActive = [...activeSchools]
                        let curIndex = activeSchools.indexOf(school)
                        curActive[curIndex].facebookID = 'notFound'
                        setActiveSchools(curActive)
                      }}
                    >
                      Change School
                    </Button>
                  )}
                  {school.facebookID && school.facebookID === 'notFound' && (
                    <div>
                      <Dropdown
                        placeholder="Search School"
                        fluid
                        search
                        selection
                        options={school?.potentialSchools || []}
                        onChange={(e, data) => {
                          let curActive = [...activeSchools]
                          let curIndex = activeSchools.indexOf(school)
                          curActive[curIndex].facebookID = data.value
                          curActive[curIndex].facebookName = e.target.innerText

                          // Save Facebook ID
                          setFBID(school.id, data.value)

                          setActiveSchools(curActive)
                        }}
                        onSearchChange={async e => {
                          let newPotentialSchools = await getPotentialSchools(
                            e.target.value,
                            school.id
                          )
                          if (newPotentialSchools) {
                            let curActive = [...activeSchools]
                            let curIndex = activeSchools.indexOf(school)
                            curActive[curIndex].potentialSchools = newPotentialSchools?.map(
                              pot => ({
                                text: pot.name,
                                value: pot.id,
                              })
                            )
                            setActiveSchools(curActive)
                          }
                        }}
                      />
                    </div>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Card>
        ))}
      <section>
        <Button
          onClick={async () => {
            // Set Loading
            setLoading(true)

            // Create Ads
            let adResponse = await createAds(groupConfig, _id)

            // Ad the adGroup to the database
            let newID = await addAdGroup(_id, adResponse)

            // If there are errored schools, return them to state
            setGroupConfig(c => ({ ...c, errorSchools: adResponse.errorSchools }))

            // Refresh the ad list
            let allAdGroups = await getAdGroups(_id)
            setAllGroups(allAdGroups.adGroups.reverse())

            if (adResponse?.errorSchools?.length > 0) {
              console.log('There was an error somewhere...')
              setErrorModal(
                'There was en error posting some of your adsets.<br/>Please do the following:<br/>1: Remove the ads that were successful (White background)<br/>2: Correct the ads that failed (Red background)<br/>3: Change the name of the adGroup<br/>4: Click publish again.'
              )
            } else {
              setGroup(newID)
            }

            // Finish loading
            setLoading(false)
            //setFinished(true)
          }}
          loading={loading}
          disabled={loading || finished}
        >
          {finished ? 'Published!' : 'Publish'}
        </Button>
        <hr />
      </section>
      <Modal open={errorModal} onClose={() => setErrorModal(null)}>
        <Modal.Header>Error Posting Adsets</Modal.Header>
        <Modal.Content dangerouslySetInnerHTML={{ __html: errorModal || '' }} />
      </Modal>

      <section>
        <Button onClick={() => setDevMode(!devMode)}>Turn dev mode {devMode ? 'off' : 'on'}</Button>
        {devMode && (
          <>
            <p>Error Schools</p>
            <pre>{JSON.stringify(groupConfig.errorSchools, null, 2)}</pre>
            <p>Group Config</p>
            <pre>{JSON.stringify(groupConfig, null, 2)}</pre>
            <p>Active Schools</p>
            <pre>{JSON.stringify(activeSchools, null, 2)}</pre>
            <p>Faceted</p>
            <pre>{JSON.stringify(faceted, null, 2)}</pre>
          </>
        )}
      </section>
    </div>
  )
}
