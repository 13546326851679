import storage from '../lib/storage'
import signin from './signin'

const BASE_URL = 'collegepulse.com'
const TOKEN_REQ_MESSAGE = { type: 'token_request' }
const TOKEN_RETURN = { type: 'token_return' }
const READY_MESSAGE = { type: 'ready' }
const SIGNOUT_MESSAGE = { type: 'logout' }
const SIGNED_OUT_MESSAGE = { type: 'logged_out' }

exports.appendCollegePulseIframe = function () {
  if (window.location.host === BASE_URL) {
    return
  }
  document.domain = BASE_URL
  var iframe = document.createElement('iframe')
  iframe.src = 'https://' + BASE_URL + '/index'
  iframe.name = 'cpParent'
  iframe.style.display = 'none'
  document.body.appendChild(iframe)
  return frames['cpParent'].location.host
}

const whitelistedDomains = [
  'https://collegepulse.com',
  'https://staging.collegepulse.com',
  'https://babson.collegepulse.com',
  'https://dartmouth.collegepulse.com',
]

exports.registerListener = function (successfulSigninCallback) {
  window.addEventListener('message', receiveMessage, false)

  if (window.location.host === BASE_URL) {
    window.parent.postMessage(READY_MESSAGE, '*')
  }

  function receiveMessage(event) {
    if (whitelistedDomains.includes(event.origin)) {
      if (!event.data || !event.data.type) {
        return
      }
      if (event.data.type === READY_MESSAGE.type) {
        if (signin.isSignedIn()) {
          exports.postTokenToCP(storage.get('token'))
        } else {
          exports.checkForStorageOnCP()
        }
      } else if (event.data.type === TOKEN_REQ_MESSAGE.type) {
        const tokenReturnObj = Object.assign({}, TOKEN_RETURN, { payload: storage.get('token') })
        event.source.postMessage(tokenReturnObj, event.origin)
      } else if (event.data.type === TOKEN_RETURN.type) {
        const didSignIn = signin.processTokenDirectly(event.data.payload)
        if (successfulSigninCallback) {
          successfulSigninCallback(didSignIn)
        }
      } else if (event.data.type === SIGNOUT_MESSAGE) {
        signin.signout()
        event.source.postMessage(SIGNED_OUT_MESSAGE, event.origin)
      }
    }
  }
}

exports.signoutOfCP = function () {
  if (window.location.host === BASE_URL) {
    return
  }

  if (!frames['cpParent']) {
    return
  }

  frames['cpParent'].postMessage(SIGNOUT_MESSAGE, 'https://' + BASE_URL)
}

exports.postTokenToCP = function (token) {
  if (window.location.host === BASE_URL) {
    return
  }

  if (!frames['cpParent']) {
    return
  }

  const tokenReturnObj = Object.assign({}, TOKEN_RETURN, { payload: token })
  frames['cpParent'].postMessage(tokenReturnObj, 'https://' + BASE_URL)
}

exports.checkForStorageOnCP = function () {
  if (window.location.host === BASE_URL) {
    return
  }
  if (signin.isSignedIn()) {
    return
  }

  if (!frames['cpParent']) {
    exports.appendCollegePulseIframe()
  }
  if (!frames['cpParent'].postMessage) {
    return
  }

  frames['cpParent'].postMessage(TOKEN_REQ_MESSAGE, 'https://' + BASE_URL)
}
