import { ActionTypes } from '../actions'

const UserReducer = (state = { user: null }, action) => {
  switch (action.type) {
    case ActionTypes.RESET_USER:
      return Object.assign({}, state, {
        user: action.payload.user,
      })
    default:
      return state
  }
}

export default UserReducer
