import request from './request'
import prefix from './prefix'

// Replace current survey adGroups with new adGroups
exports.setAdGroups = function (_id, adGroups) {
  return new Promise((resolve, reject) => {
    request
      .post(`${prefix}/api/admin-tooling/adgroups/${_id}`)
      .send({ adGroups })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}
const setAdGroups = exports.setAdGroups // Make available locally

// Get all available adGroups for a survey
exports.getAdGroups = function (_id) {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/admin-tooling/adgroups/${_id}`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}
const getAdGroups = exports.getAdGroups // Make available locally

// Function to add another adGroup
exports.addAdGroup = async (_id, adGroup) => {
  // Get all of the current adGroups
  let currentAdGroups = await getAdGroups(_id)

  // Turn that into a list of ID's
  let existingID = currentAdGroups.adGroups.map(x => x._id)

  // Add new group into array
  currentAdGroups.adGroups.push(adGroup)

  // Set the adGroups, and return new data
  let newList = await setAdGroups(_id, currentAdGroups.adGroups)

  // Return the ID that wasn't there before.
  return newList.adGroups.filter(y => !existingID.find(z => z === y._id))[0]._id
}

// Function to remove an adGroup by ID from survey
exports.removeAdGroup = async (_id, selectedAdGroup) => {
  let currentAdGroups = await getAdGroups(_id)
  currentAdGroups.adGroups = currentAdGroups.adGroups.filter(x => x._id !== selectedAdGroup)
  await setAdGroups(_id, currentAdGroups.adGroups)
  return 'Done!'
}
