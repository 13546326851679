import React from 'react'
import ReactDOM from 'react-dom'
import Hammer from 'react-hammerjs'
import ClickOutside from 'react-click-outside'
import Dropdown from 'react-dropdown'
import Select from 'react-select'
import { IconContext } from 'react-icons'
import { MdFilterNone } from 'react-icons/md'

import SpectrumConfigModal from '../../../../modals/spectrumConfigModal'
import TypologyConfigModal from '../../../../modals/typologyConfigModal'
import MCSettingsPanel from './mc-settings-panel'
import NumericSettingsPanel from './numeric-settings-panel'
import defaultQuestions from '../../../../../../actions/lib/default-questions'
import { enabled as SelectEnabledStyle } from '../../../../../../../marketing/styles/Select'
import { mapQuestionsToSelect } from '../../content/question-body/options-box/question-option'

const logicSelectStyle = SelectEnabledStyle({
  width: 350,
  height: 30,
  marginLeft: 20,
})

export default class SidebarQuestionBox extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      expanded: false,
      showFullDescription: false,
      showSpectrumModal: false,
      showTypologyModal: false,
    }

    var questionTypeSamples = Object.keys(defaultQuestions).map(function (key) {
      return defaultQuestions[key]
    })

    this.questionTypeLabels = questionTypeSamples
      .map(question => {
        let item = { value: question.type, label: question.label }
        return question.type !== 'noType' ? item : null
      })
      .filter(item => item) // filter out the null value

    const boundMethods = [
      'setQuestionToCurrent',
      'setConfirmingDeletion',
      'onConfirmDelete',
      'toggleRandomize',
      'toggleQuiz',
      'toggleLogic',
      'toggleRequired',
      'duplicateQuestion',
      'toggleFixed',
      'toggleOther',
      'toggleMaxSelect',
      'setTypologyWeight',
      'toggleStaticSkipTo',
      'toggleYesNo',
      'updateMaxSelectVal',
      'checkEmptyMaxSelectVal',
      'changeQuestionType',
      'handleQuestionDescription',
      'toggleDescriptionBox',
      'updateSpectrumName',
      'updateTextResponseCount',
      'setTypologyTopLine',
      'setStaticSkipTo',
      'updateSpectrumLearnMore',
      'toggleSpectrumQ',
      'toggleTypologyQ',
      'toggleSpectrumModal',
      'toggleTypologyModal',
      'toggleIsScreenerQ',
      'toggleHideQuestionFromDE',
    ]

    boundMethods.forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  componentWillReceiveProps(nextProps) {
    if (!(nextProps.question.type == 'mc')) {
      return
    }
  }

  /*
   * naive way to determine if the final option for given question
   * is the dummy placeholder option that we supply
   */
  getNumOptions(question) {
    if (!(question.type == 'mc')) {
      return
    }
    if (
      !question.options[question.options.length - 1] ||
      question.options[question.options.length - 1].text === ''
    ) {
      return question.options.length - 1
    } else {
      return question.options.length
    }
  }

  setConfirmingDeletion() {
    this.props.setConfirmingDeletion(this.props.index)
  }

  onConfirmDelete() {
    this.props.onConfirmDelete(this.props.index)
  }

  setQuestionToCurrent() {
    this.props.questionChange(this.props.index)
  }

  toggleRandomize() {
    this.props.toggleRandomize(this.props.index)
  }

  toggleIsScreenerQ() {
    this.props.toggleIsScreenerQ(this.props.index)
  }

  toggleHideQuestionFromDE() {
    this.props.toggleHideQuestionFromDE(this.props.index)
  }

  toggleQuiz() {
    this.props.toggleQuiz(this.props.index)
  }

  toggleLogic() {
    this.props.toggleLogic(this.props.index)
  }

  toggleRequired() {
    this.props.toggleRequired(this.props.index)
  }

  duplicateQuestion() {
    this.props.duplicateQuestion(this.props.index)
  }

  toggleFixed() {
    this.props.toggleFixed(this.props.question._id)
  }

  toggleOther() {
    this.props.toggleOther(this.props.index)
  }

  toggleMaxSelect() {
    this.props.toggleMaxSelect(this.props.index)
  }

  toggleSpectrumModal() {
    this.setState({ showSpectrumModal: !this.state.showSpectrumModal })
  }
  toggleTypologyModal() {
    this.setState({ showTypologyModal: !this.state.showTypologyModal })
  }

  setTypologyWeight({ dimension, weight }) {
    this.props.setTypologyWeight({ dimension, weight, index: this.props.index })
  }

  // how to make the options attachable to specific props. almost need to redefine them in the
  // render function so that they do not have fixed selected data

  getOptions() {
    const { question, isSpectrum, isTypology, index, fixedQuestions = [] } = this.props
    const fixed = fixedQuestions.includes(question._id)
    const selectConfig = mapQuestionsToSelect(
      this.props.questions.slice(index + 1),
      question.staticSkipTo,
      index
    )

    return {
      answerRequired: {
        key: 'answerRequired',
        title: 'Answer Required',
        selected: question.required,
        callback: this.toggleRequired,
      },
      logic: {
        callback: this.toggleLogic,
        key: 'logic',
        title: 'Conditional Jumps',
        selected: question.logicQuestion,
      },
      quiz: {
        callback: this.toggleQuiz,
        key: 'quiz',
        title: 'Is Quiz',
        selected: question.quizSettings && question.quizSettings.isQuiz,
      },
      randomize: {
        callback: this.toggleRandomize,
        key: 'randomize',
        title: 'Randomize Options Order',
        selected: (question.optionOrderSettings || {}).randomizeOptions,
      },
      isScreenerQ: {
        callback: this.toggleIsScreenerQ,
        key: 'isScreenerQ',
        title: 'Screener Question?',
        selected: question.isScreenerQ,
      },
      hideQuestionFromDE: {
        callback: this.toggleHideQuestionFromDE,
        key: 'hideQuestionFromDE',
        title: 'Hide question from DE',
        selected: question.hideQuestionFromDE,
      },
      textResponseCount: {
        key: 'textResponseCount',
        selected: this.props.question.textResponseCount > 1,
        customLabel: (
          <div className="edit-panel-option-label">
            <span>Number of Text Responses</span>
            {/*TODO: ensure that now that this type is number, we still give it a string not a number*/}
            <input
              type="number"
              className="max-select"
              name={index}
              maxLength="2"
              size="2"
              value={this.props.question.textResponseCount}
              onChange={this.updateTextResponseCount}
            />
          </div>
        ),
      },
      'write-in': {
        callback: this.toggleOther,
        key: 'write-in',
        title: 'Add Write In Option',
        selected: question.input,
      },
      multiselect: {
        callback: this.toggleMaxSelect,
        key: 'multiselect',
        title: 'Allow Multiple Answers. Max:',
        selected: question.maxSelect > 1,
        customLabel: (
          <div className="edit-panel-option-label">
            <span>Allow Multiple Answers. Max:</span>
            {/*TODO: ensure that now that this type is number, we still give it a string not a number*/}
            <input
              type="number"
              className="max-select"
              name={index}
              maxLength="2"
              size="2"
              value={this.props.question.maxSelect}
              onChange={this.updateMaxSelectVal}
              onBlur={this.checkEmptyMaxSelectVal}
            />
          </div>
        ),
      },
      staticSkipTo: {
        callback: this.toggleStaticSkipTo,
        key: 'staticSkipTo',
        title: 'Custom Next Q',
        selected: !!question.staticSkipTo,
        extension: (
          <div className="edit-panel-option-label">
            <Select
              options={selectConfig.options}
              onChange={this.setStaticSkipTo}
              value={selectConfig.selectedOpt}
              placeholder="No Fixed"
              styles={logicSelectStyle}
              isClearable
            />
          </div>
        ),
      },

      fixedPosition: {
        key: 'fixedPosition',
        title: 'Fixed Order Position',
        selected: fixed,
        callback: this.toggleFixed,
      },
    }
  }

  toggleSpectrumQ() {
    if (this.props.isSpectrum || !this.props.question.spectrumQ) {
      this.props.toggleSpectrumQ(this.props.index)
    }
    this.props.setIsSpectrum()
  }

  toggleStaticSkipTo() {
    if (this.props.question.staticSkipTo) {
      this.setStaticSkipTo({ value: null })
    } else {
      const nextQ = this.props.questions[this.props.index + 1] || { _id: 'end' }
      this.setStaticSkipTo({ value: nextQ._id || 'end' })
    }
  }

  toggleTypologyQ() {
    if (this.props.isTypology || !this.props.question.spectrumQ) {
      this.props.toggleSpectrumQ(this.props.index)
    }
    this.props.setIsTypology()
  }

  toggleYesNo() {
    this.props.toggleYesNo(this.props.index)
  }

  updateMaxSelectVal(ev) {
    if (!ev.target.value || ev.target.value === '') {
      this.props.setMaxSelectVal(this.props.index, '')
      return
    }
    let input = Number(ev.target.value)

    if (input < 1) {
      return
    }

    this.props.setMaxSelectVal(this.props.index, input)
  }

  updateTextResponseCount(ev) {
    if (!ev.target.value || ev.target.value === '') {
      this.props.setTextResponseCount(this.props.index, '')
      return
    }
    let input = Number(ev.target.value)

    if (input < 1) {
      return
    }

    this.props.setTextResponseCount(this.props.index, input)
  }

  setStaticSkipTo(val) {
    this.props.setStaticSkipTo(this.props.index, (val || {}).value)
  }

  updateSpectrumName(ev) {
    this.props.setSpectrumName(this.props.index, ev.target.value)
  }

  setTypologyTopLine(ev) {
    this.props.setTypologyTopLine(ev.target.value)
  }

  updateSpectrumLearnMore(ev) {
    this.props.setSpectrumLearnMore(this.props.index, ev.target.value)
  }

  checkEmptyMaxSelectVal() {
    if (this.props.question.maxSelect === null || this.props.question.maxSelect === '') {
      this.props.setMaxSelectVal(this.props.index, 1)
    }
  }

  changeQuestionType(ev) {
    this.props.changeQuestionType({
      index: this.props.index,
      question: this.props.question,
      newQuestionType: ev.value,
    })
  }

  handleQuestionDescription() {
    if (this.props.question.description) {
      if (this.props.question.description.length > 130 && !this.state.showFullDescription) {
        let position = 130
        let clippedString = this.props.question.description.substring(0, 130)

        /* clips down description to the last space before length breeches length 130 */
        while (clippedString.substring(position - 1, position) != ' ' && position > 100) {
          position -= 1
          clippedString = clippedString.substring(0, position)
        }
        clippedString += '...'
        return clippedString
      }
      return this.props.question.description
    } else {
      if (this.props.question.type == 'message') {
        return 'No Message Set Yet'
      }
      return 'No Question Set Yet'
    }
  }

  toggleDescriptionBox() {
    if (this.state.expanded) {
      this.setState({ showFullDescription: true })
    } else {
      this.setState({ showFullDescription: false })
    }
    this.setState({ expanded: !this.state.expanded })
  }

  determineOptionsList() {
    const { question, randomizeQuestions } = this.props
    const options = this.getOptions()
    const returnOptions = [options.staticSkipTo, options.isScreenerQ, options.hideQuestionFromDE]

    if (randomizeQuestions) {
      returnOptions.push(options.fixedPosition)
    }

    if (question.type === 'mc') {
      returnOptions.push(
        options.answerRequired,
        options.logic,
        options.quiz,
        options.randomize,
        options['write-in'],
        options.multiselect
      )
    } else if (question.type === 'text') {
      returnOptions.push(options.textResponseCount)
    }

    return returnOptions
  }

  changeDimensionName(index) {
    return ev => this.props.changeDimensionName(index, ev.target.value)
  }

  renderTypologyDimensions() {
    return (
      <div className="result-options">
        {this.props.typologyConfig.dimensions.map((cat, ind) => {
          return (
            <div className="result-row">
              <input value={cat} onChange={this.changeDimensionName(ind)} />
              {/*<span>:</span>
                    <input
                      type="number"
                      className="max-select"
                      size="3"
                      maxLength="3"
                      value={
                        (props.question.typologyWeights || {})[
                          cat.toLowerCase()
                        ]
                      }
                      onChange={this.setTypologyWeight(cat.toLowerCase())}
                    />
                    */}
              <Hammer onTap={() => this.props.removeDimension(ind)}>
                <div className="option-delete-button">
                  <img src="/img/blueCircleMinus.svg" />
                </div>
              </Hammer>
            </div>
          )
        })}
        <Hammer onTap={this.props.addDimension}>
          <div className="option-delete-button">
            <img src="/img/blueCirclePlus.svg" />
          </div>
        </Hammer>
      </div>
    )
  }

  render() {
    const props = this.props

    let deleteButton
    if (props.deleteConfirmingIndex !== props.index) {
      deleteButton = (
        <div className="question-delete-button">
          <Hammer onTap={this.setConfirmingDeletion}>
            <img
              src={
                props.deleteConfirmingIndex === props.index
                  ? '/img/redCircleMinus.svg'
                  : '/img/blueX.svg'
              }
            />
          </Hammer>
        </div>
      )
    } else {
      deleteButton = (
        <ClickOutside onClickOutside={props.onClickOutsideDelete}>
          <div className="question-delete-button">
            <Hammer onTap={this.onConfirmDelete}>
              <img
                src={
                  props.deleteConfirmingIndex === props.index
                    ? '/img/redCircleMinus.svg'
                    : '/img/blueX.svg'
                }
              />
            </Hammer>
          </div>
        </ClickOutside>
      )
    }

    let editPanel
    if (props.currentQuestionIndex === props.index && props.question.type !== 'numeric') {
      const options = this.determineOptionsList()
      editPanel = (
        <MCSettingsPanel
          options={options}
          toggleRandomize={this.toggleRandomize}
          toggleQuiz={this.toggleQuiz}
          toggleLogic={this.toggleLogic}
          toggleIsScreenerQ={this.toggleIsScreenerQ}
          toggleHideQuestionFromDE={this.toggleHideQuestionFromDE}
          toggleRequired={this.toggleRequired}
          toggleOther={this.toggleOther}
          toggleMaxSelect={this.props.toggleMaxSelect}
          toggleYesNo={this.toggleYesNo}
          question={props.question}
          updateMaxSelectVal={this.updateMaxSelectVal}
          spectrumName={props.spectrumName}
          typologyTopLine={props.typologyTopLine}
          isSpectrum={this.props.isSpectrum}
          spectrumLearnMore={props.spectrumLearnMore}
          isTypology={this.props.isTypology}
          typologyConfig={this.props.typologyConfig}
          updateSpectrumName={this.updateSpectrumName}
          setResults={this.props.setResults}
          addDimension={this.props.addDimension}
          changeDimensionName={this.props.changeDimensionName}
          removeDimension={this.props.removeDimension}
          updateSpectrumLearnMore={this.updateSpectrumLearnMore}
          setTypologyWeight={this.setTypologyWeight}
          checkEmptyMaxSelectVal={this.checkEmptyMaxSelectVal}
          toggleSpectrumQ={this.toggleSpectrumQ}
          toggleTypologyQ={this.toggleTypologyQ}
          setTypologyTopLine={this.setTypologyTopLine}
          index={props.index}
          questions={this.props.questions}
          editOptionTypologyWeights={this.props.editOptionTypologyWeights}
        />
      )
    } else if (props.currentQuestionIndex === props.index && props.question.type === 'numeric') {
      editPanel = [
        <NumericSettingsPanel
          fixed={this.props.fixedQuestions.includes(props.question._id)}
          toggleIsScreenerQ={this.toggleIsScreenerQ}
          toggleHideQuestionFromDE={this.toggleHideQuestionFromDE}
          toggleFixed={this.toggleFixed}
          toggleRequired={this.toggleRequired}
          questions={this.props.questions}
          question={props.question}
        />,
      ]
    }

    let qNumber = props.index + 1 - props.messageCount
    let qTitle = props.question.type === 'message' ? 'M' : 'Q' + qNumber

    let selectedQType =
      props.question.type !== 'noType'
        ? this.questionTypeLabels.filter(item => item.value === props.question.type)[0]
        : null

    return (
      <div data={props.index} key={props.index} className="sidebar-q-container">
        <Hammer onTap={this.setQuestionToCurrent}>
          <div
            className={`sidebar-q ${props.index === props.currentQuestionIndex ? 'selected' : ''} ${
              this.state.showFullDescription ? 'expanded' : 'collapsed'
            }`}
          >
            <div className="sidebar-q-left-container">
              <div className="sidebar-q-header">
                <div className="sidebar-q-header-title">
                  <span className="qtitle">{qTitle}</span>
                  <Dropdown
                    options={this.questionTypeLabels}
                    value={selectedQType}
                    onChange={this.changeQuestionType}
                    placeholder="[Question Type]"
                  />
                </div>
                <div className="buttons-container">
                  <div className="question-delete-button">
                    <IconContext.Provider value={{ color: '#2c3e50', size: '1.5em' }}>
                      <MdFilterNone
                        title="Duplicate"
                        onClick={this.duplicateQuestion}
                        className="closeButton"
                      />
                    </IconContext.Provider>
                  </div>
                  {deleteButton}
                </div>
              </div>
              <div
                className="sidebar-q-description"
                ref="sidebar-q-description"
                onClick={this.toggleDescriptionBox}
              >
                {this.handleQuestionDescription()}
              </div>
            </div>
            <div className="sidebar-q-right-container">
              <img src="/img/drag.svg" />
            </div>
          </div>
        </Hammer>
        {editPanel}
        <SpectrumConfigModal
          isOpen={this.state.showSpectrumModal}
          toggleSpectrumModal={this.toggleSpectrumModal}
          spectrumName={props.spectrumName}
          updateSpectrumName={this.updateSpectrumName}
          spectrumLearnMore={props.spectrumLearnMore}
          updateSpectrumLearnMore={this.updateSpectrumLearnMore}
        />
        <TypologyConfigModal
          isOpen={this.state.showTypologyModal}
          toggle={this.toggleTypologyModal}
          typologyTopLine={props.typologyTopLine}
          setResults={props.setResults}
          dimensions={props.typologyConfig.dimensions}
          results={props.typologyConfig.results}
          setTypologyTopLine={this.setTypologyTopLine}
          questions={props.questions}
          editOptionTypologyWeights={this.props.editOptionTypologyWeights}
        />
      </div>
    )
  }
}
