import React from 'react'
import rp from 'request-promise'
import axios from 'axios'
import urljoin from 'url-join'
import storage from '../../lib/storage'
import prefix from '../prefix'

var FormData = require('form-data')

const getAccessToken = async () => {
  const apiURL = `${prefix}/api/secrets/facebook`
  let { data: authToken } = await axios.get(apiURL, {
    headers: {
      'X-Access-Token': storage.get('token'),
      'client-version': 'web',
      Accept: 'application/json',
    },
  })
  return authToken
}

const facebookRequest = async ({ url, data, params, method, headers }) => {
  const apiURL = `${prefix}/api/secrets/facebook`

  // Make request to our api
  let { data: authToken } = await axios.get(apiURL, {
    headers: {
      'X-Access-Token': storage.get('token'),
      'client-version': 'web',
      Accept: 'application/json',
    },
  })

  // Create an axios request
  return axios({
    method,
    url,
    data,
    headers: {
      ...headers,
      access_token: authToken,
    },
    params: {
      ...params,
      access_token: authToken,
    },
  })
}

const act = 'act_843447062482511'
export const getSchoolNameFromFacebook = async id => {
  return facebookRequest({
    url: `https://graph.facebook.com/v9.0/${act}/targetingvalidation`,
    method: 'get',
    params: {
      targeting_list: [
        {
          id: id,
          type: 'education_schools',
        },
      ],
    },
  })
    .then(resp => {
      return resp.data.data[0].name
    })
    .catch(err => {
      console.warn('Error searching schools')
      console.log(err)
    })
}

export const pairSchoolToFacebook = async ({ _id, name }) => {
  let facebookSchools = await searchSchool(name)

  if (facebookSchools.data.length === 0) {
    return 'notFound'
  }

  const isDirectMatch = school => school.name === name || school.name === simplifySchoolName(name)

  if (facebookSchools.data.some(isDirectMatch)) {
    facebookSchools = facebookSchools.data.filter(isDirectMatch)

    // also check the school subtext against the overall name
    if (facebookSchools.length > 1) {
      const subtextMatch = school =>
        (school.subtext || '')
          .split(',')[0]
          .split(' ')
          .some(subTextWord => name.includes(subTextWord))

      if (facebookSchools.some(subtextMatch)) {
        facebookSchools = facebookSchools.filter(subtextMatch)
      }
    }
  }

  return {
    facebookID: facebookSchools?.[0]?.id || 'notFound',
    facebookName: facebookSchools?.[0]?.name || 'notFound',
  }
}

const searchSchool = async schoolName => {
  let result = await facebookRequest({
    url: 'https://graph.facebook.com/v2.11/search',
    method: 'get',
    params: {
      type: 'adeducationschool',
      q: schoolName,
    },
  })
  console.log(result)

  // try shortened search term
  if (result.data.length === 0) {
    const shortenedSearchTerm = simplifySchoolName(schoolName)
    result = await facebookRequest({
      url: 'https://graph.facebook.com/v2.11/search',
      method: 'get',
      params: {
        type: 'adeducationschool',
        q: shortenedSearchTerm,
      },
    })
  }

  return result.data
}

const simplifySchoolName = schoolName => {
  if (!schoolName) {
    return
  }
  const firstPartOfHyphen = word => word.split('-')[0]
  const excludeParens = word => word.split('(')[0]
  return firstPartOfHyphen(excludeParens(schoolName))
}

export const getPotentialSchools = (value, _id) => {
  /* Search for Uni's on Facebook */
  return facebookRequest({
    url: `https://graph.facebook.com/v9.0/${act}/targetingsearch`,
    params: {
      q: value,
      limit_type: 'education_schools',
    },
    method: 'get',
  })
    .then(resp => {
      return resp.data.data
    })
    .catch(err => {
      console.warn('Error searching schools')
      console.log(err)
    })
}

export const createAds = async (groupConfig, _id) => {
  /*
    Create Ads is the function that tyes all ad-creation together.
  */

  let errorSchools = []

  let responses = await Promise.all(
    groupConfig.activeSchools.map(async school => {
      let changedCopy = groupConfig?.adCopy || 'Take our survey!'

      changedCopy = changedCopy.replace(
        new RegExp('{schoolname}', 'g'),
        school?.altName || school?.name || school?.facebookName
      )

      let adSetResponse = await adSet({
        name: `${groupConfig?.title || 'No Title'} - ${school?.facebookName || 'NA'}`,
        campaign_id: '23843907342210602',
        daily_budget: +school?.dailyBudget * 100 || +groupConfig.adConfig.defaultDailyBudget * 100,
        optimization_goal: 'OFFSITE_CONVERSIONS',
        status: 'PAUSED',
        bid_amount: +school?.maxBid * 100 || +groupConfig.adConfig.defaultMaxBid * 100,
        bid_strategy: 'LOWEST_COST_WITH_BID_CAP',
        billing_event: 'IMPRESSIONS',
        targeting: {
          geo_locations: { countries: ['US'] },
          age_min: 18,
          age_max: 25,
          education_statuses: [2, 5],
          education_schools: [school.facebookID],
        },
        promoted_object: {
          pixel_id: '220984991973087',
          custom_event_type: 'COMPLETE_REGISTRATION',
        },
        start_time: groupConfig?.adConfig?.startTime || undefined,
        end_time: groupConfig?.adConfig?.endTime || undefined,
      })

      if (!adSetResponse) {
        errorSchools.push(school.id)
        return
      }

      let adImageResponse = await image({
        url: school?.imageURL || 'https://i.imgur.com/i1wOAVI.jpg',
      })

      let adCreativeResponse = await adCreative({
        name: `School Image: ${groupConfig?.title || 'No Title'} - ${school?.name || 'NA'}`,
        object_story_spec: {
          page_id: '718555594964404',
          instagram_actor_id: '1128650717172485',
          link_data: {
            link: urljoin(
              `https://app.collegepulse.com/external-survey/${_id}`,
              groupConfig.utmConfig.growthChannel
                ? `?growthChannel=${groupConfig.utmConfig.growthChannel}`
                : '',
              groupConfig.utmConfig.ref ? `?ref=${groupConfig.utmConfig.ref}` : '',
              school.id ? `?schoolName=${school.name}` : '',
              groupConfig.utmConfig.defaultIncentive
                ? `?sb_convenience=${groupConfig.utmConfig.defaultIncentive}`
                : '',
              '?snowballsample=true'
            ),
            attachment_style: 'link',
            image_hash: adImageResponse,
            name: 'College Pulse Survey Link',
            call_to_action: {
              type: 'LEARN_MORE',
            },
            message: changedCopy || groupConfig?.adCopy || 'Take our survey!',
          },
        },
      })

      let ad = await createAd({
        name: `Ad: ${groupConfig?.title || 'No Title'} - ${school?.name || 'NA'}`,
        adset_id: adSetResponse,
        creative: {
          creative_id: adCreativeResponse,
        },
        status: 'PAUSED',
        conversion_domain: 'collegepulse.com',
      })

      return {
        institutionId: school.id,
        fbAdSetId: await adSetResponse,
      }
    })
  )

  return {
    errorSchools,
    title: groupConfig.title,
    startTime: groupConfig?.adConfig?.startTime || null,
    endTime: groupConfig?.adConfig?.endTime || null,
    adSets: await responses.filter(Boolean),
  }
}

const adSet = async config => {
  /* 
        Needed Params:
        Access_token
        Name
        Campaign_id
        Daily_budget
        Optimization_goal
        Status
        Bid_amount
        Bid_strategy
        Billing_event
        Targeting
        Promoted_object
        start_time
        end_time
    */

  return facebookRequest({
    url: `https://graph.facebook.com/v9.0/${act}/adsets`,
    method: 'post',
    params: config,
  })
    .then(res => {
      return res.data.id
    })
    .catch(err => {
      console.warn('There was an error creating an adset')
      console.log(err)
      return false
    })
}

const image = async config => {
  /* Get image from remote server, and send it over to facebook */

  let blob = await fetch(config.url).then(r => r.blob())
  let filename = config.url.split('/')[config.url.split('/').length - 1]

  let data = new FormData()
  data.append('filename', blob, filename)
  data.append('access_token', await getAccessToken())

  return facebookRequest({
    url: `https://graph.facebook.com/v9.0/${act}/adimages`,
    method: 'post',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
  })
    .then(res => {
      return res.data.images[filename].hash
    })
    .catch(err => {
      console.log('Error uploading image')
      console.log(err)
    })
}

const adCreative = async config => {
  return facebookRequest({
    url: `https://graph.facebook.com/v9.0/${act}/adcreatives`,
    method: 'post',
    params: config,
  })
    .then(res => {
      return res.data.id
    })
    .catch(err => {
      console.log('Error creating ad creative!')
      console.log(err)
    })
}

const createAd = config => {
  /* Create the ad, link it to the adset and creative */

  return facebookRequest({
    url: `https://graph.facebook.com/v9.0/${act}/ads`,
    method: 'post',
    params: config,
  })
    .then(res => {
      return res.data.id
    })
    .catch(err => {
      console.log('Error creating Ad!')
      console.log(err)
    })
}

export const getStatus = id => {
  /* Get the status of an adset from it's ID */
  return facebookRequest({
    url: `https://graph.facebook.com/v9.0/${id}`,
    method: 'get',
    params: {
      fields: 'status',
    },
  })
    .then(res => {
      return res.data
    })
    .catch(err => {
      console.warn('Error getting status')
      console.log(err)
    })
}
