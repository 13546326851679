import { ActionTypes } from '../actions'

const defaultState = {
  currentQuestionIndex: 0,
  justSaved: false,
  currentQIsPermanent: true,
  deleteConfirmingIndex: -1,
}

const CVQuestionsBuilderReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_QUESTION_INDEX:
      return Object.assign({}, state, {
        currentQuestionIndex: action.payload,
      })
    case ActionTypes.SET_JUST_SAVED_STATUS:
      return Object.assign({}, state, {
        justSaved: action.payload,
      })
    case ActionTypes.SET_CURRENT_QUESTION_PERMANENT_STATUS:
      return Object.assign({}, state, {
        currentQIsPermanent: action.payload,
      })
    case ActionTypes.SET_DELETE_CONFIRMING_INDEX:
      return Object.assign({}, state, {
        deleteConfirmingIndex: action.payload,
      })
    default:
      return state
  }
}

export default CVQuestionsBuilderReducer
