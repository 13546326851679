import React, { useState, useEffect } from 'react'
import { fetchSurveyProperty, setSurveyProperty } from '../../../../../api/admin'
import { Input, Button } from 'semantic-ui-react'
import { store } from '../../../../store'

export default ({ _id }) => {
  const [slackCode, setSlackCode] = useState('')
  const [changesMade, setChangesMade] = useState(false)

  // Get data
  useEffect(() => {
    fetchSurveyProperty({
      _id: _id,
      property: 'slackId',
    }).then(response => {
      setSlackCode(response)
    })
  }, [])

  const sendConfig = () => {
    setChangesMade(false)
    setSurveyProperty({
      _id: _id,
      property: 'slackId',
      value: slackCode,
      store: store,
    })
  }

  return (
    <div className="tracking-view-container">
      <hr />
      <h5>Slack Monitoring</h5>
      <p>
        Go to the slack channel you wish to monitor the survey on and type{' '}
        <span
          style={{
            backgroundColor: 'yellow',
            color: 'darkred',
            padding: '0.25rem 0.5rem',
            fontWeight: 900,
          }}
        >
          /sdt whoami
        </span>
        .
      </p>
      <p>
        Copy the <strong>Current Conversation ID</strong> and enter it into the input below.
      </p>
      <Input
        type="text"
        value={slackCode || ''}
        placeholder="Slack channel ID..."
        onChange={e => {
          setSlackCode(e?.target?.value?.trim() || null)
          setChangesMade(true)
        }}
        action={{
          color: changesMade ? 'green' : undefined,
          content: 'Update Slack ID',
          onClick: () => sendConfig(),
          disabled: !changesMade,
        }}
      />
    </div>
  )
}
