import { browserHistory } from 'react-router'
import { hideClosingModal, hideAuthorImgModal } from './create-view-modal-actions'

export const ActionTypes = {
  SET_VIEWING_ADD_NEW: 'SET_VIEWING_ADD_NEW',
  SET_DISPLAY_MC_OPTIONS: 'SET_DISPLAY_MC_OPTIONS',
}

export function setViewingAddNew() {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_VIEWING_ADD_NEW,
      payload: {
        viewingAddNew: true,
      },
    })
  }
}

export function setViewingQuestions() {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_VIEWING_ADD_NEW,
      payload: {
        viewingAddNew: false,
      },
    })
  }
}

export function setDisplayMcOptionsUnderAddNew(shouldDisplay) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_DISPLAY_MC_OPTIONS,
      payload: {
        displayMcOptionsUnderAddNew: shouldDisplay,
      },
    })
  }
}
