import React from 'react'
import Hammer from 'react-hammerjs'
import StripeCheckout from 'react-stripe-checkout'
import UAParse from 'user-agent-parser'
import Modal from 'react-modal'
import _ from 'lodash'
import { stripePublicKey } from '../../../../../../lib/constants'

import api from '../../../../../../api'

import InputBox from '../../../../../../common/input-box/input-box'

import { connect } from 'react-redux'
import { setActiveSurvey } from '../../../../../actions/create-view-survey-actions/set-survey-actions'
import {
  setPointVal,
  setMaxRespondents,
  setAttachedPromoCode,
} from '../../../../../actions/create-view-survey-actions/edit-survey-actions'
import { exitCreateView } from '../../../../../actions/create-view-navigation-actions'
import { setStripeToken } from '../../../../../actions/create-view-meta-actions'

const promoCodeTypes = ['full']

const MC_POINT_VALUE = 10
const OPEN_POINT_VALUE = 20

class ReviewViewSidebar extends React.Component {
  constructor() {
    super()

    this.state = {
      promoCode: '',
      promoCodeType: '',
      showPromoCodeX: false,
      showCodeMessage: false,
      costPerResponse: 0.3,
      minCostPerResponse: 0,
      maxCostPerResponse: 100000,
      fullPromoCodeApplied: false,
    }

    const boundMethods = [
      'token',
      'setPointVal',
      'onMaxRespondentsChange',
      'onPromoCodeChange',
      'onCostPerResponseChange',
      'onPromoCodeInputFocus',
      'onPromoCodeInputBlur',
      'validateCostPerResponse',
      'clearPromoCode',
    ]
    boundMethods.forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  /**************************** component lifecycle ***************************/

  componentWillMount() {
    let costPerResponse = this.state.costPerResponse
    if (this.props.survey && this.props.survey.pointValue) {
      costPerResponse = this.props.survey.pointValue * 0.006
    }
    this.setState({
      costPerResponse,
      minCostPerResponse: this.calculateMinCostPerResponse(),
      maxCostPerResponse: this.calculateMaxCostPerResponse(),
    })
  }

  /****************************** helper functions ****************************/

  calculateMinCostPerResponse() {
    let sum = 0
    this.props.survey.questions.forEach(q => {
      if (q.type === 'mc') {
        sum += MC_POINT_VALUE
      } else if (q.type === 'text') {
        sum += OPEN_POINT_VALUE
      }
    })
    return sum * 0.006
  }

  calculateMaxCostPerResponse() {
    if (this.props.survey.targetingMechanism === 'everyone') {
      return 100000 * 0.006 + this.calculateMinCostPerResponse()
    } else {
      return 150 * 0.006 + this.calculateMinCostPerResponse()
    }
  }

  token(token) {
    if (token) {
      this.props.setStripeToken({ token })
    }
  }

  /******************************* direct setters ****************************/

  setPointVal(costPerResponse) {
    const pointValue = costPerResponse / 0.006
    this.props.setPointVal(Math.round(pointValue / 10) * 10)
  }

  /******************************* input handlers *****************************/

  onMaxRespondentsChange(ev) {
    const value = ev.target.value || 0
    this.props.setMaxRespondents(value)
  }

  onCostPerResponseChange(ev) {
    const costPerResponse = ev.target.value
    // removes all special characters other than the period
    if (costPerResponse.match()) {
      //TODO: Broke this
      this.setState({ costPerResponse: this.state.costPerResponse })
      this.setPointVal(this.state.costPerResponse)
    } else {
      if (costPerResponse.split('.').length < 3) {
        this.setState({ costPerResponse: costPerResponse })
      } else {
        this.setState({ costPerResponse: this.state.costPerResponse })
      }
      this.setPointVal(this.state.costPerResponse)
    }
  }

  onPromoCodeChange(ev) {
    const promoCode = ev.target.value
    if (promoCode) {
      // automatically validate once user enters full code length
      this.validatePromoCode(promoCode)
    } else {
      this.setState({
        promoCodeType: promoCode.length > 0 ? 'invalid' : '',
      })
    }
    // dont let user enter more than 8 characters
    this.setState({
      promoCode: promoCode.slice(0, 8),
    })
  }

  onPromoCodeInputFocus(ev) {
    const promoCode = ev.target.value
    if (promoCode) {
      this.setState({
        showPromoCodeX: false,
        showCodeMessage: false,
      })
    }
  }

  onPromoCodeInputBlur(ev) {
    const promoCode = ev.target.value
    if (promoCode && promoCode.length > 0) {
      this.setState({ showPromoCodeX: true })
    }
    this.setState({
      showCodeMessage: true,
    })
  }

  /****************************** state management ****************************/

  clearPromoCode() {
    this.setState({
      promoCode: '',
      showPromoCodeX: false,
      showCodeMessage: false,
      fullPromoCodeApplied: false,
      promoCodeType: '',
    })
  }

  /******************************** validators ********************************/

  validateCostPerResponse() {
    let costPerResponse = Math.min(this.state.costPerResponse, this.state.maxCostPerResponse)
    if (!(this.props.survey.targetingMechanism === 'individuals')) {
      // if the survey is not targeting individuals, enforce the minimum
      // cost per response
      costPerResponse = Math.max(costPerResponse, this.state.minCostPerResponse)
    }
    this.setState({
      costPerResponse: costPerResponse.toFixed(2),
    })
    this.setPointVal(costPerResponse.toFixed(2))
  }

  validatePromoCode(promoCode) {
    promoCode = promoCode || this.state.promoCode // make promoCode optional param
    api.promocodes
      .validate(promoCode)
      .then(res => {
        if (res && promoCodeTypes.indexOf(res.type) > -1) {
          const fullPromoCodeApplied = res.type === 'full'
          this.setState({
            promoCodeType: res.type,
            fullPromoCodeApplied: fullPromoCodeApplied,
          })
          this.props.setAttachedPromoCode({ type: res.type, promoCode })
          document.getElementById('promo-code-input').blur()
          if (fullPromoCodeApplied) {
            // if a full promo code was applied, set the cost and point
            // value of the survey to their minimum values
            this.setState({
              costPerResponse: this.state.minCostPerResponse,
            })
            this.setPointVal(0.006 * 10)
          }
        }
      })
      .catch(err => {
        if (typeof err === 'string')
          this.setState({
            promoCodeType: 'invalid',
          })
      })
  }

  /******************************** rendering *********************************/

  renderMaxCostLine() {
    let maxCostLeftText
    let maxCostRightText

    let maxCost = _.isNil(this.props.survey.maxNumberOfParticipants)
      ? ''
      : (this.props.survey.maxNumberOfParticipants * this.state.costPerResponse).toFixed(0)
    if (this.state.promoCodeType && this.state.promoCodeType !== 'invalid') {
      // a promo code has been applied
      let discountedCost
      switch (this.state.promoCodeType) {
        case 'full':
          discountedCost = 0
          break
        default:
          discountedCost = (
            this.state.costPerResponse * this.props.survey.maxNumberOfParticipants
          ).toFixed(0)
      }
      maxCostLeftText = 'Max Cost w/ Discount'
      maxCostRightText = `$${discountedCost}`
    } else {
      // no promo code has been applied
      maxCostLeftText = 'Max Cost'
      maxCostRightText = `$${maxCost}`
    }
    return (
      <div className="max-cost-container">
        <div className="text-and-image-container">
          {maxCostLeftText}
          <div className="image-container">
            <img src="/img/help-green.svg" />
            <div className="popup-container">
              <div className="popup-text">
                If your maximum number of respondents has not been reached after two weeks, we will
                close your survey and charge you ONLY for the completed responses.
              </div>
            </div>
          </div>
        </div>
        <div>{maxCostRightText}</div>
      </div>
    )
  }

  renderPromoCodeLine() {
    let promoCodeX
    if (this.state.showPromoCodeX) {
      promoCodeX = (
        <Hammer onTap={this.clearPromoCode}>
          <div className="image-container">
            <img src="/img/x-green.svg" />
          </div>
        </Hammer>
      )
    }

    return (
      <div className="promo-code-container">
        <div className="promo-code-text">Apply Promo Code</div>
        <div className="code-input-container">
          <input
            type="text"
            id="promo-code-input"
            value={this.state.promoCode}
            onChange={this.onPromoCodeChange}
            onFocus={this.onPromoCodeInputFocus}
            onBlur={this.onPromoCodeInputBlur}
          />
          {promoCodeX}
        </div>
      </div>
    )
  }

  render() {
    if (!this.props.survey.id) {
      return <div />
    }

    /*
      The only cost to conduct a survey comes from our built-in incentive system.
      Upon completing a survey, students receive Pulse points, which are
      exchangeable for gift cards to partnering local and national businesses.
      You will be billed two weeks after the publish date.
    */

    // cascading decision of what to show on stripe button (lowest has highest precedence)
    let stripeCheckoutLabel = 'ENTER BILLING INFORMATION'
    stripeCheckoutLabel = this.props.stripeToken
      ? 'CHANGE BILLING INFORMATION'
      : stripeCheckoutLabel
    stripeCheckoutLabel = this.state.fullPromoCodeApplied
      ? 'NO PAYMENT REQUIRED'
      : stripeCheckoutLabel

    const billingButtonClass = this.state.fullPromoCodeApplied ? 'disabled' : ''

    let codeMessage
    if (this.state.showCodeMessage) {
      switch (this.state.promoCodeType) {
        case 'invalid':
          codeMessage = <div className="invalid-message">Invalid Code</div>
          break
        case 'full':
          codeMessage = <div className="success-message">Code Applied</div>
          break
        case '':
          codeMessage = <div />
          break
        default:
          codeMessage = <div />
          break
      }
    }

    return (
      <div className="admin-review-sidebar-container">
        <div className="review-sidebar-title">
          <span>Review & Pay</span>
        </div>
        <div className="horizontal-divider" />
        <div className="pricing-inputs-container">
          <div className="pricing-input">
            <div className="text-and-image-container">
              <div>Cost Per Response</div>
              <div className="image-container">
                <img src="/img/help-green.svg" />
                <div className="popup-container">
                  <div className="popup-text">
                    Upon completing your survey, respondents will receive a certain amount of Pulse
                    Points that they can use to purchase rewards.
                  </div>
                </div>
              </div>
            </div>
            <div className="input-container currency-input">
              <input
                type="text"
                value={this.state.costPerResponse}
                onChange={this.onCostPerResponseChange}
                onBlur={this.validateCostPerResponse}
              />
            </div>
          </div>
          <div className="pricing-input">
            <div className="text-and-image-container">
              <div>Max Responses</div>
              <div className="image-container">
                <img src="/img/help-green.svg" />
                <div className="popup-container">
                  <div className="popup-text">
                    Please input the maximum number of respondents that you would like to respond to
                    your survey. Once this limit is reached, we will automatically close your
                    survey.
                  </div>
                </div>
              </div>
            </div>
            <div className="input-container">
              <input
                type="text"
                value={this.props.survey.maxNumberOfParticipants}
                onChange={this.onMaxRespondentsChange}
              />
            </div>
          </div>
        </div>
        <div className="horizontal-divider" />
        <div className="cost-code-container">
          {this.renderMaxCostLine()}
          {this.renderPromoCodeLine()}
          <div className="code-message-container">{codeMessage}</div>
        </div>
        <div className="billing-button-container">
          <StripeCheckout
            ComponentClass="div"
            token={this.token}
            stripeKey={stripePublicKey}
            name="Pulse"
            description="All Payments Secured"
            image="/img/logo.png"
            panelLabel="Submit"
            disabled={this.state.fullPromoCodeApplied}
            label={stripeCheckoutLabel}
          >
            <div className={`billing-button ${billingButtonClass}`}>{stripeCheckoutLabel}</div>
          </StripeCheckout>
          <div className="billing-explanation">
            You will only be charged when your survey closes.
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
  viewOnly: state.createViewMeta.viewOnly,
  stripeToken: state.createViewMeta.stripeToken,
  highlightPayment: state.createViewMeta.highlightPayment,
})

export default connect(mapStateToProps, {
  setActiveSurvey,
  exitCreateView,
  setStripeToken,
  setPointVal,
  setMaxRespondents,
  setAttachedPromoCode,
})(ReviewViewSidebar)
