let prefix

const datascienceHosts = [
  'marketing.collegepulse.com',
  'insights.collegepulse.com, create.collegepulse.com',
]
const stagingHosts = ['staging.collegepulse.com', 'sigma.collegepulse.com']
if (process.env.NODE_ENV === 'production') {
  if (window.location.search.includes('report')) {
    prefix = 'https://env-prod-pulse-1.aws.collegepulse.com'
  } else if (datascienceHosts.includes(window.location.host)) {
    prefix = 'https://env-datascience-pulse-1.aws.collegepulse.com'
  } else if (stagingHosts.includes(window.location.host)) {
    prefix = 'https://env-prod-pulse-1.aws.collegepulse.com'
  } else if (window.location.host === 'mock.collegepulse.com') {
    prefix = 'https://env-mock-pulse-2.aws.collegepulse.com'
  } else if (window.location.host === 'mock2.collegepulse.com') {
    prefix = 'https://dartmouthpulsemock-pr-117.herokuapp.com'
  } else if (window.location.host === 'staging-insights.collegepulse.com') {
    prefix = 'https://env-prod-pulse-1.aws.collegepulse.com'
  } else if (window.location.host === 'internal-insights.collegepulse.com') {
    prefix = 'https://env-datascience-pulse-1.aws.collegepulse.com'
  } else {
    prefix = 'https://env-datascience-pulse-1.aws.collegepulse.com'
  }
} else {
  const arr = window.location.href.split(':')
  prefix = arr[0] + ':' + arr[1] + ':' + '3001'
}

//prefix = 'https://env-mock-pulse-2.aws.collegepulse.com'
//prefix = 'https://datascience-worker.herokuapp.com'
//prefix = 'https://env-prod-pulse-1.aws.collegepulse.com'
export default prefix
