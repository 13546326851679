import request from './request'
import prefix from './prefix'

exports.getAll = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/mock-environment/get-mock-scripts').end((err, res) => {
      if (err) {
        if (res && res.body && res.body.message) {
          return reject(res.body)
        } else {
          return reject(err)
        }
      }

      return resolve(res.body)
    })
  })
}

exports.bootstrap = function () {
  return new Promise((resolve, reject) => {
    request.post(prefix + '/api/mock-environment/bootstrap-environment').end((err, res) => {
      if (err) {
        if (res && res.body && res.body.message) {
          return reject(res.body)
        } else {
          return reject(err)
        }
      }

      return resolve(res.body)
    })
  })
}

exports.sendScript = function (activeScript) {
  console.log(activeScript)
  let data = {}
  activeScript.params.forEach(p => {
    data[p.param] = p.value || null
  })
  console.log(data)
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/mock-environment/run-mock-script/' + activeScript.name)
      .send(data)
      .end((err, res) => {
        if (err) {
          if (res && res.body && res.body.message) {
            return reject(res.body)
          } else {
            return reject(err)
          }
        }

        return resolve(res.body)
      })
  })
}
