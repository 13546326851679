import React from 'react'
import Modal from 'react-modal'
import Hammer from 'react-hammerjs'
import modalStyle from './modalStyle'

export default class SubmitModal extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} style={modalStyle} contentLabel="submit confirm modal">
        <Hammer onTap={this.props.onCancel}>
          <div className="cancel-exit-button">
            <img src="/img/close-white.svg" />
          </div>
        </Hammer>
        <div className="modal-text">
          <div>Are you sure you want to publish?</div>
          <div className="bolded-modal-text">
            You will not be able to make any changes to the survey after you publish.
          </div>
          <div>
            This will make the survey live to all targeted users, and you will be billed for points
            given out.
          </div>
        </div>
        <div className="modal-buttons-container">
          <Hammer onTap={this.props.onCancel}>
            <div className="modal-button discard">
              <div>Cancel</div>
            </div>
          </Hammer>
          <Hammer onTap={this.props.onSubmitFinal}>
            <div className="modal-button save">
              <div>Publish</div>
            </div>
          </Hammer>
        </div>
      </Modal>
    )
  }
}
