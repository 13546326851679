import React, { useState, useEffect } from 'react'
import { fetchSurveyProperty, setSurveyProperty, getOneSchool } from '../../../../../api/admin'
import { Button, Grid, Card, Dropdown, Checkbox, Icon, Input } from 'semantic-ui-react'
import { store } from '../../../../store'
import _ from 'lodash'

// Default global values
const options = {
  maximum: ['school'],
  exact: ['school', 'twoYearFourYear', 'studentType'],
}
const defaultQuota = {
  demOption: null,
  exact: false,
  key: 'list-item-1',
  options: {},
  maximumValue: null,
}

const generateID = () => {
  return _.uniqueId('list-item-')
}

export default ({ _id }) => {
  const [quotasData, setQuotasData] = useState(null)
  const [allQuotas, setAllQuotas] = useState([{ ...defaultQuota }])
  const [faceted, setFaceted] = useState(null)
  const [debugPublish, setDebugPublish] = useState(null)
  const [devMode, setDevMode] = useState(false)

  // Get all initial data
  useEffect(() => {
    getInitialData({ _id, setQuotasData, setFaceted, setAllQuotas })
  }, [])

  const formatData = () => {
    let exactTargets = []
    let maximums = []
    console.log('-- All Quotas --')
    console.log(allQuotas)
    allQuotas.forEach(quota => {
      if (quota.exact) {
        // Handle Exact
        if (quota.options) {
          // Handle exact options
          let tempOptions = { ...quota.options }
          let tempValue = tempOptions.all
          delete tempOptions.all
          Object.entries(tempOptions).forEach(option => {
            exactTargets.push({
              demographicCategory: quota.demOption,
              demographicOption: option?.[2]?._id || option[0],
              value: +option[1] || tempValue,
            })
          })
        }
      }

      if (!quota.exact) {
        //Handle maximum
        maximums.push({
          demographicCategory: quota.demOption,
          value: quota.maximumValue,
        })
      }
    })

    // Add all school values in that aren't already in
    if (allQuotas.find(q => q.demOption === 'school' && q.exact)) {
      let exSchoolTargets = exactTargets.filter(ex => ex.demographicCategory === 'school')
      faceted.school.forEach(school => {
        if (!exSchoolTargets.find(ex => ex.demographicOption === school.schoolObject._id)) {
          exactTargets.push({
            demographicCategory: 'school',
            demographicOption: school.schoolObject._id,
            value: allQuotas.find(aq => aq.demOption === 'school').options.all,
          })
        }
      })
    }

    setDebugPublish({
      exactTargets,
      maximums,
    })

    if (maximums)
      setSurveyProperty({
        _id: _id,
        property: 'quotas.maximums',
        value: maximums,
        store,
      })
    if (exactTargets)
      setSurveyProperty({
        _id,
        property: 'quotas.exactTargets',
        value: exactTargets,
        store,
      })
  }

  return (
    <Grid style={{ marginTop: '1rem' }}>
      <Grid.Row>
        <Grid.Column fluid>
          {allQuotas.map((quota, index) => (
            <Card fluid key={quota.key}>
              <Card.Content>
                <Card.Header>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Dropdown
                      selection
                      placeholder="Demographic"
                      clearable
                      value={quota?.demOption || null}
                      onChange={(e, data) =>
                        setAllQuotas(current => {
                          let currentData = [...current]
                          currentData[index].demOption = data.value || null
                          currentData[index].options = {}
                          if (
                            options.maximum.find(d => d === data.value) &&
                            !options.exact.find(d => d === data.value)
                          ) {
                            currentData[index].exact = false
                          } else if (
                            !options.maximum.find(d => d === data.value) &&
                            options.exact.find(d => d === data.value)
                          ) {
                            currentData[index].exact = true
                          }
                          return currentData
                        })
                      }
                      options={_.differenceWith(
                        _.union(options.exact, options.maximum),
                        allQuotas.map(x => x.demOption).filter(x => x !== quota?.demOption),
                        _.isEqual
                      ).map(val => ({
                        key: val,
                        text: _.startCase(val),
                        value: val,
                      }))}
                    />
                    <div>
                      <Checkbox
                        toggle
                        label="Exact Targeting?"
                        checked={quota.exact}
                        disabled={
                          !options.maximum.find(d => d === quota.demOption) ||
                          !options.exact.find(d => d === quota.demOption)
                        }
                        onChange={() => {
                          setAllQuotas(current => {
                            let currentData = [...current]
                            currentData[index].exact = !currentData[index].exact
                            return currentData
                          })
                        }}
                      />
                      <Button
                        className="quota-delete"
                        onClick={() => {
                          setAllQuotas(current => {
                            let adjusted = [...current]
                            _.pullAt(adjusted, index)
                            return adjusted
                          })
                        }}
                        icon
                        style={{ marginLeft: '3rem' }}
                      >
                        <Icon name="close" />
                      </Button>
                    </div>
                  </div>
                </Card.Header>
                {faceted && quota.demOption && <hr />}
                {faceted && quota.demOption && (
                  <Card.Description>
                    {quota.exact && !faceted[quota.demOption] && (
                      <p>
                        You need to target {_.startCase(quota.demOption)} to be able to assign exact
                        targeting quotas.
                      </p>
                    )}
                    {quota.exact && faceted[quota.demOption] && quota.demOption === 'school' && (
                      <Grid.Row>
                        <Grid.Column
                          fluid
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <div>Default completes:</div>
                          <div>
                            <Button
                              disabled={!quota.options.all}
                              onClick={() =>
                                setAllQuotas(current => {
                                  let adjusted = [...current]

                                  Object.keys(adjusted[index].options).forEach(
                                    key =>
                                      (adjusted[index].options[key] =
                                        adjusted[index].options['all'])
                                  )
                                  return adjusted
                                })
                              }
                            >
                              Apply to all
                            </Button>
                            <Input
                              className="mini-quota-row"
                              placeholder="Max completes"
                              type="number"
                              value={allQuotas[index].options['all']}
                              onChange={e => {
                                setAllQuotas(current => {
                                  let adjusted = [...current]
                                  adjusted[index].options['all'] = +e.target.value
                                  return adjusted
                                })
                              }}
                              style={{ padding: 0 }}
                            />
                          </div>
                        </Grid.Column>
                        <hr style={{ opacity: 0.4 }} />
                      </Grid.Row>
                    )}
                    {quota.exact &&
                      faceted[quota.demOption]?.map(dem => (
                        <Grid.Row key={dem.name}>
                          <Grid.Column
                            fluid
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '100%',
                            }}
                          >
                            <div>
                              The most <strong>{_.startCase(dem.name)}</strong> completes:
                            </div>
                            <Input
                              className="mini-quota-row"
                              placeholder="Max completes"
                              type="number"
                              value={
                                quota.options[dem?.schoolObject?._id || dem.name] ||
                                allQuotas[index].options['all']
                              }
                              onChange={e => {
                                setAllQuotas(current => {
                                  let adjusted = [...current]
                                  adjusted[index].options[dem?.schoolObject?._id || dem.name] =
                                    +e.target.value
                                  return adjusted
                                })
                              }}
                              style={{ padding: 0 }}
                            />
                          </Grid.Column>
                          <hr style={{ opacity: 0.4 }} />
                        </Grid.Row>
                      ))}

                    {!quota.exact && (
                      <Grid.Row>
                        <Grid.Column
                          fluid
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '100%',
                          }}
                        >
                          <div>
                            The most completes per <strong>{_.startCase(quota.demOption)}</strong>:
                          </div>
                          <Input
                            className="mini-quota-row"
                            placeholder="Max completes"
                            value={quota?.maximumValue || null}
                            onChange={e => {
                              setAllQuotas(current => {
                                let adjusted = [...current]
                                adjusted[index].maximumValue = +e.target.value
                                return adjusted
                              })
                            }}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    )}
                  </Card.Description>
                )}
              </Card.Content>
            </Card>
          ))}
          <Button
            icon
            labelPosition="right"
            onClick={() => {
              setAllQuotas(current => [
                ...current,
                {
                  ...defaultQuota,
                  key: generateID(),
                },
              ])
            }}
          >
            Add a quota
            <Icon name="plus" />
          </Button>
          <Button
            icon
            primary
            labelPosition="left"
            onClick={() => {
              formatData()
            }}
          >
            Publish Changes
            <Icon name="save" />
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column fluid>
          <hr />
          <Button
            onClick={() => {
              setDevMode(c => !c)
            }}
          >
            Toggle dev mode
          </Button>
          {devMode && (
            <>
              <h5>Debug Publish</h5>
              <pre>{JSON.stringify(debugPublish, null, 2)}</pre>

              <h5>Default</h5>
              <pre>{JSON.stringify(defaultQuota, null, 2)}</pre>

              <h5>quotasData</h5>
              <pre>{JSON.stringify(quotasData, null, 2)}</pre>

              <h5>allQuotas</h5>
              <pre>{JSON.stringify(allQuotas, null, 2)}</pre>

              <h5>Faceted</h5>
              <pre>{JSON.stringify(faceted, null, 2)}</pre>
            </>
          )}
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const getInitialData = async ({ _id, setQuotasData, setFaceted, setAllQuotas }) => {
  // Quota data
  let maximums = await fetchSurveyProperty({ _id, property: 'quotas.maximums' })
  let exact = await fetchSurveyProperty({ _id, property: 'quotas.exactTargets' })
  setQuotasData({
    maximums: maximums || [],
    exactTargets: exact || [],
  })
  let allQuotasNew = []
  if (maximums) {
    maximums.forEach(max => {
      allQuotasNew.push({
        ...defaultQuota,
        demOption: max.demographicCategory,
        exact: false,
        key: generateID(),
        maximumValue: max.value,
      })
    })
  }

  if (exact) {
    exact.forEach(exact => {
      if (allQuotasNew.find(x => x.demOption === exact.demographicCategory)) {
        let index = allQuotasNew.indexOf(
          allQuotasNew.find(x => x.demOption === exact.demographicCategory)
        )
        allQuotasNew[index].options[exact.demographicOption] = exact.value
      } else {
        allQuotasNew.push({
          ...defaultQuota,
          key: generateID(),
          exact: true,
          demOption: exact.demographicCategory,
          options: {
            [exact.demographicOption]: exact.value,
          },
        })
      }
    })
  }
  setAllQuotas(allQuotasNew)

  // Faceted data
  let faceted = await fetchSurveyProperty({ _id, property: 'facetedTargetedDemographics' })
  let facetedOptions = {}
  faceted
    .filter(x => x.includes(':'))
    .forEach(x => {
      if (!facetedOptions[x.split(':')[0]]) facetedOptions[x.split(':')[0]] = []
      facetedOptions[x.split(':')[0]].push({
        name: x.split(':')[1],
      })
    })

  if (facetedOptions.school) {
    facetedOptions.school = await Promise.all(
      facetedOptions.school.map(async school => {
        let schoolObject = await getOneSchool({ name: school.name })
        return { ...school, schoolObject }
      })
    )
  }

  setFaceted(facetedOptions)
}
