import React from 'react'
import InputBox from '../../../../../../../../common/input-box/input-box'

export default class WriteInQuestionOption extends React.Component {
  render() {
    return (
      <div className="option">
        <InputBox selected={false} name="creating-question" color="darkerGrey" />
        <div className="option-text">
          <div className="option-text-container greyed">
            <input
              id="option-form"
              type="text"
              value={'Write In: ________________'}
              readOnly={true}
            />
          </div>
        </div>
        <div className="bottom-border-block"></div>
      </div>
    )
  }
}
