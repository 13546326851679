import React, { useEffect, useState, useRef } from 'react'
import DemographicQuestionsSelect from './DemographicQuestionsSelect'
import CountingQuestionSelect from './CountingQuestionSelect'
import { connect } from 'react-redux'

const InitialCreateSidebar = ({ survey }) => {
  const [sidebarChanges, setSidebarChanges] = useState(false)

  /*
    useEffect cleanups will not use the current state, only the default state.
    Using the current value of a reference is a hacky solution, but a solution.

    When the state updates, the value is stored in the reference. When the useEffect calls
    for a cleanup, it uses the references value.
  */
  let myValue = useRef()
  useEffect(() => {
    myValue.current = sidebarChanges
  }, [sidebarChanges])

  const getStatus = () => {
    if (myValue.current) {
      window.alert(
        'You made changes to either the Demographics Questions / Counting Question that were NOT updated.\nYou will have to go back, apply those settings again, and press the update button.'
      )
    }
  }

  useEffect(() => {
    // Cleanup
    return () => {
      getStatus()
    }
  }, [])

  if (!survey || !survey._id) {
    return <div className="initial-sidebar" />
  }
  return (
    <div className="initial-sidebar">
      <DemographicQuestionsSelect
        sidebarChanges={sidebarChanges}
        setSidebarChanges={setSidebarChanges}
      />
      <CountingQuestionSelect
        sidebarChanges={sidebarChanges}
        setSidebarChanges={setSidebarChanges}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  survey: state.activeSurvey,
})

export default connect(mapStateToProps, {})(InitialCreateSidebar)
