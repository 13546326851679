import React from 'react'
import Hammer from 'react-hammerjs'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import Modal from 'react-modal'
import { PulseLoader as Loader } from 'halogenium'
import VisibilitySensor from 'react-visibility-sensor'

import api from '../../../api'
import storage from '../../../lib/storage'
import AdminSurveyCard from '../admin-survey-card/admin-survey-card'
import Searchbar from '../../../common/searchbar/searchbar'
import TemplateModal from '../modals/TemplateModal'
import { Grid, Checkbox, Header, Form } from 'semantic-ui-react'

import { connect } from 'react-redux'
import { refreshSurveys, resetSurveyPage, incrementPage } from '../../actions/all-surveys-actions'

class SurveyList extends React.Component {
  constructor() {
    super()

    this.state = {
      editView: false,
      createView: false,
      currSurvey: null,
      reviewSurveyView: false,
      showSubmittedModal: false,
      filters: {},
      showDataModal: false,
      surveyData: null,
      dataModalCurrentSurvey: null,
      filteredSurveys: [],
      filter: 'all',
      loading: true,
      loadingBottom: false,
      searchQuery: '',
      filterChecks: {
        type: {
          client: true,
          tracker: false,
          student: false,
          internal: false,
          none: false,
        },
        status: {
          unpublished: false,
          running: true,
          closed: false,
        },
      },
    }

    const boundMethods =
      'resetAllSurveys removeSurveyToFavorite addSurveyToFavorite handleSearchReset handleSearchChange onScrollToBottom setFilters closeDataModal toggleTemplateModal showDataModal closeSubmittedModal setEditView setReviewView onCancelReview openResults openSurvey filterClosed filterLive filterAll filterDrafts handleFilterCheck'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  resetAllSurveys() {
    this.props.resetSurveyPage()
    this.props
      .refreshSurveys(this.props.user, 0, this.state.searchQuery, this.state.filterChecks)
      .then(() => {
        this.setState({ loading: false })
      })
  }

  async addSurveyToFavorite(surveyId) {
    this.setState({ loading: true })

    //Add to favorites
    await api.favoriteSurveys.addSurveyToFavorite(surveyId)

    this.resetAllSurveys()
    window.location.reload()
  }

  async removeSurveyToFavorite(surveyId) {
    this.setState({ loading: true })

    //Add to favorites
    await api.favoriteSurveys.removeSurveyToFavorite(surveyId)

    this.resetAllSurveys()
    window.location.reload()
  }

  handleFilterCheck(category, option) {
    this.setState({ loading: true })
    let newFilterChecks = { ...this.state.filterChecks }
    newFilterChecks[category][option] = !newFilterChecks[category][option]
    this.setState({
      filterChecks: newFilterChecks,
    })

    this.resetAllSurveys()
  }

  componentWillMount() {
    this.resetAllSurveys()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.surveys) {
      this.filterSurveys(nextProps.surveys)
    }

    if (nextProps.surveyPage !== this.props.surveyPage) {
      this.setState({ loadingBottom: true })
      this.props
        .refreshSurveys(
          this.props.user,
          nextProps.surveyPage,
          nextProps.searchQuery,
          nextProps.filterChecks
        )
        .then(() => {
          this.setState({ loadingBottom: false })
        })
    }
  }

  handleSearchChange(searchQuery) {
    this.props.resetSurveyPage()
    this.props.refreshSurveys(this.props.user, 0, searchQuery, this.state.filterChecks)
    this.setState({
      searchQuery: searchQuery,
    })
  }

  handleSearchReset() {
    this.props.resetSurveyPage()
    this.props.refreshSurveys(this.props.user, 0, '', this.state.filterChecks)
    this.setState({
      searchQuery: '',
    })
  }

  onScrollToBottom(isVisible) {
    if (isVisible) {
      this.props.incrementPage()
    }
  }

  closeSubmittedModal() {
    this.setState({
      showSubmittedModal: false,
    })
  }

  showDataModal(survey, data) {
    this.setState({
      showDataModal: true,
      surveyData: data,
      dataModalCurrentSurvey: survey,
    })
  }

  closeDataModal() {
    this.setState({
      showDataModal: false,
      surveyData: null,
      dataModalCurrentSurvey: null,
    })
  }

  toggleTemplateModal() {
    this.setState({
      showTemplateModal: !this.state.showTemplateModal,
    })
  }

  setFilters(filters) {
    this.setState({ filters })
  }

  setEditView(survey) {
    const surveyId = typeof survey == 'string' ? survey : survey.id

    api.admin
      .one(surveyId)
      .then(survey => {
        this.setState({
          editView: true,
          currSurvey: survey,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  openResults(id) {
    window.open(`https://app.collegepulse.com/survey/${id ? id : ''}`, '_blank')
  }

  openSurvey(id) {
    window.open(`/create/survey/metadata/${id ? id : ''}`, '_blank')
  }

  openNewSurvey() {
    window.open('/create/survey/metadata', '_blank')
  }

  setReviewView(survey) {
    const surveyId = typeof survey == 'string' ? survey : survey.id

    api.admin
      .one(surveyId)
      .then(s => {
        this.setState({
          currSurvey: s,
          reviewSurveyView: true,
          editView: false,
          createView: false,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  onCancelReview(survey) {
    this.state.reviewSurveyView = false
    this.setEditView(survey)
  }

  filterAll() {
    this.state.filter = 'all'
    this.filterSurveys()
  }

  filterLive() {
    this.state.filter = 'live'
    this.filterSurveys()
  }

  filterDrafts() {
    this.state.filter = 'drafts'
    this.filterSurveys()
  }

  filterClosed() {
    this.state.filter = 'closed'
    this.filterSurveys()
  }

  filterSurveys(surveys) {
    surveys = surveys || this.props.surveys
    if (!surveys) {
      return
    }
    let filterCheck
    if (this.state.filter === 'live') {
      filterCheck = survey =>
        survey.publishedAt && (!survey.closesAt || survey.closesAt > Date.now())
    } else if (this.state.filter === 'drafts') {
      filterCheck = survey => !survey.submittedAt
    } else if (this.state.filter === 'closed') {
      filterCheck = survey => survey.closesAt && Date.parse(survey.closesAt) <= Date.now()
    } else {
      filterCheck = () => true
    }

    let newFilteredSurveys = surveys.filter(filterCheck)
    this.setState({
      filteredSurveys: newFilteredSurveys,
    })
  }

  backToApp() {
    window.location = 'https://app.collegepulse.com/app'
  }

  renderSubmittedModal() {
    let modalStyle = {
      content: { top: '40%', bottom: '40%', left: '30%', right: '30%' },
      overlay: { backgroundColor: 'rgba(0,0,0,.4)' },
    }

    return (
      <Modal
        isOpen={this.state.showSubmittedModal}
        style={modalStyle}
        contentLabel="Submit Confirm Modal"
      >
        <div className="review-exit-container">
          <Hammer onTap={this.closeSubmittedModal}>
            <div className="cancel-exit-button">
              <img src="/img/blueX.svg" />
            </div>
          </Hammer>
          <div className="submit-modal-text">
            <div>Your survey has been submitted for review.</div>
            <div>You will recieve an email when it is published.</div>
          </div>
          <div className="r-buttons-container">
            <Hammer onTap={this.closeSubmittedModal}>
              <div className="check-mark-button">
                <img src="/img/circle-check.svg" />
              </div>
            </Hammer>
          </div>
        </div>
      </Modal>
    )
  }

  renderDataModal() {
    let dataModalStyle = {
      content: { top: '20%', bottom: '20%', left: '30%', right: '30%' },
      overlay: { backgroundColor: 'rgba(0,0,0,.4)' },
    }
    return (
      <Modal
        isOpen={this.state.showDataModal}
        style={dataModalStyle}
        contentLabel="Participants Data Modal"
      >
        <div className="data-modal-container">
          <Hammer onTap={this.closeDataModal}>
            <div className="cancel-exit-button">
              <img src="/img/blueX.svg" />
            </div>
          </Hammer>
          <div className="data-modal-stats">
            <span>
              Total Users Targeted:{' '}
              {this.state.surveyData.taken.length + this.state.surveyData.notTaken.length}
            </span>
            <span>Total Users Taken: {this.state.surveyData.taken.length}</span>
            <span>Total Users Not Taken: {this.state.surveyData.notTaken.length}</span>

            <span>Targeted Who Have Taken:</span>
            <textarea readonly rows={10} value={this.state.surveyData.taken.join(',\n')} />
            <span>Targeted Who Have Not Taken:</span>
            <textarea readonly rows={10} value={this.state.surveyData.notTaken.join(',\n')} />
          </div>
        </div>
      </Modal>
    )
  }

  renderSidebar(scrollable) {
    const searchBar = (
      <div className="searchbar-container" style={{ display: 'block' }}>
        <Grid.Row style={{ display: 'flex' }}>
          <Searchbar
            searchQuery={this.state.searchQuery}
            handleSearchChange={this.handleSearchChange}
            handleSearchReset={this.handleSearchReset}
            noCollapse={true}
          />
          <button onClick={this.props.incrementPage}>Load More</button>
        </Grid.Row>

        <Grid.Row style={{ marginTop: '2rem', marginLeft: '3.5rem' }} align="left">
          <Header as="h4">Type</Header>
          <Form>
            <Form.Field>
              <Checkbox
                checked={this.state.filterChecks.type.client}
                onChange={() => this.handleFilterCheck('type', 'client')}
                label="Client"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={this.state.filterChecks.type.tracker}
                onChange={() => this.handleFilterCheck('type', 'tracker')}
                label="Tracker"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={this.state.filterChecks.type.student}
                onChange={() => this.handleFilterCheck('type', 'student')}
                label="Student"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={this.state.filterChecks.type.internal}
                onChange={() => this.handleFilterCheck('type', 'internal')}
                label="Internal"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={this.state.filterChecks.type.none}
                onChange={() => this.handleFilterCheck('type', 'none')}
                label="None"
              />
            </Form.Field>
          </Form>

          <Header as="h4">Status</Header>
          <Form>
            <Form.Field>
              <Checkbox
                checked={this.state.filterChecks.status.unpublished}
                onChange={() => this.handleFilterCheck('status', 'unpublished')}
                label="Unpublished"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={this.state.filterChecks.status.closed}
                onChange={() => this.handleFilterCheck('status', 'closed')}
                label="Closed"
              />
            </Form.Field>
            <Form.Field>
              <Checkbox
                checked={this.state.filterChecks.status.running}
                onChange={() => this.handleFilterCheck('status', 'running')}
                label="Running"
              />
            </Form.Field>
          </Form>
        </Grid.Row>
      </div>
    )
    return (
      <div className={`admin-view-sidebar ${scrollable ? 'scrollable' : ''}`}>
        <div className="back-button-container" onClick={this.backToApp}>
          <img src="/img/arrow-left.svg" />
          <span>Back</span>
        </div>
        {searchBar}
      </div>
    )
  }

  render() {
    const { filters } = this.state
    let surveys = this.state.filteredSurveys

    let mainContents
    let sidebar
    let scrollable = false
    let submittedModal, surveyDataModal

    if (this.state.loading) {
      mainContents = (
        <div className="admin-survey-cards-container">
          <Loader color="#20BC9C" size="16px" margin="10px" />
        </div>
      )
    } else if (surveys) {
      // there are currently surveys to show
      scrollable = true
      let surveyCards = []

      surveyCards.push(
        <div className="survey-list-top-bar">
          <div className="create-new-survey-buttons-container">
            <div className="create-new-survey-button" onClick={this.openNewSurvey}>
              <img src="/img/plus-sign.svg" />
              CREATE A SURVEY
            </div>
            <div onClick={this.toggleTemplateModal} className="create-new-survey-button">
              Templates
            </div>
            {this.props.user.master && (
              <a
                href="https://app.collegepulse.com/admin-dashboard"
                className="create-new-survey-button"
              >
                Admin Dashboard
              </a>
            )}
          </div>
          <div className="top-bar-filler-container" />
        </div>
      )

      surveys.forEach(s => {
        let key = s ? s.id : 'new'
        s.numParticipants = s.manualNumParticipants
        surveyCards.push(
          <AdminSurveyCard
            user={this.props.user}
            survey={s}
            openResults={this.openResults}
            openSurvey={this.openSurvey}
            id={s.id}
            key={key}
            refreshSurveys={this.props.refreshSurveys}
            setEditView={this.openSurvey}
            showSurveyData={this.showDataModal}
            addSurveyToFavorite={this.addSurveyToFavorite}
            removeSurveyToFavorite={this.removeSurveyToFavorite}
          />
        )
      })

      surveyCards.push(
        <VisibilitySensor
          key={'vis'}
          onChange={this.onScrollToBottom}
          active={!this.props.endOfPagesReached}
        />
      )

      if (this.state.loadingBottom) {
        surveyCards.push(<Loader color="#20BC9C" size="16px" margin="10px" />)
      }

      mainContents = <div className="admin-survey-cards-container">{surveyCards}</div>

      submittedModal = this.renderSubmittedModal()

      if (this.state.showDataModal) {
        surveyDataModal = this.renderDataModal()
      }
      // console.log('SURVEY CARDS: ', surveyCards)
    } else {
      // not currently any surveys to show
      let surveyCards = []
      surveyCards.push(
        <div className="survey-list-top-bar">
          <div className="create-new-survey-button" onClick={this.openNewSurvey}>
            <img src="/img/plus-sign.svg" />
            CREATE A SURVEY
          </div>
          <div onClick={this.toggleTemplateModal}>Templates</div>
          <div className="top-bar-filler-container" />
        </div>
      )
      mainContents = <div className="admin-survey-cards-container">{surveyCards}</div>
    }
    sidebar = this.renderSidebar(scrollable)

    return (
      <div className="admin-view-list">
        {surveyDataModal}
        {sidebar}
        <div className={`admin-view-contents ${scrollable ? 'scrollable' : ''}`}>
          <TemplateModal isOpen={this.state.showTemplateModal} toggle={this.toggleTemplateModal} />
          {submittedModal}
          {mainContents}
        </div>
      </div>
    )
  }
}

SurveyList.propTypes = {
  user: PropTypes.object,
  surveys: PropTypes.array,
}

const mapStateToProps = state => ({
  user: state.user.user,
  surveys: state.allSurveys.surveys,
  surveyPage: state.allSurveys.surveyPage,
  endOfPagesReached: state.allSurveys.endOfPagesReached,
})

export default connect(mapStateToProps, {
  refreshSurveys,
  incrementPage,
  resetSurveyPage,
})(SurveyList)
