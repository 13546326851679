import React from 'react'
import api from '../../../api'
import UAParse from 'user-agent-parser'
import _ from 'lodash'

import CloseAndSaveModal from './modals/closeAndSaveModal'
import SelectAuthorImgModal from './modals/selectAuthorImgModal'
import EnterTitleModal from './modals/enterTitleModal'
import SubmitModal from './modals/submit-final'
import CreateSurveyCard from './views/initial-create-view/create-survey-card/create-survey-card'
import InitialCreateSidebar from './views/initial-create-view/sidebar/sidebar'

import QuestionsBuilderSidebar from './views/questions-builder-view/sidebar/sidebar'
import QuestionsBuilderContent from './views/questions-builder-view/content/content'
import ReviewViewSidebar from './views/review-view/sidebar/sidebar'
import ReviewViewContent from './views/review-view/content/content'
import defaultQuestions from '../../actions/lib/default-questions.json'
import RefreshModal from './modals/RefreshModal'

import { connect } from 'react-redux'
import {
  setActiveSurvey,
  setNewSurveyActive,
} from '../../actions/create-view-survey-actions/set-survey-actions'
import {
  initialSurveySave,
  saveSurvey,
  putTempTargetedIndividualsOnSurveyAndSave,
} from '../../actions/create-view-survey-actions/save-survey-actions'
import { assignUniqueKeysAndTemporaryVariables } from '../../actions/create-view-survey-actions/edit-survey-questions-actions'
import {
  setAuthorImg,
  setMessageCount,
} from '../../actions/create-view-survey-actions/edit-survey-actions'
import {
  hideClosingModal,
  hideAuthorImgModal,
  showEnterTitleModal,
  hideEnterTitleModal,
  showSubmitConfirmModal,
  hideSubmitConfirmModal,
} from '../../actions/create-view-modal-actions'
import { setCurrentPage, exitCreateView } from '../../actions/create-view-navigation-actions'
import { changeQuestion } from '../../actions/cv-questions-builder-actions'
import {
  initializeTempTargetedDemographics,
  initializePriceByDemographics,
  initializeTempSpecificIndividuals,
  fetchDemographicsOptions,
  setTargetingMechanism,
} from '../../actions/create-view-survey-actions/temp-demographics-actions'
import { Prompt } from 'react-router-dom'

class CreateView extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      published: false,
      // if someone saves a survey before entering a title,
      // they will be prompted to give a new title that we then put on survey before saving
      newSurveyTitle: '',
    }

    try {
      let ua = UAParse(navigator.userAgent)
      this.isSafari = ua.browser.name == 'Safari'
    } catch (e) {
      // statements to handle any exceptions
      this.isSafari = false // pass exception object to error handler
    }

    const boundMethods =
      'saveAndClose discardAndClose onSubmitFinal save setTitleAndSave setTitleAndSaveAndClose'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  componentWillMount() {
    this.props.fetchDemographicsOptions()
    this.props.setChangesMade(false)

    if (this.props.match.params && this.props.match.params.surveyId) {
      this.props.setActiveSurvey(this.props.match.params.surveyId, this.props.user)
    }
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.survey.id &&
      this.props.survey.id &&
      nextProps.survey.questions?.[0] &&
      this.props.survey.questions?.[0]
    ) {
      let one = nextProps.survey.questions.map(o => ({
        ...o,
        key: undefined,
        updatedAt: null,
        __v: null,
      }))
      let two = this.props.survey.questions.map(o => ({
        ...o,
        key: undefined,
        updatedAt: null,
        __v: null,
      }))

      if (one && two && !_.isEqual(one, two)) {
        this.props.setChangesMade(true)
      }
    }
    if (nextProps.survey.id && !this.props.survey.id) {
      const messageCount = nextProps.survey.questions.reduce(function (count, question) {
        let toAdd = question.type === 'message' ? 1 : 0
        return count + toAdd
      }, 0)
      this.props.setMessageCount(messageCount)
      this.props.assignUniqueKeysAndTemporaryVariables(nextProps.survey.questions)
    }
    // in case we mounted before demOptions had been retrieved
    if (_.size(this.props.demOptions) < _.size(nextProps.demOptions)) {
      // this.props.initializeTempTargetedDemographics(nextProps.demOptions, this.props.survey)
    }
    if (
      _.size(nextProps.tempDemographics.demOptions) > 0 &&
      _.size(this.props.tempDemographics.demOptions) === 0
    ) {
      let demGroupOptions = []
      for (let demOption in nextProps.demOptions) {
        demGroupOptions[demOption] = {
          value: demOption,
          label: _.startCase(demOption),
        }
      }
      this.props.initializePriceByDemographics(demGroupOptions, this.props.survey)
    }
  }

  mapTempDemographicsToSurveyAndSave(params) {
    if (this.props.survey.id) {
      this.props.saveSurvey(this.props.survey, true)
    } else {
      this.props.initialSurveySave(this.props.survey, {
        shouldClose: params.shouldClose,
        pageToOpenTo: params.pageToOpenTo,
        newSurveyTitle: this.state.newSurveyTitle,
      })
    }
    this.props.hideEnterTitleModal()
    return true
  }

  onSubmitFinal() {
    if (this.props.viewOnly) {
      return
    }

    let survey = _.cloneDeep(this.props.survey)

    let errorIndex = -1
    let errorMessage

    let unfinished = false
    let questionCount = 0
    let messagesSeen = 0

    // make sure yes/no questions get saved with the right options
    survey.questions.forEach(q => {
      if (q.type === 'mc' && q.yesNo) {
        q.options = defaultQuestions.mc.yesNoOptions
      }
    })

    // Check for null question titles, and remove null options
    // `some` will short circuit if we return true from any (ie find an error)
    survey.questions.some((question, index) => {
      questionCount++

      if (unfinished) return

      // increment messages seen so as to give right question number in alert
      if (question.type === 'message') {
        messagesSeen++
      }

      // if question has no type -> ERROR!
      if (question.type === 'noType') {
        errorIndex = index
        errorMessage = 'All Questions Must Have a Type'
        return true // return true to short circuit
      }
      // if question has no description -> ERROR!
      if (!question.description) {
        errorIndex = index
        if (question.type === 'message') {
          errorMessage = 'Title of Your Message Must be Set'
        } else {
          // calculate question number taking into account messages before it
          errorMessage =
            'Question Title for Question ' + (index + 1 - messagesSeen) + ' Must Be Set'
        }
        return true // return true to short circuit
      }

      // filter out empty options
      question.options = question.options.filter(option => option.text)

      // if question has no non-empty options -> ERROR!
      if (question.options.length === 0 && question.type === 'mc') {
        errorIndex = index
        let qNumber = index + 1 - messagesSeen
        errorMessage =
          'No options set for multiple choice question: q' +
          qNumber +
          '. If you want no options, please add a message slide instead.'

        return true // return true to short circuit
      }

      if (question.quizSettings && question.quizSettings.isQuiz) {
        if (!question.options.map(o => o.value).includes(question.quizSettings.correctAnswer)) {
          errorIndex = index
          let qNumber = index + 1 - messagesSeen
          errorMessage = 'No correct answer set for quiz question: q' + qNumber
          return true
        }
      }
    })

    if (errorIndex > -1) {
      this.props.setCurrentPage('questions')
      this.props.changeQuestion(errorIndex)
      this.props.hideSubmitConfirmModal()
      window.alert(errorMessage)

      return
    } else {
      survey.questionCount = questionCount
      survey.questions.forEach(q => {
        const seen = new Set()
        q.options = q.options
          .filter(o => o != null)
          .filter(o => {
            if (seen.has(o.value)) {
              return false
            } else {
              seen.add(o.value)
              return true
            }
          })
      })
      this.props.saveSurvey(survey, false, () => {
        // browserHistory.push(`create/review/${survey.id}`)
        api.admin
          .publish(
            survey.id,
            this.props.stripeToken,
            survey.promoCodeObj ? survey.promoCodeObj.promoCode : null
          )
          .then(() => {
            this.props.exitCreateView()
          })
          .catch(err => {
            if (err.status === 402) {
              alert(
                'There seems to be an issue with the payment information you have provided. Please try again.'
              )
            }
          })
      })
    }
  }

  publishSurvey() {
    if (!this.state.published) {
      api.admin.publish(this.props.survey.id).then(() => {
        this.setState({
          published: true,
        })
      })
    }
  }

  save(params) {
    params = params || {}
    if (!this.props.survey.title && !this.state.newSurveyTitle && !params.byPassTitleRequirement) {
      // if there is no title make them enter a title
      this.props.hideClosingModal()
      this.props.showEnterTitleModal({ attemptingToClose: params.attemptingToClose })
      return false
    } else {
      this.mapTempDemographicsToSurveyAndSave({
        shouldClose: params.shouldClose,
        pageToOpenTo: params.pageToOpenTo,
      })
      return true
    }
  }

  discardAndClose() {
    this.props.exitCreateView()
  }

  saveAndClose(params) {
    const allowed = this.save({ attemptingToClose: true, shouldClose: params.shouldClose })
    if (allowed) {
      this.props.exitCreateView()
    }
  }

  setTitleAndSave(title) {
    this.state.newSurveyTitle = title
    this.save()
  }

  setTitleAndSaveAndClose(title) {
    this.state.newSurveyTitle = title
    this.saveAndClose({ shouldClose: true })
  }

  render() {
    if (!this.props.survey || !this.props.survey.questions) {
      return <div />
    }
    let mainContent
    let sidebar
    let errorBanner

    let adminEditMainClass

    if (this.props.currentPage === 'details') {
      sidebar = <InitialCreateSidebar />

      mainContent = <CreateSurveyCard />

      if (this.props.navigationErrors.length > 0) {
        errorBanner = (
          <div className="create-view-error-banner">
            Please complete the information below before continuing.
          </div>
        )
      }
    } else if (this.props.currentPage === 'questions') {
      sidebar = (
        <QuestionsBuilderSidebar
          submitMessage={this.adminReview ? 'Publish' : 'Submit'}
          submitMethod={this.adminReview ? this.publishSurvey : this.onSubmitFinal}
          viewOnly={this.viewOnly}
        />
      )
      mainContent = (
        <QuestionsBuilderContent changesMade={this.props.changesMade} viewOnly={this.viewOnly} />
      )
    } else if (this.props.currentPage === 'review') {
      sidebar = (
        <ReviewViewSidebar
          submitMessage={this.adminReview ? 'Publish' : 'Submit'}
          submitMethod={this.adminReview ? this.publishSurvey : this.onSubmitFinal}
          viewOnly={this.viewOnly}
        />
      )
      mainContent = <ReviewViewContent viewOnly={this.viewOnly} />

      adminEditMainClass += ' scrollable'
    } else {
      // whoops!
    }

    const modals = (
      <React.Fragment>
        <CloseAndSaveModal
          isOpen={this.props.modals.closing}
          onCancelExit={this.props.hideClosingModal}
          discardAndClose={this.discardAndClose}
          saveAndClose={this.saveAndClose}
        />
        <SelectAuthorImgModal
          isOpen={this.props.modals.authorImg}
          authorImgUrl={this.props.survey.authorImg}
          setNewAuthorImg={this.props.setAuthorImg}
          hideAuthorImgModal={this.props.hideAuthorImgModal}
        />
        <EnterTitleModal
          isOpen={this.props.modals.title}
          onCancel={this.props.hideEnterTitleModal}
          setTitleAndSave={
            this.props.modals.closeUponSaving ? this.setTitleAndSaveAndClose : this.setTitleAndSave
          }
        />
        <SubmitModal
          isOpen={this.props.modals.submit}
          onCancel={this.props.hideSubmitConfirmModal}
          onSubmitFinal={this.onSubmitFinal}
        />
        <RefreshModal saveError={this.props.survey && this.props.survey.saveError} />
      </React.Fragment>
    )

    return (
      <React.Fragment>
        <Prompt
          when={this.props.changesMade}
          message="Are you sure you wish to continue without saving?"
        />
        {modals}
        {mainContent}
        {sidebar}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
  viewOnly: state.createViewMeta.viewOnly,
  reviewingForPublish: state.createViewMeta.reviewingForPublish,
  currentPage: state.createViewNav.currentPage,
  sectionTitle: state.createViewNav.sectionTitle,
  modals: state.createViewModals,
  tempDemographics: state.createViewTempDemographics,
  navigationErrors: state.createViewNav.navigationErrors,
  stripeToken: state.createViewMeta.stripeToken,
})

export default connect(mapStateToProps, {
  setActiveSurvey,
  setNewSurveyActive,
  initialSurveySave,
  setCurrentPage,
  exitCreateView,
  hideClosingModal,
  hideAuthorImgModal,
  setAuthorImg,
  saveSurvey,
  putTempTargetedIndividualsOnSurveyAndSave,
  changeQuestion,
  setMessageCount,
  assignUniqueKeysAndTemporaryVariables,
  initializeTempTargetedDemographics,
  initializePriceByDemographics,
  initializeTempSpecificIndividuals,
  fetchDemographicsOptions,
  setTargetingMechanism,
  showEnterTitleModal,
  hideEnterTitleModal,
  showSubmitConfirmModal,
  hideSubmitConfirmModal,
})(CreateView)
