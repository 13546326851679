import React from 'react'
import Modal from 'react-modal'
import Hammer from 'react-hammerjs'
import Select from 'react-select'
import { Input, Button } from 'semantic-ui-react'
import api from '../../../api'

const nonRespondentsModalStyle = {
  content: { top: '23%', bottom: '23%', left: '32%', right: '32%' },
  backgroundColor: 'rgba(0, 0, 0, .4)',
}

const enabledStyle = {
  option: provided => ({
    ...provided,
  }),
  control: provided => ({
    // none of react-select's styles are passed to <Control />
    ...provided,
    width: '100%',
    marginLeft: 15,
    marginRight: 15,
  }),
  singleValue: provided => {
    return { ...provided }
  },
}

export default class LongitudinalModal extends React.Component {
  constructor() {
    super()
    this.state = {
      frequencyInDays: 7,
      numberRuns: 1,
      sampleSize: 1000,
    }

    this.onChangeFrequency = this.onChangeFrequency.bind(this)
    this.onChangeStudies = this.onChangeStudies.bind(this)
    this.onChangeSample = this.onChangeSample.bind(this)
    this.submitLongitudinal = this.submitLongitudinal.bind(this)
  }

  onChangeFrequency(ev) {
    this.setState({ frequencyInDays: ev.target.value })
  }

  onChangeStudies(ev) {
    this.setState({ numberRuns: ev.target.value })
  }

  onChangeSample(ev) {
    this.setState({ sampleSize: ev.target.value })
  }

  submitLongitudinal() {
    const { surveyId } = this.props
    this.setState({ submitting: true })
    api.admin.createLongitudinal(surveyId, this.state).then(() => {
      this.setState({ success: true })
      this.setState({ submitting: false })
    })
  }

  render() {
    const { isOpen } = this.props
    const { frequencyInDays, numberRuns, sampleSize } = this.state
    return (
      <Modal isOpen={isOpen} style={nonRespondentsModalStyle} contentLabel="Longitudinal Modal">
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '100%',
            height: '100%',
          }}
        >
          <div className="close-exit-button">
            <Hammer onTap={this.props.closeModal}>
              <img className="close-button" src="/img/close-gray.svg" />
            </Hammer>
          </div>
          <Input
            label="Frequency (Days)"
            value={frequencyInDays}
            onChange={this.onChangeFrequency}
          />
          <Input label="Total # of Studies" value={numberRuns} onChange={this.onChangeStudies} />
          <Input label="Sample Size" value={sampleSize} onChange={this.onChangeSample} />
          {this.state.success && <p>Success!</p>}
          <Button onClick={this.submitLongitudinal} disabled={this.state.submitting}>
            Submit
          </Button>
        </div>
      </Modal>
    )
  }
}
