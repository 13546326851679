// /admin/ambassador/get-active-institutions/:surveyId
// TODO(Zap): Once done with ambassador outrach, create an axios file
// that will replace request :)
import request from './request'
import prefix from './prefix'
import Promise from 'bluebird'

export const getActiveInstitutions = surveyId => {
  return new Promise((resolve, reject) => {
    request
      .get(`${prefix}/api/admin/ambassador/get-active-institutions/${surveyId}`)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

// turn to post
// /admin/ambassador/get-ambassadors/:surveyId
export const getAmbassadors = (surveyId, selectedInstitutions) => {
  return new Promise((resolve, reject) => {
    request
      .post(`${prefix}/api/admin/ambassador/get-ambassadors/${surveyId}`)
      .send({ selectedInstitutions })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

exports.sendEmails = data => {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/admin/ambassador/send-emails')
      .send(data)
      .end(async (err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        return resolve(res)
      })
  })
}
