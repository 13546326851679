import { browserHistory } from 'react-router'
import {
  hideClosingModal,
  hideAuthorImgModal,
  hideEnterTitleModal,
  hideSubmitConfirmModal,
} from './create-view-modal-actions'
import { setDisplayMcOptionsUnderAddNew } from './cv-questions-builder-sidebar-actions'
import { clearActiveSurvey } from './create-view-survey-actions/set-survey-actions'
import { setStripeToken } from './create-view-meta-actions'

export const ActionTypes = {
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_NAVIGATION_ERRORS: 'SET_NAVIGATION_ERRORS',
}

// export const CURRENT_PAGE_OPTIONS = {
//   tags: 'tags',
//   demographics: 'demographics',
//   main: 'main',
// }

export function navigateBack(currentPage) {
  return dispatch => {
    let newPage, sectionTitle
    switch (currentPage) {
      case 'questions':
        newPage = 'demographics'
        sectionTitle = 'Target Users!'
        break
      case 'demographics':
        newPage = 'tags'
        sectionTitle = 'Tags??'
        break
      default:
        return
    }

    dispatch(setCurrentPage(newPage, sectionTitle))
    dispatch(setDisplayMcOptionsUnderAddNew(false))
  }
}

export function setCurrentPage(page, title) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_CURRENT_PAGE,
      payload: {
        currentPage: page,
        sectionTitle: title,
      },
    })
  }
}

export function exitCreateView() {
  window.location = '/create'
}

export function setNavigationErrors(errors) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_NAVIGATION_ERRORS,
      payload: errors,
    })
  }
}
