import React, { useState, useEffect } from 'react'
import api from '../../../../../api'
import { fetchSurveyProperty, setSurveyProperty } from '../../../../../api/admin'
import { Checkbox } from 'semantic-ui-react'
import { store } from '../../../../store'

export default ({ survey, _id }) => {
  const [published, setPublished] = useState(false)
  const [closed, setClosed] = useState(false)
  const [hideFromApp, setHideFromApp] = useState(false)
  const [recAvailable, setRecAvailable] = useState(false)

  // Get inital data
  useEffect(() => {
    getInitialData({
      _id,
      setHideFromApp,
      setRecAvailable,
    })
  }, [])

  useEffect(() => {
    // Check if the survey is published or unpublished
    if (survey?.id) {
      setPublished(survey.publishedAt ? true : false)
    }

    // Check if the survey is closed or open
    if (survey?.closesAt && new Date(survey.closesAt) < new Date()) {
      setClosed(true)
    } else {
      setClosed(false)
    }
  }, [survey])

  let publishSurvey = () => {
    if (!published && survey?.id) {
      api.admin.publish(survey.id).then(() => {
        setPublished(true)
      })
    }
  }

  let unpublishSurvey = () => {
    if (published && survey?.id) {
      api.admin.unpublish(survey.id).then(() => {
        setPublished(false)
      })
    }
  }

  let closeSurvey = () => {
    if (!closed && survey?.id) {
      api.admin.close(survey.id).then(() => {
        setClosed(true)
      })
    }
  }

  let uncloseSurvey = () => {
    if (closed && survey?.id) {
      api.admin.unclose(survey.id).then(() => {
        setClosed(false)
      })
    }
  }

  const mixpanelLink = `https://mixpanel.com/report/1617717/view/123159/insights#~(columnWidths~(bar~())~displayOptions~(chartType~'line~plotStyle~'standard~analysis~'linear~value~'absolute)~sorting~(bar~(sortBy~'column~colSortAttrs~(~(sortBy~'value~sortOrder~'desc)~(sortBy~'value~sortOrder~'desc)))~line~(sortBy~'value~sortOrder~'desc)~table~(sortBy~'column~colSortAttrs~(~(sortBy~'label~sortOrder~'asc)~(sortBy~'label~sortOrder~'asc))))~timeComparison~null~title~'~querySamplingEnabled~false~sections~(show~(~(dataset~'!mixpanel~value~(name~'API*3a*20Survey*20Submit~resourceType~'events)~resourceType~'events~profileType~null~search~'~dataGroupId~null~filter~(~(dataset~'!mixpanel~value~'survey~resourceType~'events~profileType~null~search~'~dataGroupId~null~filterType~'string~defaultType~'string~filterOperator~'equals~filterValue~(~'${survey?.id})~propertyObjectKey~null~determiner~'all))~math~'total~perUserAggregation~null~property~null~filterDeterminer~'all~labelSuffix~'survey*20equals*20${survey?.id}))~group~(~(dataset~'!mixpanel~value~'schoolName~resourceType~'people~profileType~null~search~'~dataGroupId~null~propertyType~'string~typeCast~null~unit~null))~time~(~(dateRangeType~'in*20the*20last~unit~'day~window~(unit~'day~value~30))))~legend~())`
  const mixpanelDropoffLink = `https://mixpanel.com/report/1617717/view/123159/funnels#view/chartType:chart,conversionWindow:(unit:day,value:30),countType:unique,dateRange:(type:'in%20the%20last',window:(unit:day,value:30)),eventFilters:!((filterOperator:equals,filterValue:!('${survey?.id}'),propertyDefaultType:string,propertyName:survey,propertyObjectKey:!n,propertyType:string,resourceType:event)),events:!((aggregationOperator:total,aggregationOperatorPerUser:!n,entryIndexLabelToggle:!f,event:(custom:!f,label:'External%20Survey%20Started',value:'External%20Survey%20Started'),filters:!(),filtersOperator:and,property:!n,type:event),(aggregationOperator:total,aggregationOperatorPerUser:!n,entryIndexLabelToggle:!f,event:(custom:!f,label:'External%20Survey%20Finished',value:'External%20Survey%20Finished'),filters:!(),filtersOperator:and,property:!n,type:event)),frequencyBuckets:(bucketSize:1,max:20,min:1,offset:0),frequencyType:ConversionRate,frequencyValueRepresentation:relative,frequencyVizType:Bar,funnelAttributionSelection:(action_mode:last_touch),funnelOrder:loose,groupBy:!((event:(propertyDefaultType:string,propertyName:schoolName,propertyType:string,resourceType:event))),selectMode:positional,selected:!((color:%237856FF,key:'$overall'),(color:%23FF7557,key:undefined)),selectedTableColumns:(conv-first-step:!f,conv-prev-step:!t,count:!t,stat-sig:!t,time-first-step:!f,time-prev-step:!t),sortField:statSig,sortOrder:1,timeBuckets:(bucketSize:172800,isDrilledDown:!f,max:2592000,min:0,offset:0),trendType:conversion,trendUnit:day,ttcValueRepresentation:relative,ttcVizType:Bar,v:2`

  return (
    <>
      <div className="actions-page">
        <button onClick={() => publishSurvey()} disabled={published || closed}>
          Publish Survey
        </button>
        <button onClick={() => closeSurvey()} disabled={closed}>
          Close Survey
        </button>
        <button onClick={() => unpublishSurvey()} disabled={!published || closed}>
          Unpublish Survey
        </button>
        <button>PDF Prep</button>
        <button disabled={!closed} onClick={() => uncloseSurvey()}>
          Unclose Survey
        </button>
        <button onClick={() => window.open(mixpanelLink, '_blank')}>Mixpanel Completes</button>
        <button onClick={() => window.open(mixpanelDropoffLink, '_blank')}>Mixpanel Dropoff</button>
      </div>
      <hr />
      <div>
        <Checkbox
          toggle
          label="Hide from app?"
          checked={hideFromApp}
          onChange={() => {
            setSurveyProperty({
              _id: _id,
              property: 'minVersion',
              value: !hideFromApp ? 2.0 : 1.42,
              store,
            })
            setHideFromApp(c => !c)
          }}
        />
        <Checkbox
          style={{ marginLeft: '2.5rem' }}
          toggle
          label="Recommended Mesh?"
          checked={recAvailable}
          onChange={() => {
            setSurveyProperty({
              _id: _id,
              property: 'recAvailable',
              value: !recAvailable,
              store,
            })
            setRecAvailable(c => !c)
          }}
        />
      </div>
    </>
  )
}

const getInitialData = async ({ _id, setHideFromApp, setRecAvailable }) => {
  try {
    const appV = await fetchSurveyProperty({ _id, property: 'minVersion' })
    setHideFromApp(appV < 2.0 ? false : true)

    const recAvail = await fetchSurveyProperty({ _id, property: 'recAvailable' })
    setRecAvailable(recAvail)
  } catch (err) {
    console.log('Error getting minVersion')
    console.log(err)
  }
}
