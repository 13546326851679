import React from 'react'
import PropTypes from 'prop-types'
import Hammer from 'react-hammerjs'
import ClickOutside from 'react-click-outside'
import VisibilitySensor from 'react-visibility-sensor'
import { PulseLoader as Loader } from 'halogenium'
import api from '../../../api'

// redux imports
import { connect } from 'react-redux'
import { signout } from '../../actions/user-actions'

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showErrorForm: false,
      errorEmail: '',
      errorIssue: '',
    }

    const boundMethods =
      'onLinkClick onHelpIconClick hideErrorForm onErrorEmailChange onErrorIssueChange submitErrorReport'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  onLinkClick(ev) {
    window.location = ev.target.id === 'surveys' ? '/app' : '/rewards'
  }

  onHelpIconClick() {
    this.setState({
      showErrorForm: !this.state.showErrorForm,
      errorFormSubmitted: false,
    })
  }

  hideErrorForm() {
    this.setState({
      showErrorForm: false,
      errorFormSubmitted: false,
    })
  }

  onErrorEmailChange(ev) {
    this.setState({
      errorEmail: ev.target.value,
    })
  }

  onErrorIssueChange(ev) {
    this.setState({
      errorIssue: ev.target.value,
    })
  }

  submitErrorReport() {
    api.reportBug
      .report({ email: this.state.errorEmail, issue: this.state.errorIssue })
      .then(() => {
        this.setState({
          errorFormSubmitted: true,
        })
      })
  }

  render() {
    let errorFormContent
    if (!this.state.errorFormSubmitted) {
      errorFormContent = (
        <div className={`error-report-form ${this.state.showErrorForm ? 'visible' : ''}`}>
          <span className="error-span">
            We appreciate you reporting any issues or feedback. Please provide your email and your
            message.
          </span>
          <input placeholder="Email" onChange={this.onErrorEmailChange} />
          <textarea placeholder="Issue/Feedback" onChange={this.onErrorIssueChange} />
          <div className="error-report-button" onClick={this.submitErrorReport}>
            Submit
          </div>
        </div>
      )
    } else {
      errorFormContent = (
        <div className={`error-report-form ${this.state.showErrorForm ? 'visible' : ''}`}>
          <span className="error-thank-you">Thank You!</span>
        </div>
      )
    }

    return (
      <div className="admin-navbar-container">
        <div className="navbar-content-container">
          <div className="icon-container">
            <img src="/img/logo.png" />
            <div>PULSE</div>
          </div>
          <div className="vertical-line" />
          <div className="links-container">
            <Hammer onTap={this.onLinkClick}>
              <div
                id="surveys"
                className={`link ${this.props.currentView === 'rewards' ? '' : 'active'}`}
              >
                Surveys
              </div>
            </Hammer>
            <Hammer onTap={this.onLinkClick}>
              <div
                id="rewards"
                className={`link ${this.props.currentView === 'rewards' ? 'active' : ''}`}
              >
                Rewards
              </div>
            </Hammer>
          </div>
          <div className="points-signout-container">
            <div className="points-box">
              <div className="value">{this.props.user.numPoints}</div>
              <div className="text">POINTS</div>
            </div>
            <div className="images-container">
              {/* */}
              <ClickOutside onClickOutside={this.hideErrorForm}>
                <div className="error-report-container">
                  <Hammer onTap={this.onHelpIconClick}>
                    <div className="report-icon">
                      <img
                        className="inactive-icon"
                        src="/img/caution-white.svg"
                        title="Report an Issue"
                      />
                      <img
                        className="active-icon"
                        src="/img/caution-green.svg"
                        title="Report an Issue"
                      />
                    </div>
                  </Hammer>
                  {errorFormContent}
                </div>
              </ClickOutside>
              <Hammer onTap={this.props.signout}>
                <div className="logout-icon">
                  <img className="inactive-icon" src="/img/signout.svg" title="Sign Out" />
                  <img className="active-icon" src="/img/signout-green.svg" title="Sign Out" />
                </div>
              </Hammer>
            </div>
          </div>
        </div>
        <div className="navbar-break-line" />
      </div>
    )
  }
}

AdminNavbar.propTypes = {
  user: PropTypes.object,
  signout: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.user.user,
})

export default connect(mapStateToProps, {
  signout,
})(AdminNavbar)
