import React from 'react'

import QuestionOption from './question-option'
import WriteInQuestionOption from './write-in-question-option'
import defaultQuestions from '../../../../../../../actions/lib/default-questions.json'

import { connect } from 'react-redux'
import {
  insertNewOptionForQuestionAtIndex,
  deleteOptionForQuestionAtIndex,
  editOptionForQuestionAtIndex,
  editOptionWeight,
  setOptionSkipTo,
  setOptionProperty,
} from '../../../../../../../actions/create-view-survey-actions/edit-survey-question-options-actions'
import {
  setQuizConfiguration,
  toggleFixedOption,
} from '../../../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'

class OptionsBox extends React.Component {
  constructor() {
    super()

    const boundMethods =
      'onOptionAdd onOptionDelete onOptionChange checkDups markOptionAsCorrect onOptionsPaste onOptionWeightChange setOptionSkipTo setOptionProperty'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  onOptionAdd(index) {
    this.props.insertNewOptionForQuestionAtIndex({
      questionIndex: this.props.currentQuestionIndex,
      optionIndex: index,
    })
  }

  onOptionDelete(index) {
    this.props.deleteOptionForQuestionAtIndex({
      questionIndex: this.props.currentQuestionIndex,
      optionIndex: index,
      currentOptions: this.props.questions[this.props.currentQuestionIndex].options,
    })
  }

  onOptionChange(index, ev, keepValueStatic) {
    const quizSettings = this.props.questions[this.props.currentQuestionIndex].quizSettings || {}
    const option = this.props.questions[this.props.currentQuestionIndex].options[index]
    if (option && quizSettings && quizSettings.correctAnswer === option.value) {
      this.markOptionAsCorrect(ev.target.value)
    }

    this.props.editOptionForQuestionAtIndex({
      questionIndex: this.props.currentQuestionIndex,
      optionIndex: index,
      newValue: ev.target.value,
      currentOptions: this.props.questions[this.props.currentQuestionIndex].options,
      keepValueStatic,
    })
  }

  onOptionsPaste(index, lines) {
    this.props.editOptionForQuestionAtIndex({
      questionIndex: this.props.currentQuestionIndex,
      optionIndex: index,
      newValue: lines[0],
      currentOptions: this.props.questions[this.props.currentQuestionIndex].options,
    })

    lines.slice(1).forEach((line, i) => {
      this.props.insertNewOptionForQuestionAtIndex({
        questionIndex: this.props.currentQuestionIndex,
        optionIndex: index + i,
        initialValue: line,
      })
    })
  }

  onOptionWeightChange(index, ev) {
    this.props.editOptionWeight({
      questionIndex: this.props.currentQuestionIndex,
      optionIndex: index,
      newWeight: ev.target.value,
    })
  }

  setOptionSkipTo(index, qId) {
    this.props.setOptionSkipTo({
      questionIndex: this.props.currentQuestionIndex,
      optionIndex: index,
      skipToQId: qId,
    })
  }

  setOptionProperty(index, property, value) {
    this.props.setOptionProperty({
      questionIndex: this.props.currentQuestionIndex,
      optionIndex: index,
      property,
      value,
    })
  }

  markOptionAsCorrect(optionValue) {
    const existingQuizSettings = this.props.questions[this.props.currentQuestionIndex].quizSettings
    const quizSettings = Object.assign({}, existingQuizSettings || {}, {
      correctAnswer: optionValue,
    })
    this.props.setQuizConfiguration(this.props.currentQuestionIndex, quizSettings)
  }

  checkDups(questionList) {
    let checkDups = []
    let duplicate = false
    for (let i = 0; i < questionList.options.length; i++) {
      if (!checkDups.includes(questionList.options[i].text)) {
        checkDups.push(questionList.options[i].text)
      } else if (questionList.options[i].text != '') {
        duplicate = true
      }
    }
    return duplicate
  }

  render() {
    const props = this.props

    let currentQuestion = props.questions[props.currentQuestionIndex]

    let allowDelete = true,
      allowEdit = true,
      allowAdd = true
    if (currentQuestion.yesNo) {
      allowDelete = false
      allowEdit = false
      allowAdd = false
    }

    const optionsToCheck = currentQuestion.yesNo
      ? defaultQuestions.mc.yesNoOptions
      : currentQuestion.options

    const options = optionsToCheck.map((opt, index) => {
      if (optionsToCheck.length == 1 && opt.text == '') {
        allowDelete = false
      }

      if (index == optionsToCheck.length - 1 && this.checkDups(currentQuestion)) {
        allowEdit = false
      }

      let duplicateError

      if (allowEdit == false && currentQuestion.type === 'mc' && !currentQuestion.yesNo) {
        duplicateError = (
          <div>
            <p className="option-warning">Cannot have duplicate responses</p>
          </div>
        )
        return <div>{duplicateError}</div>
      } else {
        return (
          <div>
            <QuestionOption
              key={index}
              index={index}
              opt={opt}
              allowEdit={allowEdit}
              allowAdd={allowAdd}
              allowDelete={allowDelete}
              viewOnly={props.viewOnly}
              onOptionChange={this.onOptionChange}
              onOptionWeightChange={this.onOptionWeightChange}
              setOptionSkipTo={this.setOptionSkipTo}
              setOptionProperty={this.setOptionProperty}
              onOptionAdd={this.onOptionAdd}
              onOptionDelete={this.onOptionDelete}
              markOptionAsCorrect={this.markOptionAsCorrect}
              onOptionsPaste={this.onOptionsPaste}
              quizSettings={this.props.questions[this.props.currentQuestionIndex].quizSettings}
              optionOrderSettings={
                this.props.questions[this.props.currentQuestionIndex].optionOrderSettings || {}
              }
              spectrumQ={this.props.questions[this.props.currentQuestionIndex].spectrumQ}
              logicQuestion={this.props.questions[this.props.currentQuestionIndex].logicQuestion}
              questions={this.props.questions.slice(this.props.currentQuestionIndex + 1)}
              currentQuestionIndex={this.props.currentQuestionIndex}
              isTypology={this.props.isTypology}
              toggleFixedOption={this.props.toggleFixedOption}
              isMultiSelect={currentQuestion.maxSelect > 1}
              isWriteIn={currentQuestion.input && opt.value && opt.value.toLowerCase() === 'other'}
            />
          </div>
        )
      }
    })

    // if (currentQuestion.input) {
    //   options.push(<WriteInQuestionOption key={'write-in'} />)
    // }

    return (
      <div className="options-cont">
        {options}
        <div className="common-options-row" />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
  currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
  isTypology: (state.activeSurvey.typologyConfig || {}).isTypology,
})

export default connect(mapStateToProps, {
  insertNewOptionForQuestionAtIndex,
  deleteOptionForQuestionAtIndex,
  editOptionForQuestionAtIndex,
  setQuizConfiguration,
  editOptionWeight,
  setOptionSkipTo,
  setOptionProperty,
  toggleFixedOption,
})(OptionsBox)
