import request from './request'
import storage from '../lib/storage'
import prefix from './prefix'
import signin from './signin'

exports.answer = function ({
  answers,
  surveyId,
  questionOrder = [],
  timePassed,
  externalRef,
  snowballSample,
  referralCTA,
}) {
  const submitUrl = signin.isSignedIn()
    ? `/api/survey/${surveyId}/answer`
    : `/api/survey/${surveyId}/userless-answer`

  return new Promise((resolve, reject) => {
    request
      .post(prefix + submitUrl)
      .send({
        answers,
        questionOrder,
        timeTaken: timePassed,
        snowballSample,
        externalRef,
        referralCTA,
      })
      .end((err, res) => {
        if (err) {
          if (res && res.body && res.body.message) {
            return reject(res.body)
          } else {
            return reject(err)
          }
        }

        storage.set('user', res.body.user)
        return resolve(res.body)
      })
  })
}

exports.userlessAnswer = function ({
  answers,
  surveyId,
  questionOrder = [],
  timePassed,
  userConfirmedSchool,
  rid,
  externalProvider,
}) {
  const submitUrl = `/api/survey/${surveyId}/userless-answer`
  return new Promise((resolve, reject) => {
    request
      .post(prefix + submitUrl)
      .send({
        answers,
        questionOrder,
        timeTaken: timePassed,
        userConfirmedSchool,
        rid,
        externalProvider,
      })
      .end((err, res) => {
        if (err) {
          if (res.body && res.body.message) {
            return reject(res.body)
          } else {
            return reject(err)
          }
        }

        return resolve(res.body)
      })
  })
}

exports.answerCategoriesOrdered = function (questionId, useSchool) {
  const query = { useSchool }
  return new Promise((resolve, reject) => {
    request
      .get(prefix + `/api/categories/${questionId}`)
      .query(query)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.categoryForQuestion = function ({
  questionId,
  category,
  shouldWeight,
  useSchool,
  grouped,
  pageNumber,
  sortby,
  attachSentiment,
  variableFilters,
  filterAndOr,
  skipCache,
  threshold,
}) {
  const query = {
    grouped: grouped,
    useSchool: useSchool,
    shouldWeight: shouldWeight,
    attachSentiment,
    variableFilters: JSON.stringify({
      filters: variableFilters,
      andOr: filterAndOr,
    }),
    skipCache,
    threshold,
  }

  if (Number.isInteger(pageNumber)) {
    query.pageNumber = pageNumber
  }

  if (sortby) {
    query.sortby = sortby
  }

  return new Promise((resolve, reject) => {
    request
      .get(prefix + `/api/category/answer/${category}/${questionId}`)
      .query(query)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

/*
 * answer.oneForQuestion
 * Get answer <answerId> for question.
 */
exports.oneForQuestion = function (questionId, answerId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/answer/${answerId}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.upvote = function (answerId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/answer/${answerId}/upvote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.downvote = function (answerId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/answer/${answerId}/downvote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.novote = function (answerId) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/answer/${answerId}/novote`)
      .send({})
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.reweight = function (surveyId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/answer/reweight/${surveyId}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.checkJob = function (queue, id) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/redis/isJobDone/${queue}/${id}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}
