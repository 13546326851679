import React from 'react'
import { Grid, Card, Divider } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { setFilterConfig } from '../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'
import questionTypesEnum from '../../../../../../lib/questionTypesEnum'
import FilterConfig from './FilterConfig'

class QuestionFiltering extends React.Component {
  constructor() {
    super()
    this.state = {
      activeQId: null,
    }

    this.setActiveQ = this.setActiveQ.bind(this)
  }
  setActiveQ(qId) {
    this.setState({ activeQId: qId })
  }
  render() {
    const { questions } = this.props

    const { activeQId } = this.state
    const activeQ = questions.find(q => q._id === activeQId)
    const activeQIndex = questions.findIndex(q => q._id === activeQId)

    return (
      <Grid className="questionFiltering">
        <Grid.Row>
          <Grid.Column width={4} className="questionFilteringQuestionColumn">
            <h2>Questions</h2>
            <Divider horizontal />
            {questions.map((q, i) => (
              <Card.Group itemsPerRow={1} onClick={() => this.setActiveQ(q._id)}>
                <Card
                  style={{
                    backgroundColor:
                      i === activeQIndex
                        ? '#18af9050'
                        : q.type !== questionTypesEnum.MULTIPLE_CHOICE
                        ? 'grey'
                        : 'white',
                  }}
                >
                  <Card.Content>
                    <Card.Header>Q{i + 1}</Card.Header>
                    <Card.Description>{q.description}</Card.Description>
                  </Card.Content>
                </Card>
              </Card.Group>
            ))}
          </Grid.Column>
          <Grid.Column width={12}>
            {activeQ && (
              <FilterConfig
                question={activeQ}
                index={activeQIndex}
                questionsAfter={questions.slice(activeQIndex + 1)}
                setFilterConfig={this.props.setFilterConfig}
              />
            )}
            {!activeQ && <p>Select a question to configure conditional displaying of questions</p>}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    )
  }
}

// connect redux, pull in questions, render those in the left column
// pull in questionOrderSettings and render those in the right column

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
  randomizedGroups: (state.activeSurvey.questionOrderSettings || {}).randomizedGroups || [],
})

export default connect(mapStateToProps, { setFilterConfig })(QuestionFiltering)
