import React, { useState, useEffect, useRef } from 'react'
import { Grid, Input, TextArea, Form } from 'semantic-ui-react'
import ReactFilepicker from 'react-filepicker'
import _ from 'lodash'

// Redux
import { connect } from 'react-redux'
import {
  setActiveSurvey,
  setNewSurveyActive,
} from '../../actions/create-view-survey-actions/set-survey-actions'
import {
  initialSurveySave,
  saveSurvey,
} from '../../actions/create-view-survey-actions/save-survey-actions'
import {
  setImg,
  setTitle,
  setAuthor,
  setDescription,
} from '../../actions/create-view-survey-actions/edit-survey-actions'
import { fetchDemographicsOptions } from '../../actions/create-view-survey-actions/temp-demographics-actions'

// Actions
import Actions from './components/actions/actions'

// Targeting
import TargetingContent from './components/targeting-view/content/content'
import TargetingSidebar from './components/targeting-view/sidebar/sidebar'

// Counting and Demographic Question
import CountingQuestionSelect from '../programming-view/views/initial-create-view/sidebar/CountingQuestionSelect'
import DemographicQuestionsSelect from '../programming-view/views/initial-create-view/sidebar/DemographicQuestionsSelect'

// Referral Config
import ReferralView from './components/referral-view/referralView'

// Response Count
import CountView from './components/count-view/CountView'

// Monitor
import MonitoringView from './components/monitoring-view/MonitoringView'

// Quotas View
import QuotasView from './components/quotas-view/QuotasView'

// Ads
import AdsView from './components/ads-view/AdsView'

// Email View
import EmailView from './components/email-view/EmailView'

// Sms View
import SmsView from './components/sms-view/SmsView'

// Weighting View
import WeightingView from './components/weighting-view/WeightingView'

// Ambassador View
import AmbassadorView from './components/ambassador-view/AmbassadorView'

// Ambassador Outreach View
import AmbassadorOutreachView from './components/ambassador-outreach-view/AmbassadorOutreachView'

// Recommend View
import RecommendView from './components/recommend-view/RecommendView'

const FieldingView = ({
  match,
  user,
  survey,
  setActiveSurvey,
  setNewSurveyActive,
  initialSurveySave,
  saveSurvey,
  setTitle,
  setAuthor,
  setDescription,
  setImg,
  fetchDemographicsOptions,
}) => {
  const [activePage, setActivePage] = useState('actions')

  let currentSurv = useRef()
  useEffect(() => {
    currentSurv.current = survey
    fetchDemographicsOptions()
  }, [survey])

  useEffect(() => {
    console.log('call')
    console.log(survey)
    console.log(match)
    if (!survey.id) {
      if (match?.params?.surveyId) {
        setActiveSurvey(match.params.surveyId, user)
      }
    }
  }, [])

  return (
    <div className="survey-manager-fielding-container">
      <div className="smf-sidebar">
        <button
          onClick={() => setActivePage('actions')}
          className={`${activePage === 'actions' ? 'active' : ''}`}
        >
          Actions
        </button>
        <button
          onClick={() => setActivePage('responseCount')}
          className={`${activePage === 'responseCount' ? 'active' : ''}`}
        >
          Response Count
        </button>
        <button
          onClick={() => setActivePage('targeting')}
          className={`${activePage === 'targeting' ? 'active' : ''}`}
        >
          Targeting
        </button>
        <button
          onClick={() => setActivePage('demographics')}
          className={`${activePage === 'demographics' ? 'active' : ''}`}
        >
          Demographics
        </button>
        <button
          onClick={() => setActivePage('email')}
          className={`${activePage === 'email' ? 'active' : ''}`}
        >
          Email Tracking
        </button>
        <button
          onClick={() => setActivePage('sms')}
          className={`${activePage === 'sms' ? 'active' : ''}`}
        >
          Sms Tracking
        </button>
        {/* <button
          onClick={() => setActivePage('countingQuestion')}
          className={`${activePage === 'countingQuestion' ? 'active' : ''}`}>
          Counting Question
        </button> */}
        <button
          onClick={() => setActivePage('incentives')}
          className={`${activePage === 'incentives' ? 'active' : ''}`}
        >
          Incentives
        </button>
        <button
          onClick={() => setActivePage('quotas')}
          className={`${activePage === 'quotas' ? 'active' : ''}`}
        >
          Quotas
        </button>
        <button
          onClick={() => setActivePage('monitoring')}
          className={`${activePage === 'monitoring' ? 'active' : ''}`}
        >
          Monitoring
        </button>
        {/*
          Disabling Ads since it is most likely deprecated due to
          significant changes in the facebook ads api post-apple 
          privacy changes.
        */}
        {/* <button
          onClick={() => setActivePage('ads')}
          className={`${activePage === 'ads' ? 'active' : ''}`}
        >
          Ads
        </button> */}
        <button
          onClick={() => setActivePage('weighting')}
          className={`${activePage === 'weighting' ? 'active' : ''}`}
        >
          Weighting
        </button>
        {/* <button
          onClick={() => setActivePage('ambassador')}
          className={`${activePage === 'ambassador' ? 'active' : ''}`}
        >
          Ambassador
        </button> */}
        <button
          onClick={() => setActivePage('ambassador-outreach')}
          className={`${activePage === 'ambassador-outreach' ? 'active' : ''}`}
        >
          Ambassador Outreach
        </button>
        <button
          onClick={() => setActivePage('recommend')}
          className={`${activePage === 'recommend' ? 'active' : ''}`}
        >
          Recommended
        </button>
      </div>
      <div className="smf-content">
        <div className="smf-content-header">
          <h3>{_.startCase(activePage)}</h3>
        </div>
        <div className="smf-content-body">
          {activePage === 'actions' && <Actions survey={survey} _id={match?.params?.surveyId} />}

          {activePage === 'responseCount' && <CountView _id={match?.params?.surveyId} />}

          {activePage === 'targeting' && (
            <>
              <TargetingSidebar />
              <TargetingContent surveyID={match?.params?.surveyId} />
            </>
          )}

          {activePage === 'demographics' && <DemographicQuestionsSelect />}

          {activePage === 'email' && <EmailView _id={match?.params?.surveyId} />}

          {activePage === 'sms' && <SmsView _id={match?.params?.surveyId} />}

          {/* {activePage === 'countingQuestion' && <CountingQuestionSelect />} */}

          {activePage === 'incentives' && <ReferralView _id={match?.params?.surveyId} />}

          {activePage === 'monitoring' && <MonitoringView _id={match?.params?.surveyId} />}

          {activePage === 'quotas' && <QuotasView _id={match?.params?.surveyId} />}

          {activePage === 'ads' && <AdsView _id={match?.params?.surveyId} />}

          {activePage === 'weighting' && <WeightingView _id={match?.params?.surveyId} />}

          {activePage === 'ambassador' && <AmbassadorView _id={match?.params?.surveyId} />}

          {activePage === 'ambassador-outreach' && (
            <AmbassadorOutreachView _id={match?.params?.surveyId} />
          )}

          {activePage === 'recommend' && <RecommendView _id={match?.params?.surveyId} />}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
})

export default connect(mapStateToProps, {
  setActiveSurvey,
  setNewSurveyActive,
  initialSurveySave,
  saveSurvey,
  fetchDemographicsOptions,
})(FieldingView)
