/**
 * Actions in this file change higher level info about the question editing view
 * for example current active question and whether we are confirming the deletion
 * of a question
 */

export const ActionTypes = {
  SET_CURRENT_QUESTION_INDEX: 'SET_CURRENT_QUESTION_INDEX',
  SET_JUST_SAVED_STATUS: 'SET_JUST_SAVED_STATUS',
  SET_DELETE_CONFIRMING_INDEX: 'SET_DELETE_CONFIRMING_INDEX',
  SET_CURRENT_QUESTION_PERMANENT_STATUS: 'SET_CURRENT_QUESTION_PERMANENT_STATUS',
}

/**
 * Combiner function for changing the current question. Changes current
 * question index, and also makes the current question permanent since we assume
 * if there was a new untouched question (temp) and they switch to another question
 * they want to keep the new one
 */
export function changeQuestion(index) {
  return dispatch => {
    dispatch(setCurrentQuestionIndex(index))
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function setCurrentQuestionIndex(index) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_CURRENT_QUESTION_INDEX,
      payload: index,
    })
  }
}

export function setJustSavedForPeriod() {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_JUST_SAVED_STATUS,
      payload: true,
    })

    setTimeout(() => {
      dispatch({
        type: ActionTypes.SET_JUST_SAVED_STATUS,
        payload: false,
      })
    }, 2436)
  }
}

export function setDeleteConfirmingIndex(index) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_DELETE_CONFIRMING_INDEX,
      payload: index,
    })
  }
}

export function setCurrentQuestionPermanentStatus(isPermanent) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_CURRENT_QUESTION_PERMANENT_STATUS,
      payload: isPermanent,
    })
  }
}
