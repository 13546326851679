import React from 'react'
import Modal from 'react-modal'
import templates from './templates'

const modalStyle = {
  content: { top: '20%', bottom: '20%', left: '20%', right: '20%' },
  overlay: { backgroundColor: 'rgba(0,0,0,.4)' },
}

export default class TemplateModal extends React.Component {
  constructor() {
    super()
    this.state = {
      activeTemplate: null,
    }
  }

  componentDidUpdate() {
    if (!this.props.isOpen && this.state.activeTemplate) {
      this.setState({ activeTemplate: null })
    }
  }

  render() {
    const { isOpen, toggle } = this.props
    const { activeTemplate } = this.state
    const ActiveTemplateComponent = activeTemplate
      ? (templates.find(template => template.id === activeTemplate) || {}).component
      : null
    return (
      <Modal
        isOpen={isOpen}
        style={modalStyle}
        contentLabel="Submit Confirm Modal"
        onRequestClose={toggle}
      >
        <div className="review-exit-container">
          {!ActiveTemplateComponent && (
            <div className="templates">
              {templates.map(template => (
                <div
                  className="template"
                  onClick={() => this.setState({ activeTemplate: template.id })}
                >
                  {template.name}
                </div>
              ))}
            </div>
          )}
          {ActiveTemplateComponent && <ActiveTemplateComponent toggle={toggle} />}
        </div>
      </Modal>
    )
  }
}
