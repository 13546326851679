import React from 'react'
import { IconContext } from 'react-icons'
import { IoIosClose } from 'react-icons/io'
import { Checkbox } from 'semantic-ui-react'

export default class CheckboxContainer extends React.Component {
  constructor() {
    super()
    this.onRemove = this.onRemove.bind(this)
    this.handleGroupClick = this.handleGroupClick.bind(this)
  }
  onRemove() {
    this.props.removeDemographic(this.props.demographic)
  }

  handleGroupClick(groupTitle) {
    const { demographic, selected = [], toggleDemOption, groups = [] } = this.props
    const relevantGroup = groups.find(group => group.title === groupTitle)
    const alreadySelected = selected.includes(groupTitle)

    relevantGroup.contents.forEach(opt =>
      toggleDemOption({ category: demographic, option: opt, explicitOnOrOff: !alreadySelected })
    )
  }

  render() {
    const { demographic, options, selected = [], toggleDemOption, grouped } = this.props
    return (
      <div className="individualDemContainer">
        <IconContext.Provider value={{ color: 'white', size: '3em' }}>
          <IoIosClose title="Close" onClick={this.onRemove} className="closeButton" />
        </IconContext.Provider>
        <div className="topRow">
          <h2>{demographic}</h2>
        </div>
        <div className="checkboxOptions">
          {options.map(opt => (
            <Checkbox
              label={opt}
              checked={selected.includes(opt)}
              onChange={
                grouped
                  ? () => this.handleGroupClick(opt)
                  : () => toggleDemOption({ category: demographic, option: opt })
              }
            />
          ))}
        </div>
      </div>
    )
  }
}
