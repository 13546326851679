import prefix from './prefix'
import request from './request'
import storage from '../lib/storage'

exports.me = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/user/me').end((err, res) => {
      if (err) return reject(err)

      storage.set('user', res.body.user)
      return resolve(res.body.user)
    })
  })
}

exports.setDemographics = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/user/demographics')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        storage.set('user', res.body.user)
        return resolve(res.body.user)
      })
  })
}

exports.updateDemographicCategories = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/demographics/update')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body.user)
      })
  })
}

exports.markTutorialDone = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/user/tutorial-done')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        storage.set('user', res.body.user)
        return resolve(res.body.user)
      })
  })
}

exports.markSwipeLessonSeen = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/user/swipe-seen')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        storage.set('user', res.body.user)
        return resolve(res.body.user)
      })
  })
}

exports.fetchNotifications = function (page) {
  page = page || 1
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/user/notifications?page=' + page).end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

exports.readAllNotifications = function () {
  return new Promise((resolve, reject) => {
    request.post(prefix + '/api/user/notifications/readAll').end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

exports.markNotificationClicked = function (notification) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/user/notifications/${notification}`).end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

exports.updateUsername = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/username')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.updatePassword = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/password')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.updateSecurityQuestion = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/securityQuestion')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.updateRecoveryEmail = function (data) {
  return new Promise((resolve, reject) => {
    request
      .put(prefix + '/api/user/recoveryEmail')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)
        return resolve(res.body)
      })
  })
}

exports.securityQuestionOptions = function () {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/user/securityOpts').end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}
