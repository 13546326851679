import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

const hexVals = {
  headerDark: '#34495e',
  darkerHeaderColor: '#2c3e50',
  questionColor: '#16a085',
  questionHeaderColor: '#29bb9c',
  surveyDescriptionColor: '#FEFEFE',
  backgroundGrey: '#E3E1E4',
  darkerGrey: '#c2bbc3',
  filterGrey: '#ECF0F1',
  unselectedColor: '#9b9b9b',
  cardBorderColor: '#e9e9e9',
  adminInputBoxGreen: '#50E3C2',
}

export default class InputBox extends React.Component {
  constructor() {
    super()
  }

  render() {
    const {
      selected,
      name,
      key,
      color,
      backgroundColor,
      multiSelect,
      size,
      borderWidth,
      showCheck,
    } = this.props
    let hex = hexVals[color]
    if (!hex) {
      hex = color
    }

    const bw = !_.isUndefined(borderWidth) && !_.isNull(borderWidth) ? borderWidth : 2

    let styles
    if (selected) {
      styles = {
        backgroundColor: `${hex}`,
        boxShadow: `0 0 0 ${bw}px ${hex} inset, 0 0 0 ${2 * bw}px ${backgroundColor} inset, 0 0 0 ${
          3 * bw
        }px ${hex} inset`,
      }
    } else {
      styles = {
        backgroundColor: `${backgroundColor}`,
        boxShadow: `0 0 0 ${bw}px ${hex} inset`,
      }
    }

    let outerStyles = {}
    if (!_.isUndefined(size) && !_.isNull(size)) {
      outerStyles.width = size
      outerStyles.minWidth = size
      outerStyles.height = size
      outerStyles.minHeight = size
    }

    return (
      <div
        key={key}
        style={outerStyles}
        className={`option-box-container ${selected ? 'asdfasfas' : ''}`}
      >
        {selected && showCheck ? (
          <img src="/img/checkbox-checked.svg" className="checkbox-checked" />
        ) : (
          <div style={styles} className={`option-box ${multiSelect ? 'multi' : ''}`}></div>
        )}
      </div>
    )
  }
}

InputBox.propTypes = {
  selected: PropTypes.bool,
  name: PropTypes.string,
  color: PropTypes.string,
  multiSelect: PropTypes.bool,
}
