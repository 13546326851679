// Functionality Imports
import React, { useState, useEffect } from 'react'
import { fetchSurveyProperty, setSurveyProperty } from '../../../../../api/admin'
import { store } from '../../../../store'

// UI Imports
import { Button, Dropdown, Grid, Card } from 'semantic-ui-react'
import _ from 'lodash'

export default ({ _id }) => {
  // Hold properties
  const [config, setConfig] = useState({})

  // Error
  const [error, setError] = useState(null)

  // Listen for changes
  const [changesMade, setChangesMade] = useState(false)

  // Debug
  const [toggleDebug, setToggleDebug] = useState(false)

  // Get initial data
  useEffect(() => {
    getData({ _id, setConfig, setError })
  }, [])

  const setData = async () => {
    try {
      await setSurveyProperty({
        _id: _id,
        property: 'weightingScheme',
        value: config.weightingScheme,
        store: store,
      })

      await setSurveyProperty({
        _id: _id,
        property: 'customWeightingConfig.customPopulation',
        value: config.customPopulation,
        store: store,
      })

      setChangesMade(false)
    } catch (err) {
      // Handle errors
      setError(err.message)
      console.log(err)
    }
  }

  return (
    <div className="weighting-view">
      <Button
        style={{ margin: '1rem 0rem' }}
        color={changesMade ? 'green' : undefined}
        onClick={() => setData()}
        disabled={!changesMade}
      >
        Update Weighting Config
      </Button>

      {error && <div>{error}</div>}

      <Grid fluid>
        <Grid.Row fluid>
          <Grid.Column fluid>
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <h4>Weighting Scheme</h4>
                </Card.Header>
                <Card.Description>
                  <p>
                    The available weighting methods are documented on Notion{' '}
                    <a
                      href="https://www.notion.so/cpulse/How-do-we-determine-our-targets-ef556483377b4513acfd6dc6126d72ac"
                      target="_blank"
                    >
                      Here
                    </a>
                  </p>
                  <Dropdown
                    options={[
                      {
                        text: 'Four Year',
                        value: 'fourYear',
                      },
                      {
                        text: 'Two Year',
                        value: 'twoYear',
                      },
                      {
                        text: 'Two Year / Four Year',
                        value: 'twoYearFourYear',
                      },
                      {
                        text: 'Alumni',
                        value: 'alum',
                      },
                      {
                        text: 'WSJ Weighting',
                        value: 'wsj',
                      },
                      {
                        text: 'None',
                        value: null,
                      },
                    ]}
                    selection
                    fluid
                    value={config?.weightingScheme}
                    onChange={(e, d) => {
                      setChangesMade(true)
                      setConfig(c => ({ ...c, weightingScheme: d.value }))
                    }}
                    placeholder="Select Weighting Config"
                  />
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row fluid>
          <Grid.Column fluid>
            <Card fluid>
              <Card.Content>
                <Card.Header>
                  <h4>Custom Weighting Config Population</h4>
                </Card.Header>
                <Card.Description>
                  <Dropdown
                    options={[
                      {
                        text: 'False',
                        value: false,
                      },
                      {
                        text: 'True',
                        value: true,
                      },
                    ]}
                    selection
                    fluid
                    onChange={(e, d) => {
                      setChangesMade(true)
                      setConfig(c => ({ ...c, customPopulation: d.value }))
                    }}
                    value={config?.customPopulation}
                    placeholder="Custom Population?"
                  />
                </Card.Description>
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row fluid style={{ borderTop: '1px solid black' }}>
          <Grid.Column fluid>
            <Button
              color={toggleDebug ? 'green' : undefined}
              onClick={() => setToggleDebug(p => !p)}
            >
              Toggle Debug
            </Button>

            {toggleDebug && (
              <>
                <h5>Config</h5>
                <pre>{JSON.stringify(config, null, 2)}</pre>
              </>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

const getData = async ({ _id, setConfig, setError }) => {
  try {
    // Weighting config data
    // Possible entries: 'fourYear', 'twoYear', 'twoYearFourYear', 'alum'
    let weightingScheme = await fetchSurveyProperty({
      _id: _id,
      property: 'weightingScheme',
    })

    // customWeightingConfig.customPopulation
    // Boolean
    let customPopulation = await fetchSurveyProperty({
      _id: _id,
      property: 'customWeightingConfig.customPopulation',
    })

    // Set the initial state
    setConfig({
      weightingScheme,
      customPopulation: customPopulation || false,
    })
  } catch (err) {
    // Handle errors
    setError(err.message)
    console.log(err)
  }
}
