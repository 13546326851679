import React from 'react'
import ReactFilepicker from 'react-filepicker'
import TextareaAutosize from 'react-autosize-textarea'

import { connect } from 'react-redux'
import {
  changeQuestionDescription,
  changeQuestionImage,
} from '../../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'
const authorFilepickerOptions = {
  buttonText: 'Add a question image',
  buttonClass: 'question-filepicker-button',
  mimetype: 'image/*',
  container: 'modal',
  services: ['COMPUTER', 'URL', 'FACEBOOK', 'GOOGLE_DRIVE', 'DROPBOX', 'CONVERT'],
  cropForce: true,
}

class QuestionHeader extends React.Component {
  constructor() {
    super()
    this.authorUploadKey = 'AUi37ssFTieo2GNZYg3Mwz'
    this.onQuestionDescriptionChange = this.onQuestionDescriptionChange.bind(this)
    this.onImageSelected = this.onImageSelected.bind(this)
  }
  onQuestionDescriptionChange(ev) {
    this.props.changeQuestionDescription(this.props.currentQuestionIndex, ev.target.value)
  }
  onImageSelected(blob) {
    if (blob.url) {
      const urlComponents = blob.url.split('/')
      const key = urlComponents[urlComponents.length - 1]
      const url = `https://cdn.filestackcontent.com/resize=width:1200/compress/${key}`
      this.props.changeQuestionImage(this.props.currentQuestionIndex, url)
    }
  }
  render() {
    const props = this.props

    let currentQuestion = props.questions[props.currentQuestionIndex]

    if (!currentQuestion) {
      return <div />
    }

    // TODO: Can we standardize the humanized current question index thing?
    // maybe attach a property on the question model? or just attach temp here and recompute
    // on each load
    let messageBeforeCount = 0

    for (let i = 0; i < props.currentQuestionIndex; i++) {
      if (props.questions[i].type === 'message') {
        messageBeforeCount++
      }
    }

    let humanizedCurrInd = props.currentQuestionIndex + 1 - messageBeforeCount

    let currQIsMessage = currentQuestion.type === 'message'

    let placeholder = currQIsMessage ? 'Enter your Message Title...' : 'Enter your Question...'

    let numberContainer
    if (currQIsMessage) {
      numberContainer = (
        <div className="number-container">
          <div className="number">
            <span>M</span>
          </div>
        </div>
      )
    } else {
      numberContainer = (
        <div className="number-container">
          <div className="number">
            <span>{`Q${humanizedCurrInd}`}</span>
          </div>
        </div>
      )
    }

    return (
      <div className="question-header">
        {numberContainer}
        <div className="question-text-container">
          <div className="question-text">
            <TextareaAutosize
              id="q-text-form"
              type="text"
              placeholder={placeholder}
              value={currentQuestion.description ? currentQuestion.description : ''}
              onChange={this.onQuestionDescriptionChange}
              readOnly={props.viewOnly}
            />
          </div>
        </div>
        <div className="preview-and-image">
          <div className="preview-question-container preview-and-image-button">
            <button
              onClick={() =>
                window.open(
                  `https://app.collegepulse.com/external-survey/${this.props.activeSurvey?.['_id']}?question=${currentQuestion?.['_id']}&skipDems=true`,
                  '_blank'
                )
              }
              disabled={this.props.changesMade}
            >
              Preview Question
            </button>
          </div>
          <div className="photo-section preview-and-image-button" key={props.currentQuestionIndex}>
            {this.props.questions[props.currentQuestionIndex].imageUrl ? (
              <button
                onClick={() => {
                  this.props.changeQuestionImage(this.props.currentQuestionIndex, null)
                }}
              >
                Remove image
              </button>
            ) : (
              <ReactFilepicker
                apikey={this.authorUploadKey}
                defaultWidget={true}
                mode="pick"
                options={authorFilepickerOptions}
                onSuccess={this.onImageSelected}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}
/*
{currentQuestion.imageUrl && (
  <img
    className="question-cover-photo"
    src={
       currentQuestion.imageUrl
    }
  />
)}
*/

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
  currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
  messageCount: state.activeSurvey.messageCount,
  activeSurvey: state.activeSurvey,
})

export default connect(mapStateToProps, {
  changeQuestionDescription,
  changeQuestionImage,
})(QuestionHeader)
