import React from 'react'
import { Button, Checkbox, Form, TextArea, Message } from 'semantic-ui-react'
import { createBrandTracker } from '../../../../api/admin'

export default class TemplateModal extends React.Component {
  constructor() {
    super()
    this.state = {
      filterByIndustry: false,
      industry: '',
      title: '',
      companies: '',
      primaryCompany: '',
      quality: '',
      error: null,
    }

    this.submit = this.submit.bind(this)
    this.parseCompanyNames = this.parseCompanyNames.bind(this)
  }

  submit() {
    // const
    const { filterByIndustry, industry, primaryCompany, quality, title } = this.state
    createBrandTracker({
      filterByIndustry,
      industry: industry.trim(),
      title: title.trim(),
      companyNames: this.parseCompanyNames(),
      primaryCompany: primaryCompany.trim(),
      quality: quality.trim(),
    })
      .then(result => {
        if (result && result.survey) {
          this.props.toggle()
          window.open(`/create/survey/${result.survey}`)
        } else {
          this.setState({
            error: 'No Survey Created',
          })
        }
      })
      .catch(err => this.setState(err))
  }

  parseCompanyNames() {
    const { companies } = this.state
    return companies
      .split(',')
      .map(c => c.trim())
      .filter(c => c)
  }
  render() {
    const companyNames = this.parseCompanyNames()
    return (
      <Form className="template-form" error={this.state.error}>
        <Form.Field>
          <Checkbox
            label="Include first question about industry interest, and exclude any 'Not at all interested'"
            checked={this.state.filterByIndustry}
            onChange={() => this.setState({ filterByIndustry: !this.state.filterByIndustry })}
          />
        </Form.Field>
        <Form.Field disabled={!this.state.filterByIndustry}>
          <label>Industry (ignore if not filtering by industry)</label>
          <input
            placeholder="How interested are you in working in ____"
            value={this.state.industry}
            onChange={ev => this.setState({ industry: ev.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>Title</label>
          <input
            placeholder="Finance Perceptions"
            value={this.state.title}
            onChange={ev => this.setState({ title: ev.target.value })}
          />
        </Form.Field>
        <Form.Field
          control={TextArea}
          label="Companies, comma separated"
          placeholder="BNP, Goldman Sachs, ..."
          value={this.state.companies}
          onChange={ev => this.setState({ companies: ev.target.value })}
        />
        <ul>
          {companyNames.map(name => (
            <li style={{ display: 'inline-block', marginLeft: 10 }}>- {name}</li>
          ))}
        </ul>
        <Form.Field>
          <label>Primary Company</label>
          <input
            placeholder="Primary Company"
            value={this.state.primaryCompany}
            onChange={ev => this.setState({ primaryCompany: ev.target.value })}
          />
        </Form.Field>
        <Form.Field>
          <label>
            Prestige Alternative (Optional): "Which of the following companies do you consider
            [presigious to work for]?"
          </label>
          <input
            placeholder="prestigious to work for"
            value={this.state.quality}
            onChange={ev => this.setState({ quality: ev.target.value })}
          />
        </Form.Field>
        <Message error header="Error" content={this.state.error} />{' '}
        <Button type="submit" onClick={this.submit}>
          Submit
        </Button>
      </Form>
    )
  }
}
