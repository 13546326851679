export const ActionTypes = {
  SET_MODAL_STATE: 'SET_MODAL_STATE',
  TOGGLE_MODAL_STATE: 'TOGGLE_MODAL_STATE',
}

/**
 * Open and close modals
 */

/**
 * generic function for changing a modal's open state in redux
 * used by all the exported functions below
 */
function changeModalState({ modal, isOpen, metadata, metadataValue }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_MODAL_STATE,
      payload: {
        modal,
        isOpen,
        metadata,
        metadataValue,
      },
    })
  }
}

export function showClosingModal() {
  return changeModalState({ modal: 'closing', isOpen: true })
}

export function hideClosingModal() {
  return changeModalState({ modal: 'closing', isOpen: false })
}

export function showAuthorImgModal() {
  return changeModalState({ modal: 'authorImg', isOpen: true })
}

export function hideAuthorImgModal() {
  return changeModalState({ modal: 'authorImg', isOpen: false })
}

export function showEnterTitleModal({ attemptingToClose }) {
  return changeModalState({
    modal: 'title',
    isOpen: true,
    metadata: 'closeUponSaving',
    metadataValue: attemptingToClose,
  })
}

export function hideEnterTitleModal() {
  return changeModalState({ modal: 'title', isOpen: false })
}

export function showSubmitConfirmModal() {
  return changeModalState({ modal: 'submit', isOpen: true })
}

export function hideSubmitConfirmModal() {
  return changeModalState({ modal: 'submit', isOpen: false })
}

export function toggleLogicModal() {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_MODAL_STATE,
      payload: {
        modal: 'logic',
      },
    })
  }
}
