// Functionality Imports
import React, { useState, useEffect, useCallback, useRef } from 'react'
import {
  fetchSurveyProperty,
  setSurveyProperty,
  getOneSchool,
  getOneSchoolById,
} from '../../../../../api/admin'
import { store } from '../../../../store'
import urljoin from 'url-join'
import Promise from 'bluebird'

// UI Imports
import { Checkbox, Button, Input, Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

export default ({ _id }) => {
  const [defaultConfig, setDefaultConfig] = useState({
    responseLimit: 1000,
    directIncentiveAmount: 2,
    snowballIncentiveAmount: 0,
    ambassadorIncentiveAmount: 0,
    allowDirectIncentive: true,
    allowSnowballSampling: false,
    allowAmbassadorFielding: false,
    bigboxFielding: false,
  })

  // Search box state management
  const [searchBox, setSearchBox] = useState('')

  // Loading Data State Management
  const [loading, setLoading] = useState(false)

  // Get initial data
  useEffect(() => {
    getData({ _id, setGlobalReferral, setReferralConfig, setFaceted, defaultConfig, setLoading })
  }, [])

  // Toggle school in referral
  const schoolToggle = ({ _id }) => {
    setChangesMade(true)
    if (!referralConfig?.[_id]) {
      // Add a school
      setReferralConfig(prev => {
        if (prev['all']) {
          // Delete the all property if it exists when adding a school
          delete prev['all']
        }
        return {
          ...prev,
          [_id]: {},
        }
      })
    } else {
      // Remove a school
      setReferralConfig(prev => {
        delete prev[_id]
        if (Object.keys(prev).length === 0) {
          // If there are no entries, bring back the all config
          prev['all'] = {}
        }
        return { ...prev }
      })
    }
  }

  // Toggle All Schools
  const schoolToggleAll = () => {
    setChangesMade(true)
    // Check if all schools are checked
    if (Object.keys(referralConfig).length === faceted.length) {
      // Reset Referral Config to default
      setReferralConfig(p => ({
        all: defaultConfig,
      }))
    } else {
      // Toggle all schools that are not currently toggled
      faceted.forEach(school => {
        // If the school is not toggled, toggle
        if (!referralConfig[school[1]]) {
          schoolToggle({ _id: school[1] })
        }
      })
    }
  }

  // Toggle entire referral
  const [globalReferral, setGlobalReferral] = useState(false)
  const toggleReferral = () => {
    setChangesMade(true)
    setGlobalReferral(p => !p)
  }

  // Hold data state
  const [referralConfig, setReferralConfig] = useState()
  const [faceted, setFaceted] = useState()

  // Send data up
  const sendConfig = () => {
    setChangesMade(false)

    // Put default values in where value isn't defined
    let factoredReferral = {}
    Object.keys(referralConfig).forEach(row => {
      let keys = Object.keys(defaultConfig)
      let singleConfig = {}
      keys.forEach(key => {
        singleConfig[key] = referralConfig?.[row]?.[key] || defaultConfig[key]
      })
      factoredReferral[row] = singleConfig
    })

    setSurveyProperty({
      _id: _id,
      property: 'referralConfig',
      value: globalReferral ? factoredReferral : {},
      store: store,
    })
  }

  // Handle all of the input
  const handleInput = ({ group, property, value }) => {
    setChangesMade(true)
    setReferralConfig(prev => ({
      ...prev,
      [group]: {
        ...prev[group],
        [property]: value,
      },
    }))
  }

  // Listen for changes
  const [changesMade, setChangesMade] = useState(false)

  // Current growth channel
  const [growthChannel, setGrowthChannel] = useState('')

  // Debug
  const [toggleDebug, setToggleDebug] = useState(false)

  // Overwrite props
  const overwriteProps = prop => {
    let newConfig = {}
    Object.keys(referralConfig).forEach(rowKey => {
      newConfig[rowKey] = { ...referralConfig[rowKey], [prop]: undefined }
    })
    setReferralConfig(newConfig)
  }

  // Pull quotas data
  const [pullQuotasText, setPullQuotasText] = useState('Pull data from quotas')
  const pullQuotas = async () => {
    try {
      // Get all quota information
      const exactTargets = await fetchSurveyProperty({ _id, property: 'quotas.exactTargets' })
      if (!_.isEmpty(exactTargets)) {
        // Filter quota information down to schools
        let schoolTargets = exactTargets.filter(row => row.demographicCategory === 'school')
        if (!_.isEmpty(schoolTargets)) {
          // Modify the referral config values from the school quotas
          let newReferralConfig = { ...referralConfig }
          schoolTargets.forEach(school => {
            newReferralConfig[school.demographicOption] = {
              ...newReferralConfig[school.demographicOption],
              responseLimit: school.value,
            }
          })

          // Remove the all property if there were schools
          if (newReferralConfig.all) delete newReferralConfig.all

          // Make sure incentives is on
          setGlobalReferral(true)

          // Update the referralConfig
          setReferralConfig(newReferralConfig)

          // Respond to the users input
          setPullQuotasText('Data pulled!')
          setTimeout(() => {
            setPullQuotasText('Pull data from quotas')
          }, 5000)
        }
      }
    } catch (err) {
      // Respond to the users input
      setPullQuotasText('No quotas available')
      setTimeout(() => {
        setPullQuotasText('Pull data from quotas')
      }, 5000)
    }
  }

  // Debounce Search functionality with lodash and useCallback
  const handleSearchChangeFn = (e, d) => {
    setSearchBox(d.value)
  }
  const handleSearchChange = useCallback(_.debounce(handleSearchChangeFn, 500), [])

  // Export CSV Functionality
  const handleExportCsv = async () => {
    // Columns
    let columns = [
      '_id',
      'name',
      'responseLimit',
      'directIncentiveAmount',
      'snowballIncentiveAmount',
      'ambassadorIncentiveAmount',
      'allowDirectIncentive',
      'allowSnowballSampling',
      'allowAmbassadorFielding',
      'bigboxFielding',
      'surveyLink',
      'unitid',
    ]

    // Rows
    let rows = [columns]
    Object.keys(referralConfig).forEach(rowKey => {
      let row = [
        rowKey,
        faceted?.find(f => f[1] === rowKey)?.[0],
        referralConfig[rowKey].responseLimit,
        referralConfig[rowKey].directIncentiveAmount,
        referralConfig[rowKey].snowballIncentiveAmount,
        referralConfig[rowKey].ambassadorIncentiveAmount,
        referralConfig[rowKey].allowDirectIncentive,
        referralConfig[rowKey].allowSnowballSampling,
        referralConfig[rowKey].allowAmbassadorFielding,
        referralConfig[rowKey].bigboxFielding,
        encodeURI(
          urljoin(
            `https://app.collegepulse.com/external-survey/${_id}`,
            `?schoolName=${faceted?.find(f => f[1] === rowKey)?.[2]}`,
            growthChannel ? `?growthChannel=${growthChannel}` : '',
            `?sb_convenience=${
              referralConfig?.[rowKey]?.directIncentiveAmount || defaultConfig.directIncentiveAmount
            }`,
            '?ref=snowball-',
            '?snowballsample=true'
          )
        ),
        faceted?.find(f => f[1] === rowKey)?.[3],
      ]

      rows.push(row)
    })

    // Save as CSV
    const csv = rows.map(row => row.join(',')).join('\n')
    const blob = new Blob([csv], { type: 'text/csv' })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.setAttribute('hidden', '')
    a.setAttribute('href', url)
    a.setAttribute('download', `${_id}-referral-config.csv`)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  }

  // Upload CSV Functionality
  const handleFileUpload = async e => {
    // get the file
    const file = e.target.files[0]

    // read the file
    const reader = new FileReader()
    reader.readAsText(file)
    reader.onload = async e => {
      // parse the file
      const csv = e.target.result
      const rows = csv.split('\n').map(row => row.split(','))

      // Get the header row
      const header = rows[0]

      // Get the data rows
      const data = rows.slice(1)

      // Create the new config
      let newConfig = {}
      data.forEach(row => {
        let newRow = {}
        header.forEach((header, i) => {
          if (row[i] === 'TRUE') row[i] = true
          if (row[i] === 'FALSE') row[i] = false

          if (header.includes('Amount') || header.includes('Limit')) {
            row[i] = Number(row[i])
            newRow[header] = row[i]
          }
          if (header === '_id') {
            newRow[header] = row[i]
          }
          if (typeof row[i] === 'boolean') {
            newRow[header] = row[i]
          }
        })
        newConfig[newRow._id] = newRow
      })

      // Update the config
      setReferralConfig(newConfig)

      // Set changes to true so we can upload
      setChangesMade(true)
    }
  }

  // Reference handler to the file input so we can use a button instead of the
  // ugly input
  const fileInputRef = useRef(null)

  return (
    <div className="referral-view">
      <Checkbox
        toggle
        checked={globalReferral}
        onClick={() => toggleReferral()}
        label={`Incentives are: ${globalReferral ? 'ON' : 'OFF'}`}
      />
      <Button
        style={{ margin: '1rem 2rem' }}
        color={changesMade ? 'green' : undefined}
        onClick={() => sendConfig()}
        disabled={!changesMade}
      >
        Update referral config
      </Button>
      <Button onClick={() => pullQuotas()}>{pullQuotasText}</Button>
      <Button onClick={() => handleExportCsv()}>Save as CSV</Button>
      <Button
        onClick={() => {
          // Fake the hidden input click
          fileInputRef.current.click()
        }}
      >
        Upload Config
      </Button>
      <input type="file" onChange={handleFileUpload} hidden ref={fileInputRef} />

      {globalReferral && referralConfig && (
        <>
          <hr />
          <h4 style={{ marginBottom: '0.25rem' }}>Default Config</h4>
          <p style={{ marginTop: 0 }}>
            <em>The default values will be applied unless a custom value has been entered.</em>
          </p>
          <div style={{ display: 'flex', marginBottom: '2rem', flexWrap: 'wrap' }}>
            {Object.keys(defaultConfig).map(prop => (
              <div
                style={{
                  paddingRight: '1rem',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  minWidth: '20%',
                }}
                key={_.startCase(prop)}
              >
                <div
                  className="delete-prop"
                  onClick={() => {
                    overwriteProps(prop)
                  }}
                >
                  {_.startCase(prop)}
                </div>
                {typeof defaultConfig[prop] === 'boolean' ? (
                  <Dropdown
                    value={defaultConfig[prop]}
                    onChange={(e, d) => {
                      setDefaultConfig(c => ({ ...c, [prop]: d.value }))
                    }}
                    selection
                    fluid
                    options={[
                      { key: 'True', value: true, text: 'True' },
                      { key: 'False', value: false, text: 'False' },
                    ]}
                  />
                ) : (
                  <Input
                    type={typeof defaultConfig[prop]}
                    value={defaultConfig[prop]}
                    onChange={(e, d) => {
                      setDefaultConfig(c => ({ ...c, [prop]: +d.value }))
                    }}
                  />
                )}
              </div>
            ))}
          </div>
          <hr />
          <h4 style={{ marginBottom: '0.25rem' }}>Link Generation</h4>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: 0 }}>
            <span>Growth Channel</span>
            <Input
              type="text"
              value={growthChannel}
              onChange={(e, d) => setGrowthChannel(d.value)}
              placeholder="Growth Channel"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
            <span>
              Non School Specific Link{' '}
              <em>
                <small>
                  (Incentive amount is pulled from the default direct incentive amount above)
                </small>
              </em>
            </span>
            <Input
              type="text"
              style={{ flexGrow: 1 }}
              value={encodeURI(
                urljoin(
                  `https://app.collegepulse.com/external-survey/${_id}`,
                  growthChannel ? `?growthChannel=${growthChannel}` : '',
                  `?sb_convenience=${defaultConfig.directIncentiveAmount}`,
                  '?ref=snowball-',
                  '?snowballsample=true'
                )
              )}
              onChange={e => e.preventDefault()}
            />
          </div>
          <hr />
          <h4>
            Toggle Schools {loading ? '| DATA IS LOADING... This might take a second.' : ''}{' '}
            {typeof loading === 'string' ? `| ${loading}` : ''}
          </h4>
          <Button style={{ marginBottom: '0.5rem' }} onClick={() => schoolToggleAll()}>
            Toggle All
          </Button>
          {faceted?.map(school => (
            <Button
              style={{ marginBottom: '0.5rem' }}
              color={referralConfig[school[1]] ? 'green' : undefined}
              onClick={() => schoolToggle({ _id: school[1] })}
            >
              {school[0]}
            </Button>
          ))}
          <hr style={{ margin: '2rem 0' }} />

          <h4>Referral Config</h4>
          <div style={{ display: 'flex', flexDirection: 'column', marginTop: '1rem' }}>
            <span>Search</span>
            <Input
              style={{ marginBottom: '1rem' }}
              type="text"
              onChange={handleSearchChange}
              //value={searchBox}
            />
          </div>

          {(searchBox
            ? Object.keys(referralConfig).filter(
                key =>
                  faceted
                    ?.find(f => f[1] === key)?.[0]
                    .toLowerCase()
                    .includes(searchBox.toLowerCase()) ||
                  faceted
                    ?.find(f => f[1] === key)?.[2]
                    .toLowerCase()
                    .includes(searchBox.toLowerCase())
              )
            : Object.keys(referralConfig)
          ).map(key => (
            <div key={key} className="group">
              <h5 className="hover-red" onClick={() => schoolToggle({ _id: key })}>
                {faceted?.find(f => f[1] === key)?.[0] || key || 'All'}{' '}
                {faceted?.find(f => f[1] === key)?.[0] && (
                  <em style={{ marginLeft: '2rem' }}>
                    AKA: {faceted?.find(f => f[1] === key)?.[2]}
                  </em>
                )}
              </h5>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {Object.keys(defaultConfig).map(prop => (
                  <div
                    style={{
                      paddingRight: '1rem',
                      flex: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      minWidth: '10%',
                    }}
                    key={_.startCase(prop)}
                  >
                    <span>{_.startCase(prop).split(' ').slice(0, 2).join(' ')}</span>
                    {typeof defaultConfig[prop] === 'boolean' ? (
                      <Dropdown
                        value={referralConfig?.[key]?.[prop] || defaultConfig[prop]}
                        onChange={(e, d) => {
                          handleInput({ group: key, property: prop, value: d.value })
                        }}
                        selection
                        style={{
                          color: referralConfig?.[key]?.[prop] ? '#000000' : '#00000040',
                        }}
                        fluid
                        options={[
                          { key: 'True', value: true, text: 'True' },
                          { key: 'False', value: false, text: 'False' },
                        ]}
                      />
                    ) : (
                      <Input
                        type={typeof defaultConfig[prop]}
                        style={{
                          opacity: referralConfig?.[key]?.[prop] ? 1 : 0.4,
                        }}
                        value={referralConfig?.[key]?.[prop] || defaultConfig[prop]}
                        onChange={(e, d) =>
                          handleInput({ group: key, property: prop, value: +d.value })
                        }
                      />
                    )}
                  </div>
                ))}
              </div>
              <div style={{ display: 'flex', paddingTop: '0.5rem', flexDirection: 'column' }}>
                <span>Default Incentive Link</span>
                <Input
                  type="text"
                  style={{ flexGrow: 1 }}
                  value={encodeURI(
                    urljoin(
                      `https://app.collegepulse.com/external-survey/${_id}`,
                      `?schoolName=${faceted?.find(f => f[1] === key)?.[2]}`,
                      growthChannel ? `?growthChannel=${growthChannel}` : '',
                      `?sb_convenience=${
                        referralConfig?.[key]?.directIncentiveAmount ||
                        defaultConfig.directIncentiveAmount
                      }`,
                      '?ref=snowball-',
                      '?snowballsample=true'
                    )
                  )}
                  onChange={e => e.preventDefault()}
                />
              </div>
            </div>
          ))}

          <hr style={{ margin: '2rem 0' }} />

          <Button color={toggleDebug ? 'green' : undefined} onClick={() => setToggleDebug(p => !p)}>
            Toggle Debug
          </Button>
          {toggleDebug && (
            <>
              <h5>Default Config</h5>
              <pre>{JSON.stringify(defaultConfig, null, 2)}</pre>

              <h5>Global Referral</h5>
              <pre>{JSON.stringify(globalReferral, null, 2)}</pre>

              <h5>Referral Config</h5>
              <pre>{JSON.stringify(referralConfig, null, 2)}</pre>

              <h5>Schools</h5>
              <pre>{JSON.stringify(faceted, null, 2)}</pre>
            </>
          )}
        </>
      )}
    </div>
  )
}

const getData = async ({
  _id,
  setGlobalReferral,
  setReferralConfig,
  setFaceted,
  defaultConfig,
  setLoading,
}) => {
  try {
    setLoading(true)
    //Get Data
    //Get Referral Config
    let referralConfig = await fetchSurveyProperty({
      _id: _id,
      property: 'referralConfig',
    })

    // Get Faceted
    let facetedTargetedDemographics = await fetchSurveyProperty({
      _id: _id,
      property: 'facetedTargetedDemographics',
    })

    // Set up the referralConfig
    if (Object.keys(referralConfig || {}).length) {
      setGlobalReferral(true)
    }
    setReferralConfig(
      Object.keys(referralConfig || {}).length
        ? referralConfig
        : {
            all: defaultConfig,
          }
    )

    // Set up faceted
    // 1: Turn dems into list of school names
    let schools = facetedTargetedDemographics
      .filter(x => x.includes('school:'))
      .map(x => x.replace('school:', ''))

    let usingIds = false
    if (!schools || schools.length === 0) {
      usingIds = true
      schools = Object.keys(referralConfig)
    }

    // 2: For each school, get their school object
    let d = []
    let myInt = setInterval(() => {
      setLoading(`${d.length} / ${schools.length}`)
    }, 1000)
    await Promise.map(
      schools,
      async (name, index) => {
        if (!name) return
        let schoolResponse
        if (usingIds) {
          // Support for searching via ID's rather then faceted
          schoolResponse = await getOneSchoolById(name)
          schoolResponse = schoolResponse[0]
        } else {
          schoolResponse = await getOneSchool({ name: name })
        }

        if (schoolResponse) {
          d.push([
            schoolResponse.institutionName,
            schoolResponse._id,
            name ? name : schoolResponse.name,
            schoolResponse.unitid,
          ])
        }
      },
      { concurrency: 200 }
    )

    clearInterval(myInt)

    setFaceted(d)
    setLoading(false)
  } catch (err) {
    console.log('Error getting data')
    console.log(err)
    setLoading(false)
  }
}
