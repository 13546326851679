import React from 'react'
import PropTypes from 'prop-types'
import Hammer from 'react-hammerjs'
import ReactFilepicker from 'react-filepicker'

import { connect } from 'react-redux'
import {
  setImg,
  setTitle,
  setAuthor,
  setDescription,
} from '../../../../../actions/create-view-survey-actions/edit-survey-actions'
import {
  showClosingModal,
  showAuthorImgModal,
} from '../../../../../actions/create-view-modal-actions'

const MAX_DESCRIPTION_LENGTH = 90

const errorMessages = {
  title: 'name your survey',
  authorImg: 'upload an author image',
  authorName: 'add an author name',
  description: 'add a survey description',
}

class CreateSurveyCard extends React.Component {
  constructor() {
    super()

    this.bannerUploadKey = 'AUi37ssFTieo2GNZYg3Mwz'
    this.state = {
      errMsg: '',
      editingTitle: false,
      editingName: false,
      editingDescription: false,
      titleError: false,
      descriptionError: false,
      authorImgError: false,
      authorNameError: false,
    }

    const boundMethods =
      'editTitle editAuthorName editDescription setErrMsg onNameEditClick onTitleEditClick onDescriptionInputFocus onDescriptionInputBlur onDescriptionEditClick onImageUploaded'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setErrMsg(nextProps.navigationErrors)
    this.setRedFields(nextProps.navigationErrors)
  }

  editTitle(ev) {
    this.props.setTitle(ev.target.value)
  }

  editAuthorName(ev) {
    this.props.setAuthor(ev.target.value)
  }

  editDescription(ev) {
    this.props.setDescription(ev.target.value)
  }

  setErrMsg(errs) {
    // "natural language" nonsense
    let msg
    if (errs.length == 0) {
      msg = ''
    } else {
      msg = 'Please '
    }
    if (errs.length == 1) {
      msg = `${msg}${errorMessages[errs[0]]}.`
    } else if (errs.length == 2) {
      msg = `${msg}${errorMessages[errs[0]]} and ${errorMessages[errs[1]]}.`
    } else {
      errs.forEach((e, i) => {
        if (i < errs.length - 1) {
          msg = `${msg}${errorMessages[e]}, `
        } else {
          msg = `${msg}and ${errorMessages[e]}.`
        }
      })
    }

    this.setState({
      errMsg: msg,
    })
  }

  setRedFields(errs) {
    const possibleErrs = ['title', 'description', 'authorImg', 'authorName']
    const nonErrs = possibleErrs.filter(e => {
      return errs.indexOf(e) < 0
    })
    errs.forEach(e => {
      if (e == 'title') {
        this.setState({
          titleError: true,
        })
      }
      if (e == 'description') {
        this.setState({
          descriptionError: true,
        })
      }
      if (e == 'authorImg') {
        this.setState({
          authorImgError: true,
        })
      }
      if (e == 'authorName') {
        this.setState({
          authorNameError: true,
        })
      }
    })
    nonErrs.forEach(e => {
      if (e == 'title') {
        this.setState({
          titleError: false,
        })
      }
      if (e == 'description') {
        this.setState({
          descriptionError: false,
        })
      }
      if (e == 'authorImg') {
        this.setState({
          authorImgError: false,
        })
      }
      if (e == 'authorName') {
        this.setState({
          authorNameError: false,
        })
      }
    })
  }

  onDescriptionInputFocus() {
    this.setState({ editingDescription: true })
  }

  onDescriptionInputBlur() {
    this.setState({ editingDescription: false })
  }

  onNameEditClick() {
    if (!this.state.editingName) {
      document.getElementById('author-name').focus()
    }
    this.setState({
      editingName: !this.state.editingName,
    })
  }

  onTitleEditClick() {
    if (!this.state.editingTitle) {
      document.getElementById('title-form').focus()
    }
    this.setState({
      editingTitle: !this.state.editingTitle,
    })
  }

  onDescriptionEditClick() {
    if (!this.state.editingDescription) {
      document.getElementById('description-form').focus()
    }
    this.setState({
      editingDescription: !this.state.editingDescription,
    })
  }

  onImageUploaded(blob) {
    const urlComponents = blob.url.split('/')
    const key = urlComponents[urlComponents.length - 1]
    const url = `https://cdn.filestackcontent.com/resize=height:465/compress/${key}`
    this.props.setImg(url)
  }

  render() {
    const props = this.props
    const bannerFilepickerOptions = {
      buttonText: 'Add a Background Image...',
      buttonClass: 'details-filepicker-button',
      mimetype: 'image/*',
      container: 'modal',
      services: ['COMPUTER', 'URL', 'FACEBOOK', 'GOOGLE_DRIVE', 'DROPBOX', 'CONVERT'],
      cropRatio: 2.5 / 1,
      cropForce: true,
    }

    let bannerStyle = props.survey.imgSrc
      ? {
          backgroundImage: `url(${props.survey.imgSrc})`,
          backgroundSize: 'cover',
        }
      : {}

    // <button htmlFor='title-form'
    //   className={this.state.titleError ? 'admin-edit-button red' : 'admin-edit-button'}
    //   onClick={this.onTitleEditClick}>
    //   {this.state.editingTitle ? 'DONE' : 'EDIT'}
    // </button>

    // <button
    //   htmlFor='description-form'
    //   className={this.state.descriptionError ? 'admin-edit-button red' : 'admin-edit-button'}
    //   onClick={this.onDescriptionEditClick}>
    //   {this.state.editingDescription ? 'DONE' : 'EDIT'}
    // </button>

    // <button
    //   htmlFor='author-name'
    //   className={this.state.authorNameError ? 'admin-edit-button red' : 'admin-edit-button'}
    //   onClick={this.onNameEditClick}>
    //   {this.state.editingName ? 'DONE' : 'EDIT'}
    // </button>

    const currentLength = this.props.survey.description ? this.props.survey.description.length : 0

    return (
      <div className="create-survey-card-container">
        <div className="survey-card-explanation-container">
          <div className="survey-card-explanation-header">Create Your Survey Card</div>
          <div className="survey-card-explanation">
            This is how students will see your survey in their feed.
          </div>
        </div>
        <div className="create-survey-card">
          <div className="create-survey-card-top" style={bannerStyle}>
            <ReactFilepicker
              apikey={this.bannerUploadKey}
              defaultWidget={false}
              mode="pick"
              options={bannerFilepickerOptions}
              onSuccess={this.onImageUploaded}
            />
            <div className="edit-title-container">
              <input
                id="title-form"
                className={this.state.titleError ? 'red-letters' : ''}
                type="text"
                placeholder="Survey Title"
                value={props.survey.title}
                onChange={this.editTitle}
                onFocus={this.onTitleEditClick}
                onBlur={this.onTitleEditClick}
                maxLength={20}
              />
            </div>
          </div>
          <div className="create-survey-card-bottom">
            <div className="description-container">
              <textarea
                id="description-form"
                className={this.state.descriptionError ? 'red-letters' : ''}
                placeholder="Enter a description of your survey! Let the student know why you need his/her opinion."
                value={props.survey.description || ''}
                onChange={this.editDescription}
                onFocus={this.onDescriptionInputFocus}
                onBlur={this.onDescriptionInputBlur}
                maxLength={MAX_DESCRIPTION_LENGTH}
              />
              <div className="description-content-container">
                <div
                  className={`remaining-chars-text ${
                    this.state.editingDescription ? '' : 'hidden'
                  }`}
                >
                  {`(${MAX_DESCRIPTION_LENGTH - currentLength} remaining)`}
                </div>
              </div>
            </div>
            <div className="author-container">
              <Hammer onTap={props.showAuthorImgModal}>
                <div className={this.state.authorImgError ? 'author-avatar red' : 'author-avatar'}>
                  <img
                    src={
                      props.survey.authorImg != '/img/user.svg'
                        ? props.survey.authorImg
                        : '/img/user-white.svg'
                    }
                    style={{ position: 'relative', borderRadius: '25px' }}
                    draggable={false}
                  />
                </div>
              </Hammer>
              <input
                id="author-name"
                className={this.state.authorNameError ? 'red-letters' : ''}
                type="text"
                placeholder="Author Name"
                value={props.survey.authorName}
                onChange={this.editAuthorName}
                onFocus={this.onNameEditClick}
                onBlur={this.onNameEditClick}
                maxLength={40}
              />
            </div>
          </div>
        </div>
        <div className="err-msg">{this.state.errMsg}</div>
      </div>
    )
  }
}

CreateSurveyCard.propTypes = {
  survey: PropTypes.object,
  setTitle: PropTypes.func,
  setAuthor: PropTypes.func,
  setDescription: PropTypes.func,
  setImg: PropTypes.func,
  showAuthorImgModal: PropTypes.func,
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
  viewOnly: state.createViewMeta.viewOnly,
  reviewingForPublish: state.createViewMeta.reviewingForPublish,
  currentPage: state.createViewNav.currentPage,
  navigationErrors: state.createViewNav.navigationErrors,
})

export default connect(mapStateToProps, {
  setImg,
  setTitle,
  setAuthor,
  setDescription,
  showClosingModal,
  showAuthorImgModal,
})(CreateSurveyCard)
