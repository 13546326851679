import React from 'react'

import { connect } from 'react-redux'
import { editMessageForQuestionAtIndex } from '../../../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'

class MessageBox extends React.Component {
  constructor() {
    super()

    this.onMessageChange = this.onMessageChange.bind(this)
  }
  onMessageChange(ev) {
    this.props.editMessageForQuestionAtIndex({
      questionIndex: this.props.currentQuestionIndex,
      newMessage: ev.target.value,
    })
  }
  render() {
    const props = this.props

    let currentQuestion = props.questions[props.currentQuestionIndex]
    if (!currentQuestion) {
      return <div></div>
    }

    let message = currentQuestion.message
    if (props.textEntry) {
      message = 'User text entry responses will go here'
    }

    return (
      <div className={`message-text-container ${props.viewOnly ? 'grey' : ''}`}>
        <div className="message-text">
          <textarea
            id="m-text-form"
            type="text"
            placeholder="Enter your message body..."
            value={message ? message : ''}
            onChange={this.onMessageChange}
            readOnly={props.viewOnly}
          />
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
  currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
})

export default connect(mapStateToProps, { editMessageForQuestionAtIndex })(MessageBox)
