import axios from 'axios'
import { setImage } from '../school'
import storage from '../../lib/storage'
import prefix from '../prefix'

const imgurRequest = async ({ url, data, params, method, headers }) => {
  const apiURL = `${prefix}/api/secrets/imgur`

  let { data: authToken } = await axios.get(apiURL, {
    headers: {
      'X-Access-Token': storage.get('token'),
      'client-version': 'web',
      Accept: 'application/json',
    },
  })

  return axios({
    method,
    url,
    data,
    headers: {
      ...headers,
      authorization: 'Client-ID 5d664ee4a138095', //App Auth (Only has permissions to upload files anon)
      'x-rapidapi-key': authToken,
      'x-rapidapi-host': 'imgur-apiv3.p.rapidapi.com',
    },
    params,
  })
}

export const uploadImage = async ({ image, _id }) => {
  let data = new FormData()
  data.append('image', image, image.name)

  return imgurRequest({
    url: 'https://imgur-apiv3.p.rapidapi.com/3/image',
    data,
    method: 'post',
  })
    .then(res => {
      setImage(_id, res.data.data.link)
      return res.data.data.link
    })
    .catch(error => {
      console.warn('Failed to post image')
      console.log(error)
    })
}
