import { ActionTypes } from '../actions'

const defaultState = {}

const CreateViewModalReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.SET_MODAL_STATE:
      return Object.assign({}, state, {
        [action.payload.modal]: action.payload.isOpen,
        [action.payload.metadata]: action.payload.metadataValue,
      })
    case ActionTypes.TOGGLE_MODAL_STATE:
      return Object.assign({}, state, {
        [action.payload.modal]: !state[action.payload.modal],
        [action.payload.metadata]: action.payload.metadataValue,
      })
    default:
      return state
  }
}

export default CreateViewModalReducer
