import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import api from '../../../api'

import { saveSurvey } from '../../actions/create-view-survey-actions/save-survey-actions'

import { Popup } from 'semantic-ui-react'

const Nav = ({ survey, saveSurvey, setChangesMade, changesMade, match }) => {
  const id = window.location.href.split('/')[window.location.href.split('/').length - 1]

  return (
    <div className="survey-manager-header">
      <div className="survey-manager-container-left" style={{ display: 'flex' }}>
        <img
          onClick={() => (window.location.href = '/')}
          style={{ marginRight: '2rem' }}
          src="/img/logo.png"
          className="logo-button"
          alt="Logo"
        />
        {!window.location.href.includes('fielding') && (
          <Popup
            content="Save the entire survey object"
            trigger={
              <button
                onClick={() => {
                  saveSurvey(survey)
                  setChangesMade(false)
                }}
                style={{ marginRight: '1rem' }}
                className={`survey-manager-icon-button ${changesMade ? 'changes' : ''}`}
              >
                <img src="/img/survey-save.svg" className="survey-manager-icon" />
              </button>
            }
          />
        )}
        <Popup
          content="Preview Survey (Gives you a preview URL)"
          trigger={
            <button
              onClick={() =>
                window.prompt(
                  'View the survey at:',
                  `https://app.collegepulse.com/external-survey/${survey._id}?skipDems=true`
                )
              }
              className="survey-manager-icon-button"
              style={{ marginRight: '1rem' }}
            >
              <img src="/img/survey-eye.svg" className="survey-manager-icon" />
            </button>
          }
        />

        <Popup
          content="Survey PDF Link"
          trigger={
            <button
              className="survey-manager-icon-button"
              onClick={() => window.open(api.admin.downloadSurveyPDFLink(id), '_blank')}
            >
              <img src="/img/file-icon.svg" className="survey-manager-icon" />
            </button>
          }
        />
      </div>
      <div className="survey-manager-container-right" style={{ display: 'flex' }}>
        <Link
          to={`/create/survey/metadata/${id}`}
          className={`survey-manager-button-link ${
            window.location.href.includes('metadata') ? 'survey-manager-active' : ''
          }`}
          style={{ marginRight: '1rem' }}
        >
          Metadata
        </Link>

        <Link
          to={`/create/survey/programming/${id}`}
          className={`survey-manager-button-link ${
            location.pathname.includes('programming') ? 'survey-manager-active' : ''
          }`}
          style={{ marginRight: '1rem' }}
        >
          Programming
        </Link>

        <Link
          to={`/create/survey/fielding/${id}`}
          className={`survey-manager-button-link ${
            location.pathname.includes('fielding') ? 'survey-manager-active' : ''
          }`}
          style={{ marginRight: '1rem' }}
        >
          Fielding
        </Link>
        <div className="survey-manager-divider" style={{ width: 2, marginRight: '1rem' }} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: '-0.5rem',
            marginBottom: '-0.5rem',
          }}
        >
          <Link to={`/create/survey/tracking/${id}`}>
            <button
              className={`survey-manager-button-link ${
                location.pathname.includes('tracking') ? 'survey-manager-active' : ''
              }`}
              style={{ marginBottom: '0.5rem' }}
            >
              Track Status
            </button>
          </Link>
          <button
            onClick={() =>
              window.open(`https://internal-insights.collegepulse.com/vault/survey/${id}`, '_blank')
            }
            style={{ marginTottom: '0.5rem' }}
          >
            Insights
          </button>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
})

export default connect(mapStateToProps, {
  saveSurvey,
})(Nav)
