import prefix from './prefix'
import request from './request'
import storage from '../lib/storage'

/* -----------------------------------------------------------------------------
  Routes
*/

exports.all = function (sortby, category) {
  category = category ? encodeURIComponent(category) : ''
  const categoryQuery = category ? `&category=${category}` : ''
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/prize/all' + `?sortby=${sortby}` + categoryQuery).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.categories = function () {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/prize/category`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)
      const categories = res.body
      const categoryStrings =
        typeof categories[0] === 'string' ? categories : categories.map(c => c.category)
      return resolve(categoryStrings)
    })
  })
}

exports.claims = function (sortby) {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/prize/claims' + `?sortby=${sortby}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.usedClaims = function (sortby) {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/prize/used' + `?sortby=${sortby}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.markUsed = function (prizeId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/prize/markused/${prizeId}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      if (res.body.issue) return resolve({ issue: res.body.issue })

      return resolve()
    })
  })
}

exports.claim = function (prizeId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/prize/claim/${prizeId}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      if (res.body.issue) return resolve({ issue: res.body.issue })

      storage.set('user', res.body.user)
      return resolve({ user: res.body.user, promoCode: res.body.promoCode })
    })
  })
}

exports.redeem = function (prizeId, vendorCode) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + `/api/prize/redeem/${prizeId}`)
      .send({ vendorCode: vendorCode })
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.bookmarkAdd = function (prizeId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/prize/bookmarks/${prizeId}/add`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.bookmarkRemove = function (prizeId) {
  return new Promise((resolve, reject) => {
    request.post(prefix + `/api/prize/bookmarks/${prizeId}/remove`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

/* -----------------------------------------------------------------------------
  Analytics 
*/

exports.trackClick = function (prizeId) {
  return prizeEvent('click', prizeId)
}

exports.trackSiteVisit = function (prizeId) {
  return prizeEvent('site-visit', prizeId)
}

exports.trackImpression = function (prizeId) {
  return prizeEvent('impression', prizeId)
}

/* -----------------------------------------------------------------------------
  Helpers
*/

function prizeEvent(pEventExtension, prizeId) {
  return new Promise(resolve => {
    request.post(prefix + `/api/prize/event/${pEventExtension}/${prizeId}`).end((err, res) => {
      return resolve(res.body)
    })
  })
}
