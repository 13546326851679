import React from 'react'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import { Tab, Button } from 'semantic-ui-react'
import QuestionGrouping from './QuestionGrouping'
import QuestionMultiversionGrouping from './QuestionMultiversionGrouping'
import QuestionFiltering from './QuestionFiltering'
import OptionFiltering from './OptionFiltering'
import isMongooseId from '../../../../../lib/isMongooseId'
import modalStyle from '../modalStyle'

const panes = [
  {
    menuItem: 'Question Order Shuffling',
    render: () => (
      <Tab.Pane>
        <QuestionGrouping />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Question Randomization Group',
    render: () => (
      <Tab.Pane>
        <QuestionMultiversionGrouping />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Question Filtering',
    render: () => (
      <Tab.Pane style={{ height: '100%' }}>
        <QuestionFiltering />
      </Tab.Pane>
    ),
  },
  {
    menuItem: 'Options Dependencies',
    render: () => (
      <Tab.Pane>
        <OptionFiltering />
      </Tab.Pane>
    ),
  },
]

class LogicModal extends React.Component {
  render() {
    const unsavedQuestions =
      this.props.questions.filter(q => !q._id || !isMongooseId(q._id)).length > 0
    return (
      <Modal isOpen={this.props.isOpen} style={modalStyle} contentLabel="Close And Save Modal">
        <Button onClick={this.props.toggle} style={{ alignSelf: 'flex-end' }}>
          Done
        </Button>
        {unsavedQuestions && <h1>Please save all changes before configuring logic</h1>}
        {!unsavedQuestions && (
          <div
            style={{ width: '100%', height: '100%', padding: 30 }}
            className="logicModalContainer"
          >
            <Tab
              menu={{
                fluid: true,
                vertical: true,
                tabular: true,
                inverted: true,
              }}
              panes={panes}
            />
          </div>
        )}
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  questions: state.activeSurvey.questions,
})

export default connect(mapStateToProps, {})(LogicModal)
