import React, { useEffect, useState, useCallback } from 'react'
import api from '../../../../../api'
import './AmbassadorOutreachView.styl'
import _ from 'lodash'
import Select from 'react-select'
import Promise from 'bluebird'

// UI Components
import {
  Card,
  Button,
  Input,
  Divider,
  Label,
  Icon,
  Dropdown,
  Loader,
  Segment,
  Dimmer,
  Image,
} from 'semantic-ui-react'

// TODO (Before PR):
// - Update the template key values to match PROD once, currently working with DEV

// TODO (For v2):
// - In v2 we should add custom templates. But for now we'll just use the default
//  templates that we will create. We will also need to add a way to add custom templates.
// - When selecting ambassadors, allow the selection of a school, then for each school,
//   we should be abale to select ambassadors etc.

const selectSchoolsStyles = {
  container: (baseStyle, state) => ({
    ...baseStyle,
    width: '100%',
    marginRight: '0.5rem',
  }),
}

const isProd = process.env.NODE_ENV === 'production'
const emailTemplateOptions = [
  { value: `${isProd ? '30780760' : '30726821'}`, label: '[NEW]' },
  { value: `${isProd ? '30779511' : '30726827'}`, label: '[REMINDER]' },
]

const AmbassadorOutreachView = ({ _id }) => {
  const [activeInstitutions, setActiveInstitutions] = useState(null)
  const [selectedInstitutions, setSelectedInstitutions] = useState([])
  const [template, setTemplate] = useState('')
  const [allAmbassadors, setAllAmbassadors] = useState(null)
  const [error, setError] = useState(null)
  const [surveyTopic, setSurveyTopic] = useState('')
  const [subject, setSubject] = useState('')
  //   const [ambassadorOptions, setAmbassadorOptions] = useState(null)

  // Load up schools that are active
  useEffect(() => {
    loadActiveInstitutions({ _id, setActiveInstitutions, setError })
  }, [])

  const loadAmbassadorsCallback = useCallback(
    () =>
      loadAmbassadors({
        surveyId: _id,
        selectedInstitutions,
        setAllAmbassadors,
        setError,
        // setAmbassadorOptions,
      }),
    [selectedInstitutions, _id]
  )

  const sendEmails = async () => {
    await api.ambassador.sendEmails({
      ambassadorIds: allAmbassadors.map(a => a._id),
      templateId: template,
      surveyId: _id,
      subject: subject,
      surveyTopic: surveyTopic,
    })
  }

  return (
    <div className="ambassador-outreach-view">
      <Card fluid>
        <Card.Content>
          <Card.Header>Email Template</Card.Header>
          <Card.Description>
            <Select
              options={emailTemplateOptions}
              isSearchable={true}
              onChange={obj => {
                setTemplate(obj.value)
              }}
              placeholder="Select Email Template"
              styles={selectSchoolsStyles}
            />
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Institutions</Card.Header>
          <Card.Description>
            <p>To get ambassadors from ALL institutions, leave this field blank.</p>
            {activeInstitutions ? (
              <div className="institutions-container">
                <div className="ole-male">
                  <Select
                    options={activeInstitutions}
                    isMulti
                    isSearchable={true}
                    onChange={options => {
                      setSelectedInstitutions(options)
                    }}
                    placeholder="Select Institutions"
                    styles={selectSchoolsStyles}
                  />
                  <Button onClick={() => loadAmbassadorsCallback()} style={{ minWidth: '8rem' }}>
                    Get&nbsp;&nbsp;&nbsp;
                    <Icon name="users" />
                  </Button>
                </div>
              </div>
            ) : (
              <Segment>
                <Dimmer active inverted>
                  <Loader size="small">Loading Institutions</Loader>
                </Dimmer>

                <Image src="https://react.semantic-ui.com/images/wireframe/short-paragraph.png" />
              </Segment>
            )}
          </Card.Description>
        </Card.Content>
      </Card>
      <Card fluid>
        <Card.Content>
          <Card.Header>Send To Ambassadors</Card.Header>
          <Card.Description>
            {allAmbassadors ? (
              <div className="send-details-box">
                <p>
                  There are <b>{allAmbassadors?.length}</b> ambassadors from the selected
                  institutions.
                </p>
                <Input
                  fluid
                  label="Survey Topic"
                  onChange={e => setSurveyTopic(e.target.value)}
                  value={surveyTopic}
                />
                <Input
                  fluid
                  label=" Subject Line "
                  onChange={e => setSubject(e.target.value)}
                  value={subject}
                />
                <Button
                  onClick={() => sendEmails()}
                  disabled={!subject || !surveyTopic || !template || !_id}
                >
                  Send <Icon name="send" />
                </Button>
              </div>
            ) : null}
          </Card.Description>
        </Card.Content>
      </Card>
      {error ? <p style={{ color: 'red' }}>There was an ERROR: {error}</p> : null}
    </div>
  )
}

const loadActiveInstitutions = async ({ _id, setActiveInstitutions, setError }) => {
  try {
    const activeInstitutions = await api.ambassador.getActiveInstitutions(_id)

    // let institutionsWithNames = await api.demographics.schoolNamesForIds(activeInstitutions)

    let institutionChunks = _.chunk(activeInstitutions, 10)
    let institutionsWithNames = await Promise.reduce(
      institutionChunks,
      async (acc, chunk) => {
        let names = await api.demographics.schoolNamesForIds(chunk)
        return acc.concat(names)
      },
      []
    )

    institutionsWithNames = institutionsWithNames.map(institution => ({
      value: institution._id,
      label: institution.name,
    }))

    setActiveInstitutions(institutionsWithNames)
  } catch (err) {
    setError(err.message)
    console.error(err.message)
  }
}

const loadAmbassadors = async ({
  surveyId,
  selectedInstitutions,
  setAllAmbassadors,
  setError,
  //   setAmbassadorOptions,
}) => {
  try {
    selectedInstitutions = selectedInstitutions?.map(obj => obj.value) || []
    const allAmbassadors = await api.ambassador.getAmbassadors(surveyId, selectedInstitutions)

    setAllAmbassadors(allAmbassadors)
    // setAmbassadorOptions(allAmbassadors)
  } catch (err) {
    setError(err.message)
    console.error(err.message)
  }
}

export default AmbassadorOutreachView
