import React from 'react'
import { IconContext } from 'react-icons'
import { IoIosClose } from 'react-icons/io'
import { Checkbox, Button } from 'semantic-ui-react'
import AsyncSelect from 'react-select/lib/Async'
import { searchDemographics, schoolNamesForIds } from '../../../../../../../api/demographics'
import { enabled as SelectEnabledStyle } from '../../../../../../../marketing/styles/Select'

const searchStyle = SelectEnabledStyle({
  width: 300,
  marginRight: 10,
})

export default class CheckboxContainer extends React.Component {
  constructor() {
    super()
    this.state = {
      textInputSchoolIds: '',
    }
    this.onRemove = this.onRemove.bind(this)
    this.getDemographicSearchResults = this.getDemographicSearchResults.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.getSchoolNamesByIdAndSet = this.getSchoolNamesByIdAndSet.bind(this)
  }

  onRemove() {
    this.props.removeDemographic(this.props.demographic)
  }

  getDemographicSearchResults(input, callback) {
    searchDemographics({ category: this.props.demographic, searchTerm: input }).then(
      demographics => {
        let options = demographics.map(demographic => {
          return { value: demographic, label: demographic }
        })

        callback(options)
      }
    )
  }

  onInputChange(selected) {
    this.props.setDemOptionsForCategory({
      category: this.props.demographic,
      options: selected.map(s => s.value),
    })
  }

  async getSchoolNamesByIdAndSet() {
    const processedIds = this.state.textInputSchoolIds
      .split('\n')
      .map(id => id.trim().split(',').join(''))

    const schoolNames = await schoolNamesForIds(processedIds)

    const { selected = [], demographic, setDemOptionsForCategory } = this.props
    setDemOptionsForCategory({
      category: demographic,
      options: [...selected, ...schoolNames.map(s => s.name)],
    })

    this.setState({ textInputSchoolIds: '' })
  }

  renderSchoolIdLookupContainer() {
    return (
      <div className="schoolLookupContainer">
        <p>
          Enter schools by <strong>Internal Id</strong> or <strong>IPEDS Unit Id</strong>? Separate
          ids by newline. Comma at end of each line is optional—will work with our without it. Don't
          forget to press Update above to save changes.
        </p>
        <textarea
          placeholder={'353700\n341800\n178500\n711300\n195300\n195200\n111700'}
          value={this.state.textInputSchoolIds}
          onChange={ev => this.setState({ textInputSchoolIds: ev.target.value })}
        />
        <br />
        <Button onClick={this.getSchoolNamesByIdAndSet}>Add Schools to Targeting By Id</Button>
      </div>
    )
  }

  render() {
    const { demographic, options, selected = [], toggleDemOption } = this.props
    return (
      <div className="individualDemContainer">
        <IconContext.Provider value={{ color: 'white', size: '3em' }}>
          <IoIosClose title="Close" onClick={this.onRemove} className="closeButton" />
        </IconContext.Provider>
        <div className="topRow">
          <h2>{demographic}</h2>
        </div>
        <AsyncSelect
          name="form-field-name"
          loadOptions={this.getDemographicSearchResults}
          value={selected.map(option => ({ value: option, label: option }))}
          placeholder="All Variables"
          autosize={false}
          clearable
          isMulti
          defaultOptions
          onChange={this.onInputChange}
          onSelectResetsInput
          styles={searchStyle}
        />
        {demographic === 'school' && this.renderSchoolIdLookupContainer()}
      </div>
    )
  }
}
