import request from 'request-promise'

exports.pressPage = function () {
  var options = {
    method: 'GET',
    uri: 'https://collegepulse.com/wp-json/wp/v2/pages/2035',
    json: true,
  }
  return request(options)
}
