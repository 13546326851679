import React from 'react'
import Modal from 'react-modal'

const modalStyle = {
  content: { top: '20%', bottom: '20%', left: '20%', right: '20%' },
  overlay: { backgroundColor: 'rgba(0,0,0,.4)' },
}

export default ({ saveError }) => {
  return (
    <Modal isOpen={saveError} style={modalStyle} contentLabel="Refresh the page">
      <h2>The survey has been edited somewhere else, please refresh.</h2>
      <p>
        Your changes up until one minute ago or less have been saved. Anything done in the last
        minute that was unsaved may need to be redone. We apologize.
      </p>
    </Modal>
  )
}
