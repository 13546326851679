import React from 'react'
import { Card, Select, Form, Button, List, Divider, Segment } from 'semantic-ui-react'
import _ from 'lodash'

// Logic for adding a filter:
// Maybe we render a Does and Does Not row for every option, and then just set it
// in the config if/when they set actual questions
export default class QuestionFiltering extends React.Component {
  constructor() {
    super()
    this.state = {
      pull: {},
      push: {},
    }
  }

  addQuestion({ controllingOption, isPull, questionToFilter }) {
    // TODO: the push pull stuff for options needs to go onto the options themselves
    // in the toFilterActions property on the option

    // if isPull, append questionToFilter to the pull array and to the top level toFilter array
    // if !isPull, append questionToFilter to the push array
    const { question } = this.props
    const { toFilterConfig, options } = question || {}
    const { toFilter = [] } = toFilterConfig

    const newOptions = options.map(option => {
      if (option.value !== controllingOption) {
        return { ...option }
      }

      return {
        ...option,
        toFilterActions: {
          ...(option.toFilterActions || {}),
          ...(isPull
            ? {
                pull: [...(option.toFilterActions || { pull: [] }).pull, questionToFilter],
              }
            : {
                push: [...(option.toFilterActions || { push: [] }).push, questionToFilter],
              }),
        },
      }
    })

    const newFilterConfig = {
      ...toFilterConfig,
      hasFilterOpts: true,
      toFilter: isPull ? [...toFilter, questionToFilter] : toFilter,
    }

    this.props.setFilterConfig({
      _id: this.props.question._id,
      toFilterConfig: newFilterConfig,
      options: newOptions,
    })
    this.setState({ [isPull ? 'pull' : 'push']: { [controllingOption]: null } })
  }

  removeQuestion({ controllingOption, isPull, questionToRemoveFromFilter }) {
    const { question } = this.props
    const { toFilterConfig, options } = question || {}
    let { toFilter = [] } = toFilterConfig

    const newOptions = options.map(option => {
      if (option.value !== controllingOption) {
        return { ...option }
      }

      return {
        ...option,
        toFilterActions: {
          ...(option.toFilterActions || {}),
          ...(isPull
            ? {
                pull: (option.toFilterActions || { pull: [] }).pull.filter(
                  q => q !== questionToRemoveFromFilter
                ),
              }
            : {
                push: (option.toFilterActions || { push: [] }).push.filter(
                  q => q !== questionToRemoveFromFilter
                ),
              }),
        },
      }
    })
    if (isPull) {
      _.pullAt(toFilter, [toFilter.indexOf(questionToRemoveFromFilter)])
    }

    const newFilterConfig = {
      ...toFilterConfig,
      hasFilterOpts: true,
      toFilter: toFilter,
    }

    this.props.setFilterConfig({
      _id: this.props.question._id,
      toFilterConfig: newFilterConfig,
      options: newOptions,
    })
  }

  render() {
    const { question, questionsAfter, index } = this.props

    const _idsToDescriptions = questionsAfter.reduce(
      (acc, q, i) => ({
        ...acc,
        [q._id]: `Q${i + 1 + index + 1}: ${q.description}`,
      }),
      {}
    )
    const rows = question.options.reduce((allRows, { value: optValue, toFilterActions }, i) => {
      const { pull = [], push = [] } = toFilterActions || {}
      const actionArrays = { pull, push }
      return [
        ...allRows,
        <Segment tertiary={i % 2 === 0}>
          <Card.Header as="h3">{optValue}</Card.Header>

          {['push', 'pull'].map(action => (
            <Card className="filter-card" fluid>
              <Card.Content>
                <Card.Description>
                  If the user{' '}
                  {action === 'push' ? <strong>selects</strong> : <strong>does not select</strong>}
                  <strong> '{optValue}'</strong>, do not show questions:
                  <List celled verticalAlign="middle">
                    {actionArrays[action].map(qId => (
                      <List.Item style={{ display: 'flex', alignItems: 'center' }}>
                        <div
                          style={{
                            flex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          {_idsToDescriptions[qId]}
                        </div>
                        <Button
                          onClick={() =>
                            this.removeQuestion({
                              controllingOption: optValue,
                              isPull: action === 'pull',
                              questionToRemoveFromFilter: qId,
                            })
                          }
                        >
                          X
                        </Button>
                      </List.Item>
                    ))}
                  </List>
                </Card.Description>
              </Card.Content>
              <Card.Content extra>
                <Form>
                  <Form.Group>
                    <label>
                      <h4 style={{ marginTop: '0.7rem', marginRight: '1rem', marginLeft: '1rem' }}>
                        Select a question to hide:
                      </h4>
                    </label>
                    <Select
                      placeholder="Select Question"
                      options={questionsAfter.slice().map((q, i) => ({
                        key: q._id,
                        value: q._id,
                        text: `Q${i + 1 + index + 1}: ${q.description}`,
                      }))}
                      onChange={(_, ev) => {
                        this.setState({ [action]: { ...this.state[action], [optValue]: ev.value } })

                        this.addQuestion({
                          controllingOption: optValue,
                          isPull: action === 'pull',
                          questionToFilter: ev.value,
                        })
                      }}
                      value={''}
                    />
                  </Form.Group>
                </Form>
              </Card.Content>
            </Card>
          ))}
        </Segment>,
        <Divider />,
      ]
    }, [])

    return <React.Fragment>{rows}</React.Fragment>

    // const existingRows = Object.entries(options).map(([opt, { pull = [], push = [] }]) => {
    //   const rows = []
    //   if (pull.length) {
    //     rows.push(
    //       <div>
    //         If user <strong>does not</strong> select {opt}, filter questions {pull}
    //       </div>
    //     )
    //   }
    //   if (push.length) {
    //     rows.push(
    //       <div>
    //         If user <strong>does</strong> select {opt}, filter questions {push}
    //       </div>
    //     )
    //   }
    //   return rows
    // })
    //
    // return _.flatten(existingRows)
  }
}
