import React from 'react'
import Hammer from 'react-hammerjs'
import Select from 'react-select'
import _, { upperFirst } from 'lodash'
import { Checkbox } from 'semantic-ui-react'
import { enabled as SelectEnabledStyle } from '../../../../../../../../marketing/styles/Select'
import TextareaAutosize from 'react-autosize-textarea'

import InputBox from '../../../../../../../../common/input-box/input-box'

const logicSelectStyle = SelectEnabledStyle({
  width: 200,
})

export function mapQuestionsToSelect(questions, selectionId, currentQuestionIndex) {
  let messageCount = 0
  let selectedOpt = null
  const options = questions
    .map((q, i) => {
      if (q.type === 'message') {
        messageCount++
      }
      let qNumber = i + 1 - messageCount + (currentQuestionIndex + 1)
      let qTitle = q.type === 'message' ? 'M' : 'Q' + qNumber
      const opt = { value: q.id, label: `${qTitle}: ${q.description}` }
      if (q.id === selectionId) {
        selectedOpt = opt
      }
      return opt
    })
    .concat(
      { value: 'end', label: 'End of Survey' },
      { value: 'absolute-end', label: 'Absolute End of Survey' }
    )
  if (!selectedOpt && selectionId === 'end') {
    selectedOpt = { value: 'end', label: 'End of Survey' }
  }
  if (!selectedOpt && selectionId === 'absolute-end') {
    selectedOpt = { value: 'absolute-end', label: 'Absolute End of Survey' }
  }
  return { options, selectedOpt }
}

export default class QuestionOption extends React.Component {
  constructor() {
    super()

    const boundMethods =
      'onOptionAdd onOptionDelete onOptionChange onOptionWeightChange markOptionAsCorrect onPaste setOptionSkipTo toggleExclusive toggleFixedOption onBlur'
    boundMethods.split(' ').forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  onOptionChange(ev) {
    this.props.onOptionChange(this.props.index, ev, this.props.isWriteIn)
  }

  onOptionWeightChange(ev) {
    this.props.onOptionWeightChange(this.props.index, ev)
  }

  setOptionSkipTo(val) {
    if (val) {
      this.props.setOptionSkipTo(this.props.index, val.value)
    } else {
      this.props.setOptionSkipTo(this.props.index, null)
    }
  }

  toggleExclusive() {
    this.props.setOptionProperty(
      this.props.index,
      'exclusiveOnMultiSelect',
      !this.props.opt.exclusiveOnMultiSelect
    )
  }

  onOptionAdd() {
    this.props.onOptionAdd(this.props.index)
  }

  markOptionAsCorrect() {
    this.props.markOptionAsCorrect(this.props.opt.value)
  }

  toggleFixedOption() {
    this.props.toggleFixedOption(this.props.currentQuestionIndex, this.props.opt.value)
  }

  onOptionDelete() {
    this.props.onOptionDelete(this.props.index)
  }

  onBlur() {
    const trimmed = this.props.opt.text.trim()
    this.onOptionChange({ target: { value: trimmed } })
  }

  onPaste(e) {
    var clipboardData, pastedData

    // Get pasted data via clipboard API
    clipboardData = e.clipboardData || window.clipboardData
    pastedData = clipboardData.getData('Text')

    // Do whatever with pasteddata

    const lines = pastedData.split('\n')
    if (lines.length > 1) {
      this.props.onOptionsPaste(this.props.index, lines)
      // Stop data actually being pasted into div
      e.stopPropagation()
      e.preventDefault()
    }
  }

  render() {
    const props = this.props

    let addOptionAfterButton,
      deleteOptionButton,
      markCorrectButton,
      nextQuestionDropdown,
      markFixedPositionButton

    if (!props.viewOnly && props.allowAdd) {
      addOptionAfterButton = (
        <Hammer onTap={this.onOptionAdd}>
          <div className="option-add-button">
            {!props.viewOnly && props.allowAdd ? <img src="/img/blueCirclePlus.svg" /> : <span />}
          </div>
        </Hammer>
      )
    }

    if (!props.viewOnly && props.allowDelete) {
      deleteOptionButton = (
        <Hammer onTap={this.onOptionDelete}>
          <div className="option-delete-button">
            {!props.viewOnly && props.allowDelete ? (
              <img src="/img/blueCircleMinus.svg" />
            ) : (
              <span />
            )}
          </div>
        </Hammer>
      )
    }

    if (props.optionOrderSettings && props.optionOrderSettings.randomizeOptions) {
      markFixedPositionButton = (
        <Hammer onTap={this.toggleFixedOption}>
          <div
            title="Pin this option. Its order will not be randomized"
            className="option-delete-button notSVG"
          >
            {props.optionOrderSettings &&
            props.optionOrderSettings.fixedOptions.includes(props.opt.value) ? (
              <img src="/img/pin-green.png" />
            ) : (
              <img src="/img/pin-blue.png" />
            )}
          </div>
        </Hammer>
      )
    }
    if (props.quizSettings && props.quizSettings.isQuiz) {
      markCorrectButton = (
        <Hammer onTap={this.markOptionAsCorrect}>
          <div className="option-delete-button">
            {props.quizSettings && props.quizSettings.correctAnswer === props.opt.value ? (
              <img src="/img/check-green.svg" />
            ) : (
              <img src="/img/check-grey.svg" />
            )}
          </div>
        </Hammer>
      )
    }

    if (props.logicQuestion) {
      const selectConfig = mapQuestionsToSelect(
        this.props.questions,
        props.opt.skipTo,
        props.currentQuestionIndex
      )

      nextQuestionDropdown = (
        <Select
          options={selectConfig.options}
          onChange={this.setOptionSkipTo}
          value={selectConfig.selectedOpt}
          placeholder="No Logic"
          styles={logicSelectStyle}
          isClearable
        />
      )
    }

    return (
      <div className="option" key={props.index} data={props.index}>
        {!!props.isWriteIn && <div className="tooltip">Write-in</div>}

        <InputBox
          selected={false}
          key={props.index}
          name="creating-question"
          color="questionHeaderColor"
        />
        <div className="option-text">
          <div className="option-text-container">
            <TextareaAutosize
              onKeyPress={e => {
                if (e.key === 'Enter') e.preventDefault()
              }}
              id={props.index}
              type="text"
              placeholder="Enter Option..."
              value={props.opt.text}
              onChange={this.onOptionChange}
              onPaste={this.onPaste}
              onBlur={this.onBlur}
              readOnly={props.viewOnly || !props.allowEdit}
            />
          </div>
        </div>

        {nextQuestionDropdown}
        {addOptionAfterButton}
        {deleteOptionButton}
        {markCorrectButton}
        {markFixedPositionButton}
        {props.isMultiSelect && (
          <Checkbox
            label="Exclusive"
            onChange={this.toggleExclusive}
            checked={props.opt.exclusiveOnMultiSelect}
          />
        )}
      </div>
    )
  }
}
