import api from '../../../api'

export const ActionTypes = {
  SET_DEMOGRAPHICS_OPTIONS: 'SET_DEMOGRAPHICS_OPTIONS',
  SET_TARGETING_MECHANISM: 'SET_TARGETING_MECHANISM',
  TOGGLE_ALL_SCHOOLS: 'TOGGLE_ALL_SCHOOLS',
  INITIALIZE_TEMP_PRICE_BY_DEMOGRAPHICS: 'INITIALIZE_TEMP_PRICE_BY_DEMOGRAPHICS',
  INITIALIZE_TEMP_SPECIFIC_INDIVIDUALS: 'INITIALIZE_TEMP_SPECIFIC_INDIVIDUALS',
  INITIALIZE_TEMP_TARGETED_DEM: 'INITIALIZE_TEMP_TARGETED_DEM',
  SET_TEMP_TARGETED_DEM_CATEGORY: 'SET_TEMP_TARGETED_DEM_CATEGORY',
  SET_PRICE_BY_OPTIONS: 'SET_PRICE_BY_OPTIONS',
  SET_SPECIFIC_SELECT_USERS: 'SET_SPECIFIC_SELECT_USERS',
  SET_SPECIFIC_SELECT_GROUPS: 'SET_SPECIFIC_SELECT_GROUPS',
  SET_SPECIFIC_FREE_INPUT_USERS: 'SET_SPECIFIC_FREE_INPUT_USERS',

  SET_TARGETED_INDIVIDUALS: 'SET_TARGETED_INDIVIDUALS',
  SET_TARGETED_DEMOGRAPHICS_FOR_CATEGORY: 'SET_TARGETED_DEMOGRAPHICS_FOR_CATEGORY',
  ADD_TOP_LEVEL_CATEGORY_TO_FACETED: 'ADD_TOP_LEVEL_CATEGORY_TO_FACETED',
  REMOVE_TOP_LEVEL_CATEGORY_FROM_FACETED: 'REMOVE_TOP_LEVEL_CATEGORY_FROM_FACETED',
  TOGGLE_DEM_OPTION_IN_FACETED: 'TOGGLE_DEM_OPTION_IN_FACETED',
  TOGGLE_DEM_OPTION_IN_INVERSE_FACETED: 'TOGGLE_DEM_OPTION_IN_INVERSE_FACETED',
  SET_DEM_OPTIONS_FOR_CATEGORY: 'SET_DEM_OPTIONS_FOR_CATEGORY',
}

export function fetchDemographicsOptions() {
  return dispatch => {
    api.demographics.getAll().then(config => {
      dispatch({
        type: ActionTypes.SET_DEMOGRAPHICS_OPTIONS,
        payload: {
          demOptions: config,
        },
      })
    })
  }
}

export function toggleAllSchools() {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_ALL_SCHOOLS,
    })
  }
}

export function setTargetingMechanism(category) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_TARGETING_MECHANISM,
      payload: category,
    })
  }
}
// export function initializeTempTargetedDemographics(demOptions, survey) {
//   return (dispatch) => {
//     let targetedDemographics = {}
//     for (var demOption in demOptions) {
//       try {
//         targetedDemographics[demOption] = survey.targetedDemographics[demOption].map(function(option) {
//           return {
//             value: option,
//             label: option
//           }
//         })
//       } catch (e) {
//         targetedDemographics[demOption] = []
//       }
//     }
//     dispatch({
//       type: ActionTypes.INITIALIZE_TEMP_TARGETED_DEM,
//       payload: {
//         targetedDemographics
//       }
//     })
//   }
// }

export function setTargetedDemographicsCategory({ category, value }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_TARGETED_DEMOGRAPHICS_FOR_CATEGORY,
      payload: {
        category,
        value,
      },
    })
  }
}

export function addTopLevelCategoryToFaceted(dem) {
  return dispatch => {
    dispatch({
      type: ActionTypes.ADD_TOP_LEVEL_CATEGORY_TO_FACETED,
      payload: dem,
    })
  }
}

export function removeTopLevelCategoryFromFaceted(dem) {
  return dispatch => {
    dispatch({
      type: ActionTypes.REMOVE_TOP_LEVEL_CATEGORY_FROM_FACETED,
      payload: dem,
    })
  }
}

export function toggleDemOptionInFaceted({ category, option, explicitOnOrOff = null }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_DEM_OPTION_IN_FACETED,
      payload: { category, option, explicitOnOrOff },
    })
  }
}

export function toggleDemOptionsInInverseFaceted({ options, explicitOnOrOff = null }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.TOGGLE_DEM_OPTION_IN_INVERSE_FACETED,
      payload: { options, explicitOnOrOff },
    })
  }
}

export function setDemOptionsForCategory({ category, options }) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_DEM_OPTIONS_FOR_CATEGORY,
      payload: { category, options },
    })
  }
}

export function initializePriceByDemographics(demGroupSelectValues, survey) {
  return dispatch => {
    let selectedPriceByOptions = !survey.priceBy
      ? []
      : survey.priceBy.map(option => demGroupSelectValues[option])

    dispatch({
      type: ActionTypes.INITIALIZE_TEMP_PRICE_BY_DEMOGRAPHICS,
      payload: {
        selectedPriceByOptions,
      },
    })
  }
}

export function setPriceByOptions(newPriceByValue) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_PRICE_BY_OPTIONS,
      payload: {
        selectedPriceByOptions: newPriceByValue,
      },
    })
  }
}

// export function initializeTempSpecificIndividuals(survey) {
//   return (dispatch) => {
//     let targetedIndividualsStr = survey.targetedIndividuals ? survey.targetedIndividuals.join(', ') : ''
//     dispatch({
//       type: ActionTypes.INITIALIZE_TEMP_SPECIFIC_INDIVIDUALS,
//       payload: {
//         specificSelectUsers: [],
//         specificSelectGroups: [],
//         specificFreeInputUsers: targetedIndividualsStr,
//       }
//     })
//   }
// }

export function setTargetedIndividuals(newEmails) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_TARGETED_INDIVIDUALS,
      payload: newEmails,
    })
  }
}

export function setSpecificFreeInputUsers(newInput) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_SPECIFIC_FREE_INPUT_USERS,
      payload: newInput,
    })
  }
}

export function setSpecificSelectGroups(newGroups) {
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_SPECIFIC_SELECT_GROUPS,
      payload: newGroups,
    })
  }
}
