import request from './request'
import prefix from './prefix'

// Get an imageUrl from a school by ID
exports.getImage = function (_id) {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/admin-tooling/institutionImage/${_id}`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

// Set an imageUrl from a school by ID
exports.setImage = function (_id, url) {
  return new Promise((resolve, reject) => {
    request
      .post(`${prefix}/api/admin-tooling/institutionImage/${_id}`)
      .send({
        imageUrl: url,
      })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}

// Get facebook ID from school
exports.getFBID = function (_id) {
  return new Promise((resolve, reject) => {
    request.get(`${prefix}/api/admin-tooling/institutionFacebookId/${_id}`).end((err, res) => {
      if (err) return reject(err)

      return resolve(res.body)
    })
  })
}

// Set the facebook ID for a school
exports.setFBID = function (_id, FBID) {
  return new Promise((resolve, reject) => {
    request
      .post(`${prefix}/api/admin-tooling/institutionFacebookId/${_id}`)
      .send({
        facebookId: FBID,
      })
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}
