import storage from '../../lib/storage'
import api from '../../api'
import ReactGA from 'react-ga'

export const ActionTypes = {
  RESET_USER: 'RESET_USER',
}

export function signout() {
  return dispatch => {
    api.signin.signout()
    if (window.location.hostname === 'localhost') {
      window.location = 'http://localhost:3000'
    } else {
      window.location = 'https://collegepulse.com/index?clearToken=true'
    }
  }
}

export function resetUser() {
  return dispatch => {
    api.user
      .me()
      .then(user => {
        storage.set('user', user)
        ReactGA.set({ userId: user.id })
        dispatch({
          type: ActionTypes.RESET_USER,
          payload: {
            user,
            loggedIn: api.signin.isSignedIn(),
          },
        })
      })
      .catch(err => {
        console.log(err)
        // dispatch({
        //   type: ActionTypes.SET_LOGIN_STATE,
        //   payload: {
        //     loggedIn: api.signin.isSignedIn()
        //   }
        // })
      })
  }
}
