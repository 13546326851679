import React from 'react'
//import _ from 'lodash'
import { store } from '../../../../../store'

import { connect } from 'react-redux'
import { Form, Radio, Button, Header } from 'semantic-ui-react'
import { fetchSurveyProperty, setSurveyProperty } from '../../../../../../api/admin'

class TargetingContent extends React.Component {
  constructor() {
    super()
    this.state = {
      countingQuestion: '',
      changesMade: false,
    }
    this.fetchProperty = this.fetchProperty.bind(this)
    this.onConfirm = this.onConfirm.bind(this)
  }
  componentWillMount() {
    if (!this.props.survey) {
      return
    }
    this.fetchProperty()
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.survey && nextProps.survey) {
      this.fetchProperty()
    }
  }

  fetchProperty() {
    fetchSurveyProperty({
      _id: this.props.survey._id,
      property: 'countingQuestion',
    }).then(countingQuestion => this.setState({ countingQuestion: countingQuestion }))
  }

  onConfirm() {
    this.setState({ changesMade: false })
    setSurveyProperty({
      _id: this.props.survey._id,
      property: 'countingQuestion',
      value: this.state.countingQuestion,
      store: store,
    }).then(resp => this.setState({ countingQuestion: resp.countingQuestion }))
  }

  render() {
    // Approach:
    // This module will be a standalone one that fetches the property directly from the survey
    // and sets it directly. Instead of updating and saving the monolith survey, we should move
    // towards having individual components that only know about specifically requested parts of
    // the survey.

    return (
      <div className="sidebar-object">
        <Button
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
          color={this.state.changesMade ? 'green' : undefined}
          onClick={this.onConfirm}
        >
          Update
        </Button>
        <Form>
          <Form.Field>
            <Radio
              name="countingQuestion"
              label="None"
              value={null}
              checked={this.state.countingQuestion === null}
              onChange={() => {
                this.setState({ changesMade: true, countingQuestion: null })
              }}
            />
          </Form.Field>
          {this.props.survey.questions.map(question => (
            <Form.Field>
              <Radio
                name="countingQuestion"
                label={question.description}
                value={question._id}
                checked={this.state.countingQuestion === question._id}
                onChange={() => {
                  console.log(question._id)
                  this.setState({
                    changesMade: true,
                  })
                  this.setState({
                    countingQuestion: question._id,
                  })
                }}
              />
            </Form.Field>
          ))}
        </Form>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
  school: ((state.user.user.demographics || {}).school || [])[0] || 'Your School',
})

export default connect(mapStateToProps, {})(TargetingContent)
