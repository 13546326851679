import request from './request'
import prefix from './prefix'

exports.sendIncentiveCode = function (incentiveCode, email) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/incentives/redeem')
      .send({ incentiveCode: incentiveCode, email: email })
      .end((err, res) => {
        if (err) return reject(err)

        resolve(res.body)
      })
  })
}

exports.sendPhoneNumber = function (phoneNumber, email) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/mobile-verification/send')
      .send({ phoneNumber: phoneNumber, email: email })
      .end((err, res) => {
        if (err) return reject(err)

        resolve(res.body)
      })
  })
}

exports.checkVerificationCode = function (phoneNumber, email, code, optInTexts) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/mobile-verification/check')
      .send({ number: phoneNumber, email: email, code: code, optInToSMSNotifs: optInTexts })
      .end((err, res) => {
        if (err) return reject(err)

        resolve(res.body)
      })
  })
}

exports.incentiveRemaining = function (surveyID, institutionID) {
  return new Promise((resolve, reject) => {
    request
      .get(prefix + '/api/admin-tooling/incentives-remaining')
      .query({ surveyId: surveyID, institutionId: institutionID })
      .end((err, res) => {
        if (err) return reject(err)

        resolve(res.body)
      })
  })
}
