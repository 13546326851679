import _ from 'lodash'
import { setCurrentQuestionPermanentStatus } from '../cv-questions-builder-actions'

export const ActionTypes = {
  CHANGE_QUESTION_PROPERTY: 'CHANGE_QUESTION_PROPERTY',
  CHANGE_OPTION_NAME_AND_VALUE: 'CHANGE_OPTION_NAME_AND_VALUE',
  CHANGE_OPTION_NAME_AND_VALUE_AND_APPEND: 'CHANGE_OPTION_NAME_AND_VALUE_AND_APPEND',
  INSERT_QUESTION_OPTION: 'INSERT_QUESTION_OPTION',
  CHANGE_OPTION_WEIGHT: 'CHANGE_OPTION_WEIGHT',
  CHANGE_OPTION_TYPOLOGY_WEIGHTS: 'CHANGE_OPTION_TYPOLOGY_WEIGHTS',
  CHANGE_OPTION_SKIP_TO: 'CHANGE_OPTION_SKIP_TO',
  CHANGE_OPTION_PROPERTY: 'CHANGE_OPTION_PROPERTY',
}

let nextOptionKey = 1

export function insertNewOptionForQuestionAtIndex(params) {
  // deconstruct options object
  let { questionIndex, optionIndex, initialValue } = params

  if (initialValue) {
    initialValue = initialValue.trim()
  }

  return dispatch => {
    let newOption = {
      value: initialValue || 0,
      text: initialValue || '',
    }

    // assign unique key
    newOption.key = nextOptionKey
    nextOptionKey += 1

    dispatch({
      type: ActionTypes.INSERT_QUESTION_OPTION,
      payload: {
        questionIndex,
        optionIndex,
        newOption,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function deleteOptionForQuestionAtIndex(params) {
  // deconstruct options object
  let { questionIndex, optionIndex, currentOptions } = params

  return dispatch => {
    let options = _.cloneDeep(currentOptions)
    options.splice(optionIndex, 1)

    // in case the only option gets deleted, put a new one in
    if (options.length === 0) {
      let newOption = {
        value: 0,
        text: '',
      }
      // assign unique key
      newOption.key = nextOptionKey
      nextOptionKey += 1
      options.push(newOption)
    }

    dispatch({
      type: ActionTypes.CHANGE_QUESTION_PROPERTY,
      payload: {
        index: questionIndex,
        propertyName: 'options',
        value: options,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function editOptionWeight(params) {
  let { questionIndex, optionIndex, newWeight } = params

  return dispatch => {
    // if they edit the very last option, append a new empty one under for fast editing
    dispatch({
      type: ActionTypes.CHANGE_OPTION_WEIGHT,
      payload: {
        questionIndex,
        optionIndex,
        newWeight,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function editOptionTypologyWeights(params) {
  let { questionIndex, optionIndex, typologyWeights } = params

  return dispatch => {
    // if they edit the very last option, append a new empty one under for fast editing
    dispatch({
      type: ActionTypes.CHANGE_OPTION_TYPOLOGY_WEIGHTS,
      payload: {
        questionIndex,
        optionIndex,
        typologyWeights,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function editOptionForQuestionAtIndex(params) {
  let { questionIndex, optionIndex, newValue, currentOptions, keepValueStatic } = params

  return dispatch => {
    // if they edit the very last option, append a new empty one under for fast editing
    if (optionIndex === currentOptions.length - 1) {
      dispatch({
        type: ActionTypes.CHANGE_OPTION_NAME_AND_VALUE_AND_APPEND,
        payload: {
          questionIndex,
          optionIndex,
          keepValueStatic,
          newValue: newValue,
          newOption: {
            key: nextOptionKey++,
            value: 0,
            text: '',
          },
        },
      })
    } else {
      dispatch({
        type: ActionTypes.CHANGE_OPTION_NAME_AND_VALUE,
        payload: {
          questionIndex,
          optionIndex,
          newValue,
          keepValueStatic,
        },
      })
    }
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function setOptionSkipTo(params) {
  let { questionIndex, optionIndex, skipToQId } = params

  return dispatch => {
    // if they edit the very last option, append a new empty one under for fast editing
    dispatch({
      type: ActionTypes.CHANGE_OPTION_SKIP_TO,
      payload: {
        questionIndex,
        optionIndex,
        skipToQId,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}

export function setOptionProperty(params) {
  const { questionIndex, optionIndex, property, value } = params

  return dispatch => {
    // if they edit the very last option, append a new empty one under for fast editing
    dispatch({
      type: ActionTypes.CHANGE_OPTION_PROPERTY,
      payload: {
        questionIndex,
        optionIndex,
        property,
        value,
      },
    })
    dispatch(setCurrentQuestionPermanentStatus(true))
  }
}
