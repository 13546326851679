export const ActionTypes = {
  SET_EDIT_PRIVILEGES: 'SET_EDIT_PRIVILEGES',
}
export function setEditPrivileges(survey, user) {
  return dispatch => {
    const viewOnly = survey.submittedAt && !user.master
    const reviewingForPublish = survey.submittedAt && user.master
    dispatch({
      type: ActionTypes.SET_EDIT_PRIVILEGES,
      payload: {
        viewOnly,
        reviewingForPublish,
      },
    })
  }
}
