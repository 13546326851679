import { combineReducers } from 'redux'

import UserReducer from './user-reducer'
import CreateViewSurveyReducer from './create-view-survey-reducer'
import CreateViewNavigationReducer from './create-view-navigation-reducer'
import CreateViewMetaReducer from './create-view-meta-reducer'
import CreateViewModalReducer from './create-view-modal-reducer'
import CreateViewTempDemographicsReducer from './create-view-temp-demographics-reducer'
import AllSurveysReducer from './all-surveys-reducer'
import CVQuestionsSidebarReducer from './cv-questions-sidebar-reducer'
import CVQuestionsBuilderReducer from './cv-questions-builder-reducer'

const rootReducer = () =>
  combineReducers({
    user: UserReducer,
    activeSurvey: CreateViewSurveyReducer,
    createViewNav: CreateViewNavigationReducer,
    createViewMeta: CreateViewMetaReducer,
    createViewModals: CreateViewModalReducer,
    createViewTempDemographics: CreateViewTempDemographicsReducer,
    allSurveys: AllSurveysReducer,
    cvQuestionsSidebar: CVQuestionsSidebarReducer,
    cvQuestionsBuilder: CVQuestionsBuilderReducer,
  })

export default rootReducer
