import request from './request'
import prefix from './prefix'

exports.surveysForMaster = function (page, searchQuery, filterChecks) {
  return new Promise((resolve, reject) => {
    let myRequest = request
      .get(prefix + '/api/master/survey')
      .query({ page })
      .query({ searchQuery })

    if (filterChecks) {
      let typeArray = Object.keys(filterChecks.type).filter(x => filterChecks.type[x])
      let statusArray = Object.keys(filterChecks.status).filter(x => filterChecks.status[x])

      if (typeArray) {
        typeArray.forEach(t => {
          myRequest.query({ type: t })
        })
      }
      if (statusArray) {
        statusArray.forEach(s => {
          myRequest.query({ status: s })
        })
      }
    }

    myRequest.end((err, res) => {
      if (err) return reject(err)
      return resolve(res.body)
    })
  })
}

exports.createPrize = function (data) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/master/prize/create')
      .send(data)
      .end((err, res) => {
        if (err) return reject(err)

        return resolve(res.body)
      })
  })
}
