export default {
  content: {
    top: '0%',
    bottom: '0%',
    left: '0%',
    right: '0%',
    borderRadius: '0px',
    border: 'none',
    padding: '0 0 0 0',
    backgroundColor: 'rgba(64, 91, 118, .9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //marginTop: '65px',
  },
  overlay: { backgroundColor: 'rgba(0,0,0,.4)' },
}

export const overflowModal = {
  content: {
    top: '0%',
    bottom: '0%',
    left: '0%',
    right: '0%',
    borderRadius: '0px',
    height: 'calc(100vh - 65px)',
    border: 'none',
    padding: '0 0 0 0',
    backgroundColor: 'rgba(64, 91, 118, .9)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    //marginTop: '65px',
  },
  overlay: { backgroundColor: 'rgba(0,0,0,.4)' },
}
