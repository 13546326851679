import request from './request'
import storage from '../lib/storage'
import prefix from './prefix'

exports.validate = function (promoCode) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/promocodes/validate')
      .send({ promoCode })
      .end((err, res) => {
        if (err) return reject(res.body ? res.body.issue : 'Unknown error')

        return resolve({ type: res.body.type })
      })
  })
}
