import moment from 'moment'
import _ from 'lodash'
import api from '../../../api'
import { setCurrentPage } from '../create-view-navigation-actions'
import { setEditPrivileges } from '../create-view-meta-actions'
import { browserHistory } from 'react-router'
import defaultNewSurvey from '../lib/default-survey'
import defaultQuestions from '../lib/default-questions'

defaultNewSurvey.questions.push(defaultQuestions.noType)

export const ActionTypes = {
  SET_ACTIVE_SURVEY: 'SET_ACTIVE_SURVEY',
  CLEAR_ACTIVE_SURVEY: 'CLEAR_ACTIVE_SURVEY',
}

const NUMBER_OF_DEFAULT_IMAGES = 3

export function setNewSurveyActive() {
  let newSurvey = _.cloneDeep(defaultNewSurvey)
  const imageNum = Math.ceil(Math.random() * NUMBER_OF_DEFAULT_IMAGES)
  newSurvey.imgSrc = `https://env-prod-pulse-1.aws.collegepulse.com/default-survey-backgrounds/${imageNum}.jpg`
  return dispatch => {
    dispatch({
      type: ActionTypes.SET_ACTIVE_SURVEY,
      payload: newSurvey,
    })
    dispatch(setCurrentPage('questions', 'Create A New Survey'))
    dispatch(
      setEditPrivileges({
        viewOnly: false,
        reviewingForPublish: false,
      })
    )
  }
}

export function setActiveSurvey(survey, user, pageToOpenTo) {
  return dispatch => {
    const surveyId = typeof survey == 'string' ? survey : survey.id

    api.admin.one(surveyId).then(survey => {
      if (!survey) {
        return browserHistory.push('/create')
      }
      if (!survey.questions || !survey.questions.length) {
        survey.questions.push(defaultQuestions.noType)
      }
      survey.questions.forEach(q => {
        let nextOptionKey = 0
        if (!q.options || !q.options.length) {
          q.options.push({
            newOption: {
              key: nextOptionKey++,
              value: 0,
              text: '',
            },
          })
        }
      })
      dispatch({
        type: ActionTypes.SET_ACTIVE_SURVEY,
        payload: survey,
      })

      if (
        pageToOpenTo === 'questions' ||
        pageToOpenTo === 'targeting' ||
        pageToOpenTo === 'details' ||
        pageToOpenTo === 'review'
      ) {
        dispatch(setCurrentPage(pageToOpenTo, 'Create Your Survey'))
      } else {
        dispatch(setCurrentPage('questions', 'Create Your Survey'))
      }

      if (user) {
        const viewOnly = survey.submittedAt && !user.master
        const reviewingForPublish = survey.submittedAt && user.master
        dispatch(
          setEditPrivileges({
            viewOnly,
            reviewingForPublish,
          })
        )
      }
    })
  }
}

export function clearActiveSurvey() {
  return dispatch => {
    dispatch({
      type: ActionTypes.CLEAR_ACTIVE_SURVEY,
    })
  }
}
