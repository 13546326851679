import React, { useState, useEffect, useRef } from 'react'
import { Grid, Input, TextArea, Form, Dropdown } from 'semantic-ui-react'
import ReactFilepicker from 'react-filepicker'
import { Prompt } from 'react-router-dom'

// Redux
import { connect } from 'react-redux'
import {
  setActiveSurvey,
  setNewSurveyActive,
} from '../../actions/create-view-survey-actions/set-survey-actions'
import {
  initialSurveySave,
  saveSurvey,
} from '../../actions/create-view-survey-actions/save-survey-actions'
import {
  setImg,
  setTitle,
  setAuthor,
  setDescription,
  setType,
} from '../../actions/create-view-survey-actions/edit-survey-actions'

const MetadataView = ({
  params,
  user,
  survey,
  setActiveSurvey,
  setNewSurveyActive,
  initialSurveySave,
  saveSurvey,
  setTitle,
  setAuthor,
  setDescription,
  setImg,
  setType,
  match,
  changesMade,
  setChangesMade,
}) => {
  const bannerUploadKey = 'AUi37ssFTieo2GNZYg3Mwz'
  const bannerFilepickerOptions = {
    buttonText:
      survey && survey.imgSrc ? 'Change background image...' : 'Choose background image...',
    buttonClass: 'survey-manager-filepicker',
    mimetype: 'image/*',
    container: 'modal',
    services: ['COMPUTER', 'URL', 'FACEBOOK', 'GOOGLE_DRIVE', 'DROPBOX', 'CONVERT'],
    cropRatio: 2.5 / 1,
    cropForce: true,
  }
  const onImageUploaded = blob => {
    setChangesMade(true)
    const urlComponents = blob.url.split('/')
    const key = urlComponents[urlComponents.length - 1]
    const url = `https://cdn.filestackcontent.com/resize=height:465/compress/${key}`
    setImg(url)
  }

  // Reference trick to have access to the survey post-unmount
  let currentSurv = useRef()
  useEffect(() => {
    currentSurv.current = survey
  }, [survey])

  // Initialization
  useEffect(() => {
    setChangesMade(false)

    if (!survey.id) {
      if (match?.params?.surveyId) {
        setActiveSurvey(match.params.surveyId, user)
      } else {
        // Give the survey object default properties
        setNewSurveyActive()
      }
    }
  }, [])

  // Initialization with API
  const [initOnce, setInitOnce] = useState(false)
  useEffect(() => {
    if (survey) {
      // Check for no id, one time function, and that the default properties are applied.
      if (!survey.id && !initOnce && survey.questions[0]) {
        // Just a double check method to make sure this isn't called twice in-case of race.
        setInitOnce(true)

        // Send the current survey object up to api for the first time.
        initialSurveySave(survey, {})
      }
    }
  }, [survey])

  return (
    <Grid
      className="survey-manager-metadata-container"
      columns={3}
      divided
      style={{
        width: '100%',
        margin: 0,
        padding: 0,
        overflowY: 'visible',
        maxHeight: 'calc(100vh - 65px)',
      }}
    >
      <Prompt when={changesMade} message="Are you sure you wish to continue without saving?" />
      <Grid.Row>
        <Grid.Column width={5} style={{ padding: '1.5rem' }}>
          <h2 style={{ width: '100%', textAlign: 'left' }}>Preview Card</h2>
          <div className="survey-manager-metadata-preview-card">
            <div
              className="survey-manager-metadata-preview-card-header"
              style={{ backgroundImage: `url(${survey && survey.imgSrc ? survey.imgSrc : ''})` }}
            >
              <span className="smm-title">
                {survey && survey.title ? survey.title : 'Survey Title'}
              </span>
            </div>
            <div className="smm-preview-content">
              <div>{survey?.description || 'Survey description...'}</div>
              <div className="smm-author-container">
                <div className="smm-author-icon">
                  <img src={survey && survey.authorImg ? survey.authorImg : ''} />
                </div>
                <div>{survey?.authorName || 'Author Name'}</div>
              </div>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column width={11} style={{ padding: '1.5rem' }}>
          <div style={{ width: '100%', textAlign: 'left' }}>
            <h2 style={{ width: '100%', textAlign: 'left' }}>Basic Info</h2>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1, marginRight: '0.75rem' }}>
                <h5 style={{ marginBottom: '0.25rem' }}>Title</h5>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Title..."
                  value={survey?.title || ''}
                  onChange={v => {
                    setTitle(v.target.value)
                    setChangesMade(true)
                  }}
                />
              </div>
              <div style={{ flex: 1, marginLeft: '0.75rem' }}>
                <h5 style={{ marginBottom: '0.25rem' }}>Author</h5>
                <Input
                  style={{ width: '100%' }}
                  placeholder="Author..."
                  value={survey?.authorName || ''}
                  onChange={v => {
                    setAuthor(v.target.value)
                    setChangesMade(true)
                  }}
                />
              </div>
            </div>

            <div style={{ display: 'flex', marginTop: '1.5rem' }}>
              <div style={{ flex: 1, marginRight: '0.75rem' }}>
                <h5 style={{ marginBottom: '0.25rem' }}>Description</h5>
                <Form>
                  <TextArea
                    placeholder="Description for the survey..."
                    value={survey?.description || ''}
                    onChange={v => {
                      setDescription(v.target.value)
                      setChangesMade(true)
                    }}
                  />
                </Form>
              </div>
              <div style={{ flex: 1, marginLeft: '0.75rem' }}>
                <h5 style={{ marginBottom: '0.25rem' }}>Image</h5>
                <ReactFilepicker
                  apikey={bannerUploadKey}
                  defaultWidget={false}
                  mode="pick"
                  options={bannerFilepickerOptions}
                  onSuccess={onImageUploaded}
                />
              </div>
            </div>
          </div>

          <div style={{ width: '100%', textAlign: 'left', marginTop: '2rem' }}>
            <h2 style={{ width: '100%', textAlign: 'left' }}>Details</h2>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1, marginRight: '0.75rem' }}>
                <h5 style={{ marginBottom: '0.25rem' }}>Survey Category</h5>
                <Dropdown
                  selection
                  value={survey.surveyCategory}
                  placeholder="Category..."
                  onChange={(e, d) => {
                    setChangesMade(true)
                    setType(d.value)
                  }}
                  options={[
                    {
                      key: 'client',
                      text: 'Client',
                      value: 'client',
                    },
                    {
                      key: 'tracker',
                      text: 'Tracker',
                      value: 'tracker',
                    },
                    {
                      key: 'student',
                      text: 'Student',
                      value: 'student',
                    },
                    {
                      key: 'internal',
                      text: 'Internal',
                      value: 'internal',
                    },
                  ]}
                />
              </div>
            </div>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const mapStateToProps = state => ({
  user: state.user.user,
  survey: state.activeSurvey,
})

export default connect(mapStateToProps, {
  setActiveSurvey,
  setNewSurveyActive,
  initialSurveySave,
  saveSurvey,
  setTitle,
  setAuthor,
  setDescription,
  setImg,
  setType,
})(MetadataView)
