import React from 'react'
import { Select, Form, Divider, Radio, Popup, Image } from 'semantic-ui-react'
import _ from 'lodash'

// Logic for adding a filter:
// Maybe we render a Does and Does Not row for every option, and then just set it
// in the config if/when they set actual questions
export default class QuestionFiltering extends React.Component {
  constructor() {
    super()
    this.onChangeIndependentQuestion = this.onChangeIndependentQuestion.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }
  handleChange(e, { value }) {
    this.props.setOptionsDependency({
      _id: this.props.question._id,
      optionsDependency: {
        ...this.props.question.optionsDependency,
        inverse: value === 'hide',
      },
    })
  }
  onChangeIndependentQuestion(__, ev) {
    const independentQ = ev.value
    this.props.setOptionsDependency({
      _id: this.props.question._id,
      optionsDependency: {
        independentQ: independentQ,
        staticOptions: [],
        autoSelectIfLast: [],
        inverse: false,
        mirrorOrder: false,
      },
    })
  }
  configuration() {
    // Toggle: Inverse
    // Toggle: Mirror Order
    // Render all options with checkboxes for Auto-Select If Last, Static Option
    const { question } = this.props

    const { optionsDependency = {}, options } = question
    const { inverse, mirrorOrder, staticOptions, autoSelectIfLast } = optionsDependency
    return (
      <React.Fragment>
        <Form.Group inline>
          <Popup
            trigger={
              <Form.Field
                control={Radio}
                label="Hide selected options"
                value="hide"
                checked={inverse}
                onChange={this.handleChange}
              />
            }
          >
            <Popup.Header>Hide Selected Options</Popup.Header>
            <Popup.Content>
              <Image size="medium" src="/img/admin/hide-noauto.png" />
            </Popup.Content>
          </Popup>

          <Popup
            trigger={
              <Form.Field
                control={Radio}
                label="Show selected options"
                value="show"
                checked={!inverse}
                onChange={this.handleChange}
              />
            }
          >
            <Popup.Header>Show Selected Options</Popup.Header>
            <Popup.Content>
              <Image size="medium" src="/img/admin/show-noauto.png" />
            </Popup.Content>
          </Popup>
        </Form.Group>
        <Form.Group>
          <Form.Checkbox
            label="Mirror Order—Use same option order as previous question if both have randomized option order"
            checked={mirrorOrder}
            onChange={() =>
              this.props.setOptionsDependency({
                _id: this.props.question._id,
                optionsDependency: {
                  ...optionsDependency,
                  mirrorOrder: !mirrorOrder,
                },
              })
            }
          />
        </Form.Group>
        {options
          .filter(opt => opt.text)
          .map(option => (
            <React.Fragment>
              <Form.Group widths="equal">
                <Form.Field
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <label>{option.text}</label>
                </Form.Field>
                <Form.Field style={{ textAlign: 'left' }}>
                  <Popup
                    trigger={
                      <Form.Checkbox
                        label="Always Show"
                        checked={staticOptions.includes(option.value)}
                        onChange={() =>
                          this.props.setOptionsDependency({
                            _id: this.props.question._id,
                            optionsDependency: {
                              ...optionsDependency,
                              staticOptions: _.xor(staticOptions, [option.value]),
                            },
                          })
                        }
                      />
                    }
                  >
                    <Popup.Header>Always Show</Popup.Header>
                    <Popup.Content>
                      This checkbox will overide the "Hide / Show Selected Options" field and always
                      show this option regardless as to whether or not the option was selected on
                      the last question.
                    </Popup.Content>
                  </Popup>

                  <Popup
                    trigger={
                      <Form.Checkbox
                        label="Auto Select if Last Option Left"
                        checked={autoSelectIfLast.includes(option.value)}
                        onChange={() =>
                          this.props.setOptionsDependency({
                            _id: this.props.question._id,
                            optionsDependency: {
                              ...optionsDependency,
                              autoSelectIfLast: _.xor(autoSelectIfLast, [option.value]),
                            },
                          })
                        }
                      />
                    }
                  >
                    <Popup.Header>Auto Select Last Option</Popup.Header>
                    <Popup.Content>
                      <div>
                        This option will allow the selected option to be auto selected if there are
                        no other possible options.
                      </div>
                      <Image
                        size="medium"
                        src={`/img/admin/${inverse ? 'hide' : 'show'}-auto.png`}
                      />
                    </Popup.Content>
                  </Popup>
                </Form.Field>
              </Form.Group>
              <Divider />
            </React.Fragment>
          ))}
      </React.Fragment>
    )
  }
  render() {
    // Question i's options depend on <Select />
    // Question i will show only options (not) selected in question j ('description')

    // Toggle: Inverse
    // Toggle: Mirror Order
    // Render all options with checkboxes for Auto-Select If Last, Static Option

    const { index, question, questionsBefore } = this.props

    const { optionsDependency = {} } = question
    const { independentQ, inverse } = optionsDependency

    return (
      <Form>
        <Form.Group>
          <h2>Question {index + 1}'s options depend on</h2>
          <Select
            style={{ marginLeft: '1em', flex: 1 }}
            options={[{ key: 'Null', value: null, text: 'None' }].concat(
              questionsBefore.slice().map((q, i) => ({
                key: q._id,
                value: q._id,
                text: `Q${i + 1}: ${q.description}`,
              }))
            )}
            onChange={this.onChangeIndependentQuestion}
            value={independentQ}
          />
        </Form.Group>
        {independentQ && this.configuration()}
      </Form>
    )
  }
}
