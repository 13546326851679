import React from 'react'

import SurveyManagerHeader from './survey-manager-header'

export default ({ children, location, setChangesMade, changesMade }) => {
  return (
    <div className="admin-edit-view">
      <SurveyManagerHeader
        setChangesMade={setChangesMade}
        changesMade={changesMade}
        location={location}
      />

      <div className={`admin-edit-main scrollable`}>{children}</div>
    </div>
  )
}
