import React from 'react'

export default class Avatar extends React.Component {
  constructor() {
    super()
    this.state = {
      width: 100,
    }
  }

  componentDidMount() {
    this.setState({
      width: this.refs.container.offsetHeight,
    })
  }

  render() {
    return (
      <div className="avatar-container" style={{ width: `${this.state.width}px` }} ref="container">
        <div>
          <img
            src={this.props.src}
            draggable={false}
            style={{
              position: 'relative',
              right: '5px',
              height: '50px',
              width: '50px',
              borderRadius: '25px',
            }}
          />
        </div>
      </div>
    )
  }
}
