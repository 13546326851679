import React from 'react'
import { connect } from 'react-redux'
import Hammer from 'react-hammerjs'
import _ from 'lodash'
import InputBox from '../../../../../../../common/input-box/input-box'
import { editPropertyForQuestionAtIndex } from '../../../../../../actions/create-view-survey-actions/edit-survey-questions-actions'

const MAX_MIN_ERROR = 'Maximum and minimum must be whole numbers.'
const RANGE_ERROR = 'Minimum must be less than the maximum.'

class NumericSettingsPanel extends React.Component {
  constructor(props) {
    super(props)

    const boundMethods = [
      'onMinChange',
      'onMaxChange',
      'onLabelChange',
      'toggleRange',
      'toggleRangeFromInput',
      'toggleDecimals',
      'toggleLabel',
      'toggleLabelFromInput',
      'getNumericValue',
      'toggleSlider',
      'changeSliderLeftLabel',
      'changeSliderRightLabel',
      'editRangeInc',
      'toggleIsPercentageSlider',
      'onPrefixUnitLabelChange',
    ]

    boundMethods.forEach(m => {
      this[m] = this[m].bind(this)
    })
  }

  componentWillMount() {
    const question = this.props.questions[this.props.currentQuestionIndex]
    const hasMaxOrMin = !_.isNil(question.maximum) || !_.isNil(question.minimum)
    this.setState({
      rangeSelected: hasMaxOrMin,
      decimalsSelected: question.rangeInc !== 1,
      labelSelected: !_.isNil(question.unitLabel),
    })
  }

  getNumericValue(val) {
    let minVal
    if (val === '' || isNaN(parseInt(val))) {
      minVal = null
    } else if (parseFloat(val, 10) % 1 === 0) {
      minVal = parseInt(val, 10)
    } else {
      minVal = parseFloat(val, 10)
    }

    return minVal
  }

  onMinChange(ev) {
    const minVal = this.getNumericValue(ev.target.value)
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'minimum',
      value: minVal,
    })
  }

  onMaxChange(ev) {
    const maxVal = this.getNumericValue(ev.target.value)
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'maximum',
      value: maxVal,
    })
  }

  onLabelChange(ev) {
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'unitLabel',
      value: ev.target.value,
    })
  }

  onPrefixUnitLabelChange(ev) {
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'prefixUnitLabel',
      value: ev.target.value,
    })
  }

  toggleRange() {
    if (this.state.rangeSelected) {
      this.props.editPropertyForQuestionAtIndex({
        index: this.props.currentQuestionIndex,
        propertyName: 'minimum',
        value: null,
      })
      this.props.editPropertyForQuestionAtIndex({
        index: this.props.currentQuestionIndex,
        propertyName: 'maximum',
        value: null,
      })
    }

    this.setState({
      rangeSelected: !this.state.rangeSelected,
    })
  }

  toggleRangeFromInput() {
    if (!this.state.rangeSelected) {
      this.setState({
        rangeSelected: true,
      })
    }
  }

  toggleSlider() {
    const question = this.props.questions[this.props.currentQuestionIndex]
    const { numericSliderConfig = {} } = question
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'numericSliderConfig',
      value: {
        ...numericSliderConfig,
        isSlider: !numericSliderConfig.isSlider,
      },
    })
  }

  changeSliderLeftLabel(ev) {
    const question = this.props.questions[this.props.currentQuestionIndex]
    const { numericSliderConfig = {} } = question
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'numericSliderConfig',
      value: {
        ...numericSliderConfig,
        leftLabel: ev.target.value,
      },
    })
  }

  toggleIsPercentageSlider() {
    const question = this.props.questions[this.props.currentQuestionIndex]
    const { numericSliderConfig = {} } = question
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'numericSliderConfig',
      value: {
        ...numericSliderConfig,
        percentageSlider: !numericSliderConfig.percentageSlider,
      },
    })
  }

  changeSliderRightLabel(ev) {
    const question = this.props.questions[this.props.currentQuestionIndex]
    const { numericSliderConfig = {} } = question
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'numericSliderConfig',
      value: {
        ...numericSliderConfig,
        rightLabel: ev.target.value,
      },
    })
  }

  editRangeInc(ev) {
    this.props.editPropertyForQuestionAtIndex({
      index: this.props.currentQuestionIndex,
      propertyName: 'rangeInc',
      value: ev.target.value,
    })
  }
  toggleDecimals() {
    if (this.state.decimalsSelected) {
      this.props.editPropertyForQuestionAtIndex({
        index: this.props.currentQuestionIndex,
        propertyName: 'rangeInc',
        value: 1,
      })
    } else {
      this.props.editPropertyForQuestionAtIndex({
        index: this.props.currentQuestionIndex,
        propertyName: 'rangeInc',
        value: null,
      })
    }

    this.setState({
      decimalsSelected: !this.state.decimalsSelected,
    })
  }

  toggleLabel() {
    if (this.state.labelSelected) {
      this.props.editPropertyForQuestionAtIndex({
        index: this.props.currentQuestionIndex,
        propertyName: 'unitLabel',
        value: null,
      })
    }

    this.setState({
      labelSelected: !this.state.labelSelected,
    })
  }

  toggleLabelFromInput() {
    if (!this.state.labelSelected) {
      this.setState({
        labelSelected: true,
      })
    }
  }

  render() {
    const question = this.props.questions[this.props.currentQuestionIndex]
    const { numericSliderConfig = {} } = question
    const hasMin = !_.isNil(question.minimum)
    const hasMax = !_.isNil(question.maximum)
    let maxMinError, rangeError, minBoxClass, maxBoxClass
    if (!this.state.decimalsSelected) {
      if (hasMin && question.minimum % 1 !== 0) {
        maxMinError = <div className="clarification red">{MAX_MIN_ERROR}</div>
        minBoxClass = 'red'
      }
      if (hasMax && question.maximum % 1 !== 0) {
        maxMinError = <div className="clarification red">{MAX_MIN_ERROR}</div>
        maxBoxClass = 'red'
      }
    }

    if (hasMax && hasMin && question.minimum >= question.maximum) {
      rangeError = <div className="clarification red">{RANGE_ERROR}</div>
      maxBoxClass = 'red'
      minBoxClass = 'red'
    }
    return (
      <div className="edit-panel">
        <span className="edit-panel-title">QUESTION OPTIONS</span>

        <Hammer onTap={this.toggleSlider}>
          <div className="edit-panel-option">
            <InputBox
              name="decimals"
              color="questionHeaderColor"
              backgroundColor="#FFFFFF"
              selected={(numericSliderConfig || {}).isSlider}
              key="decimals"
            />
            <div className="edit-panel-option-label">Slider Q</div>
          </div>
        </Hammer>
        {(numericSliderConfig || {}).isSlider && (
          <div className="edit-panel-option">
            <div className="edit-panel-option-label">
              <span>Left Slider Label</span>
              <input
                onChange={this.changeSliderLeftLabel}
                value={numericSliderConfig.leftLabel}
                className={minBoxClass}
                id="minimum-input-box"
              />
            </div>
          </div>
        )}
        {(numericSliderConfig || {}).isSlider && (
          <div className="edit-panel-option">
            <div className="edit-panel-option-label">
              <span>Right Slider Label</span>
              <input
                onChange={this.changeSliderRightLabel}
                value={numericSliderConfig.rightLabel}
                className={maxBoxClass}
                id="maximum-input-box"
              />
            </div>
          </div>
        )}
        {(numericSliderConfig || {}).isSlider && (
          <Hammer onTap={this.toggleIsPercentageSlider}>
            <div className="edit-panel-option">
              <div className="edit-panel-option-label">
                <InputBox
                  name="decimals"
                  color="questionHeaderColor"
                  backgroundColor="#FFFFFF"
                  selected={numericSliderConfig.percentageSlider}
                  key="percentageSlider"
                />
              </div>
              <div className="edit-panel-option-label">Is Percentage Slider</div>
            </div>
          </Hammer>
        )}
        <Hammer onTap={this.props.toggleFixed}>
          <div className="edit-panel-option">
            <InputBox
              name="decimals"
              color="questionHeaderColor"
              backgroundColor="#FFFFFF"
              selected={this.props.fixed}
              key="decimals"
            />
            <div className="edit-panel-option-label">Fix Order Position</div>
          </div>
        </Hammer>
        <Hammer onTap={this.props.toggleIsScreenerQ}>
          <div className="edit-panel-option">
            <InputBox
              name="isScreenerQ"
              color="questionHeaderColor"
              backgroundColor="#FFFFFF"
              selected={question.isScreenerQ}
              key="isScreenerQ"
            />
            <div className="edit-panel-option-label">Screener Question?</div>
          </div>
        </Hammer>
        <Hammer onTap={this.props.toggleRequired}>
          <div className="edit-panel-option">
            <InputBox
              name="isRequired"
              color="questionHeaderColor"
              backgroundColor="#FFFFFF"
              selected={question.required}
              key="isRequired"
            />
            <div className="edit-panel-option-label">Require Answer</div>
          </div>
        </Hammer>
        <Hammer onTap={this.props.toggleHideQuestionFromDE}>
          <div className="edit-panel-option">
            <InputBox
              name="hideQuestionFromDE"
              color="questionHeaderColor"
              backgroundColor="#FFFFFF"
              selected={question.hideQuestionFromDE}
              key="hideQuestionFromDE"
            />
            <div className="edit-panel-option-label">Hide Question From DE?</div>
          </div>
        </Hammer>
        <div className="edit-panel-option">
          <div onClick={this.toggleRange}>
            <InputBox
              name="range"
              color="questionHeaderColor"
              backgroundColor="#FFFFFF"
              selected={this.state.rangeSelected}
              key="range"
            />
          </div>
          <div className="edit-panel-option-label">
            <span>Range of Values:</span>
            <input
              type="number"
              onChange={this.onMinChange}
              value={question.minimum}
              onClick={this.toggleRangeFromInput}
              className={minBoxClass}
              id="minimum-input-box"
            />
            <span>to</span>
            <input
              type="number"
              onChange={this.onMaxChange}
              value={question.maximum}
              onClick={this.toggleRangeFromInput}
              className={maxBoxClass}
              id="maximum-input-box"
            />
          </div>
        </div>
        {maxMinError}
        {rangeError}
        <div className="clarification">(Leave empty if not required.)</div>
        <div className="edit-panel-option">
          <InputBox
            name="label"
            color="questionHeaderColor"
            backgroundColor="#FFFFFF"
            selected={true}
            key="label"
          />
          <div className="edit-panel-option-label">
            <span>Enforce Increment</span>
            <input
              type="number"
              onChange={this.editRangeInc}
              value={question.rangeInc}
              className={maxBoxClass}
            />
          </div>
        </div>
        <div className="edit-panel-option">
          <div onClick={this.toggleLabel}>
            <InputBox
              name="label"
              color="questionHeaderColor"
              backgroundColor="#FFFFFF"
              selected={this.state.labelSelected}
              key="label"
            />
          </div>
          <div className="edit-panel-option-label">
            <span>Unit Label:</span>
            <input
              type="text"
              onChange={this.onLabelChange}
              value={question.unitLabel}
              onClick={this.toggleLabelFromInput}
            />
          </div>
        </div>
        <div className="edit-panel-option">
          <InputBox
            name="label"
            color="questionHeaderColor"
            backgroundColor="#FFFFFF"
            selected={true}
            key="prefixUnitLabel"
          />
          <div className="edit-panel-option-label">
            <span>Prefix Unit Label:</span>
            <input
              type="text"
              onChange={this.onPrefixUnitLabelChange}
              value={question.prefixUnitLabel}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    questions: state.activeSurvey.questions,
    currentQuestionIndex: state.cvQuestionsBuilder.currentQuestionIndex,
  }
}

export default connect(mapStateToProps, { editPropertyForQuestionAtIndex })(NumericSettingsPanel)
