import FileSaver from 'file-saver'
import request from './request'
import prefix from './prefix'
import storage from '../lib/storage'
import _ from 'lodash'

exports.downloadCSVLink = questionId =>
  `${prefix}/api/question/csv/${questionId}?token=${storage.get('token')}`

exports.fetchQuestion = function (questionId) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/question/${questionId}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.searchQuestions = function (searchTerm) {
  return new Promise((resolve, reject) => {
    request.get(prefix + `/api/search/question?searchQuery=${searchTerm}`).end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}

exports.fetchAggChiSq = function ({ targetQuestion, questionIds, triggerNew }) {
  return new Promise((resolve, reject) => {
    request
      .get(
        prefix +
          `/api/chi2/json/${targetQuestion}/${
            Array.isArray(questionIds) ? questionIds.join(',') : questionIds
          }/?${triggerNew ? 'triggerNew=true' : ''}`
      )
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.contingencyTable = function (targetQuestion, featureQuestion, shouldWeight) {
  return new Promise((resolve, reject) => {
    request
      .get(prefix + `/api/chi2/jsoncontingency/${targetQuestion}/${featureQuestion}`)
      .query({ shouldWeight })
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        return resolve(res.body)
      })
  })
}

exports.downloadChi2 = (questionId, agg, questionDescription, featureQuestions) => {
  return new Promise(resolve => {
    request
      .get(
        `${prefix}/api/chi2/download/${
          agg ? 'aggFile' : 'indFile'
        }/${questionId}/${featureQuestions}`
      )
      .end((err, res) => {
        if (!res) {
          return resolve()
        }
        const relateTo = agg ? 'Questions' : 'AnswerOptions'
        const filename = `${_.snakeCase(questionDescription.slice(0, 20))}_Relate_${relateTo}`

        let blob = new Blob([res.text], { type: 'text/csv' })
        FileSaver.saveAs(blob, `${filename}.csv`)
        resolve()
      })
  })
}

exports.transformContingency = (breakdown, questionDescription, category) => {
  return new Promise(resolve => {
    request
      .post(`${prefix}/api/question/contingency/transformJSON`)
      .send(breakdown)
      .end((err, res) => {
        if (!res || !res.body || !res.body.csv) {
          return resolve()
        }
        const filename = `${_.snakeCase(questionDescription.slice(0, 20))}_${category}`
        let blob = new Blob([res.body.csv], { type: 'text/csv' })
        FileSaver.saveAs(blob, `${filename}.csv`)
        resolve()
      })
  })
}

exports.getSchoolOptions = () => {
  return new Promise((resolve, reject) => {
    request.get(prefix + '/api/panel/filters/options/school').end((err, res) => {
      if (err) return reject(err)
      if (res.body.error) return reject(res.body.error)

      return resolve(res.body)
    })
  })
}
