import request from 'request-promise'

/*
 * metadata: fetch survey metadata
 * in accordance with search query
 */
exports.demoRequest = function (body) {
  var options = {
    method: 'POST',
    uri: 'https://hooks.zapier.com/hooks/catch/4979893/jlgba8/',
    form: body,
  }
  return request(options)
}
