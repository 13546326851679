import React from 'react'
import Modal from 'react-modal'
import Hammer from 'react-hammerjs'

import modalStyle from './modalStyle'

export default class CloseAndSaveModal extends React.Component {
  render() {
    return (
      <Modal isOpen={this.props.isOpen} style={modalStyle} contentLabel="Close And Save Modal">
        <Hammer onTap={this.props.onCancelExit}>
          <div className="cancel-exit-button">
            <img src="/img/close-white.svg" />
          </div>
        </Hammer>
        <span className="modal-text">Would you like to save or discard your changes?</span>
        <div className="modal-buttons-container">
          <Hammer onTap={this.props.discardAndClose}>
            <div className="discard modal-button">
              <div>Discard</div>
            </div>
          </Hammer>
          <Hammer onTap={this.props.saveAndClose}>
            <div className="save modal-button">
              <div>Save</div>
            </div>
          </Hammer>
        </div>
      </Modal>
    )
  }
}
