import request from './request'
import storage from '../lib/storage'
import prefix from './prefix'

import { saveNewToken, isSignedIn } from './signin'

exports.externalverify = function (email, verificationKey) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/external-verify')
      .send({ email: email, verificationCode: verificationKey })
      .end(async (err, res) => {
        if (res && res.body && res.body.error) return reject(res.body.error)
        if (err) return reject(err)

        await storage.set('user', res.body.user)
        await saveNewToken(res.body.token)

        return resolve(res)
      })
  })
}
