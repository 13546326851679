import request from './request'
import storage from '../lib/storage'
import prefix from './prefix'

exports.report = function (bugObject) {
  return new Promise((resolve, reject) => {
    request
      .post(prefix + '/api/bugs')
      .send(bugObject)
      .end((err, res) => {
        if (err) return reject(err)
        if (res.body.error) return reject(res.body.error)

        storage.set('user', res.body.user)
        return resolve(res.body.user)
      })
  })
}
