import React from 'react'
import Modal from 'react-modal'
import Hammer from 'react-hammerjs'
import modalStyle from './modalStyle'

export default class EnterTitleModal extends React.Component {
  constructor() {
    super()
    this.state = {
      title: '',
    }
    this.onTitleChange = this.onTitleChange.bind(this)
    this.setTitleAndSave = this.setTitleAndSave.bind(this)
  }
  onTitleChange(ev) {
    this.setState({
      title: ev.target.value,
    })
  }
  setTitleAndSave() {
    this.props.setTitleAndSave(this.state.title)
  }
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        style={modalStyle}
        contentLabel="enter title before saving modal"
      >
        <Hammer onTap={this.props.onCancel}>
          <div className="cancel-exit-button">
            <img src="/img/close-white.svg" />
          </div>
        </Hammer>
        <span className="modal-text">Please Give Your Survey A Title Before Saving</span>
        <input
          className="modal-input"
          type="text"
          value={this.state.title}
          onChange={this.onTitleChange}
          placeholder="title"
        />
        <div className="modal-buttons-container">
          <Hammer onTap={this.props.onCancel}>
            <div className="discard modal-button">
              <div>Cancel</div>
            </div>
          </Hammer>
          <Hammer onTap={this.setTitleAndSave}>
            <div className="save modal-button">
              <div>Save</div>
            </div>
          </Hammer>
        </div>
      </Modal>
    )
  }
}
