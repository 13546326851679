import prefix from './prefix'
import request from './request'
import storage from '../lib/storage'

// get the data required for desktop results sharing
exports.getSharelinkAndPreview = function (surveyId, questionId, answerId, category) {
  return new Promise((resolve, reject) => {
    let data = `/api/share/${surveyId}/${questionId}`
    data += `?answerId=${answerId}`
    data += `&category=${category}`
    request.get(prefix + data).end((err, res) => {
      if (err) {
        return reject(err)
      }
      return resolve(res.body)
    })
  })
}
