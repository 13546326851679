import React from 'react'
import AdminNavbar from './admin-navbar/admin-navbar'
import api from '../../api'

import { connect } from 'react-redux'
import { resetUser } from '../actions/user-actions'

class Admin extends React.Component {
  componentWillMount() {
    this.props.resetUser()
    const loggedIn = api.signin.isSignedIn()

    this.props.resetUser()
  }

  render() {
    if (!this.props.user) {
      return <div />
    }

    const hideNavBar = this.props.route && this.props.route.hideNavBar
    return (
      <div className="admin-view">
        {!hideNavBar && <AdminNavbar />}
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user.user,
})

export default connect(mapStateToProps, {
  resetUser,
})(Admin)
